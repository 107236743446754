import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import contactReducer from './contactReducer';
import sysReducer from './sysReducer';
import financeReducer from './financeReducer';
import navbarReducer from './navbarReducer'; 
import affiliationReducer from './affiliationReducer';
import libraryReducer from './libraryReducer';

export default combineReducers({        
    loginReducer,
    contactReducer,
    financeReducer,
    sysReducer,
    navbarReducer,
    affiliationReducer,
    libraryReducer
});
