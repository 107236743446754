import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import InvoiceForm from './InvoiceForm';
import { updateBillingPlan } from '../../../hooks/store/actions/financeActions';
var currentDate = new Date();
var newDate = new Date(currentDate.setMonth(currentDate.getMonth() + 2));
newDate = new Date(newDate.setDate(1));

class ExtendBillingPlan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mnth: "",
            year: newDate.getFullYear(),
            month: newDate.getMonth(),
            ongoing: false
        };
    }

    save = () => {
        let newBp = this.props.bp
        let newBpDate = new Date(this.state.year, this.state.month).toISOString()
        newBp.lastFullBill = newBpDate
        this.props.closeBillingPlanModal(newBp);
        // this.props.save();  // update in DB        
        
    }

    onDateChange = (date) => {
        //console.log(date);
        this.setState({ month: Number(date)+1 });
    }

    mapYearSelects = () => {
        const years = [];

        for (let i = this.state.year; i < 2100; i++) {
            years.push(i);
        }
        let yearSelects = years.map((year) => {
            return <option key={year} value={year}>{year}</option>
        }
        );

        return yearSelects;
    }

    handleCheckboxClick = (e) => {
        this.setState({
            ongoing: e.target.checked, 
            year: (e.target.checked) ? "2099" : newDate.getFullYear()
        })
    }

    onMonthChange = (e) => {
        this.setState({month: e.target.value })
    }

    onYearChange = (e) => {
        this.setState({year: e.target.value})
    }

    componentDidMount() {
    }

    render() {

        return (
            <Modal.Body className="full-screenModal pauseBillingPlanModal pauseBillingPlanModalContents">
                <div>
                    <Form.Group as={Row} style={{ justifyContent: "center", textAlign: 'center' }}>
                        <Form.Label className="pauseBillingPlanTitle">
                            <div style={{ fontSize: '24px', fontWeight: '700', fontFamily: 'open sans' }}>EXTEND LAST BILL ISSUED<br />UNTIL</div>
                    </Form.Label>
                    </Form.Group>


                <Form.Group as={Row} style={{ justifyContent: "center", alignItems: 'center' }}>
                    <Form.Label className="pauseBillingPlanTitle" style={{ marginBottom: 0 }}>
                        Month
                    </Form.Label>
                    &nbsp;&nbsp;&nbsp;
                    <div>
                        <select className="pauseBillingPlanSelect" disabled={this.state.ongoing} onChange={this.onMonthChange} value={this.state.month}>
                            <option key={"January"} value={0}>January</option>
                            <option key={"February"} value={1}>February</option>
                            <option key={"March"} value={2}>March</option>
                            <option key={"April"} value={3}>April</option>
                            <option key={"May"} value={4}>May</option>
                            <option key={"June"} value={5}>June</option>
                            <option key={"July"} value={6}>July</option>
                            <option key={"August"} value={7}>August</option>
                            <option key={"September"} value={8}>September</option>
                            <option key={"October"} value={9}>October</option>
                            <option key={"November"} value={10}>November</option>
                            <option key={"December"} value={11}>December</option>
                        </select>
                    </div>
                </Form.Group>
                <Form.Group as={Row} style={{ justifyContent: "center", alignItems: 'center' }}>
                    <Form.Label className="pauseBillingPlanTitle" style={{ marginBottom: 0 }}>
                        Year
                    </Form.Label>
                    &nbsp;&nbsp;&nbsp;
                    <div>
                        <select className="pauseBillingPlanSelect" disabled={this.state.ongoing} value={this.state.year} onChange={this.onYearChange}>
                            {this.mapYearSelects()}
                        </select>
                    </div>
                </Form.Group>
                {this.props.showInvoiceForm && (
                    <InvoiceForm invoice={this.props.invoice} hideButtons={true} />
                )}
                <Form.Group as={Row} style={{ justifyContent: "center", fontSize: '14px' }}>
                    <Form.Check onClick={this.handleCheckboxClick} type="checkbox" label="Ongoing" checked={this.state.ongoing}/>
                    </Form.Group>

                    <div><br /><br /><br /></div>

                <Form.Group as={Row} style={{ justifyContent: "center", marginBottom: '0px' }}>
                    <Button className="button-gridrow clientDetailsBtn" onClick={() => (this.save(this.props.bp))} href="#">EXTEND BILLING PLAN</Button>
                    &nbsp;&nbsp;
                </Form.Group>
                </div>
            </Modal.Body>
        );
    }

}

function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user
    }
}

export default connect(mapStateToProps, {updateBillingPlan})(ExtendBillingPlan);
