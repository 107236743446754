import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { selectContact } from '../../../hooks/store/actions/contactActions.js';
import { getBillingPlans } from '../../../hooks/store/actions/financeActions.js';
import { sleep } from '../../../hooks/store/actionConsts';
import { showClientDetails } from '../../../hooks/store/actions/financeActions';
import { impersonateUser } from '../../../hooks/store/actions/loginActions';

const monthNames = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.",
    "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."
];

class SearchContactRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.viewClientDetails = this.viewClientDetails.bind(this);
    }

    viewClientDetails(e, contact) {
        e.stopPropagation();
        this.refreshContactDetails(contact);
    }

    impersonate(e, contact) {
        e.stopPropagation();
        this.props.impersonateUser(this.props.logintoken, contact.affiliateID);
        sleep(500).then(() => {
            document.location = "/action";
        })
    }

    refreshContactDetails(contact) {
        this.props.selectContact(contact);
        sleep(50).then(() => {
            document.location = "/clientMgmt/autoload";
        })
    }

    editContact(contact) {
        this.props.selectContact(contact);
        sleep(50).then(() => {
            document.location = "/action";
        })
    }

    getClientStatus(c) {
        return c.clientStatus;
    }

    formatDate(d) {
        if (d && d.length >= 10) {
            var m = Number(d.substring(5, 7));
            return monthNames[m - 1] + " " + d.substring(8, 10) + ", " + d.substring(0, 4);
        }
        else
            return "";
    }

    render() {
        return (
            this.props.contacts.map(
                (j, r) =>

                    <Row onClick={() => (this.editContact(j))} className={r % 2 === 0 ? "grid-row row-white searchRowHighlight" : "grid-row row-gray searchRowHighlight"} key={j.contactID}>
                        <Col className="searchGridColumn" sm={this.props.sysUser.userRole > 2 ? 1 : 2} xs={this.props.sysUser.userRole > 2 ? 1 : 2}><span className="graphTextFront">{this.getClientStatus(j)}</span><div className="borderMarkerCol"></div></Col>
                        {(this.props.sysUser.userRole > 2) &&
                            (<Col className="searchGridColumn" sm={1} xs={1}><span className="graphTextFront">{j.affiliateName}</span><div className="borderMarkerCol"></div></Col>)
                        }
                        <Col className="searchGridColumn" sm={this.props.sysUser.userRole > 2 ? 1 : 2} xs={this.props.sysUser.userRole > 2 ? 1 : 2}><span className="graphTextFront">{j.firstName}</span><div className="borderMarkerCol"></div></Col>
                        <Col className="searchGridColumn" sm={this.props.sysUser.userRole > 2 ? 1 : 2} xs={this.props.sysUser.userRole > 2 ? 1 : 2}><span className="graphTextFront">{j.lastName}</span><div className="borderMarkerCol"></div></Col>
                        <Col className="searchGridColumn" sm={this.props.sysUser.userRole > 2 ? 1 : 1} xs={this.props.sysUser.userRole > 2 ? 1 : 1}><span className="graphTextFront">{j.mobilePhone}</span><div className="borderMarkerCol"></div></Col>
                        <Col className="searchGridColumn" sm={this.props.sysUser.userRole > 2 ? 3 : 2} xs={this.props.sysUser.userRole > 2 ? 3 : 2}><span className="graphTextFront">{j.email}</span><div className="borderMarkerCol"></div></Col>
                        <Col className="searchGridColumn" sm={1} xs={1}><span className="graphTextFront">{j.contactType.name}</span></Col>
                        {/* <Col className="searchGridColumn" sm={1} xs={1}>{j.paymentStatus}</Col>
                        <Col className="searchGridColumn" sm={1} xs={1}>{this.formatDate(j.expirationDate)}</Col> */}
                        <Col style={{ display: 'flex', justifyContent: 'flex-end' }} className="searchGridColumn padr5imp" sm={1} xs={1}><Button className="button-gridrow clientDetailsBtn margin3Button" onClick={() => (this.editContact(j))} href="#">SELECT</Button></Col>
                        
                        {this.props.sysUser.userRole > 2 && (
                            <Col className="searchGridColumn clientDetailsSearchCol" sm={1} xs={1}>
                                <Button className="button-gridrow searchResultsImpersonateButton margin3Button" onClick={(e) => this.impersonate(e, j)} href="#">IMPERSONATE</Button>
                            </Col>
                        )}
                        {(this.props.sysUser.userRole > 2 || (this.props.sysUser.userRole == 2 && this.getClientStatus(j) != '' && this.getClientStatus(j).toUpperCase() != 'NONE')) && (
                            <Col style={{paddingRight: '6px'}} className="searchGridColumn clientDetailsSearchCol" sm={1} xs={1}>
                                <Button id="clientDetailsSearchButton" className="button-gridrow clientDetailsBtn margin3Button" onClick={(e) => this.viewClientDetails(e, j)} href="#">CLIENT DETAILS</Button>
                            </Col>
                        )}
                    </Row>
            )
        );
    }
}

function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        sysUser: state.loginReducer.sysUser
    }
}

export default connect(mapStateToProps, { selectContact, getBillingPlans, showClientDetails, impersonateUser })(SearchContactRow);
