import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { getSystemNotifications, updateSystemNotification, deleteSystemNotification } from '../../../hooks/store/actions/sysActions.js';
import { monthNames } from '../../../hooks/store/actionConsts.js';

class SystemNotificationRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {            
            editMode: false,
            editModeRows: [],
        };

    }

    handleChange = (e) => {
        this.setState({ ta: e.target.value });
    }

    deleteSysNotification(sysNotification) {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

        swal({
            title: "Delete System Notification Message", text: "Are you sure you want to Delete this System Notification Message?", icon: "warning",
            buttons:
            {
                no: {
                    text: "No",
                    value: "no"
                },
                yes: {
                    text: "Yes",
                    value: "yes"
                }
            }
        }).then((value) => {
            switch (value) {
                case "yes":
                    this.props.deleteSystemNotification(this.props.token, sysNotification.notificationId)
                    this.setState({editModeRows: []})
                    break;
                case "no":
                    break;
                default:
                    break;
            }
        });
    }

    toggleEditMode = (sysNotification) => {

        this.setState({ ta: sysNotification.notificationText })

        var editModeRow = null;
        var arrNew = [];
        this.props.systemNoficationsList.forEach((item, index) => {
            let obj = {}; 
            obj.notificationId = item.notificationId;
            obj.editMode = false;
            arrNew.push(obj)
        });

        arrNew.forEach((item, index) => {
                console.log(item.notificationId)
                if(item.notificationId === sysNotification.notificationId){ 
                    item.editMode = !item.editMode;
                    editModeRow = item.editMode
                 }
             });
        if(editModeRow != null){
            //alert(editModeRow)
            this.setState({editModeRows: arrNew})
        }
        
        //this.setState({editMode: !this.state.editMode});
    }

    editSysNotification(sysNotification) {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        sysNotification.notificationText = this.state.ta

        this.props.updateSystemNotification(this.props.token, sysNotification)
        this.setState({editModeRows: []})
    }

    formatDate(d) {
        if (!d || d == null || d === undefined || d == '') {
            return 'none'
        }

        if (d && d.length >= 10) {
            var m = Number(d.substring(5, 7));
            if (d.substring(0, 4) == '2099' || d.substring(0, 4) == '0001') {
                return 'Ongoing'
            }
            else {
            return monthNames[m-1] + " " + d.substring(8, 10) + ", " + d.substring(0, 4);
            }
        }
        else
            return "";
    }

    formatDate2(d) {
        if (d && d.length >= 10) {
            var m = Number(d.substring(5, 7));
            if (d.substring(0, 4) == '2099') {
                return 'Ongoing'
            }
            else {
                return d.substring(5, 7) + "/" + d.substring(8, 10) + "/" + d.substring(0, 4) + ' - ' + d.substring(11, d.length - 8);
            }
        }
        else
            return "";
    }

    componentDidMount() {
        this.props.getSystemNotifications(this.props.token)
    }

    resetEditModeRows = () => {
        var arr = [];
        this.props.systemNoficationsList.forEach((item, index) => {
            let obj = {}; 
            obj.notificationId = item.notificationId;
            obj.editMode = false;
            arr.push(obj)
        });
        //console.log('arr=', arr)
        this.setState({editModeRows: arr});
        //console.log('this.state.editModeRows=', this.state.editModeRows)
    }

        setEditModeRows = () => {
            if( (this.state.editModeRows.length === 0 && this.props.systemNoficationsList && this.props.systemNoficationsList.length > 0) || (this.props.systemNoficationsList && (this.state.editModeRows.length !== this.props.systemNoficationsList.length))) {
                var arr = [];
                this.props.systemNoficationsList.forEach((item, index) => {
                    let obj = {}; 
                    obj.notificationId = item.notificationId;
                    obj.editMode = false;
                    arr.push(obj)
                 });
                //console.log('arr=', arr)
                this.setState({editModeRows: arr});
            }
            //console.log('this.state.editModeRows=', this.state.editModeRows)
    }

    render() {

        this.setEditModeRows();
        
        return (
            this.props.systemNoficationsList.map(
                (j, r) =>
            
                    <Row className={r % 2 === 0 ? "grid-row row-white sysNotificationHeader3" : "grid-row row-gray sysNotificationHeader3"} key={j.sysNotificationID}>
                        <Col style={{ display: 'flex', alignItems: 'center', zIndex: '0', overflow: 'hidden' }} xs={2} sm={2}>
                            <span className="graphTextFront">{this.formatDate2(j.lastUpdatedDateUTC)}</span>
                            <div style={{ position: 'absolute', width: '100%', right: '0', height: '78%', borderRight: '1px solid black' }}></div>
                        </Col>
                        <Col style={{ display: 'flex', alignItems: 'center', zIndex: '0', overflow: 'hidden' }} xs={4} sm={7}>
                            {this.state.editModeRows[r] && this.state.editModeRows[r].editMode === true && (
                                <textarea id={'ta_' + r} onBlur={this.handleChange} style={{height: '105px'}} className="graphTextFront">{j.notificationText}</textarea>
                            )}
                            {this.state.editModeRows[r] && this.state.editModeRows[r].editMode === false && (
                                <label style={{height: '105px'}} className="graphTextFront">{j.notificationText}</label>
                            )}
                            <div style={{ position: 'absolute', width: '100%', right: '0', height: '78%', borderRight: '1px solid black' }}></div>
                        </Col>

                        <Col style={{ display: 'flex', alignItems: 'center', zIndex: '0', overflow: 'hidden' }} xs={1} sm={1}>
                            <span className="graphTextFront">{this.formatDate(j.startDateUTC)}</span><div className="highlightCell"></div>
                            <div style={{ position: 'absolute', width: '100%', right: '0', height: '78%', borderRight: '1px solid black' }}></div>
                        </Col>
                        <Col style={{ display: 'flex', alignItems: 'center', zIndex: '0', overflow: 'hidden' }} xs={1} sm={1}>
                            <span className="graphTextFront">{this.formatDate(j.endDateUTC)}</span><div className="highlightCell"></div>
                            <div style={{ position: 'absolute', width: '100%', right: '0', height: '78%', borderRight: '1px solid black' }}></div>
                            </Col>

                        <Col style={{ display: 'flex', alignItems: 'center', zIndex: '0', height: '125px' }} xs={1} sm={1}>
                            {this.state.editModeRows[r] && this.state.editModeRows[r].editMode === true && (
                                <div style={{height: '125px', verticalAlign: 'center'}}>
                                    <Button style={{ marginTop: '7px', marginBottom: '7px', paddingTop: '7px' }} className="button-gridrow-invdetails payorrefundActions2" onClick={() => (this.editSysNotification(j))} href="#">SAVE</Button>
                                    <br />
                                    <Button style={{ marginTop: '7px', marginBottom: '7px', paddingTop: '7px' }} className="button-gridrow-invdetails payorrefundActions2" onClick={() => (this.deleteSysNotification(j))} href="#">DELETE</Button>
                                    <br />
                                    <Button style={{ marginTop: '7px', marginBottom: '7px', paddingTop: '7px' }} className="button-gridrow-invdetails payorrefundActions2" 
                                        onClick={this.resetEditModeRows} href="#">CANCEL</Button>
                                    </div>  
                            )}
                            {this.state.editModeRows[r] && this.state.editModeRows[r].editMode === false && (
                                <div style={{height: '25px'}}>
                                    <Button style={{ marginTop: '7px', marginBottom: '7px', paddingTop: '7px' }} className="button-gridrow-invdetails payorrefundActions2" 
                                        onClick={() => this.toggleEditMode(j)} href="#">EDIT</Button>
                                </div>  
                            )}
                        </Col>
                    </Row>
            )
        );
    }
}

function mapStateToProps(state) {
    return {
        token: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        systemNoficationsList: state.sysReducer.systemNoficationsList,
    }
}

export default connect(mapStateToProps, { getSystemNotifications, updateSystemNotification, deleteSystemNotification })(SystemNotificationRow);
