/*
 * Login.js handles everything relating to 
 * user security and account management
 *  
 * */

import axios from 'axios';

import { loginActionTypes, contactActionTypes, pending_function, rejected_function, fulfilled_function, serverEnvironment, libraryActionTypes } from '../actionConsts';

export var axiosConfig = {
    headers: { 'token': '', 'refUrl': document.location.host }
};


/*
 * startLogin() starts the login process (shows the login screen)
 *  
 * */
export function startLogin() {
    const { START_LOGIN } = loginActionTypes;
    return function (dispatch) {
        dispatch(fulfilled_function(START_LOGIN));
    }
}

/*
 * cancelLogin hides the login screen
 * 
 * */
export function cancelLogin() {
    const { CANCEL_LOGIN } = loginActionTypes;
    return function (dispatch) {
        dispatch(fulfilled_function(CANCEL_LOGIN));
    }
}

/*
 * login() calls the /login endpoint that returns a login token if successful
 * 
 * */
export function login(userName, password) {
    const { LOGIN } = loginActionTypes;
    const { SHOW_EDIT_FORM } = contactActionTypes;

    var token = '';
    var userId = '';

    return function (dispatch, getState) {

        dispatch(pending_function(LOGIN));
        var payload =
        {
            'userName': userName,
            'password': password
        };

        var url = serverEnvironment.API_URL + '/login';
        axios
            .post(url, payload, axiosConfig)
            .then((res) => {
                //console.log(res.data)
                token = res.data.token;
                userId = res.data.user.userID;
                dispatch(fulfilled_function(LOGIN, res.data));  
            })
            .then((res) => {
                const { GET_USER_SYSNOTIFICATIONS, NEW_DATA_PROFILE } = loginActionTypes;
                //alert('/user/systemnotifications/' + String(userId))

                axiosConfig.headers.token = token;
                //alert('/user/systemnotifications/' + String(userId))
                axios
                    .get(serverEnvironment.API_URL + '/user/systemnotifications/' + String(userId), axiosConfig)
                    .then(res => {
                        dispatch(fulfilled_function(GET_USER_SYSNOTIFICATIONS, res));
                        //console.log(res)
                    })
                    .catch(err => {
                        //alert(err)
                        dispatch(rejected_function(GET_USER_SYSNOTIFICATIONS, err));
                    });

                axios
                    .post(serverEnvironment.AC_BACKEND_API_URL + '/api/v1/user/profile?userId=' + String(userId), axiosConfig)
                    .then(res => {
                        dispatch(fulfilled_function(NEW_DATA_PROFILE, res.data));
                        //console.log(res)
                    })
                    .catch(err => {
                        //alert(err)
                        dispatch(rejected_function(NEW_DATA_PROFILE, err));
                    });
            })
            .catch(err => {
                dispatch(rejected_function(LOGIN, err));
            })
        dispatch(fulfilled_function(SHOW_EDIT_FORM, false));
    }

}


/*
 * logout() calls /logout to end the current user's session.  This should never
 * fail, even if the token is invalid.
 *  
 * */
export function logout(token) {
    const { LOGOUT } = loginActionTypes;

    return function (dispatch, getState) {
        dispatch(pending_function(LOGOUT));

        axiosConfig.headers.token = token;
        axios
            .post(serverEnvironment.API_URL + '/logout', '', axiosConfig)
            .then(res => {
                dispatch(fulfilled_function(LOGOUT));
            })
            .catch(err => {
                dispatch(rejected_function(LOGOUT, err));
            });
        
    };
}


/*
 * changePassword() allows the user to change their password
 *  
 * */
export function changePassword(userName, oldPassword, newPassword) {
    const { CHANGE_PASSWORD } = loginActionTypes;


    return function (dispatch, getState) {
        dispatch(pending_function(CHANGE_PASSWORD));

        var payload =
        {
            'userName': userName,
            'password': oldPassword,
            'newpassword': newPassword
        };

        axios
            .post(serverEnvironment.API_URL + '/changePassword', payload)
            .then(res => {
                dispatch(fulfilled_function(CHANGE_PASSWORD));
            })
            .catch(err => {
                dispatch(rejected_function(CHANGE_PASSWORD, err));
            });

    };
}

/*
 * sendResetPasswordEmail() sends an email with a token to confirm their password
 *  
 * */
export function sendResetPasswordEmail(email, isNewFlow) {
    const { RESET_PASSWORD } = loginActionTypes;

    return function (dispatch) {

        var payload = { name: email, id: 0, newFlowResetPass: isNewFlow };
        axios.post(serverEnvironment.API_URL + '/email/reset', payload , axiosConfig)
            .then(res => {
                dispatch(fulfilled_function(RESET_PASSWORD, res.data));
            })
            .catch(err => {
                dispatch(rejected_function(RESET_PASSWORD, err));
            })
    }
}


/*
 * verifyToken() confirms the entered token is correct
 *  
 * */
export function verifyToken(email, token) {
    const { VERIFY_TOKEN } = loginActionTypes;

    return function (dispatch) {
        var payload = {
            email: email,
            token: token
        }

        axios.post(serverEnvironment.API_URL + '/verifyToken', payload, axiosConfig)
            .then(res => {
                dispatch(fulfilled_function(VERIFY_TOKEN, res.data));
            })
            .catch(err => {
                dispatch(rejected_function(VERIFY_TOKEN, err));
            })
    }
}

/*
 * getUserSysNotifications() retrieves any unread user system notifications       
 *  
 * */
export function getUserSysNotifications(token, id) {
    const { GET_USER_SYSNOTIFICATIONS } = loginActionTypes;

    return function (dispatch, getState) {
        axiosConfig.headers.token = token;
        //alert('/user/systemnotifications/' + String(id))
        axios
            .get(serverEnvironment.API_URL + '/user/systemnotifications/' + String(id), axiosConfig)
            .then(res => {
                dispatch(fulfilled_function(GET_USER_SYSNOTIFICATIONS, res));
                //console.log(res)
            })
            .catch(err => {
                //alert(err)
                dispatch(rejected_function(GET_USER_SYSNOTIFICATIONS, err));
            });
    }

}

/*
 * dismissUserSysNotification() marks as read a given user system notification      
 *  
 * */
export function dismissUserSysNotification(token, userId, notificationId) {
    const { DISMISS_USER_SYSNOTIFICATION } = loginActionTypes;
    return function (dispatch, getState) {
        axiosConfig.headers.token = token;
        //alert('/user/systemnotification/' + String(userId) + '/' + String(notificationId))
        axios
            .post(serverEnvironment.API_URL + '/user/systemnotification/' + String(userId) + '/' + String(notificationId), null, axiosConfig)
            .then(res => {
                dispatch(fulfilled_function(DISMISS_USER_SYSNOTIFICATION, res));
            })
            .catch(err => {
                alert(err)
                dispatch(rejected_function(DISMISS_USER_SYSNOTIFICATION, err));
            });
    }

}

/*
 * register() records a user's initial login information in the database.        
 *  
 * */
export function register(payload) {
    const { REGISTER } = loginActionTypes;
    return function (dispatch, getState) {
        dispatch(pending_function(REGISTER));

        axios
            .post(serverEnvironment.API_URL + '/user/register', payload, axiosConfig)
            .then(res => {
                dispatch(fulfilled_function(REGISTER, res.data));
            })
            .catch(err => {                
                dispatch(rejected_function(REGISTER, err));
            });
    }

}

/*
 * deactivateUser() sets a user's isActive flag to false
 *  
 * */
export function deactivateUser(token, id) {
    const { DEACTIVATE_USER } = loginActionTypes;
    return function (dispatch, getState) {
        dispatch(pending_function(DEACTIVATE_USER));
        axiosConfig.headers.token = token;
        axios
            .delete(serverEnvironment.API_URL + '/user/' + String(id), axiosConfig)
            .then(res => {
                dispatch(fulfilled_function(DEACTIVATE_USER, id));
            })
            .catch(err => {
                dispatch(rejected_function(DEACTIVATE_USER, err));
            });
    }

}

/*
 * editUserProfile() allows the user to change their name, email and phone number
*
 * */
export function editUserProfile(token, oldUser, email, firstname, lastname, phone, logoURL,
    includeLogoURL, professionalPic, biography, programInformation, certsAndCredentials,
    affiliateFeeType, userRole, accessDocs, isLive, website, coachingType, companyName) {
    const { EDIT_USER_PROFILE } = loginActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {
        dispatch(pending_function(EDIT_USER_PROFILE));

        var payload =
        {
            ...oldUser,
            id: oldUser.id,
            name: email,
            FirstName: firstname,
            LastName: lastname,
            Email: email,
            Phone: phone,
            logoURL: logoURL,
            includeLogoURL: includeLogoURL,
            professionalPic: professionalPic,
            biography: biography,
            programInformation: programInformation,
            certsAndCredentials: certsAndCredentials,
            affiliateFeeType: affiliateFeeType,
            userRole: userRole,
            modifiedDate: new Date(),
            ts: "QEA=",
            canAccessDocs: accessDocs,
            isLive: isLive,
            website: website,
            coachingType: coachingType,
            companyName: companyName,
        }

        axiosConfig.headers.token = token;

        axios
            .put(serverEnvironment.API_URL + '/user/' + String(oldUser.id), payload, axiosConfig)
            .then(res => {
                dispatch(fulfilled_function(EDIT_USER_PROFILE, res.data));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(EDIT_USER_PROFILE, err));
            });
    }

}

/*
 * reactivateUser() reactivates a user
*
 * */
export function reactivateUser(token, user) {
    const { EDIT_USER_PROFILE } = loginActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {
        dispatch(pending_function(EDIT_USER_PROFILE));

        user.isActive = true;
        user.isLockedOut = false;

        axiosConfig.headers.token = token;

        axios
            .put(serverEnvironment.API_URL + '/user/' + String(user.id), user, axiosConfig)
            .then(res => {
                dispatch(fulfilled_function(EDIT_USER_PROFILE, res.data));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(EDIT_USER_PROFILE, err));
            });
    }

}

/*
 * startEditProfile() invokes the edit profile window
 *  
 * */
export function startEditProfile() {
    const { SHOW_EDIT_PROFILE } = loginActionTypes;
    return function (dispatch) {
        dispatch(fulfilled_function(SHOW_EDIT_PROFILE));
    }
}

/*
 * cancelEditProfile() closes the edit profile window
 *  
 * */
export function cancelEditProfile() {
    const { CANCEL_EDIT_PROFILE } = loginActionTypes;
    return function (dispatch) {
        dispatch(fulfilled_function(CANCEL_EDIT_PROFILE));
    }
}


/*
 * getAllUsers() 
 * 
 * */
export function getAllUsers(token, status) {
    const { GET_ALL_USERS, LOGOUT } = loginActionTypes;
    
    return function (dispatch, getState) {

        dispatch(pending_function(GET_ALL_USERS));
        axiosConfig.headers.token = token;

        var url = serverEnvironment.API_URL + (status ? '/user?status='+status : '/user');
        axiosConfig.headers.token = token;

        axios
            .get(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(GET_ALL_USERS, res));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(GET_ALL_USERS, err));
            });

    }
}


/*
 * selectUser() chooses a single user to view/edit
 *  
 * */
export function selectUser(user, impersonate) {
    const { SELECT_USER } = loginActionTypes;
    return function (dispatch) {
        dispatch(fulfilled_function(SELECT_USER, { user, impersonate }));
    }
}

/*
 * showEditUserForm() shows the edit user form for other users
 *  
 * */
export function showEditUserForm(show) {
    const { SHOW_EDIT_USER_FORM } = loginActionTypes;
    return function (dispatch) {
        dispatch(fulfilled_function(SHOW_EDIT_USER_FORM, show));
    }
}

/*
 * impersonateUser() gets a user
 *  
 * */
export function impersonateUser(token, id) {
    const { IMPERSONATE_USER } = loginActionTypes;
    return function (dispatch, getState) {
        dispatch(pending_function(IMPERSONATE_USER));
        axiosConfig.headers.token = token;
        axios
            .get(serverEnvironment.API_URL + '/user/' + String(id), axiosConfig)
            .then(res => {
                dispatch(fulfilled_function(IMPERSONATE_USER, res));
            })
            .catch(err => {
                dispatch(rejected_function(IMPERSONATE_USER, err));
            });
    }

}

export function getUserProfile(userId) {
    const { NEW_DATA_PROFILE } = loginActionTypes;
                return function (dispatch, getState) {

                axios
                .post(serverEnvironment.AC_BACKEND_API_URL + '/api/v1/user/profile?userId=' + String(userId), axiosConfig)
                .then(res => {
                    dispatch(fulfilled_function(NEW_DATA_PROFILE, res.data));
                    //console.log(res)
                })
                .catch(err => {
                    //alert(err)
                    dispatch(rejected_function(NEW_DATA_PROFILE, err));
                });
            }
}

export function disconnectStripeConnect(userId) {
    const { DISCONNECT_STRIPE_CONNECT } = loginActionTypes;
    const { UPDATE_LOADING } = libraryActionTypes;

                return function (dispatch, getState) {

                axios
                .post(serverEnvironment.AC_BACKEND_API_URL + '/api/v1/connect/disconnect?userId=' + String(userId), axiosConfig)
                .then(res => {
                    dispatch(fulfilled_function(UPDATE_LOADING, false));

                    dispatch(fulfilled_function(DISCONNECT_STRIPE_CONNECT, {}));

                })
                .catch(err => {
                    //alert(err)
                    dispatch(fulfilled_function(UPDATE_LOADING, false));

                    dispatch(rejected_function(DISCONNECT_STRIPE_CONNECT, err));
                });
            }
}

