import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { hideNavbar } from '../../hooks/store/actions/navBarActions'; 

import ReportResult from './admin/ReportResult'
import ReportParamList from './admin/ReportParamList'
import Label from 'react-bootstrap/FormLabel';
import Select from 'react-styled-select';
import ActivityGrid from './contact/ActivityGrid';
import InvoiceList from './finance/InvoiceList';
import { getContactsByUser } from '../../hooks/store/actions/contactActions.js';
import { dashboard, getInfoPackets, getAllActivity, getAllInvoices } from '../../hooks/store/actions/financeActions.js';
import { getReports, runReport, clearParams, selectReport, runReportBillExports } from '../../hooks/store/actions/sysActions.js';
import swal from 'sweetalert';

class ReportForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            report: 0,
            showNoResults: false,
            reportName: "",
            ascending: true,
        };
        this.props.hideNavbar();

        this.props.dashboard(this.props.logintoken, this.props.user.userID)

        //console.log((this.props.user.id !== this.props.sysUser.id) && this.props.user.userRole === 2)
        //console.log('this.props.user.userRole', this.props.user.userRole)

        this.props.getContactsByUser(this.props.logintoken, this.props.user.id);
        this.props.getInfoPackets(this.props.logintoken, this.props.user.id);
        this.props.getAllInvoices(this.props.logintoken, this.props.user.id);
        this.props.getAllActivity(this.props.logintoken, this.props.user.id);

        this.props.getReports(this.props.logintoken, (this.props.user.id !== this.props.sysUser.id) && this.props.user.userRole === 2 ? 2 : -1);
        this.exportToCSV = this.exportToCSV.bind(this);
        this.onRunReport = this.onRunReport.bind(this);
        this.onSelectReport = this.onSelectReport.bind(this);
        this.sortCol = this.sortCol.bind(this);
        this.exportCsvBillcom = this.exportCsvBillcom.bind(this);
    }

    reportsArrayLength = 0;
    customReports = [];

    onSelectReport(item) {
       // console.log('onSelectReport --- item=', item, 'customReports=', this.customReports); //find the report name
        var reportName = this.customReports.find(x => x.value === item);
        //console.log('onSelectReport --- reportName=', reportName);
        if (reportName){
            this.setState({ report: item, showNoResults: true, reportName: reportName.label });
        }else{
            this.setState({ report: item, showNoResults: true });
        }
        this.props.clearParams();
        this.props.selectReport();
    }

    onRunReport() {
        if (this.state.report) {
            // Hack to workaround that it's not letting me select position 0
            // console.log('this.reportsArrayLength - this.state.report + 1=', this.reportsArrayLength - this.state.report + 1)
            var reportNumber = this.reportsArrayLength > 0 ? this.reportsArrayLength - this.state.report + 1 : this.state.report;
            //console.log('reportNumber=', reportNumber)
            var report = this.props.reports[reportNumber];

            if (!report) {
                report = this.props.reports.find(r => r.name === this.state.reportName);
                console.log('report=', report)
            }

            //console.log('prop report=', this.props.reports, 'customReports=', this.customReports);
            //console.log('state=', this.state ,'report=', report, "reportNumber=", reportNumber);

            if (report) {
                var params = this.props.params.slice();

                // Merge params
                for (var i = 0; i < report.parameters.length; i++) {
                    if (report.parameters[i].isHidden) {
                        params.push({ name: report.parameters[i].sqlParamName, value: report.parameters[i].defaultValue });
                    }
                }

                if(this.getReport() === 9 && this.props.subOption && this.props.subOption.value === 2) {
                    // console.log('perform seach control number, params', params, this.props.subOption);
                    const paramsFilter = this.filterParams(params, true);
                   // console.log(paramsFilter);
                    const value = paramsFilter[0].value;
                    this.props.runReportBillExports(value);
                    return;
                }

                const paramsFilter = this.filterParams(params, false);
                this.props.runReport(this.props.logintoken, report.id, paramsFilter, (this.props.user.id !== this.props.sysUser.id) && this.props.user.userRole === 2 ? 2 : -1, this.props.user.id);
                    //console.log('onRunReport --- params=.........', params);



            }
        }
    }

    filterParams(params, excludeParamsSystem){
        const keyword = "@";
        if (excludeParamsSystem) {
            return params.filter( p => !p.name.includes(keyword))
        }
        return params.filter( p => p.name.includes(keyword))
    }

    sortCol(type) {
        //console.log('sortCol --- type=', type)
        //console.log('sortCol --- type.name=' + type.name)
        //console.log('this.props.reportResults.data=..........', this.props.reportResults.data)
        //console.log('this.state=........', this.state)
        var typeName = type ? type.name : '';
        //console.log('sortCol --- typeName=', typeName)

        if (typeName != '') {
            if (this.state.sort != typeName && this.props.reportResults.data) {
                this.setState({ sort: typeName, ascending: true })
            } else if (this.props.reportResults.data) {
                this.setState({ sort: typeName, ascending: !this.state.ascending })
            }
        }
    }

    filterReports() {
        //console.log('this.props.reportResults.data=', this.props.reportResults.data)
        var reportData = [];
        var d;
        if (this.props.reportResults.data) {
            for (var i = 0; i < this.props.reportResults.data.length; i++) {
                d = this.props.reportResults.data[i].values;
                    reportData.push(d);
            }
        }
        //console.log('reportData=', reportData)
        //console.log('filterReports --- this.state.sort=', this.state.sort)

        if (this.state.sort) {
            if (this.state.sort.toLowerCase() === "lastname" && this.state.ascending) {
                //console.log('here1')
                reportData = reportData.sort((a, b) => (a.lastName > b.lastName) ? 1 : -1)
                //console.log('reportData=', reportData)
            }
            else if (this.state.sort.toLowerCase() === "lastname" && !this.state.ascending) {
                //console.log('here2')
                reportData = reportData.sort((a, b) => (a.lastName > b.lastName) ? -1 : 1)
            }
            else if (this.state.sort.toLowerCase() === "firstname" && this.state.ascending) {
                //console.log('here3')
                reportData = reportData.sort((a, b) => (a.FirstName > b.FirstName) ? 1 : -1)
                //console.log('reportData=', reportData)
            }
            else if (this.state.sort.toLowerCase() === "firstname" && !this.state.ascending) {
                //console.log('here4')
                reportData = reportData.sort((a, b) => (a.firstName > b.firstName) ? -1 : 1)
            }
            else if (this.state.sort === "clientStatus" && this.state.ascending) {
                reportData = reportData.sort((a, b) => (getClientStatus(a) > getClientStatus(b)) ? 1 : -1)
            }
            else if (this.state.sort === "clientStatus" && !this.state.ascending) {
                reportData = reportData.sort((a, b) => (getClientStatus(a) > getClientStatus(b)) ? -1 : 1)
            }
            else if (this.state.sort === "paymentStatus" && this.state.ascending) {
                reportData = reportData.sort((a, b) => (a.paymentStatus ? a.paymentStatus : "" > b.paymentStatus ? b.paymentStatus : "") ? 1 : -1)
            }
            else if (this.state.sort === "paymentStatus" && !this.state.ascending) {
                reportData = reportData.sort((a, b) => (a.paymentStatus ? a.paymentStatus : "" > b.paymentStatus ? b.paymentStatus : "") ? -1 : 1)
            }
            else if (this.state.sort === "email" && this.state.ascending) {
                reportData = reportData.sort((a, b) => (a.email.toLowerCase() > b.email.toLowerCase()) ? 1 : -1)
            }
            else if (this.state.sort === "email" && !this.state.ascending) {
                reportData = reportData.sort((a, b) => (a.email.toLowerCase() > b.email.toLowerCase()) ? -1 : 1)
            }
            else if (this.state.sort === "source" && this.state.ascending) {
                reportData = reportData.sort((a, b) => (a.contactType.name > b.contactType.name) ? 1 : -1)
            }
            else if (this.state.sort === "source" && !this.state.ascending) {
                reportData = reportData.sort((a, b) => (a.contactType.name > b.contactType.name) ? -1 : 1)
            }
            else if (this.state.sort === "contractEnd") {
                reportData = reportData.sort((a, b) => (String(a.expirationDate) > String(b.expirationDate)) ? 1 : -1)
            }
        }
        return reportData;
    }

    filterActivity() {
        var activity = this.props.activity;
        var ret = [];
        if (this.state.report === "5") {
            var thisMonth = new Date();
            thisMonth = thisMonth.getFullYear() + "-" + ((thisMonth.getMonth() + 1) < 10 ? "0" : "") + (thisMonth.getMonth() + 1)
            for (var i = 0; i < activity.length; i++) {
                var thatMonth = activity[i].lastUpdatedDateUTC.substring(0, 7);
                if (thisMonth === thatMonth) {
                    ret.push(activity[i]);
                }
            }
        }
        else if (this.state.report === "6") {
            ret = activity;
        }
        else if (this.state.report !== "8") {
            ret = this.props.allActivity.sort((a, b) => (a.lastUpdatedDateUTC.substring(0, 10) < b.lastUpdatedDateUTC.substring(0, 10)) ? 1 : -1);

        }

        return ret;
    }

    filterInvoices() {
        var invoices = this.props.invoices;
        ////console.log('invoices=', invoices)
        var ret = [];
        var today = new Date();
        var thisMonth = today.getFullYear() + "-" + ((today.getMonth() + 1) < 10 ? "0" : "") + (today.getMonth() + 1);
        var thirtyDays = new Date();
        thirtyDays.setDate(thirtyDays.getDate() - 30);
        var now = thirtyDays.getFullYear() + "-"
            + ((thirtyDays.getMonth() + 1) < 10 ? "0" : "") + (thirtyDays.getMonth() + 1) + "-"
            + ((thirtyDays.getDay() + 1) < 10 ? "0" : "") + (thirtyDays.getDay() + 1);


        

        for (var i = 0; i < invoices.length; i++) {
            switch (this.state.report) {
                case "0":
                    return invoices;
                case "1":
                    if ((invoices[i].invoiceStatusID > 1 && invoices[i].invoiceStatusID < 5) || invoices[i].invoiceStatusID == 6) {
                        ret.push(invoices[i]);
                    }
                    break;
                case "2":
                    var thatMonth = invoices[i].createdDateUTC.substring(0, 7);
                    if (thisMonth === thatMonth) {
                        ret.push(invoices[i])
                    }
                    break;
                case "3":
                    if (invoices[i].invoiceStatusID === 5) {
                        thatMonth = invoices[i].lastUpdatedDateUTC.substring(0, 7);
                        if (thisMonth === thatMonth) {
                            ret.push(invoices[i])
                        }
                    }
                    break;
                case "4":                
                    //invoices overdue report
                    ret = this.props.data.invoicesOverdue;

                    //old deprecated code
                    //if (invoices[i].invoiceStatusID === 6) {
                    //    ret.push(invoices[i]);
                    //}
                    //else if (invoices[i].dueDate.substring(0, 10) < now &&
                    //    (invoices[i].invoiceStatusID === 2 ||
                    //        invoices[i].invoiceStatusID === 3 ||
                    //        invoices[i].invoiceStatusID === 4)) {
                    //    ret.push(invoices[i]);

                    //}
                    break;
                default:
                    break;
            }
        }

        return ret;
    }

    getOptions(j) {
        var options = [];
        if (j.values) {
            for (var i = 0; i < j.values.length; i++) {
                options.push({ label: j.values[i].name, value: i+1});
            }
        }
        return options;
    }

    /*
     * 
     * Export to CSV
     * */
    
    exportToCSV() {

        if (this.props.reportResults.data.length === 0) {
            swal("No data to export!");
            return;
        }

        var data = "";
        var needComma = false;
        // Header row
        for (var i = 0; i < this.props.reportResults.columnList.length; i++) {
            if (needComma)
                data += ",";
            data += "\"" + this.props.reportResults.columnList[i].name + "\"";
            needComma = true;
        }
        data += "\n";
        needComma = false;

        for (i = 0; i < this.props.reportResults.data.length; i++) { // One row

            for (var i2 = 0; i2 < this.props.reportResults.data[i].values.length; i2++) {
                if (needComma)
                    data += ",";
                if (this.props.reportResults.columnList[i2].value === "String" || this.props.reportResults.columnList[i2].value === "DateTime" || this.props.reportResults.data[i].values[i2].startsWith("(") )
                    data += "\"" + this.props.reportResults.data[i].values[i2].replace(',', '') + "\"";
                else
                    data += this.props.reportResults.data[i].values[i2].replace(',','');

                needComma = true;
            }
            data += "\n";
            needComma = false;
        }
       

        var today = new Date();
        var fileName = "acadmin-report-" + today.toDateString() + ".csv";

        var blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, fileName);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    getReport(){
        // console.log(this.state.report);
        var reportNumber = this.reportsArrayLength > 0 ? this.reportsArrayLength - this.state.report + 1 : this.state.report;
        //console.log('reportNumber=', reportNumber)
        var report = this.props.reports[reportNumber];
        // console.log(report);
        if(report)
            return report.id
        return 0;
    }

    exportCsvBillcom() {

        const instance = this;

        swal({
            title: "AC Admin", text: "Are you sure you want to export this information for bill.com csv file ?", icon: "warning",
            buttons:
            {
                no: {
                    text: "No",
                    value: "no"
                },
                yes: {
                    text: "Yes",
                    value: "yes"
                }
            }
        }).then((value) => {
            switch (value) {
                case "yes":
                    instance.exportToCSV();
                    break;
                case "no":
                   break;
                default:
                   break;
            }
        });

    }


    render() {

        //console.log('this.state.report=', this.state.report)
        //console.log('this.props.user && this.props.user.userRole=', this.props.user && this.props.user.userRole)
        //console.log('this.props.user && this.props.user.id=', this.props.user && this.props.user.id)
        //console.log('this.props.sysUser && this.props.sysUser.userRole=', this.props.sysUser && this.props.sysUser.userRole)
        //console.log('this.props.sysUser && this.props.sysUser.id=', this.props.sysUser && this.props.sysUser.id)

        var reportDataRender = this.filterReports();
        ////console.log('reportDataRender=' + reportDataRender)

        var reports = [];
        if (this.props.user && this.props.user.userRole === 2) {
            reports = [
                { label: "Recent Invoices", value: "0" },
                { label: "Unpaid Invoices", value: "1" },
                { label: "Invoices Issued This Month", value: "2" },
                { label: "Invoices Paid This Month", value: "3" },
                { label: "Overdue Invoices", value: "4" },
                { label: "Information Packets Sent This Month", value: "5" },
                { label: "Information Packets Sent", value: "6" },
                { label: "All Activity", value: "7" },
            ];

            var activity = this.filterActivity();
            var invoices = this.filterInvoices();
        }

        var runBtnVisible = false;
        //if (this.props.reports.length > 0) { runBtnVisible = true; }
        if (this.props.user.userRole === 3 || this.props.user.userRole === 8 || (this.props.user.userRole === 2 && this.state.report >= 8)) {
            runBtnVisible = true;
        }

        // Admin reports
        this.reportsArrayLength = reports.length-1;
        for (var i =  0; i < this.props.reports.length; i++) {
            reports.push({ label: this.props.reports[i].name, value:(this.reportsArrayLength+i+1).toString()});
        }


        reports.reverse();
        //console.log('reports=', reports)

  

        var data = [];
        var header = [];
        var params = [];
        if (this.props.reportResults && this.props.reportResults.data && this.props.reportResults.data.values) {
            data = this.props.reportResults.data;
            header = this.props.reportResults.columnList
        }
        if (this.props.reports[this.state.report - this.reportsArrayLength - 1] ) {
            params = this.props.reports[this.state.report - this.reportsArrayLength - 1].parameters;
            for (i = 0; i < params.length; i++) {
                params[i].options = this.getOptions(params[i]);
            }
        }

        this.customReports = reports;
        

        return (
            <div className="reportGrid" >      
                {(this.props.showLoadingGif || this.props.showFinanceLoadingGif) && (<div id="loadingGif"></div>)}
                <span className="contact-grid-form">                    
                    <Row sm={8}>
                        <Col sm={4}>
                            <Label className="reports-header grid-column">Select a Report: </Label>
                            <Select
                                name="reportType"
                                options={reports}                        
                                value={this.state.report}
                                className="rs-report-theme"
                                onChange={this.onSelectReport}/>
                            
                        </Col>
                    </Row>
                    {this.props.showParams ?
                        <Row sm={8}>
                            <Col sm={4}>
                                <br />
                                <ReportParamList params={params} report={this.props.reports[this.state.report]} />
                            </Col>
                        </Row>
                        : <br />
                    }
                    <Row sm={8}>
                        <Col sm={12}>
                            {runBtnVisible === true ? <><Button className="button-gridrow-edit-profile payorrefundActions mb15" onClick={this.onRunReport}>RUN</Button></> : <></>}
                        </Col>
                    </Row>
                    <Row className={this.props.user.userRole === 2 ? "" : "hidden"}>
                        {this.props.logintoken && (Number(this.state.report) >= 5 && (Number(this.state.report) <= 7)) && activity && activity.length > 0 && (
                            <div className="report-results">
                                <ActivityGrid activity={activity} />
                            </div>
                        )}
                        {(this.props.logintoken && (Number(this.state.report) < 5) && invoices && invoices.length > 0 ) && (
                            <div className="report-results">
                                <InvoiceList invoices={invoices} reportFormId={Number(this.state.report)} />
                            </div>
                        )}
                        {this.props.logintoken && this.state.report != 0 && ((Number(this.state.report) >= 5 && Number(this.state.report) <= 7 && (!activity || activity.length === 0)) || (Number(this.state.report) < 5 && (!invoices || invoices.length === 0))) &&
                            (<div className="no-report-results">No results</div>)
                        }
                    </Row>
                    

                    <Row>
                        <div className="table-responsive contactGridTableResponsive reportGridData" style={{ overflow: 'unset', whiteSpace: 'pre-line' }}>
                            <ReportResult data={data} columnList={header} sortCol={this.sortCol} />
                        </div>
                    </Row>
                    
                    

                    {this.props.reportResults && this.props.reportResults.data && this.props.reportResults.data.length > 0 && (
                        <Row>
                            <Button className="button-gridrow-edit-profile payorrefundActions mb5" onClick={this.exportToCSV} >Export to CSV</Button>
                        </Row>
                    )}

                    {this.props.reportResults && this.props.reportResults.data && this.props.reportResults.data.length > 0 && this.getReport() === 9 && (this.props.subOption && this.props.subOption.value === 1) && (
                            <Row>
                                <Button className="payorrefundActions mb5" onClick={this.exportCsvBillcom} >Generate Bill.com Import</Button>
                            </Row>
                            )}
                </span>

                </div>
        );
    }
    
}
    
function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        sysUser: state.loginReducer.sysUser,
        reports: state.sysReducer.reports,
        params: state.sysReducer.params,
        reportResults: state.sysReducer.reportResults,
        data: state.financeReducer.dashboard,
        activity: state.financeReducer.infoPackets,
        allActivity: state.financeReducer.allactivity,
        invoices: state.financeReducer.allinvoices, 
        showLoadingGif: state.sysReducer.showLoadingGif,
        showFinanceLoadingGif: state.financeReducer.showLoadingGif,
        showParams: state.sysReducer.showParams,
        subOption: state.sysReducer.subOption,
    }
}

export default connect(mapStateToProps, { dashboard, getAllActivity, getContactsByUser, getInfoPackets, getAllInvoices, hideNavbar, getReports, runReport, clearParams, selectReport, runReportBillExports  })(ReportForm);