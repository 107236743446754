import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from 'react-bootstrap/FormLabel';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ActivityGrid from '../contact/ActivityGrid';
import InvoiceList from './InvoiceList';
import { getContactsByUser } from '../../../hooks/store/actions/contactActions.js';
import { dashboard, getInfoPackets, getAllInvoices } from '../../../hooks/store/actions/financeActions.js';
import { logout } from '../../../hooks/store/actions/loginActions.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatCurrency } from '../../../hooks/store/actionConsts.js'

const CURRENT = 1;
const PAID = 2;
const OVERDUE = 3;
const UNPAID = 4;

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            currentInfoPackets: false,
            showIModal: false,
            invoiceFilter: 0
        };

        if (!this.props.user) {
            this.props.logout();
        }
        this.props.dashboard(this.props.logintoken, this.props.user.userID);
        this.showActiveClients = this.showActiveClients.bind(this);
        this.showUnpaidClients = this.showUnpaidClients.bind(this);
        this.showExpiringContracts = this.showExpiringContracts.bind(this);
        this.showCurrentInvoices = this.showCurrentInvoices.bind(this);
        this.showPaidInvoices = this.showPaidInvoices.bind(this);
        this.showOverdueInvoices = this.showOverdueInvoices.bind(this);
        this.showCurrentInfoPackets = this.showCurrentInfoPackets.bind(this);
        this.showTotalInfoPackets = this.showTotalInfoPackets.bind(this);
        this.showProspectiveClients = this.showProspectiveClients.bind(this);
        this.onHideModal = this.onHideModal.bind(this);
        this.onHideIModal = this.onHideIModal.bind(this);
        this.filterActivity = this.filterActivity.bind(this);
        this.filterInvoices = this.filterInvoices.bind(this);
        this.showPausedClients = this.showPausedClients.bind(this);

        this.props.getContactsByUser(this.props.logintoken, this.props.user.id);
        this.props.getInfoPackets(this.props.logintoken, this.props.user.id);
        this.props.getAllInvoices(this.props.logintoken, this.props.user.id);
    }

    showActiveClients() {
        document.location = "/clientMgmt?current=1";
    }

    showPausedClients() {
        document.location = "/clientMgmt?current=0";
    }


    showUnpaidClients() {
        this.setState({ showIModal: true, invoiceFilter: UNPAID });
    }

    showExpiringContracts() {
        document.location = "/clientMgmt?expiring=1";
    }

    showCurrentInvoices() {
        this.setState({ showIModal: true, invoiceFilter: CURRENT });
    }

    showPaidInvoices() {
        this.setState({ showIModal: true, invoiceFilter: PAID });
    }

    showOverdueInvoices() {
        this.setState({ showIModal: true, invoiceFilter: OVERDUE });
    }

    showCurrentInfoPackets() {
        this.setState({ currentInfoPackets: true, showModal: true });
    }

    showTotalInfoPackets() {
        this.setState({ currentInfoPackets: false, showModal: true });
    }

    showProspectiveClients() {
        document.location = "/viewContacts?prospective=1";
    }

    onHideModal() {
        this.setState({ showModal: false });
    }

    onHideIModal() {
        this.setState({ showIModal: false });
    }

    filterActivity() {
        var activity = this.props.activity;
        var ret = [];
        if (this.state.currentInfoPackets) {
            var thisMonth = new Date();
            thisMonth = thisMonth.getFullYear() + "-" + ((thisMonth.getMonth() + 1) < 10 ? "0" : "") + (thisMonth.getMonth() + 1)
            for (var i = 0; i < activity.length; i++) {
                var thatMonth = activity[i].lastUpdatedDateUTC.substring(0, 7);
                if (thisMonth === thatMonth) {
                    ret.push(activity[i]);
                }
            }
        }
        else {
            ret = activity;
        }
        return ret;
    }

    filterInvoices() {
        var invoices = this.props.invoices;
        var ret = [];
        var today = new Date();
        var thisMonth = today.getFullYear() + "-" + ((today.getMonth() + 1) < 10 ? "0" : "") + (today.getMonth() + 1);
        var thirtyDays = new Date();
        thirtyDays.setDate(thirtyDays.getDate() - 30);
        var now = thirtyDays.getFullYear() + "-"
            + ((thirtyDays.getMonth() + 1) < 10 ? "0" : "") + (thirtyDays.getMonth() + 1) + "-"
            + ((thirtyDays.getDay() + 1) < 10 ? "0" : "") + (thirtyDays.getDay() + 1);

        for (var i = 0; i < invoices.length; i++) {
            switch (this.state.invoiceFilter) {
                case 0:
                    return invoices;
                case CURRENT:
                    //console.log(this.props.data.invoicesIssued)
                    ret = this.props.data.invoicesIssued;

                    //var thatMonth = invoices[i].createdDateUTC.substring(0, 7);
                    //if (thisMonth === thatMonth) {
                    //    ret.push(invoices[i])
                    //}
                    break;
                case PAID:
                    //console.log(this.props.data.invoicesPaid)
                    ret = this.props.data.invoicesPaid;

                    //if (invoices[i].invoiceStatusID === 5) {
                    //    thatMonth = invoices[i].lastUpdatedDateUTC.substring(0, 7);
                    //    if (thisMonth === thatMonth) {
                    //        ret.push(invoices[i])
                    //    }
                    //}
                    break;
                case OVERDUE:
                    //console.log(this.props.data.invoicesOverdue)
                    ret = this.props.data.invoicesOverdue;
                    //if (invoices[i].invoiceStatusID === 6) {
                    //    ret.push(invoices[i]);
                    //}
                    //else if (invoices[i].dueDate.substring(0, 10) < now &&
                    //    (invoices[i].invoiceStatusID === 2 ||
                    //        invoices[i].invoiceStatusID === 3 ||
                    //    invoices[i].invoiceStatusID === 4)) {
                    //    ret.push(invoices[i]);
                        
                    //}
                    break;
                case UNPAID:
                    //console.log(this.props.data.invoicesUnpaid)
                    ret = this.props.data.invoicesUnpaid;

                    //if ((invoices[i].invoiceStatusID > 1 && invoices[i].invoiceStatusID < 5) || invoices[i].invoiceStatusID == 6) {
                    //    ret.push(invoices[i]);
                    //}
                    break;
                default:
                    break;
            }
        }
        return ret;
    }

    render() {
        var activity = this.filterActivity();
        var invoices = this.filterInvoices();

        return (
            <div >
                {this.props.data && (
                    <span className="dashboard">
                        <div id="clients" className="small-table smalltabledashboard">
                            <Row className="dashboard-header" sm={8}><FontAwesomeIcon className="dashboardIcon" icon="users" color="white" />&nbsp;Clients</Row>
                            <Row className="dashboard-body" sm={8}>
                                <Col xs={6} sm={6}><Label className="dashboard-text">Total Active Clients</Label></Col>
                                <Col xs={2} sm={2} className="lineRightNumber"><Label className="dashboard-numeric">{this.props.data.totalActive}</Label></Col>
                                <Col xs={4} sm={4} style={{textAlign: 'right'}}><Button className="button-gridrow cardButton" onClick={this.showActiveClients}>DETAILS</Button></Col>
                            </Row>
                            <Row className="dashboard-body" sm={8}>
                                <Col xs={6} sm={6}><Label className="dashboard-text">Total Paused Clients</Label></Col>
                                <Col xs={2} sm={2} className="lineRightNumber"><Label className="dashboard-numeric">{this.props.data.totalPaused}</Label></Col>
                                <Col xs={4} sm={4} style={{textAlign: 'right'}}><Button className="button-gridrow cardButton" onClick={this.showPausedClients}>DETAILS</Button></Col>
                            </Row>
                            <Row className="dashboard-body" sm={8}>
                                <Col xs={6} sm={6} className={this.props.data.totalUnpaid > 0 ? "dangerText" : ""}><Label className="dashboard-text">Unpaid Clients</Label></Col>
                                <Col xs={2} sm={2} className={this.props.data.totalUnpaid > 0 ? "lineRightNumber dangerText" : "lineRightNumber"}><Label className="dashboard-numeric">{this.props.data.totalUnpaidCount}</Label></Col>
                                <Col xs={4} sm={4} style={{textAlign: 'right'}}><Button className="button-gridrow cardButton" onClick={this.showUnpaidClients}>DETAILS</Button></Col>
                            </Row>
                            <Row className="dashboard-body dashboard-footer" sm={8}>
                                <Col xs={6} sm={6}><Label className="dashboard-text">Contracts Ending Within 60 Days</Label></Col>
                                <Col xs={2} sm={2} className="lineRightNumber"><Label className="dashboard-numeric">{this.props.data.contractsEnding60Days}</Label></Col>
                                <Col xs={4} sm={4} style={{textAlign: 'right'}}><Button className="button-gridrow cardButton" onClick={this.showExpiringContracts}>DETAILS</Button></Col>
                            </Row>
                        </div>

                        {/* <div /> */}

                        <div id="invoices" className="small-table smalltabledashboard">
                            <Row className="dashboard-header" sm={8}><FontAwesomeIcon className="dashboardIcon" icon="file-alt" color="white" />&nbsp;Invoices</Row>
                            <Row className="dashboard-body" sm={8}>
                                <Col xs={6} sm={6}><Label className="dashboard-text labelPush">Invoices Issued This Month</Label></Col>
                                <Col xs={2} sm={2} className="lineRightNumber"><Label className="dashboard-numeric">{this.props.data.invoicesIssuedThisMonthCount}</Label></Col>
                                <Col xs={4} sm={4} style={{textAlign: 'right'}}><Button className="button-gridrow cardButton" onClick={this.showCurrentInvoices}>DETAILS</Button></Col>
                            </Row>
                            <Row className="dashboard-body" sm={8}>
                                <Col xs={6} sm={6}><Label className="dashboard-text labelPush">Invoices Paid This Month</Label></Col>
                                <Col xs={2} sm={2} className="lineRightNumber"><Label className="dashboard-numeric">{this.props.data.invoicesPaidThisMonthCount}</Label></Col>
                                <Col xs={4} sm={4} style={{textAlign: 'right'}}><Button className="button-gridrow cardButton" onClick={this.showPaidInvoices}>DETAILS</Button></Col>
                            </Row>
                            <Row className="dashboard-body dashboard-footer" sm={8}>
                                <Col xs={6} sm={6}><Label className={this.props.data.invoicesOverdueCount > 0 ? "dashboard-text dangerText labelPush" : "dashboard-text labelPush"}>Invoices Overdue</Label></Col>
                                <Col xs={2} sm={2} className={this.props.data.invoicesOverdueCount > 0 ? "lineRightNumber dangerText" : "lineRightNumber"}><Label className="dashboard-numeric">{this.props.data.invoicesOverdueCount}</Label></Col>
                                <Col xs={4} sm={4} style={{textAlign: 'right'}}><Button className="button-gridrow cardButton" onClick={this.showOverdueInvoices}>DETAILS</Button></Col>
                            </Row>
                        </div>

                        <div id="revenue" className="small-table smalltabledashboard">
                            <Row className="dashboard-header" sm={8}><FontAwesomeIcon className="dashboardIcon" icon="dollar-sign" color="white" />&nbsp;Revenue</Row>
                            <Row className="dashboard-body" sm={8}>
                                <Col xs={6} sm={6}><Label className="dashboard-text labelPush">Total Client Payments (Year To Date)</Label></Col>
                                <Col xs={5} sm={5} className="moneyDashCol"><Label className="dashboard-money">${formatCurrency(this.props.data.totalRevenueYTD.toFixed(2))}</Label></Col>

                            </Row>
                            <Row className="dashboard-body" sm={8}>
                                <Col xs={6} sm={6}><Label className="dashboard-text labelPush">Total Invoiced This Month</Label></Col>
                                <Col xs={5} sm={5} className="moneyDashCol"><Label className="dashboard-money">${formatCurrency(this.props.data.totalInvoiceThisMonth.toFixed(2))}</Label></Col>

                            </Row>
                            <Row className="dashboard-body dashboard-footer" sm={8}>
                                <Col xs={6} sm={6}><Label className="dashboard-text labelPush">Revenue Received This Month</Label></Col>
                                <Col xs={5} sm={5} className="moneyDashCol"><Label className="dashboard-money">${formatCurrency(this.props.data.revenueThisMonth.toFixed(2))}</Label></Col>

                            </Row>
                        </div>

                         {/* <div /> */}

                        <div id="potential" className="small-table smalltabledashboard">
                            <Row className="dashboard-header" sm={8}><FontAwesomeIcon className="dashboardIcon" icon="lightbulb" color="white" />&nbsp;Potential Clients</Row>
                            <Row className="dashboard-body" sm={8}>
                                <Col xs={6} sm={6}><Label className="dashboard-text labelPush">Info Packets Sent This Month</Label></Col>
                                <Col xs={2} sm={2} className="lineRightNumber"><Label className="dashboard-numeric">{this.props.data.infoPacketsSentThisMonth}</Label></Col>
                                <Col xs={4} sm={4} style={{textAlign: 'right'}}><Button className="button-gridrow cardButton" onClick={this.showCurrentInfoPackets}>DETAILS</Button></Col>
                            </Row>
                            <Row className="dashboard-body" sm={8}>
                                <Col xs={6} sm={6}><Label className="dashboard-text labelPush">Info Packets Sent To Date</Label></Col>
                                <Col xs={2} sm={2} className="lineRightNumber"><Label className="dashboard-numeric">{this.props.data.infoPacketsSentTotal}</Label></Col>
                                <Col xs={4} sm={4} style={{textAlign: 'right'}}><Button className="button-gridrow cardButton" onClick={this.showTotalInfoPackets}>DETAILS</Button></Col>
                            </Row>
                            <Row className="dashboard-body dashboard-footer" sm={8}>
                                <Col xs={6} sm={6}><Label className="dashboard-text labelPush">Potential Clients</Label></Col>
                                <Col xs={2} sm={2} className="lineRightNumber"><Label className="dashboard-numeric">{this.props.data.potentialClients}</Label></Col>
                                <Col xs={4} sm={4} style={{textAlign: 'right'}}><Button className="button-gridrow cardButton" onClick={this.showProspectiveClients}>DETAILS</Button></Col>
                            </Row>
                        </div>
                       
                    </span>
                )}
                {this.props.logintoken && this.state.showModal && activity && activity.length > 0 && (
                    <Modal
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        className="modal-popup"
                        show={true}
                        onHide={this.onHideModal}>
                        <Modal.Body>
                            <ActivityGrid activity={activity} />
                        </Modal.Body>
                    </Modal>
                )}
                {this.props.logintoken && this.state.showIModal && invoices && invoices.length > 0 && (
                    <Modal
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        className="modal-popup wide-table invoicesModalPopup"
                        show={true}
                        style={{width: '90%', marginLeft: '5%', marginRight: '5%'}}
                        onHide={this.onHideIModal}>

                        <Modal.Body>
                            <InvoiceList invoices={invoices} reportId={this.state.invoiceFilter} />
                        </Modal.Body>
                    </Modal>
                )}
            </div>
        );

    }

}

function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        data: state.financeReducer.dashboard,
        activity: state.financeReducer.infoPackets,
        invoices: state.financeReducer.allinvoices
    }
}

export default connect(mapStateToProps, { dashboard, getInfoPackets, getAllInvoices, getContactsByUser, logout })(Dashboard);
