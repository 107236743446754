import React from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { hideNavbar } from '../../hooks/store/actions/navBarActions'; 
import { showEditUserForm } from '../../hooks/store/actions/loginActions'; 
import UserGrid from './admin/UserGrid';
import UserProfile from './admin/UserProfile';

class SettingsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            createUser: false
        };
        this.props.hideNavbar(); 
        this.onClickCancel = this.onClickCancel.bind(this);
        this.onClickHideForm = this.onClickHideForm.bind(this);
        this.onClickShowForm = this.onClickShowForm.bind(this);
    }

    onClickCancel() {
        this.props.showEditUserForm(false);
    }

    onClickShowForm() {
        this.setState({ createUser: true});
    }

    onClickHideForm() {
        this.setState({ createUser: false });
    }

    render() {
/*
4/21: We don't need to create a user without a contact.
{this.props.sysUser.userRole > 2 && (
                    <Button onClick={this.onClickShowForm} className="button-gridrow">Add New User</Button>
                )}*/

        return (
            <div style={{ color: "black" }}>
                {this.props.sysUser.userRole > 2 ? (
                    <UserGrid />
                )
                :
                (
                    <h3>Settings Coming Soon</h3>
                )}
                
                {this.props.sysUser.userRole > 2 && this.props.showEUForm && this.props.selectedUser && (
                    <Modal
                        {...this.props}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        className="modal-popup"
                        user={this.props.selectedUser}
                        show={this.props.showEUForm}
                        onHide={this.onClickCancel}
                    >
                        <UserProfile user={this.props.selectedUser} />
                    </Modal>
                )}
                {this.state.createUser && (
                    <Modal
                        {...this.props}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        className="modal-popup"
                        show={this.state.createUser}
                        onHide={this.onClickHideForm}
                    >
                        <UserProfile user=
                            {{
                                userID: 0,                                
                                contactId: 0,
                                userRole: 2 // affiliate
                            }} />
                    </Modal>

                )}
            </div>
        );
    }

    
}
    
function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        sysUser: state.loginReducer.sysUser,
        selectedUser: state.loginReducer.selectedUser,
        showEUForm: state.loginReducer.showEUForm
    }
}

export default connect(mapStateToProps, { hideNavbar, showEditUserForm })(SettingsForm);
