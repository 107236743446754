import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import AddressDetail from './AddressDetail.js';
import { showAddressForm, deleteAddress } from '../../../hooks/store/actions/contactActions.js';

class AddressList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    editAddress(address) {
        address.contactID = this.props.contactID;
        this.props.showAddressForm(address);
    }

    deleteAddress(address) {
        address.contactID = this.props.contactID;
        this.props.deleteAddress(address);
    }

    render() {
        return (
            this.props.addresses.map(
                (j, r) =>
                    <span className="addressRow " key={r}>
                        <Row className={r % 2 === 0 ? "grid-row row-white" : "grid-row row-gray"} key={r}>
                            <Col sm={2}>{j.address.address1}</Col>
                            <Col sm={1}>{j.address.address2}</Col>
                            <Col sm={2}>{j.address.city}</Col>
                            <Col sm={1}>{j.address.state}</Col>
                            <Col sm={1}>{j.address.zip}</Col>
                            <Col sm={1}>{j.address.country}</Col>
                            <Col sm={2}>{j.addressType ? j.addressType.name : ""}</Col>                        
                            <Col sm={1}><Button className="button-gridrow viewEditButtonStyle" onClick={() => (this.editAddress(j))} href="#">EDIT</Button></Col>                        
                            <Col sm={1}><Button disabled={j.address.addressID === 0 ? true : false} className="button-gridrow viewEditButtonStyle" onClick={() => (this.deleteAddress(j))} href="#">DELETE</Button></Col>                        
                        </Row>                        
                        {this.props.show === j && (
                            <span>
                            <br />
                                <AddressDetail contactID={this.props.contactID} address={j} />
                            <br /></span>
                        )}
                    </span>

            )
        );
    }
    
}
    
function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        show: state.contactReducer.showAddressForm
    }
}

export default connect(mapStateToProps, { showAddressForm, deleteAddress })(AddressList);
