

function getMessageTerminatePlan() {
    var content = document.createElement("div");
        content.style = "margin-top:-35px;text-align:left;line-height:18px;";
        content.innerHTML = `THIS IS A 2 STEP PROCESS. <br> 
        <p>STEP 1: Terminate the <strong>contract</strong> by following the instructions below. </p> 
        <p>STEP 2: Terminate the invoice (and other actions) by clicking the VOID INVOICE/PAUSE AUTO-PAY button under your Client Management tab.</p>
        <h4>Are you sure you want to terminate this contract??</h4>`;
    return content;
}

module.exports = {
    getMessageTerminatePlan
}