
import axios from 'axios';

import { financeActionTypes, loginActionTypes, pending_function, rejected_function, fulfilled_function, serverEnvironment, libraryActionTypes } from '../actionConsts';

export var axiosConfig = {
    headers: { 'token': '', 'refUrl': document.location.host }
};


/*
 * dashboard() 
 * 
 * */
export function dashboard(token, userId) {
    const { DASHBOARD } = financeActionTypes;
    const { LOGOUT } = loginActionTypes;

    return function (dispatch, getState) {

        dispatch(pending_function(DASHBOARD));

        var url = serverEnvironment.API_URL + '/report/dashboard/' + String(userId);
        axiosConfig.headers.token = token;

        axios
            .get(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(DASHBOARD, res));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(DASHBOARD, err));
            });

    }
}



/*
 * getClients() returns billing plan clients by user
 * 
 * */
export function getClients(token, userId) {
    const { GET_CLIENTS } = financeActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(GET_CLIENTS));

        var url = serverEnvironment.API_URL + '/contact/fReport/' + String(userId);
        axiosConfig.headers.token = token;

        axios
            .get(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(GET_CLIENTS, res));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(GET_CLIENTS, err));
            });

    }
}


/*
 * getContactsById() returns contacts by contact ID
 * 
 * */
export function getContactsById(token, contactId, affiliateId) {
    const { GET_CONTACT } = financeActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(GET_CONTACT));

        var url = serverEnvironment.API_URL + '/contact/' + String(contactId) + '/' + String(affiliateId);
        axiosConfig.headers.token = token;

        axios
            .get(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(GET_CONTACT, res));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(GET_CONTACT, err));
            });

    }
}


/*
 * getBillingPlans() returns contacts by contact ID
 * 
 * */
export function getBillingPlans(token, contactId, affiliateId) {
    const { GET_BILLING_PLANS } = financeActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(GET_BILLING_PLANS));

        var url = serverEnvironment.API_URL + '/billingPlan/' + String(contactId) + '/' + String(affiliateId);
        axiosConfig.headers.token = token;

        axios
            .get(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(GET_BILLING_PLANS, res));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(GET_BILLING_PLANS, err));
            });

    }
}


/*
 * getInvoices() returns contacts by contact ID
 * 
 * */
export function getInvoices(token, contactId, affiliateId) {
    const { GET_INVOICES } = financeActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(GET_INVOICES));

        var url = serverEnvironment.API_URL + '/invoice/' + String(contactId) + '/' + String(affiliateId);
        axiosConfig.headers.token = token;

        axios
            .get(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(GET_INVOICES, res));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(GET_INVOICES, err));
            });

    }
}

/*
 * makePayment() records a payment against an invoice
 * 
 * */
export function makePayment(token, paymentDetail) {
    const { ISSUE_PAYMENT } = financeActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(ISSUE_PAYMENT));

        var url = serverEnvironment.API_URL + '/invoice/pay';

        axiosConfig.headers.token = token;

        axios
            .post(url, paymentDetail, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(ISSUE_PAYMENT, res));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(ISSUE_PAYMENT, err));
            });

    }
}

/*
 * getAllInvoices() returns contacts by affiliateID
 * 
 * */
export function getAllInvoices(token, affiliateId) {
    const { GET_ALL_INVOICES } = financeActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(GET_ALL_INVOICES));

        var url = serverEnvironment.API_URL + '/invoice/'+ String(affiliateId);
        axiosConfig.headers.token = token;

        axios
            .get(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(GET_ALL_INVOICES, res));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(GET_ALL_INVOICES, err));
            });

    }
}


/*
 * getAllActivity() returns activity by affiliateID
 * 
 * */
export function getAllActivity(token, affiliateId) {
    const { GET_ALL_ACTIVITY } = financeActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(GET_ALL_ACTIVITY));

        var url = serverEnvironment.API_URL + '/activity/byAffiliate/' + String(affiliateId);
        axiosConfig.headers.token = token;

        axios
            .get(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(GET_ALL_ACTIVITY, res));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(GET_ALL_ACTIVITY, err));
            });

    }
}

/*
 * getInfoPackets() returns activity by affiliateID
 * 
 * */
export function getInfoPackets(token, affiliateId) {
    const { GET_INFO_PACKET_ACTIVITY } = financeActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(GET_INFO_PACKET_ACTIVITY));

        var url = serverEnvironment.API_URL + '/activity/byAffiliate/infoPackets/' + String(affiliateId);
        axiosConfig.headers.token = token;

        axios
            .get(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(GET_INFO_PACKET_ACTIVITY, res));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(GET_INFO_PACKET_ACTIVITY, err));
            });

    }
}

/*
 * cancelBillingPlan() cancels the current billing plan
 * 
 * */
export function cancelBillingPlan(token, billingPlan) {
    const { CANCEL_BILLING_PLAN } = financeActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(CANCEL_BILLING_PLAN));
        billingPlan.statusID = 4; // Cancelled
        var url = serverEnvironment.API_URL + '/billingPlan/' + String(billingPlan.billingPlanID);
        axiosConfig.headers.token = token;

        axios
            .put(url, billingPlan, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(CANCEL_BILLING_PLAN, res));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(CANCEL_BILLING_PLAN, err));
            });

    }
}


/*
 * deactivateBillingPlan() cancels the current billing plan
 * 
 * */
export function deactivateBillingPlan(token, billingPlan) {
    const { DEACTIVATE_BILLING_PLAN } = financeActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(DEACTIVATE_BILLING_PLAN));
        billingPlan.statusID = 3; // Inactive

        var url = serverEnvironment.API_URL + '/billingPlan/' + String(billingPlan.billingPlanID);
        axiosConfig.headers.token = token;

        axios
            .put(url, billingPlan, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(DEACTIVATE_BILLING_PLAN, res));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(DEACTIVATE_BILLING_PLAN, err));
            });

    }
}

/*
 * updateBillingPlan() updates the current billing plan
 * 
 * */
export function updateBillingPlan(token, billingPlan) {
    const { UPDATE_BILLING_PLAN } = financeActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(UPDATE_BILLING_PLAN));
        var url = serverEnvironment.API_URL + '/billingPlan/' + String(billingPlan.billingPlanID);
        axiosConfig.headers.token = token;

        axios
            .put(url, billingPlan, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(UPDATE_BILLING_PLAN, res));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(UPDATE_BILLING_PLAN, err));
            });

    }
}

/*
 * activateBillingPlan() activates the current billing plan
 * 
 * */
export function activateBillingPlan(token, billingPlan) {
    const { ACTIVATE_BILLING_PLAN } = financeActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(ACTIVATE_BILLING_PLAN));
        billingPlan.statusID = 2; // Active

        var url = serverEnvironment.API_URL + '/billingPlan/' + String(billingPlan.billingPlanID);
        axiosConfig.headers.token = token;

        axios
            .put(url, billingPlan, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(ACTIVATE_BILLING_PLAN, res));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(ACTIVATE_BILLING_PLAN, err));
            });

    }
}


/*
 * showClientDetails() shows the billing plan and invoice list
 * 
 * */
export function showClientDetails(show) {
    const { SHOW_CLIENT_DETAILS } = financeActionTypes;
    
    return function (dispatch, getState) {
        dispatch(fulfilled_function(SHOW_CLIENT_DETAILS, show));
    }
}


/*
 * getAgreementTemplates() returns all active templates for this user
 * 
 * */
export function getAgreementTemplates(token, userId) {
    const { GET_AGREEMENT_TEMPLATES } = financeActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(GET_AGREEMENT_TEMPLATES));

        var url = serverEnvironment.API_URL + '/agreementTemplate/' + String(userId);
        axiosConfig.headers.token = token;

        axios
            .get(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(GET_AGREEMENT_TEMPLATES, res));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(GET_AGREEMENT_TEMPLATES, err));
            });

    }
}

/*
 * requestTemplateChange() requests a change and sends an email
 * 
 * */
export function requestTemplateChange(token, id, payload) {
    const { REQUEST_TEMPLATE_CHANGE } = financeActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(REQUEST_TEMPLATE_CHANGE));

        var url = serverEnvironment.API_URL + '/agreementTemplate/requestChange/' + String(id);
        axiosConfig.headers.token = token;

        axios
            .post(url, payload, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(REQUEST_TEMPLATE_CHANGE, res));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(REQUEST_TEMPLATE_CHANGE, err));
            });

    }
}

/*
 * getEmptyInvoice() returns a new invoice from template
 * 
 * */
export function getEmptyInvoice(token, contactID, affiliateID, typeID, currency) {
    const { GET_EMPTY_INVOICE } = financeActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(GET_EMPTY_INVOICE));

        var url = serverEnvironment.API_URL + '/invoice/empty/' + String(contactID) + "/" + String(affiliateID) + "/" + String(typeID) + "/" + currency;
        axiosConfig.headers.token = token;

        axios
            .get(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(GET_EMPTY_INVOICE, res));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(GET_EMPTY_INVOICE, err));
            });

    }
}

/*
 * saveInvoice() creates a new invoice
 * 
 * */
export function saveInvoice(token, invoice) {
    const { SAVE_INVOICE } = financeActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(SAVE_INVOICE));

        var url = serverEnvironment.API_URL + '/invoice/generateOne';
        axiosConfig.headers.token = token;

        axios
            .post(url, invoice, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(SAVE_INVOICE, res));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(SAVE_INVOICE, err));
            });

    }
}


/*
 * voidInvoice() voids an invoice
 * 
 * */
export function voidInvoice(token, invoice) {
    const { VOID_INVOICE } = financeActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(VOID_INVOICE));

        var url = serverEnvironment.API_URL + '/invoice/' + String(invoice.invoiceID);
        axiosConfig.headers.token = token;

        axios
            .put(url, invoice, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(VOID_INVOICE, invoice.invoiceID));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(VOID_INVOICE, err));
            });
        
    }
}


/*
 * recordInvoiceDetail() records a line in the current invoice
 * 
 * */
export function recordInvoiceDetail(rowNum, price) {
    const { RECORD_INVOICE_DETAIL } = financeActionTypes;
    
    return function (dispatch, getState) {
        dispatch(fulfilled_function(RECORD_INVOICE_DETAIL, { rowNum: rowNum, price: price} ));
    }
}

/*
 * recordInvoiceData() updates the current invoice
 * 
 * */
export function recordInvoiceData(invoice) {
    const { RECORD_INVOICE_DATA } = financeActionTypes;

    return function (dispatch, getState) {
        dispatch(fulfilled_function(RECORD_INVOICE_DATA, invoice));
    }
}

/*
 * voidPayment() voids a payment
 * 
 * */
export function voidPayment(token, invoiceID, id) {
    const { VOID_PAYMENT } = financeActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(VOID_PAYMENT));

        var invoice = {};
        var url = serverEnvironment.API_URL + '/payment/void/' + String(invoiceID) + '/' + String(id);
        axiosConfig.headers.token = token;

        axios
            .put(url, invoice, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(VOID_PAYMENT, id));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(VOID_PAYMENT, err));
            });

    }
}

/*
 * reissueInvoice() resissues an existing invoice
 * 
 * */
export function reissueInvoice(token, billingPlanID, invoiceID) {
    const { REISSUE_INVOICE } = financeActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(REISSUE_INVOICE));

            var url = serverEnvironment.API_URL + '/invoice/generate/test/' + String(billingPlanID) + '/' + String(invoiceID);
        axiosConfig.headers.token = token;

        axios
            .get(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(REISSUE_INVOICE, invoiceID));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(REISSUE_INVOICE, err));
            });

    }
}

/*
 * requestManualInvoice() pauses or terminates and emails request 
 * 
 * */
export function requestManualInvoice(token, billingPlan) {
    
    const { REQUEST_MANUAL_INVOICE } = financeActionTypes;
    const { LOGOUT } = loginActionTypes;

    return function (dispatch, getState) {

        dispatch(pending_function(REQUEST_MANUAL_INVOICE));
        var url = serverEnvironment.API_URL + '/billingPlan/requestManualInvoice';
        console.log(url)
        axiosConfig.headers.token = token;

        axios
            .post(url, billingPlan, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(REQUEST_MANUAL_INVOICE, res));
                
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(REQUEST_MANUAL_INVOICE, err));
            });

    }
}

export function updateInvoiceDetail(token, invoiceId, amount) {
    
    const { UPDATE_INVOICE_DETAIL } = financeActionTypes;

    return function (dispatch) {

        dispatch(pending_function(UPDATE_INVOICE_DETAIL));
        var url = serverEnvironment.API_URL + '/invoice/update-details?id='+invoiceId+'&amount='+amount;
        axiosConfig.headers.token = token;

        axios
            .post(url, {}, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(UPDATE_INVOICE_DETAIL, res));
                
            })
            .catch(err => {
                dispatch(rejected_function(UPDATE_INVOICE_DETAIL, err));
            });

    }
}

export function fetchPaymentDetailsOnFeeInvoice(userId, year, month) {
    const { FETCH_PAYMENT_DETAILS_INVOICE_FEE } = financeActionTypes;
    const { UPDATE_LOADING } = libraryActionTypes;
                return function (dispatch, getState) {
                 
                dispatch(pending_function(FETCH_PAYMENT_DETAILS_INVOICE_FEE));

                axios
                .post(serverEnvironment.AC_BACKEND_API_URL + '/api/v1/coach/fee-invoices/detail?userId=' +String(userId) +'&year='+year +'&month='+month, axiosConfig)
                .then(res => {
                    //dispatch(fulfilled_function(UPDATE_LOADING, false));
                    dispatch(fulfilled_function(FETCH_PAYMENT_DETAILS_INVOICE_FEE, res.data.data));

                })
                .catch(err => {
                   // dispatch(fulfilled_function(UPDATE_LOADING, false));
                    dispatch(rejected_function(FETCH_PAYMENT_DETAILS_INVOICE_FEE, err));
                });
            }
}

export function resetDetailsOnFeeInvoice() {
    const { RESET_PAYMENT_DETAILS_INVOICE_FEE } = financeActionTypes;
                return function (dispatch, getState) {
                    dispatch(fulfilled_function(RESET_PAYMENT_DETAILS_INVOICE_FEE, {}));
            }
}

export function regenerateInvoiceClient(id, genPdf, sendEmail, genStripeInvoice) {
    const { GENERATE_OPTIONS_INVOICE_CLIENT } = financeActionTypes;
                return function (dispatch, getState) {
                
                dispatch(pending_function(GENERATE_OPTIONS_INVOICE_CLIENT));

                axios
                .get(serverEnvironment.API_URL + '/invoice/pdf?ids=' +id +'&shouldGenPdf='+genPdf +'&shouldSendEmail='+sendEmail+'&shouldGenInvoice='+genStripeInvoice, axiosConfig)
                .then(res => {
                    dispatch(fulfilled_function(GENERATE_OPTIONS_INVOICE_CLIENT, {}));

                })
                .catch(err => {
                    dispatch(rejected_function(GENERATE_OPTIONS_INVOICE_CLIENT, err));
                });
            }
}

