import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { getInfoPackets } from '../../../hooks/store/actions/financeActions';
import ActivityGridRow from './ActivityGridRow';
import { monthNames } from '../../../hooks/store/actionConsts';
import Label from 'react-bootstrap/FormLabel';

class ActivityGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.onClick = this.onClick.bind(this);
        this.processRow = this.processRow.bind(this);
    }

    formatDate(d) {
        if (d && d.length >= 10) {
            var m = Number(d.substring(5, 7));
            return monthNames[m - 1] + " " + d.substring(8, 10) + ", " + d.substring(0, 4);
        }
        else
            return "";
    }

    processRow (row) {
            if (!row) {
                return "\r\n";
            }
            
            var contact = row.contacts && row.contacts.length > 0 ? row.contacts[0] : {};

            return "\"" +     
                    row.activityType + "\",\"" +    
                    row.title + "\",\"" + 
                    row.detail+ "\",\"" + 
                    this.formatDate(row.lastUpdatedDateUTC) + "\",\"" + 
                    String(contact.firstName).replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, "'") + " " +
                    String(contact.lastName).replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, "'") + "\",\"" +
                    String(contact.email) + "\"\r\n";

    }

    onClick() {        

        if (this.props.activity.length === 0) {
            swal("No data to export!");
            return;
        }

        var csvFile = "\"Activity Type\",\"Title\",\"Detail\",\"Activity Date\",\"Recipient Name\",\"Recipient Email\"\n";
        for (var i = 0; i < this.props.activity.length; i++) {
            csvFile += this.processRow(this.props.activity[i]);
        }

        var today = new Date();
        var fileName = "acadmin-activity-" + today.toDateString() + ".csv";

        var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, fileName);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }


    render() {
        return (
           <span>
            <div className="table-responsive invoiceModalTable" style={{ overflow: 'unset', whiteSpace: 'pre-line'}}>
                <Row className="grid-row wide-table row-header">
                        <Col  className="contactGridHead" sm={2} xs={2}><Label className="contactGridLabel">Activity Type</Label></Col>
                        <Col  className="contactGridHead" sm={2} xs={2}><Label className="contactGridLabel">Title</Label></Col>
                        <Col  className="contactGridHead" sm={2} xs={2}><Label className="contactGridLabel">Activity Date</Label></Col>
                        <Col  className="contactGridHead" sm={2} xs={2}><Label className="contactGridLabel">Recipient Name</Label></Col>
                        <Col  className="contactGridHead" sm={2} xs={2}><Label className="contactGridLabel">Recipient Email</Label></Col>                                                
                    </Row>
                    <ActivityGridRow activity={this.props.activity}/>
            </div>
                <Row className="grid-row-report medium-text wide-table">
                    <Button className="button-gridrow cardButton" onClick={this.onClick}>EXPORT TO CSV</Button>
                </Row>
            </span>
        );
    }
    
}
    
function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        contacts: state.contactReducer.contacts
    }
}

export default connect(mapStateToProps, { getInfoPackets })(ActivityGrid);
