import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { } from '../../../hooks/store/actions/financeActions.js';
import { monthNames } from '../../../hooks/store/actionConsts';


class InvoiceListRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDetails: false,
            currentRow: 0
        };        
    }

    formatDate(d) {
        if (d && d.length >= 10) {
            var m = Number(d.substring(5, 7));
            return monthNames[m - 1] + " " + d.substring(8, 10) + ", " + d.substring(0, 4);
        }
        else
            return "";
    }

    getStatus(id) {
        var ret = this.props.invoiceStatus.filter((a) => id === a.id);
        if (ret && ret.length > 0) {
            return ret[0].name;
        }
    }

    getPaymentMethod(id) {
        var ret = this.props.paymentMethods.filter((a) => Number(id) === a.id);
        if (ret && ret.length > 0) {
            return ret[0].name;
        }
    }

    getType(id) {
        var ret = this.props.invoiceTypes.filter((a) => id === a.id);
        if (ret && ret.length > 0) {
            return ret[0].name;
        }
    }

    lookupContact(id) {
        for (var i = 0; i < this.props.contacts.length; i++) {
            if (id === this.props.contacts[i].contactID) {
                return this.props.contacts[i];
            }
        }
        return undefined;
    }

    getEmail(j) {
       let email = this.lookupContact(j.contactID) ? this.lookupContact(j.contactID).email  : ""
       if (j.invoiceTypeID === 3)
         email = this.props.user.email;
       return email;
    }

    getTotal(j) {
        //console.log(this.props.totalColumnText)
        if (this.props.totalColumnText && this.props.totalColumnText !== 'Amount') {
            switch (this.props.totalColumnText) {
                case 'ISSUED':
                    return this.getInvoiceTotal(j) ? this.getInvoiceTotal(j).toFixed(2) : 0.00;
                case 'PAID':
                    return this.getTotalPayments(j) ? this.getTotalPayments(j).toFixed(2) : 0.00;
                case 'OVERDUE':
                    return this.getBalance(j) ? this.getBalance(j).toFixed(2) : 0.00;
                case 'UNPAID':
                    return this.getBalance(j) ? this.getBalance(j).toFixed(2) : 0.00;
                default:
                    return this.getInvoiceTotal(j) ? this.getInvoiceTotal(j).toFixed(2) : 0.00;
            }
        } else {
            return this.getInvoiceTotal(j) ? this.getInvoiceTotal(j).toFixed(2) : 0.00;
        }
    }

    getInvoiceTotal(j) {
        return j.invoiceTotal;
    }

    getBalance(j) {
        var pmt = this.getTotalPayments(j);
        if (pmt >= 0) {
            return j.invoiceTotal - pmt;
        }
        else {
            return j.invoiceTotal + pmt;
        }
    }

    /* Refunds should be negative #s*/
    getTotalPayments(j) {
        var ret = 0;
        for (var i = 0; i < j.paymentDetails.length; i++) {
            if (!j.paymentDetails[i].isVoid) {
                ret = ret + j.paymentDetails[i].amountReceived;
            }
        }
        return ret;
    }

    getCCType(id) {
        if (id === 1) {
            return "send invoice";
        }
        else if (id === 2) {
            return "auto";
        }
        else {
            return "manual";
        }
    }

    render() {

        return (
            this.props.invoices.map(
                (j, r) =>
                    <span key={j.invoiceID}  >
                        <Row className={r % 2 === 0 ? "grid-row-report row-white wide-table invoiceListCol" : "grid-row-report row-gray wide-table invoiceListCol"}>
                            <Col className="searchGridColumn" sm={2} xs={2}>{this.lookupContact(j.contactID) ? this.lookupContact(j.contactID).firstName + " " + this.lookupContact(j.contactID).lastName : ""}<div className="borderMarkerCol"></div></Col>
                            <Col className="searchGridColumnv2" sm={2} xs={2}>{this.getEmail(j)}<div className="borderMarkerCol"></div></Col>
                            <Col className="searchGridColumn" sm={2} xs={2}>{j.invoiceName}, {this.getType(j.invoiceTypeID)}<div className="borderMarkerCol"></div></Col>
                           
                            <Col className="searchGridColumn" sm={1} xs={1}>Sent {this.formatDate(j.createdDateUTC)}<div className="borderMarkerCol"></div></Col>
                            <Col className="searchGridColumn" sm={1} xs={1}>Pay By {this.getPaymentMethod(j.paymentMethodID)}<div className="borderMarkerCol"></div></Col>
                            <Col className="searchGridColumn" sm={1} xs={1}>${this.getTotal(j)}<div className="borderMarkerCol"></div></Col>
                            <Col className="searchGridColumn" sm={1} xs={1}>Due {this.formatDate(j.dueDate)}<div className="borderMarkerCol"></div></Col>
                            <Col className="searchGridColumn" sm={2} xs={2}>{this.getStatus(j.invoiceStatusID)}</Col>                            
                        </Row>                        
                    </span>

            )
        );
    }
}

function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        contacts: state.contactReducer.contacts,
        paymentMethods: state.sysReducer.paymentMethods,
        invoiceStatus: state.sysReducer.invoiceStatus,
        invoiceTypes: state.sysReducer.invoiceTypes
    }
}

export default connect(mapStateToProps, {})(InvoiceListRow);
