import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CheckBox from 'react-bootstrap/FormCheckInput';
import ContactRow from './ContactRow.js';
import Label from 'react-bootstrap/FormLabel';
import ViewEditContact from './ViewEditContact.js';
import { getEmptyContact, showEditForm } from '../../../hooks/store/actions/contactActions.js';
import { showNavbar } from '../../../hooks/store/actions/navBarActions'; 


class NewContact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };  
        this.props.getEmptyContact(this.props.logintoken);
        this.props.showNavbar(); 
    }

    render() {
        this.props.showEditForm(this.props.emptyContact);

        return (
            <span>
                {this.props.emptyContact && this.props.emptyContact.addresses && (
                    <ViewEditContact show={true} currentContact={this.props.emptyContact} />
                )}
            </span>
        );
    }
}

    
function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        contacts: state.contactReducer.contacts,
        emptyContact: state.contactReducer.emptyContact
    }
}

export default connect(mapStateToProps, { showEditForm, getEmptyContact, showNavbar })(NewContact);

