import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import ViewEditContact from './ViewEditContact.js';
import { getEmptyContact, showEditForm, editUserContact } from '../../../hooks/store/actions/contactActions.js';

class EditUserContact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };        
        
        this.onClickCancel = this.onClickCancel.bind(this);
        
    }

    onClickCancel() {
        this.props.editUserContact(false);
    }

    render() {        

        return (
            <div>
                {this.props.userContact && this.props.userContact.addresses && (

                    <Modal
                        {...this.props} 
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="modal-popup"
                    show={this.props.show}
                    onHide={this.onClickCancel}
                    >
                        <Modal.Body>
                            <ViewEditContact currentContact={this.props.userContact} show={true} />
                        </Modal.Body>
                </Modal>
                )}
            </div>

        );
    }
}

    
function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        contacts: state.contactReducer.contacts,
        currentContact: state.contactReducer.currentContact,
        userContact: state.contactReducer.userContact
    }
}

export default connect(mapStateToProps, { editUserContact, showEditForm})(EditUserContact);

