import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from 'react-bootstrap/FormLabel';
import DatePicker from "react-datepicker";
import { hideNavbar } from '../../../hooks/store/actions/navBarActions';
import { getInvoiceReport } from '../../../hooks/store/actions/affiliationActions';
import { AffiliationRow } from './AffiliationRow';


class AffiliationGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDate: new Date(),
            sort: "",
            direction: true,
        };

        this.props.hideNavbar();
        this.getReport = this.getReport.bind(this);
        this.onDateChanged = this.onDateChanged.bind(this);

        this.getSortedReport = this.getSortedReport.bind(this);
        this.sort = this.sort.bind(this);
    }

    getReport() {
        this.props.getInvoiceReport(this.props.logintoken, this.props.user.id, this.state.selectedDate);
    }

    onDateChanged(date) {
        this.setState({selectedDate: date});
    }
    
    sortBy(array, property, direction) {
        return array.slice(0).sort((a, b) => direction ? ((a[property] < b[property]) ? 1 : -1) : (a[property] > b[property]) ? 1 : -1);
    }

    getSortedReport() {
        var report = this.props.report.slice();
        if (report.length == 0) return report;
        
        let summary = report.reduce((prev, current) => {
            return {
                ClientName: "Total",
                PaymentType: "---",
                AffiliateTax: prev.AffiliateTax + current.AffiliateTax,
                AffiliateTotal: prev.AffiliateTotal + current.AffiliateTotal,
                BillingFee: prev.BillingFee + current.BillingFee,
                MerchantFee: prev.MerchantFee + current.MerchantFee,
                NetIncome: prev.NetIncome + current.NetIncome,
                NetIncomeBeforeTax: prev.NetIncomeBeforeTax + current.NetIncomeBeforeTax,
                NetPayment: prev.NetPayment + current.NetPayment,
                Total: prev.Total + current.Total,
                TotalBeforeTax: prev.TotalBeforeTax + current.TotalBeforeTax,
                TotalTax: prev.TotalTax + current.TotalTax,
            }
        });
        report.unshift(summary);
        return this.sortBy(report, this.state.sort, this.state.direction);
    }

    sort(type) {
        if (this.props.report) {
            if (this.state.sort === type) {
                this.setState({ direction: !this.state.direction });
            }
            else {
                this.setState({ sort: type, direction: true });
            }
        }
    }

    render() {
        var report = this.getSortedReport();

        return (
            <div className="reportGrid">
                <span className="contact-grid-form">
                    <Row>
                        <Col sm={1}>
                            <Label className="reports-header grid-column form-label">Select initial date</Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={1}>
                            <DatePicker
                                dateFormat="MMMM yyyy"
                                showMonthYearPicker
                                selected={this.state.selectedDate}
                                onChange={this.onDateChanged} />
                        </Col>
                        <Col sm={1}>
                            <button className="button-gridrow-edit-profile payorrefundActions mb15 btn btn-primary" onClick={this.getReport}>GET</button>
                        </Col>
                    </Row>
                </span>
                <span className="contact-grid-form">
                    <div className="table-responsive contactGridTableResponsive contactGridMobile" style={{ overflow: "unset", whiteSpace: "pre-line" }}>
                        <Row className="grid-row row-header wide-table wideTableMobile">
                            <Col className="grid-header contactGridHead" onClick={() => this.sort("ClientName")} xs={1} sm={1}><Label className="contactGridLabel" style={{ cursor: 'pointer' }}>Client Name</Label>{(this.state.sort === "ClientName" && this.state.direction === true) && (<span className="material-icons iconSort">expand_more</span>)} {(this.state.sort === "ClientName" && this.state.direction === false) && (<span className="material-icons iconSort">expand_less</span>)}{(this.state.sort !== "ClientName") && (<span className="material-icons iconSort">chevron_right</span>)}</Col>
                            <Col className="grid-header contactGridHead" onClick={() => this.sort("PaymentType")} xs={1} sm={1}><Label className="contactGridLabel" style={{ cursor: 'pointer' }}>Payment Type</Label>{(this.state.sort === "PaymentType" && this.state.direction === true) && (<span className="material-icons iconSort">expand_more</span>)} {(this.state.sort === "PaymentType" && this.state.direction === false) && (<span className="material-icons iconSort">expand_less</span>)}{(this.state.sort !== "PaymentType") && (<span className="material-icons iconSort">chevron_right</span>)}</Col>
                            <Col className="grid-header contactGridHead" onClick={() => this.sort("NetPayment")} xs={1} sm={1}><Label className="contactGridLabel" style={{ cursor: 'pointer' }}>Net Payment</Label>{(this.state.sort === "NetPayment" && this.state.direction === true) && (<span className="material-icons iconSort">expand_more</span>)} {(this.state.sort === "NetPayment" && this.state.direction === false) && (<span className="material-icons iconSort">expand_less</span>)}{(this.state.sort !== "NetPayment") && (<span className="material-icons iconSort">chevron_right</span>)}</Col>
                            <Col className="grid-header contactGridHead" onClick={() => this.sort("BillingFee")} xs={1} sm={1}><Label className="contactGridLabel" style={{ cursor: 'pointer' }}>Billing Fee</Label>{(this.state.sort === "BillingFee" && this.state.direction === true) && (<span className="material-icons iconSort">expand_more</span>)} {(this.state.sort === "BillingFee" && this.state.direction === false) && (<span className="material-icons iconSort">expand_less</span>)}{(this.state.sort !== "BillingFee") && (<span className="material-icons iconSort">chevron_right</span>)}</Col>
                            <Col className="grid-header contactGridHead" onClick={() => this.sort("MerchantFee")} xs={1} sm={1}><Label className="contactGridLabel" style={{ cursor: 'pointer' }}>Merchant Fee</Label>{(this.state.sort === "MerchantFee" && this.state.direction === true) && (<span className="material-icons iconSort">expand_more</span>)} {(this.state.sort === "MerchantFee" && this.state.direction === false) && (<span className="material-icons iconSort">expand_less</span>)}{(this.state.sort !== "MerchantFee") && (<span className="material-icons iconSort">chevron_right</span>)}</Col>
                            <Col className="grid-header contactGridHead" onClick={() => this.sort("AffiliateTax")} xs={1} sm={1}><Label className="contactGridLabel" style={{ cursor: 'pointer' }}>Affiliate Tax</Label>{(this.state.sort === "AffiliateTax" && this.state.direction === true) && (<span className="material-icons iconSort">expand_more</span>)} {(this.state.sort === "AffiliateTax" && this.state.direction === false) && (<span className="material-icons iconSort">expand_less</span>)}{(this.state.sort !== "AffiliateTax") && (<span className="material-icons iconSort">chevron_right</span>)}</Col>
                            <Col className="grid-header contactGridHead" onClick={() => this.sort("TotalTax")} xs={1} sm={1}><Label className="contactGridLabel" style={{ cursor: 'pointer' }}>Total Tax</Label>{(this.state.sort === "TotalTax" && this.state.direction === true) && (<span className="material-icons iconSort">expand_more</span>)} {(this.state.sort === "TotalTax" && this.state.direction === false) && (<span className="material-icons iconSort">expand_less</span>)}{(this.state.sort !== "TotalTax") && (<span className="material-icons iconSort">chevron_right</span>)}</Col>
                            <Col className="grid-header contactGridHead" onClick={() => this.sort("NetIncome")} xs={1} sm={1}><Label className="contactGridLabel" style={{ cursor: 'pointer' }}>Net Income</Label>{(this.state.sort === "NetIncome" && this.state.direction === true) && (<span className="material-icons iconSort">expand_more</span>)} {(this.state.sort === "NetIncome" && this.state.direction === false) && (<span className="material-icons iconSort">expand_less</span>)}{(this.state.sort !== "NetIncome") && (<span className="material-icons iconSort">chevron_right</span>)}</Col>
                            <Col className="grid-header contactGridHead" onClick={() => this.sort("NetIncomeBeforeTax")} xs={1} sm={1}><Label className="contactGridLabel" style={{ cursor: 'pointer' }}>Net Before Tax</Label>{(this.state.sort === "NetIncomeBeforeTax" && this.state.direction === true) && (<span className="material-icons iconSort">expand_more</span>)} {(this.state.sort === "NetIncomeBeforeTax" && this.state.direction === false) && (<span className="material-icons iconSort">expand_less</span>)}{(this.state.sort !== "NetIncomeBeforeTax") && (<span className="material-icons iconSort">chevron_right</span>)}</Col>
                            <Col className="grid-header contactGridHead" onClick={() => this.sort("TotalBeforeTax")} xs={1} sm={1}><Label className="contactGridLabel" style={{ cursor: 'pointer' }}>Total Before Tax</Label>{(this.state.sort === "TotalBeforeTax" && this.state.direction === true) && (<span className="material-icons iconSort">expand_more</span>)} {(this.state.sort === "TotalBeforeTax" && this.state.direction === false) && (<span className="material-icons iconSort">expand_less</span>)}{(this.state.sort !== "TotalBeforeTax") && (<span className="material-icons iconSort">chevron_right</span>)}</Col>
                            <Col className="grid-header contactGridHead" onClick={() => this.sort("AffiliateTotal")} xs={1} sm={1}><Label className="contactGridLabel" style={{ cursor: 'pointer' }}>Affiliate Total</Label>{(this.state.sort === "AffiliateTotal" && this.state.direction === true) && (<span className="material-icons iconSort">expand_more</span>)} {(this.state.sort === "AffiliateTotal" && this.state.direction === false) && (<span className="material-icons iconSort">expand_less</span>)}{(this.state.sort !== "AffiliateTotal") && (<span className="material-icons iconSort">chevron_right</span>)}</Col>
                            <Col className="grid-header contactGridHead" onClick={() => this.sort("Total")} xs={1} sm={1}><Label className="contactGridLabel" style={{ cursor: 'pointer' }}>Total</Label>{(this.state.sort === "Total" && this.state.direction === true) && (<span className="material-icons iconSort">expand_more</span>)} {(this.state.sort === "Total" && this.state.direction === false) && (<span className="material-icons iconSort">expand_less</span>)}{(this.state.sort !== "Total") && (<span className="material-icons iconSort">chevron_right</span>)}</Col>
                        </Row>
                        <div className="short-table shortTableMobile">
                            <AffiliationRow report={report}/>
                        </div>
                    </div>
                </span>
            </div>
        );
    }

    
}
    
function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        sysUser: state.loginReducer.sysUser,
        report: state.affiliationReducer.report,
    }
}

export default connect(mapStateToProps, { hideNavbar, getInvoiceReport })(AffiliationGrid);