import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getContactsById } from '../../../hooks/store/actions/contactActions.js';
import { showNavbar } from '../../../hooks/store/actions/navBarActions'; 


class AffiliateView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };

        this.props.showNavbar(); 

    }

    formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
        if (match) {
          var intlCode = (match[1] ? '+1 ' : '')
          return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
        }
        return null
      }

    componentDidMount() {        
    }

    render() {
        return (
            <div className="leftNav">
                <div className="large-text">
                    {this.props.user.firstName}&nbsp;{this.props.user.lastName}
                </div>
                <br/>
                <div className="logo">
                    <img height="75" src={this.props.user ? this.props.user.logoURL : this.props.userContact.companyLogoURL} alt=""/>
                </div>
                <div className="medium-text">
                    {this.props.userContact ? this.props.userContact.companyName : ""}
                </div>
                <hr style={{width: '65%', borderTop: '2px solid black'}}/>
                <div className="small-text">Phone: {this.formatPhoneNumber(this.props.user.phone)}</div>    
                <div className="small-text">Mobile Phone: {this.formatPhoneNumber(this.props.userContact ? this.props.userContact.mobilePhone : "")}</div>
                <div className="small-text">Email: {this.props.user.email}</div>                
                <div className="small-text">CTP Program: {this.props.user.programInformation}</div>
                <div className="small-text">Credential/Certification: {this.props.user.certsAndCredentials}</div>
            </div>
                  
            )        
    }
}

function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        userContact: state.contactReducer.userContact
    }
}

export default connect(mapStateToProps, { getContactsById, showNavbar })(AffiliateView);
