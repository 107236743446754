// ActionConstants.js
//

var apiURL = '', aspnetURL = '', maintMode = '';

if (process.env && process.env.API_URL) {
    apiURL = process.env.API_URL;
    //console.log("Using " + serverEnvironment.API_URL);
}
if (process.env && process.env.ASPNET_URL) {
    aspnetURL = process.env.ASPNET_URL;
    //console.log("Using " + serverEnvironment.ASPNET_URL);
}
if (process.env && process.env.MAINT_MODE) {
    maintMode = process.env.MAINT_MODE;
    //console.log("Using " + serverEnvironment.MAINT_MODE);
}

// This is only for local dev - the actual prod values are pulled from the App Service Config and setup on index.js
export var serverEnvironment = {
    API_URL: (apiURL != '') ? apiURL : "https://acadminmt-dev.azurewebsites.net",
    //API_URL: (apiURL != '') ? apiURL : "http://localhost:2430",
    ASPNET_URL: (aspnetURL != '') ? aspnetURL : "https://webforms-sandbox.acadmin.coach/react/",
    //ASPNET_URL: (aspnetURL != '') ? aspnetURL : "http://local.acadmin.coach//react/",
    MAINT_MODE: (maintMode != '') ? maintMode : "none",
    //TODO - remove or fix MAINT_MODE flag
}

//console.log("serverEnvironment=.........", serverEnvironment);

export const monthNames = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.",
    "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."
];

export const getClientStatus = function(c) {
    return c.clientStatus;
}

export const formatCurrency = function(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const sleep = function(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const pending = function (status) {
    return status.concat("_PENDING");
}

export const rejected = function (status) {
    return status.concat("_REJECTED");
}

export const fulfilled = function (status) {
    return status.concat("_FULFILLED");
}


export function pending_function(type) {
    return {
        type: pending(type)
    }
}

export function fulfilled_function(type, res) {
    return {
        type: fulfilled(type),
        payload: res
    }
}

export function rejected_function(type, err) {
    return {
        type: rejected(type),
        payload: err
    }
}

export const affiliationActionTypes = {
    GET_INVOICE_REPORT: "GET_INVOICE_REPORT",
    GET_AFFILIATION_PROGRESS: "GET_AFFILIATION_PROGRESS",
}

export const libraryActionTypes = {
    GET_LIBRARY_DATA: "GET_LIBRARY_DATA",
    DOWNLOAD_FILE_LIBRARY : "DOWNLOAD_FILE_LIBRARY",
    UPDATE_OPEN_ITEM : "UPDATE_OPEN_ITEM",
    UPDATE_LOADING : "UPDATE_LOADING",
}


export const loginActionTypes = {
    START_LOGIN: "START_LOGIN",
    CANCEL_LOGIN: "CANCEL_LOGIN",
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    REGISTER: "REGISTER",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    RESET_PASSWORD: "RESET_PASSWORD",
    VERIFY_TOKEN: "VERIFY_TOKEN",
    SEND_MAIL: "SEND_MAIL",
    SEND_MAIL_RESET: "SEND_MAIL_RESET",
    READY_TO_VERIFY: "READY_TO_VERIFY",
    START_REGISTERING: "START_REGISTERING",
    DONE_REGISTERING: "DONE_REGISTERING",
    VERIFY_EMAIL: "VERIFY_EMAIL",
    EDIT_USER_PROFILE: "EDIT_USER_PROFILE",
    SHOW_EDIT_PROFILE: "SHOW_EDIT_PROFILE",
    CANCEL_EDIT_PROFILE: "CANCEL_EDIT_PROFILE",
    REQUEST_TOKEN: "REQUEST_TOKEN",
    GET_ALL_USERS: "GET_ALL_USERS",
    SELECT_USER: "SELECT_USER",
    SHOW_EDIT_USER_FORM: "SHOW_EDIT_USER_FORM",
    DEACTIVATE_USER: "DEACTIVATE_USER",
    IMPERSONATE_USER: "IMPERSONATE_USER",
    GET_USER_SYSNOTIFICATIONS: "GET_USER_SYSNOTIFICATIONS",
    DISMISS_USER_SYSNOTIFICATION: "DISMISS_USER_SYSNOTIFICATION",
    NEW_DATA_PROFILE: "NEW_DATA_PROFILE",
    DISCONNECT_STRIPE_CONNECT: "DISCONNECT_STRIPE_CONNECT",
}

export const contactActionTypes = {
    GET_CONTACT_BY_USER: "GET_CONTACT_BY_USER",
    GET_CONTACT: "GET_CONTACT",
    INSERT_CONTACT: "INSERT_CONTACT",
    UPDATE_CONTACT: "UPDATE_CONTACT",
    DELETE_CONTACT: "DELETE_CONTACT",
    SHOW_CONTACT_LIST: "SHOW_CONTACT_LIST",
    HIDE_CONTACT_LIST: "HIDE_CONTACT_LIST",
    SHOW_EDIT_FORM: "SHOW_EDIT_FORM",
    DELETE_ADDRESS: "DELETE_ADDRESS",
    SHOW_ADDRESS_FORM: "SHOW_ADDRESS_FORM",
    SAVE_ICON: "SAVE_ICON",
    EMPTY_CONTACT: "EMPTY_CONTACT",
    EDIT_USER_CONTACT: "EDIT_USER_CONTACT",
    SEARCH_CONTACT: "SEARCH_CONTACT",
    CANCEL_SEARCH: "CANCEL_SEARCH",
    SELECT_CONTACT: "SELECT_CONTACT",
    SET_PERSONAL_DATA: "SET_PERSONAL_DATA",
    INITIALIZE_GIF: "INITIALIZE_GIF",
    SEND_CONTACT_STRIPE_LINK: "SEND_CONTACT_STRIPE_LINK",
    RESEND_CSA_PDF: "RESEND_CSA_PDF",
    SEND_INFO_PACKET: "SEND_INFO_PACKET",
    UPDATE_ADDRESS: "UPDATE_ADDRESS"
}

export const systemActionTypes = {
    GENDERS: "GENDERS",
    CONTACT_TYPE: "CONTACT_TYPE",
    MARITAL_STATUS: "MARITAL_STATUS",
    ADDRESS_TYPE: "ADDRESS_TYPE",
    PAYMENT_METHOD: "PAYMENT_METHOD",
    INVOICE_STATUS: "INVOICE_STATUS",
    INVOICE_TYPE: "INVOICE_TYPE",
    CC_CHARGE_TYPE: "CC_CHARGE_TYPE",
    TAX_RATES: "TAX_RATES",
    GET_REPORTS: "GET_REPORTS",
    RUN_REPORT: "RUN_REPORT",
    SET_PARAM: "SET_PARAM",
    CLEAR_PARAMS: "CLEAR_PARAMS",
    SORT_COL: "SORT_COL",
    SELECT_REPORT: "SELECT_REPORT",
    GET_SYSTEMNOTIFICATIONS: "GET_SYSTEMNOTIFICATIONS",
    UPDATE_SYSTEMNOTIFICATION: "UPDATE_SYSTEMNOTIFICATION",
    DELETE_SYSTEMNOTIFICATION: "DELETE_SYSTEMNOTIFICATION",
    ADD_SYSTEMNOTIFICATION: "ADD_SYSTEMNOTIFICATION",
    SET_SUBOPTION: "SET_SUBOPTION",
    OPEN_MODAL_BILLEXPORTDETAILS: "OPEN_MODAL_BILLEXPORTDETAILS",
    OPEN_MODAL_DETAILS: "OPEN_MODAL_DETAILS",
}

export const financeActionTypes = {
    DASHBOARD: "DASHBOARD",
    GET_CLIENTS: "GET_CLIENTS",
    GET_BILLING_PLANS: "GET_BILLING_PLANS",
    GET_INVOICES: "GET_INVOICES",
    SHOW_CLIENT_DETAILS: "SHOW_CLIENT_DETAILS",
    CANCEL_BILLING_PLAN: "CANCEL_BILLING_PLAN",
    DEACTIVATE_BILLING_PLAN: "DEACTIVATE_BILLING_PLAN",
    ACTIVATE_BILLING_PLAN: "ACTIVATE_BILLING_PLAN",
    GET_ALL_INVOICES: "GET_ALL_INVOICES",
    GET_ALL_ACTIVITY: "GET_ALL_ACTIVITY",
    GET_INFO_PACKET_ACTIVITY: "GET_INFO_PACKET_ACTIVITY",
    GET_AGREEMENT_TEMPLATES: "GET_AGREEMENT_TEMPLATES",
    REQUEST_TEMPLATE_CHANGE: "REQUEST_TEMPLATE_CHANGE",
    UPDATE_BILLING_PLAN: "UPDATE_BILLING_PLAN",
    ISSUE_PAYMENT: "ISSUE_PAYMENT",
    GET_EMPTY_INVOICE: "GET_EMPTY_INVOICE",
    SAVE_INVOICE: "SAVE_INVOICE",
    VOID_INVOICE: "VOID_INVOICE",
    RECORD_INVOICE_DETAIL: "RECORD_INVOICE_DETAIL",
    RECORD_INVOICE_DATA: "RECORD_INVOICE_DATA",
    VOID_PAYMENT: "VOID_PAYMENT",
    REISSUE_INVOICE: "REISSUE_INVOICE",
    REQUEST_MANUAL_INVOICE: "REQUEST_MANUAL_INVOICE",
    UPDATE_INVOICE_DETAIL: "UPDATE_INVOICE_DETAIL",
    FETCH_PAYMENT_DETAILS_INVOICE_FEE: "FETCH_PAYMENT_DETAILS_INVOICE_FEE",
    RESET_PAYMENT_DETAILS_INVOICE_FEE: "RESET_PAYMENT_DETAILS_INVOICE_FEE",
    GENERATE_OPTIONS_INVOICE_CLIENT: "GENERATE_OPTIONS_INVOICE_CLIENT",
}

export const navBarActionTypes = {
    SHOW_NAVBAR: "SHOW_NAVBAR",
    HIDE_NAVBAR: "HIDE_NAVBAR",
    SHOW_EDITPROF: "SHOW_EDITPROF", 
    HIDE_EDITPROF: "HIDE_EDITPROF"
}
