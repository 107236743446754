import axios from 'axios';

import { serverEnvironment } from '../actionConsts';

export var axiosConfig = {
    headers: { 'token': '', 'refUrl': document.location.host }
};


export function sendRequestClient(token, subject, email, body) {
    var url = serverEnvironment.API_URL + '/email/client-request';
    axiosConfig.headers.token = token;

    const payload = {
        to: email,
        body: body,
        subject: subject
    }

   return axios
        .post(url, payload, axiosConfig);
}