import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Label from 'react-bootstrap/FormLabel';
import Container from 'react-bootstrap/Container';
import { sortCol, setOpenModalBillExportDetails, setOpenModalDetails } from '../../../hooks/store/actions/sysActions';
import { fetchPaymentDetailsOnFeeInvoice, resetDetailsOnFeeInvoice } from '../../../hooks/store/actions/financeActions';
import NewModal from './NewModal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


class ReportRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.processFetchPaymentDetails = this.processFetchPaymentDetails.bind(this);
    }

    processFetchPaymentDetails(value) {
        const values = value.split('&');
        const userId = values.find ( p => p.includes('AFFILIATE')).split("=")[1];
        const year = values.find ( p => p.includes('YEAR')).split("=")[1];
        const month = values.find ( p => p.includes('MONTH')).split("=")[1];
       // this.props.updateLoading(true);
        this.props.fetchPaymentDetailsOnFeeInvoice(userId, year, month);
        setTimeout(() => {
            this.props.setOpenModalDetails(true);
        }, 500);
    }


    renderValue(value) {
        if(!value){
            return "";
        }
        if(value.includes("https")){
            return <a target="_blank" rel="noopener noreferrer" href={value}>Show</a>
        }
        if(value.includes("SEE_MORE")){
            return <a href="#" rel="noopener noreferrer" onClick={() => this.props.setOpenModalBillExportDetails() }>Details</a>
        }
        if(value.includes("SHOW_DETAILS")){
            return <a href="#" rel="noopener noreferrer" onClick={() => this.processFetchPaymentDetails(value) }>Details</a>
        }
        return value;
    }

    render() {
        return (
            this.props.data.map(
                (j, r) =>
                    <Col className={j != null ? (j.startsWith("$") || j.startsWith("($") ? "reportGridDataRight" : j.includes("@") ? "reportGridDataExtended" : "reportGridData") :  "reportGridData" } key={"datarow" + r}>{this.renderValue(j)}</Col>
            )
            )
    }

}

class ReportHead extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };       
    }

    render() {
    return (
        this.props.columnList.map(
        (j, r) =>
            <Col onClick={() => this.props.sortCol(r)} className={j.SqlValue == 'SqlMoney' ? "reportGridHeadRight" : "reportGridHead"} key={"headerCol_" + r}>
                    <label className="contactGridLabel form-label" style={{ cursor: 'pointer', whiteSpace: 'nowrap' }} key={'lbl_' + r}>{j.name}</label>

                    {this.props.sortColumn === r && this.props.sortDir ? <span className="material-icons reportIconSort" key={'sortMore_' + r}>expand_more</span> : <></>}
                    {this.props.sortColumn === r && !this.props.sortDir ? <span className="material-icons reportIconSort" key={'sortLess_' + r}>expand_less</span> : <></>}
                    {this.props.sortColumn !== r ? <span className="material-icons reportIconSort" key={'sort_' + r}>chevron_right</span> : <></>}
                    &nbsp;
            </Col>

        )
    )
    }

}

class ReportResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // showModalBillExportDetails: false,
            dataMock: [
                {field1: 'Rita Philips', field2: '$300.00', field3: '03/05/2023 09:00', field4: '', field5: 'Pending'},
                {field1: 'Aex Terranova', field2: '$250.00', field3: '02/05/2023 11:00', field4: '', field5: 'Proccesed'},
                {field1: 'Jhon Stuart', field2: '$500.00', field3: '01/05/2023 12:00', field4: '01/05/2023 13:00', field5: 'Completed'},
            ]
        };
        this.onClickCancelModalBillExportDetails = this.onClickCancelModalBillExportDetails.bind(this);
        this.formatDate = this.formatDate.bind(this);
    }

    onClickCancelModalBillExportDetails() {
        console.log('close modal');
        // this.props.editUserContact(false);
        this.props.setOpenModalBillExportDetails(false);
    }

    formatDate(dateString) {
        const date = new Date(dateString);
    
        let day = date.getDate().toString().padStart(2, '0');
        let month = (date.getMonth() + 1).toString().padStart(2, '0'); // January is 0
        let year = date.getFullYear();
    
        //let hours = date.getHours().toString().padStart(2, '0');
        //let minutes = date.getMinutes().toString().padStart(2, '0');
    
       // return `${year}-${month}-${day} ${hours}:${minutes}`;
       return `${year}-${month}-${day}`;
    }
    

    render() {
        return (
            <div>
                {this.props.data.length ? (
                    <div>
                        <Modal
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            className="modal-popup"
                            show={this.props.showModalBillExportDetails}
                            onHide={this.onClickCancelModalBillExportDetails}
                            style={{width: '60%', marginLeft: '20%', marginTop: '10%', marginRight: '20%', height: '500px'}}
                            >
                             <Modal.Body className="full-screen" style={{height: '450px'}}>
                                <Container  className="Modal.Body form-editcontact modal-small">

                                    <div className="reportGrid">
                                        <span className="contact-grid-form">
                                            <div className="table-responsive contactGridTableResponsive contactGridMobile" style={{ overflow: "unset", whiteSpace: "pre-line" }}>
                                                <Row className="grid-row row-header wide-table wideTableMobile">
                                                    <Col className="grid-header contactGridHead"  sm={2}><Label className="contactGridLabel" style={{ cursor: 'pointer' }}>Affiliate</Label></Col>
                                                    <Col className="grid-header contactGridHead"  sm={2}><Label className="contactGridLabel" style={{ cursor: 'pointer' }}>Amount</Label></Col>
                                                    <Col className="grid-header contactGridHead"  sm={2}><Label className="contactGridLabel" style={{ cursor: 'pointer' }}>Date</Label></Col>
                                                    <Col className="grid-header contactGridHead"  sm={2}><Label className="contactGridLabel" style={{ cursor: 'pointer' }}>Date Updated</Label></Col>
                                                    <Col className="grid-header contactGridHead"  sm={2}><Label className="contactGridLabel" style={{ cursor: 'pointer' }}>Status</Label></Col>
                                                    <Col className="grid-header contactGridHead"  sm={2}><Label className="contactGridLabel" style={{ cursor: 'pointer' }}></Label></Col>
                                                </Row>
                                                <div>
                                                    {this.state.dataMock.map(
                                                            (item, key) =>
                                                            <Row className={key % 2 === 0 ? "grid-row row-white settingsTableScroll" : "grid-row row-gray settingsTableScroll"} key={key}>
                                                                <Col className="centerUsersCol" sm={2}><span>{item.field1}</span><div className="borderMarkerCol"></div></Col>
                                                                <Col className="centerUsersCol" sm={2}><span>{item.field2}</span><div className="borderMarkerCol"></div></Col>
                                                                <Col className="centerUsersCol" sm={2}><span>{item.field3}</span><div className="borderMarkerCol"></div></Col>
                                                                <Col className="centerUsersCol" sm={2}><span>{item.field4}</span><div className="borderMarkerCol"></div></Col>
                                                                <Col className="centerUsersCol" sm={2}><span>{item.field5}</span><div className="borderMarkerCol"></div></Col>
                                                                <Col className="centerUsersCol" sm={2}><span className="material-icons iconSort" style={{ color: '#158a69' }}>edit</span></Col>
                                                            </Row>
                                                            )}
                                                </div>
                                            </div>
                                        </span>

                                        <Row>
                                            &nbsp;
                                        </Row>
                                        <Row>
                                            &nbsp;
                                        </Row>
                                        <Row>
                                            <Col sm={10}><button className="button-gridrow-new-contact" onClick={this.onClickCancelModalBillExportDetails}>Close</button></Col>
                                        </Row>

                                    </div>



                                </Container>
                            </Modal.Body>
                        </Modal>
                        <NewModal title={'Payments details'} onClose={this.props.resetDetailsOnFeeInvoice}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">InvoiceID</TableCell>
                                                <TableCell align="center">Invoice Name</TableCell>
                                                <TableCell align="center">Paid Date</TableCell>
                                                <TableCell align="center">Contact</TableCell>
                                                <TableCell align="center">Amount</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {this.props.paymentDetailsFee.length ? (
                                            this.props.paymentDetailsFee.map(
                                                (j, r) => 
                                                        <TableRow key={r}>
                                                            <TableCell align="left">{j.invoiceID}</TableCell>
                                                            <TableCell align="left">{j.invoiceName}</TableCell>
                                                            <TableCell width={'120px'} align="left">{this.formatDate(j.datePaid)}</TableCell>
                                                            <TableCell align="left">{j.contactName}</TableCell>
                                                            <TableCell align="left">{j.amount}</TableCell>
                                                        </TableRow>
                                            )
                                        ) : (
                                            <TableRow>
                                            <TableCell colSpan={5} align="center"><p>No payments found</p></TableCell>
                                            </TableRow>
                                        )}    
                                        
                                        </TableBody>
                                </Table>
                            </TableContainer>    
                       </NewModal>     

                        <p style={{fontSize: '16px', marginTop: '30px'}}>{this.props.data.length} rows </p>
                        {this.props.data.map(
                                (j, r) =>
                                    <div key={'divReport_' + r}>
                                        <div className="grid-row report-row-header reportRowData row" key={"gridDiv_" + r}>
                                            {(r === 0) && <ReportHead sortDir={this.props.sortDir} sortCol={this.props.sortCol} sortColumn={this.props.sortColumn} columnList={this.props.columnList} key={'reportHead_' + r} />}
                                        </div>
                                        <Row key={"gridRow_" + r} className={r % 2 === 0 ? "grid-row row-white reportRowData" : "grid-row row-gray reportRowData"}>
                                            <ReportRow sortCol={this.props.sortCol} data={j.values} key={'reportRow_' + r} setOpenModalBillExportDetails={this.props.setOpenModalBillExportDetails} 
                                            setOpenModalDetails={this.props.setOpenModalDetails} fetchPaymentDetailsOnFeeInvoice={this.props.fetchPaymentDetailsOnFeeInvoice} />
                                        </Row>
                                    </div>
                        )}
                    </div>) : <p style={{fontSize: '16px', marginTop: '30px'}}>No results</p>}
            </div>
         )
    }
}

function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        sortDir: state.sysReducer.sortDir,
        sortColumn: state.sysReducer.sortCol,
        showModalBillExportDetails: state.sysReducer.openModalBillExportDetails,
        paymentDetailsFee: state.financeReducer.paymentDetailsFee,
    }
}

export default connect(mapStateToProps, { sortCol, setOpenModalBillExportDetails, setOpenModalDetails, fetchPaymentDetailsOnFeeInvoice, resetDetailsOnFeeInvoice })(ReportResult);
