import React from 'react';
import { connect } from 'react-redux';
import AffiliationGrid from './affiliation/AffiliationGrid';


class AffiliationPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <>
                <AffiliationGrid />
            </>
        );
    }

    
}
    
function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        sysUser: state.loginReducer.sysUser,
    }
}

export default connect(mapStateToProps, {  })(AffiliationPage);