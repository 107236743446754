/*
 * Login.js handles everything relating to 
 * user security and account management
 *  
 * */

import axios from 'axios';

import { contactActionTypes, loginActionTypes, pending_function, rejected_function, fulfilled_function, serverEnvironment } from '../actionConsts';

export var axiosConfig = {
    headers: { 'token': '', 'refUrl': document.location.host }
};


/*
 * getEmptyContact() returns empty contact
 * 
 * */
export function getEmptyContact(token) {
    const { EMPTY_CONTACT } = contactActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(EMPTY_CONTACT));

        var url = serverEnvironment.API_URL + '/contact/empty';
        axiosConfig.headers.token = token;

        axios
            .get(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(EMPTY_CONTACT, res));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(EMPTY_CONTACT, err));
            });

    }
}

/*
 * getContactsByUser() returns contacts by user
 * 
 * */
export function getContactsByUser(token, userId) {
    const { GET_CONTACT_BY_USER } = contactActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(GET_CONTACT_BY_USER));

        var url = serverEnvironment.API_URL + '/contact/report/' + String(userId);
        axiosConfig.headers.token = token;

        axios
            .get(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(GET_CONTACT_BY_USER, res));
            })
            .catch(err => {                
                if (err.response && err.response.status === 401) {                    
                    dispatch(fulfilled_function(LOGOUT), err);                    
                }
                dispatch(rejected_function(GET_CONTACT_BY_USER, err));
            });

    }
}

/*
 * getContactsById() returns contacts by contact ID
 * 
 * */
export function getContactsById(token, contactId, affiliateId) {
    const { GET_CONTACT } = contactActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(GET_CONTACT));

        var url = serverEnvironment.API_URL + '/contact/' + String(contactId) + '/' + String(affiliateId);
        axiosConfig.headers.token = token;

        axios
            .get(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(GET_CONTACT, res));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(GET_CONTACT, err));
            });

    }
}


/*
 * searchContacts() returns contacts by name or email
 * 
 * */
export function searchContacts(token, affiliateId, term) {
    const { SEARCH_CONTACT } = contactActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(SEARCH_CONTACT));

        var url = serverEnvironment.API_URL + '/contact/search/' + String(affiliateId) + "/" + term;
        if (term.includes(".")) {
            url = url + "/";
        }
        axiosConfig.headers.token = token;

        axios
            .get(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(SEARCH_CONTACT, res));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(SEARCH_CONTACT, err));
            });

    }
}

/*
 * insertContact() writes a contact
 * 
 * */
export function insertContact(token, contact) {
    const { INSERT_CONTACT } = contactActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(INSERT_CONTACT));

        var url = serverEnvironment.API_URL + '/contact';
        axiosConfig.headers.token = token;

        axios
            .post(url, contact, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(INSERT_CONTACT, res));                
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(INSERT_CONTACT, err));
            });

    }
}

// Shows or hides the edit contact form
export function showEditForm(contact) {
    const { SHOW_EDIT_FORM } = contactActionTypes;

    return function (dispatch, getState) {
        dispatch(fulfilled_function(SHOW_EDIT_FORM, contact));
    }
}

/*
 * updateContact() writes a contact
 * 
 * */
export function updateContact(token, contact) {
    const { UPDATE_CONTACT } = contactActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(UPDATE_CONTACT));

        var url = serverEnvironment.API_URL + '/contact/' + String(contact.contactID);
        axiosConfig.headers.token = token;

        axios
            .put(url, contact, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(UPDATE_CONTACT, res));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(UPDATE_CONTACT, err));
            });

    }
}

export function sendUpdateCreditCardLink(token, contact, country) {
    const { SEND_CONTACT_STRIPE_LINK } = contactActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(SEND_CONTACT_STRIPE_LINK));

        var url = serverEnvironment.API_URL + '/contact/sendUpdateCreditCardLink/' + String(contact.contactID) + '/' + String(contact.affiliateID) + '/' + country;
        axiosConfig.headers.token = token;

        axios
            .post(url, contact, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(SEND_CONTACT_STRIPE_LINK, res));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(SEND_CONTACT_STRIPE_LINK, err));
            });
    }
}


/*
 * deleteContact() writes a contact
 * 
 * */
export function deleteContact(token, contactId) {
    const { DELETE_CONTACT } = contactActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(DELETE_CONTACT));

        var url = serverEnvironment.API_URL + '/contact/' + String(contactId);
        axiosConfig.headers.token = token;        

        axios
            .delete(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(DELETE_CONTACT, contactId));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(DELETE_CONTACT, err));
            });

    }
}

/*
 * updateAddress(address) Notify when the address in the AdressDetail form is being modified.
 *
 * */
export function updateAddress(address) {
    const { UPDATE_ADDRESS } = contactActionTypes;

    return function(dispatch, getState) {
        dispatch(fulfilled_function(UPDATE_ADDRESS, address));
    }
}

/*
 * deleteAddress() deletes an address from the current contact
 * 
 * */
export function deleteAddress(address) {
    const { DELETE_ADDRESS } = contactActionTypes;

    return function (dispatch, getState) {        
        dispatch(fulfilled_function(DELETE_ADDRESS, address)); 
    }
}

/*
 * showAddressForm() shows or hides the address form
 * 
 * */
export function showAddressForm(show) {
    const { SHOW_ADDRESS_FORM } = contactActionTypes;

    return function (dispatch, getState) {
        dispatch(fulfilled_function(SHOW_ADDRESS_FORM, show));
    }
}

/*
 * 
 * */
export function saveIcon(token, userId, name, icon) {
    const { SAVE_ICON } = contactActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(SAVE_ICON));

        var payload = {
            id: 0,
            description: '',
            sort: 0,
            name: name,
            userID: userId,
            iconBlob: icon,
        }

        var url = serverEnvironment.API_URL + '/image';
        axiosConfig.headers.token = token;

        axios
            .post(url, payload, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(SAVE_ICON, res));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(SAVE_ICON, err));
            });

    }
}

/*
 * Shows or hides the user contact page
 * 
 * */
export function editUserContact(show) {
    const { EDIT_USER_CONTACT } = contactActionTypes;

    return function (dispatch, getState) {
        dispatch(fulfilled_function(EDIT_USER_CONTACT, show));
    }
}


/*
 * Hides search results
 * 
 * */
export function cancelSearch() {
    const { CANCEL_SEARCH } = contactActionTypes;

    return function (dispatch, getState) {
        dispatch(fulfilled_function(CANCEL_SEARCH));
    }
}

/*
 * Selects a contact
 * 
 * */
export function selectContact(contact) {
    const { SELECT_CONTACT } = contactActionTypes;

    return function (dispatch, getState) {
        dispatch(fulfilled_function(SELECT_CONTACT, contact));
    }
}

/*
 * Stashes the current personal data for a new contact
 * 
 * */
export function setPersonalData(data) {
    const { SET_PERSONAL_DATA } = contactActionTypes;
    return function (dispatch, getState) {
        dispatch(fulfilled_function(SET_PERSONAL_DATA, data));
    }
}

/*
Sets the gif to false on homepage mount (edge case for page refresh during search)
*/
export function initializeGif() {
    const { INITIALIZE_GIF } = contactActionTypes;
    return function (dispatch, getState) {
        dispatch(fulfilled_function(INITIALIZE_GIF)); 
    }
}

/*
Re-Sends CSA PDF to Client Email
*/
export function resendCSA_PDF(token, email, attachmentURL, attachmentName, affiliateName) {
    const { RESEND_CSA_PDF } = contactActionTypes;

    return function (dispatch, getState) {

        dispatch(pending_function(RESEND_CSA_PDF));

        var url = serverEnvironment.API_URL + '/email/resendCSA';
        axiosConfig.headers.token = token;

        var payload = {
            to: email,
            attachmentURL: attachmentURL,
            attachmentName: attachmentName,
            subject: 'Copy of Client Services Agreement from ' + affiliateName,
            body: 'Attached please find a copy of your most recent signed Client Services Agreement.'
        }

        axios
            .post(url, payload, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(RESEND_CSA_PDF, res));
            })
            .catch(err => {
                dispatch(rejected_function(RESEND_CSA_PDF, err));
            });
    }
}

/*
Send Information Packet
*/
export function SIP(token, firstName, lastName, email, contactId, userId) {
    const { SEND_INFO_PACKET } = contactActionTypes;

    return function (dispatch, getState) {

        dispatch(pending_function(SEND_INFO_PACKET));

        var url = serverEnvironment.API_URL + '/contact/sendInformationPacket';
        axiosConfig.headers.token = token;

        var payload = {
            FirstName: firstName,
            LastName: lastName,
            Email: email,
            ContactID: contactId,
        }

        axios
            .post(url, payload, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(SEND_INFO_PACKET, res));
            })
            .catch(err => {
                dispatch(rejected_function(SEND_INFO_PACKET, err));
            })
            .then((res) => {
                getContactsByUser(token, userId)
            })
    }
}
