import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from 'react-bootstrap/FormLabel';
import Button from 'react-bootstrap/Button';
import CheckBox from 'react-bootstrap/FormCheckInput';
import Select from 'react-styled-select';
import { getAgreementTemplates, requestTemplateChange } from '../../../hooks/store/actions/financeActions';
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MediaQuery from 'react-responsive';

class PendingChanges extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="languagechangesheader">PENDING CHANGES</div>
                <div className="languagechangesbox">
                    {this.props.changes.map(
                        (j, r) =>
                            <span key={r}  >
                                <Row className="border-row languagechangerow" sm={8}>
                                    <Col xs={12} sm={6} md={6}>
                                        <Row>
                                            <Col className="languagechangerowItem" sm={6}><b>Original:</b> {j.oldWording}</Col>
                                            <Col className="languagechangerowItem" sm={6}><b>Change to:</b> {j.newWording}</Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </span>
                    )}
                </div>
            </>
        )
    }
}

class TemplateViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTemplate: 0,
            currTemplate: {},
            newClient: false,
            changes: [],
            show: this.props.show
        };

        this.props.getAgreementTemplates(this.props.logintoken, this.props.user.id);
        this.onSelectTemplate = this.onSelectTemplate.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onCheck = this.onCheck.bind(this);
        this.onAddChange = this.onAddChange.bind(this);
    }

    onSelectTemplate(t) {
        this.setState({ selectedTemplate: t });
        var item = this.props.templates.filter((a) => a.templateId === t);
        if (item && item.length > 0) {
            this.setState({ currTemplate: item[0] });
        } else {
            this.setState({ currTemplate: {} });
        }
    }

    onClick() {
        //swal('This will submit this request to  Assistant@accomplishmentcoaching.com, cc: office@accomplishmentcoaching.com  ');

        var old = [];
        var news = [];
        var changes = this.state.changes;

        if (this.state.newWording && this.state.oldWording) {
            console.log("direct entry"); 
            changes.push({ oldWording: this.state.oldWording, newWording: this.state.newWording });
            this.setState({ oldWording: "", newWording: "", changes: changes });

            for (var n = 0; n < this.state.changes.length; n++) {
                old.push(this.state.changes[n].oldWording);
                news.push(this.state.changes[n].newWording)
            }
        } 
        else {
            for (var i = 0; i < this.state.changes.length; i++) {
                old.push(this.state.changes[i].oldWording);
                news.push(this.state.changes[i].newWording)
            }
        }

        
        var payload =
            {
                affiliateName: this.props.userContact.firstName + " " + this.props.userContact.lastName,
                contactName: this.props.currentContact.firstName + " " + this.props.currentContact.lastName,
                newClient: this.state.newClient,
                agreementTemplateId: this.state.selectedTemplate,
                agreementTemplateName: this.state.currTemplate.displayName,
                oldText: old,
                newText: news
            }

        this.props.requestTemplateChange(this.props.logintoken, this.state.selectedTemplate, payload);

    }

    onChange = (e) => {
        if (!this.state.selectedTemplate) {
            swal("Please select a template to modify");
            return;
        }
        this.setState({ [e.target.id]: e.target.value });
    }

    onCheck = (e) => {
        this.setState({ [e.target.id]: e.target.checked });
    }

    onAddChange() {
        if (!this.state.newWording || !this.state.oldWording) {
            return;
        }

        var changes = this.state.changes;
        changes.push({ oldWording: this.state.oldWording, newWording: this.state.newWording });
        this.setState({ oldWording: "", newWording: "", changes: changes });
    }

    hideModal() {
        this.setState({ show: false });
    }

    render() {
        var templates = [];
        for (var i = 0; i < this.props.templates.length; i++) {
            templates.push({ label: this.props.templates[i].displayName, value: this.props.templates[i].templateId });
        }

        return (
            <span className="modal-form modifyLanguageModal">
                <Row sm={8}>
                    <Col sm={6}><Label className="medium-text">Affiliate: {this.props.userContact.firstName} {this.props.userContact.lastName}</Label></Col>
                    <Col sm={6}><Label className="medium-text">Contact: {this.props.currentContact.firstName} {this.props.currentContact.lastName}</Label></Col>
                </Row>
                <Row sm={8}>
                    <Col sm={2}>
                        <Label>Select a template: </Label>
                    </Col>
                    <Col sm={6}>
                        <Select
                            name="template"
                            options={templates}
                            value={this.state.selectedTemplate}
                            onChange={this.onSelectTemplate} />
                    </Col>
                    <Col>
                        {/* <CheckBox id="newClient" checked={this.state.newClient} onChange={this.onCheck} />
                        New Client */}
                    </Col>
                </Row>

                <Row sm={8}>
                    {this.state.currTemplate && (
                        <div className="htmlViewer">
                            <span dangerouslySetInnerHTML={{ __html: this.state.currTemplate.contentHTML }} />
                        </div>
                    )}
                </Row>

                {this.state.changes.length > 0 && (
                    <PendingChanges changes={this.state.changes} />
                )
                }

                <Row sm={8}>
                    <Col sm={2}>Old Wording: </Col>
                    <Col sm={6}><textarea
                        className="modal-longbox-narrow modifyLanguageTextArea"
                        rows="3"
                        cols="50"
                        type="text" id="oldWording"
                        onChange={this.onChange}
                        value={this.state.oldWording} /></Col>
                </Row>
                <Row sm={8}>
                    <Col sm={2}>New Wording:</Col>
                    <Col sm={6}><textarea
                        className="modal-longbox-narrow modifyLanguageTextArea"
                        rows="3"
                        cols="50"
                        type="text" id="newWording"
                        onChange={this.onChange}
                        value={this.state.newWording} /></Col>
                    <MediaQuery minWidth={1000}>
                        <Col sm={1}>
                            <div className="topNav right-justify"><Button className="button-gridrow viewEditButtonStyle" style={{ fontSize: '11px' }} onClick={this.onAddChange}>ADD NEW ITEM</Button></div>
                        </Col>
                    </MediaQuery>
                </Row>
                <MediaQuery maxWidth={999}>
                    <Button className="button-gridrow viewEditButtonStyle" style={{ fontSize: '11px' }} onClick={this.onAddChange}>ADD NEW ITEM</Button>
                </MediaQuery>

                <Row>
                    <Col sm={2} width="50px">
                        {/* <Button disabled={!this.state.changes || !this.state.changes.length} className="button-gridrow viewEditButtonStyle templateModalButton" href="#" onClick={this.onClick}>SUBMIT</Button> */}
                        <Button disabled={(!this.state.changes  || !this.state.changes.length) && (!this.state.newWording || !this.state.oldWording)} className="button-gridrow viewEditButtonStyle templateModalButton" href="#" onClick={this.onClick}>SUBMIT</Button>
                    </Col>
                    <Col sm={2}>
                        <Button className="button-gridrow viewEditButtonStyle templateModalButton" onClick={this.props.hideTemplate}>CANCEL</Button>
                    </Col>
                </Row>


            </span>
        )
    }

}

function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        templates: state.financeReducer.templates,
        currentContact: state.contactReducer.currentContact,
        userContact: state.contactReducer.userContact
    }
}

export default connect(mapStateToProps, { getAgreementTemplates, requestTemplateChange })(TemplateViewer);
