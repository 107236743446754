import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Label from 'react-bootstrap/FormLabel';
import { cancelBillingPlan, deactivateBillingPlan, activateBillingPlan, showClientDetails, updateBillingPlan } from '../../../hooks/store/actions/financeActions.js';
import BillingPlanDetails from './BillingPlanDetails.js';
import swal from 'sweetalert';
import { formatCurrency } from '../../../hooks/store/actionConsts.js'
import DatePicker from "react-datepicker";
import { StyleSheet, css } from 'aphrodite'
import "react-datepicker/dist/react-datepicker.css";
import MediaQuery from 'react-responsive';
import { getMessageTerminatePlan } from '../../form/utils/message-utils';

const monthNames = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.",
    "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."
];

const styles = StyleSheet.create({
    billingPlanFormInputDate: {
        backgroundColor: '#5487cf7d',
        border: '1px solid #949494',
        borderRadius: "0px",
        // fontSize: '14px',
        fontFamily: "'Open Sans', sans-serif",
        width: "100%",
        padding: ".375rem .75rem;",
        color: "#495057;"
    }
})


class BillingPlanRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDetails: false,
            isDirty: false,
            currentRow: 0,
            ongoing: false,
            editing: false,
            lastDayOfService: null,
            lastFullBill: null
        };
        this.cancelBP = this.cancelBP.bind(this);
        this.deactivateBP = this.deactivateBP.bind(this);
        this.activateBP = this.activateBP.bind(this);
        this.editingBP = this.editingBP.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.save = this.save.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
    }

    editBP(row) {
        var open = true;
        //alert(row.lastDayOfService)
        //alert(row.lastFullBill)

        this.setState({ ...row });
        this.setState({ lastDayOfService: row.lastDayOfService, lastFullBill: row.lastFullBill });

        if (this.state.currentRow === row.billingPlanID) {
            open = (this.state.showDetails ? false : true);
            //alert(this.state.lastDayOfService)
        }
        this.setState({ showDetails: open, currentRow: row.billingPlanID });
    }

    editingBP(row) {
        this.setState({ ...row });
        this.setState({ editing: true, currentRow: row.billingPlanID });
    }

    cancelEdit(row) {
        this.setState({
            editing: false,
            ongoing: (this.state.lastFullBill == "2099-01-01T00:00:00") ? true : false
        });
    }

    save(bp) {
        var newDate = new Date(bp.lastFullBill.substring(0, 10));
        newDate.setDate(newDate.getDate() + 1);
        var oldDate = new Date(this.state.lastFullBill.substring(0, 10));
        oldDate.setDate(oldDate.getDate() + 1);

        var dateDiffLFB = Math.floor((newDate - oldDate) / (1000 * 60 * 60 * 24));
        //alert('newDate=' + newDate + ' --- oldDate=' + oldDate)
        //alert(' --- dateDiffLFB=' + dateDiffLFB)

        var ldos = (this.state.lastDayOfService == null || bp.lastFullBill >= new Date('01/01/2099')) ? '01/31/2099 00:00:00.000' : this.state.lastDayOfService;
        var lds = new Date(ldos);
        lds.setDate(lds.getDate() + dateDiffLFB);
        if (lds.getDate() == 1) {
            //alert('erere')
            lds.setDate(lds.getDate() - 1)
        } else {
            var dateDiffLDS = (lds.setDate(lds.getDate() + 1) / (1000 * 60 * 60 * 24));
            //alert(lds)
            if (lds.getDate() == 1) {
                //alert('erere')
                lds.setDate(lds.getDate() - 1)
            } else {
                dateDiffLDS = (lds.setDate(lds.getDate() + 1) / (1000 * 60 * 60 * 24));
                //alert(lds)
                if (lds.getDate() == 1) {
                    //alert('erere')
                    lds.setDate(lds.getDate() - 1)
                } else {
                    dateDiffLDS = (lds.setDate(lds.getDate() + 1) / (1000 * 60 * 60 * 24));
                    //alert(lds)
                    if (lds.getDate() == 1) {
                        //alert('erere')
                        lds.setDate(lds.getDate() - 1)
                    }
                }
            }
            
            
        }

        ///temp
        //lds = new Date(newDate.toDateString());
        //lds.setDate(newDate.getDate() - 1);
        //lds = '01/31/2020 00:00:00.000'

        this.setState({ lastDayOfService: lds });
        bp.lastDayOfService = lds;
        //alert('lds=' + lds)

        this.setState({ lastDayOfService: lds });
        bp.lastDayOfService = lds;
        this.setState({ lastFullBill: newDate });

        this.props.updateBillingPlan(this.props.logintoken, bp);  // update in DB
        this.setState({ editing: false, currentRow: bp.billingPlanID });
        this.setState({ showDetails: false, currentRow: bp.billingPlanID });

    }

    cancelBP(row) {
        swal({
            title: "AC Admin",
            content: getMessageTerminatePlan(), 
            icon: "warning",
            buttons:
                {
                    no: {
                        text: "No",
                        value: "no"
                    },
                    yes: {
                        text: "Yes",
                        value: "yes"
                    }
                }
        }).then((value) => {
            switch (value) {
                case "yes":
                    swal({
                        title: "AC Admin", text: "Are you sure? This process is irreversible.", icon: "warning",
                        buttons:
                            {
                                no: {
                                    text: "No",
                                    value: "no"
                                },
                                yes: {
                                    text: "Yes",
                                    value: "yes"
                                }
                            }
                    }).then((value) => {
                        //console.log(value)
                        switch (value) {
                            case "yes":
                                this.props.cancelBillingPlan(this.props.logintoken, row);
                                this.props.showClientDetails(1);
                                this.setState({ showDetails: false });
                                break;
                            default:
                                break
                        }
                    })
                    break
                case "no":
                    break;
                default:
                    break;
            }
        });
    }

    deactivateBP(row) {
        swal({
            title: "AC Admin", text: "Pause this billing plan?", icon: "warning",
            buttons:
                {
                    no: {
                        text: "No",
                        value: "no"
                    },
                    yes: {
                        text: "Yes",
                        value: "yes"
                    }
                }
        }).then((value) => {
            switch (value) {
                case "yes":
                    this.props.deactivateBillingPlan(this.props.logintoken, row);
                    this.props.showClientDetails(1);
                    this.setState({ showDetails: false });
                    break;
                case "no":
                    break;
                default:
                    break;
            }
        });
    }

    activateBP(row) {
        swal({
            title: "AC Admin", text: "Activate this billing plan?", icon: "warning",
            buttons:
                {
                    no: {
                        text: "No",
                        value: "no"
                    },
                    yes: {
                        text: "Yes",
                        value: "yes"
                    }
                }
        }).then((value) => {
            switch (value) {
                case "yes":
                    this.props.activateBillingPlan(this.props.logintoken, row);
                    this.props.showClientDetails(1);
                    this.setState({ showDetails: false });
                    break;
                case "no":
                    break;
                default:
                    break;
            }
        });
    }

    formatDate(d) {
        // console.log(d)
        if (d && d.length >= 10) {
            var m = Number(d.substring(5, 7));
            if (d.substring(0, 4) == '2099') {
                return 'Ongoing'
            }
        else if (d.substring(0, 4) == '1900') {
            return 'Non-Billable'
        }
        else {
            return monthNames[m - 1] + " " + d.substring(8, 10) + ", " + d.substring(0, 4);
        }
        }
        else
            return "";
    }

    getStatus(statusId) {
        switch (statusId) {
            case 1:
                return "Pending"
            case 2:
                return "Active"
            case 3:
                return "Paused"
            case 5:
                return "Expired"
            default:
                return "Terminated"
        }
    }

    getColor(statusId) {

        switch (statusId) {
            case 1:
                return "label-yellow"
            case 2:
                return "label-green"
            case 3:
                return "label-darkgray"
            default:
                return "label-darkred"
        }
    }

    onChange = (e) => {
        this.setState({ [e.target.id]: e.target.value, isDirty: true });
    }

    onDateChange = (date) => {

        var tempDate = new Date(this.state.lastFullBill.substring(0, 10));
        var dateDiff = Math.floor((date - tempDate) / (1000*60*60*24));
        if (dateDiff > 0) {
            var ldos = new Date(this.state.lastDayOfService.substring(0, 10));
            ldos.setDate(ldos.getDate() + dateDiff);
            this.setState({ lastDayOfService: ldos });
        }

        this.setState({ lastFullBill: date });
    }

    onBillingCheckboxChange = (e) => {
        if (e.target.checked) {
            this.setState({ ongoing: e.target.checked, lastFullBill: "2099-01-01", lastDayOfService: "2099-01-01" });
        } else {
            this.setState({ ongoing: false, lastFullBill: new Date() });
        }

    }

    getCCType(id) {
        switch (id) {
            case 1:
                return "Send Invoice";
            case 2:
                return "Auto-charge";
            default:
                return "Manual";
        }
    }

    render() {

        var newArr = [];
        newArr = this.props.billingPlans;
        console.log(newArr)

        return (
            this.props.billingPlans.filter((bp) => !(bp.statusID === 4 && bp.acceptedOnUTC === null)).map(
                (j, r) =>
                    <span key={j.billingPlanID}>
                        <Row className={r % 2 === 0 ? "grid-row row-white grid-billing-plan billingPlanRow notranslate" : "grid-row row-gray grid-billing-plan billingPlanRow notranslate"} >
                            {this.state.editing && j.billingPlanID === this.state.currentRow && (
                                <Col sm={2}>
                                    <input
                                        id="billingPlanName"
                                        name="billingPlanName"
                                        type="text"
                                        value={this.state.billingPlanName}
                                        onChange={this.onChange}
                                        style={{ backgroundColor: "#5487cf7d" }}
                                    />
                                </Col>
                            )}
                            {!(this.state.editing && j.billingPlanID === this.state.currentRow) && (
                                <Col sm={2} className="billingPlanRowItemText">{j.billingPlanName}</Col>
                            )}
                            <Col sm={1} className="billingPlanRowItem billingPlanRowItemText"><Label style={{ marginBottom: '0px' }} className={this.getColor(j.statusID)}>{this.getStatus(j.statusID)}</Label></Col>
                            <Col sm={1} className="billingPlanRowItemText">{this.formatDate(j.fullBillingStart)}</Col>
                            {/* SAVE CODE BLOCK BELOW FOR FUTURE DEV */}
                            {/* {this.state.editing && j.billingPlanID === this.state.currentRow && (
                                <Col sm={1}>
                                    {this.state.ongoing === false &&
                                        <div className="billingPlanDateDiv">
                                            <DatePicker
                                                dateFormat="MMM d, yyyy"
                                                selected={(this.state.lastFullBill == "2099-01-01T00:00:00") ? null : new Date(this.state.lastFullBill)}
                                                onChange={this.onDateChange}
                                                className={css(styles.billingPlanFormInputDate)}
                                                showMonthDropdown
                                                showYearDropdown
                                                openToDate={new Date()}
                                                placeholderText="Ongoing"
                                            />
                                        </div>
                                    }
                                    <input onChange={this.onBillingCheckboxChange} type="checkbox" id="ongoingBilling" name="ongoingBilling" />
                                    <label className="billingPlanCheckboxLabel" htmlFor="ongoingBilling">&nbsp;Ongoing</label>
                                </Col>
                            )} */}
                            {this.state.editing && (
                                <Col sm={1} className="billingPlanRowItemText">{this.formatDate(j.lastFullBill)}</Col>
                            )}

                            {!this.state.editing && (
                                <Col sm={1} className="billingPlanRowItemText">{this.formatDate(j.lastFullBill)}</Col>
                            )}
                            <Col sm={1} className="billingPlanRowItem billingPlanRowItemText">{this.getCCType(j.creditCardChargeTypeId)}</Col>

                            <Col sm={1} className="billingPlanRowItem billingPlanRowItemText billingPlanRowItemSessions">{j.sessionsPerMonth}</Col>
                            <Col sm={1} className="billingPlanRowItem billingPlanRowItemText">{j.chargeCurrency}</Col>
                            <Col sm={1} className="billingPlanRowItem billingPlanRowItemText">${j.billingPlanDetails.length > 0 && j.billingPlanDetails[0] ? formatCurrency(j.billingPlanDetails[0].totalInvoiceCharge.toFixed(2)) : 0.00}</Col>
                            <Col sm={1} className="billingPlanRowItem billingPlanRowItemText">${j.billingPlanDetails.length > 0 && j.billingPlanDetails[0] ? formatCurrency(j.billingPlanDetails[0].affiliateNetPayment.toFixed(2)) : 0.00}</Col>
                            {/* <MediaQuery minWidth={1306}> */}
                            <Col sm={1}></Col>
                            {/* </MediaQuery> */}
                            {/* <MediaQuery minWidth={1306}> */}
                            <Col sm={1} className="detailsActionsCol" style={{display: 'flex'}}>
                                {this.state.currentRow === j.billingPlanID && this.state.showDetails == true
                                    ? <button translate="yes" style={{ marginBottom: 7 }} className="button-gridrow-new-contact payorrefundActions" onClick={() => (this.editBP(j))} href="#">CLOSE</button>
                                    : <div>
                                    <MediaQuery minWidth={1031}>
                                        <button translate="yes" className="button-gridrow-invdetails payorrefundActions2 mb10" onClick={() => (this.editBP(j))} href="#">DETAILS<br />& ACTIONS</button>
                                    </MediaQuery>
                                    <MediaQuery maxWidth={1030} minWidth={500}>
                                        <button translate="yes" className="button-gridrow-invdetailssm payorrefundActions2 mb10" onClick={() => (this.editBP(j))} href="#">DETAILS</button>
                                    </MediaQuery>
                                    <MediaQuery maxWidth={499}>
                                        <button translate="yes" className="button-gridrow-invdetails payorrefundActions2 mb10" onClick={() => (this.editBP(j))} href="#">DETAILS<br />& ACTIONS</button>
                                    </MediaQuery>
                                </div>
                                    }
                            </Col>
                            {/* </MediaQuery>
                            <MediaQuery maxWidth={1305}>
                            <Col className="payorrefundDetailsCol" sm={1}>
                                {this.state.currentRow === j.billingPlanID && this.state.showDetails == true ? <button className="button-gridrow-new-contact payorrefundActions" onClick={() => (this.editBP(j))} href="#">CLOSE</button> : <button className="button-gridrow-new-contact payorrefundActions" onClick={() => (this.editBP(j))} href="#">
                                   
                                        <span>MORE DETAILS<br />& ACTIONS</span>
                                  
                                </button>}
                                &nbsp;
                            </Col>
                            </MediaQuery> */}
                        </Row>

                        {this.state.showDetails && this.state.currentRow === j.billingPlanID && (
                            <BillingPlanDetails editing={this.state.editing} row={r} bp={j} cancelBP={this.cancelBP} activateBP={this.activateBP} deactivateBP={this.deactivateBP} editingBP={this.editingBP} cancelEdit={this.cancelEdit} save={this.save} />
                        )}

                    </span>
            )
        );
    }
}
/*                               <Row className="grid-row row-header">
                                    <Col className="grid-header" sm={1}>AC TAX</Col>
                                    <Col className="grid-header" sm={1}>AC TOTAL FEES</Col>
                                    <Col className="grid-header" sm={1}>MERCHANT FEE</Col>
                                    <Col className="grid-header" sm={1}>MERCHANT SURPLUS</Col>
                                    <Col className="grid-header" sm={1}>AC GROSS INCOME</Col>
                                    <Col className="grid-header" sm={1}>AC NET INCOME</Col>
                                </Row>
*/

function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user
    }
}

export default connect(mapStateToProps, { cancelBillingPlan, showClientDetails, deactivateBillingPlan, activateBillingPlan, updateBillingPlan })(BillingPlanRow);
