import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

class MaintenancePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return <div>
            <p/><p/>
            <center><h1>The site is under maintenance.  Please try again later.</h1></center>
            </div>;
    }
    
}
    
function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user        
    }
}

export default connect(mapStateToProps, {})(MaintenancePage);
