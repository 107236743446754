import React from 'react';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from 'react-bootstrap/FormLabel';
import Button from 'react-bootstrap/Button';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-styled-select';
import { setParam, setSuboption } from '../../../hooks/store/actions/sysActions.js';

class ReportParamList extends React.Component {

    billReportOperations = [
        { value: 1, label: 'Generate Payments Bill.com' },
        { value: 2, label: 'Process payments status' },
      ];

    stripeConnectReportOperations = [
        { value: 1, label: 'Generate Payments' },
        { value: 2, label: 'Show History' },
      ];

    constructor(props) {
        super(props);
        this.state = {
            billReportOption: -1,
            numberControl: '',
            stripConnectReportOption: -1,
        };

        this.onChange = this.onChange.bind(this);
        this.onInputClear = this.onInputClear.bind(this);
        this.onInputClear2 = this.onInputClear2.bind(this);
        this.onInputClear3 = this.onInputClear3.bind(this);
        this.onInputClear4 = this.onInputClear4.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onDateChange2 = this.onDateChange2.bind(this);
        this.onDateChange3 = this.onDateChange3.bind(this);
        this.onDateChange4 = this.onDateChange4.bind(this);
        this.onChangeFile = this.onChangeFile.bind(this);
        this.onBillOptionChange = this.onBillOptionChange.bind(this);
        this.onStripeConnectChange = this.onStripeConnectChange.bind(this);
        // this.onBillOptionChange = this.onBillOptionChange.bind(this);

       // console.log(this.props.report);
    }

    componentDidMount() {
        this.setState({ value: null, value2: null, value3: null, value4: null });
    }

    getParam(id) {
        for (var i = 0; i < this.props.params.length; i++) {
            if (this.props.params[i].id === id) {
                return this.props.params[i];
            }
        }
        return {};
    }

    getSelectParam(pos) {
        var cntr = 0;
        for (var i = 0; i < this.props.params.length; i++) {
            if (this.props.params[i].dataType === "DateTime" || (this.props.params[i].values && this.props.params[i].values.length > 0)) {
                if (cntr === pos) {
                    return this.props.params[i];
                }
                cntr++;
            }
        }
        return {};

    }

    getValue(param, pos) {
        if (param.values.length >= pos) {
            if (param.values[pos - 1] != undefined) { return param.values[pos - 1].sqlValue; } else {return null}
        }
        return pos;
    }

    onChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
        var param = this.getParam(e.target.id);
        console.log(param);
        this.props.setParam({ "name": param.sqlParamName, "value": e});
    }

    onChangeFile = (e) => {
        console.log(e.target.id, e.target.files);
        this.setState({ [e.target.id]: e.target.files[0] });
        // var param = this.getParam(e.target.id);
        this.props.setParam({ "name": "@File", "value": e.target.files[0]});
    }

    onInputClear = (e) => {
        this.setState({ 'value': null });
        var param = this.getSelectParam(0);
        this.props.setParam({ "name": param.sqlParamName, "value": null });
    }
    onInputClear2 = (e) => {
        this.setState({ 'value2': null });
        var param = this.getSelectParam(1);
        this.props.setParam({ "name": param.sqlParamName, "value2": null });
    }
    onInputClear3 = (e) => {
        this.setState({ 'value3': null });
        var param = this.getSelectParam(2);
        this.props.setParam({ "name": param.sqlParamName, "value3": null });
    }

    onInputClear4 = (e) => {
        this.setState({ 'value4': null });
        var param = this.getSelectParam(3);
        this.props.setParam({ "name": param.sqlParamName, "value4": null });
    }

    onDateChange = (e) => {    
        console.log('onDateChange');    
        this.setState({ 'value' : e });        
        var param = this.getSelectParam(0);
        console.log(param);
        console.log({ "name": param.sqlParamName, "value": this.getValue(param,e)});
        this.props.setParam({ "name": param.sqlParamName, "value": this.getValue(param,e) });
    }
    onDateChange2 = (e) => {
        console.log('onDateChange2');
        this.setState({ 'value2': e });
        var param = this.getSelectParam(1);
        this.props.setParam({ "name": param.sqlParamName, "value": this.getValue(param,e)});
    }
    onDateChange3 = (e) => {
        console.log('onDateChange3');
        this.setState({ 'value3': e });
        var param = this.getSelectParam(2);
        this.props.setParam({ "name": param.sqlParamName, "value": this.getValue(param,e) });
    }

    onDateChange4 = (e) => {
        console.log('onDateChange4');
        this.setState({ 'value4': e });
        var param = this.getSelectParam(3);
        console.log(param);
        console.log({ "name": param.sqlParamName, "value": this.getValue(param,e)})
        this.props.setParam({ "name": param.sqlParamName, "value": this.getValue(param,e) });
    }

    onBillOptionChange = (e) => {
        // console.log(e);
        this.setState({ 'billReportOption' : e });
        this.props.setSuboption({"name": this.billReportOperations[e - 1].label, "value": e})
    }

    onStripeConnectChange = (e) => {
        // console.log(e);
        this.setState({ stripConnectReportOption : e });
        this.props.setSuboption({"name": this.stripeConnectReportOperations[e - 1].label, "value": e})
    }

    onNumberControlChange = (e) => {
        // console.log(e.target.value);
        this.setState({ numberControl : e.target.value });
        this.props.setParam({ "name": "controNumber", "value": e.target.value });
    }


    viewCustomReport = (reportId) => {
        switch(reportId){
            case 9:
                return this.viewReportBillcom();
            case 11:
                return this.viewReportStripeConnect();
            default:
                return (<h1>No defined</h1>)
        }
    }


    viewReportStripeConnect = () => {
        return (<div>

                        <Row>
                            <Col sm={4}>
                                <Label>Operation type</Label>
                            </Col>
                            <Col sm={8}>

                                <Select
                                  className="basic-single"
                                  classNamePrefix="select"
                                  defaultValue={this.stripeConnectReportOperations[0]}
                                  options={this.stripeConnectReportOperations}
                                  value={this.state.stripConnectReportOption}
                                  onChange={this.onStripeConnectChange}
                                />

                            </Col>
                        </Row>


                        {this.state.stripConnectReportOption === 1 &&

                                <div>

                                    <h5 style={{ marginTop: "50px"}}>Generate Stripe Transfers</h5>

                                    {this.props.params.map(
                                    (j, r) =>
                                        <div key={j.id} className={j.isHidden ? "hidden" : ""}>
                                            <Row>
                                                <Col sm={3}>
                                                    <Label>{j.name}</Label>
                                                </Col>
                                                <Col sm={4}>
                                                    <Select
                                                        id={"ddl_" + j.id}
                                                        name="dropdown"
                                                        clearable
                                                        className={j.values && j.values.length ? "rs-dropdown-theme" : "hidden"}
                                                        options={j.options}
                                                        defaultValue={j.defaultValue}
                                                        value={(r === 1 ? this.state.value2 : r === 2 ? this.state.value3 : this.state.value)}
                                                        onChange={(r === 1 ? this.onDateChange2 : r === 2 ? this.onDateChange3 : this.onDateChange)}
                                                        onInputClear={(r === 1 ? this.onInputClear2 : r === 2 ? this.onInputClear3 : this.onInputClear)}
                                                    />

                                                    <Form.Control className={(!j.values || !j.values.length) && j.dataType === "String" ? "" : "hidden"}
                                                        id={"txt_" + j.id} onChange={this.onChange} value={this.state[String(j.id)]} defaultValue={j.defaultValue} />
                                                    <DatePicker
                                                        id={"cal_" + j.id}
                                                        clearable
                                                        dateFormat="MMMM d, yyyy"
                                                        selected={(r === 1 ? this.state.value2 : r=== 2 ? this.state.value3 : this.state.value)}
                                                        onChange={(r === 1 ? this.onDateChange2 : r === 2 ? this.onDateChange3 : this.onDateChange)}
                                                        onInputClear={(r === 1 ? this.onInputClear2 : r === 2 ? this.onInputClear3 : this.onInputClear)}
                                                        className={j.dataType === "DateTime" ? "" : "hidden"}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        openToDate={new Date(Date.now())}
                                                    />

                                                </Col>
                                            </Row>
                                        </div>
                                    )}

                                </div >
                        }

                        {this.state.stripConnectReportOption === 2 &&

                            <div>
                                <h5 style={{ marginTop: "50px"}}>Show history payments</h5>

                                <Form.Group className="mb-3">
                                    <Form.Label>Control Number</Form.Label>
                                    <Form.Control
                                        onChange={this.onNumberControlChange}
                                        value={this.state.numberControl}
                                    />
                                </Form.Group>

                            </div>

                        }
                    </div>)
    }


    viewReportBillcom = () => {
        return (<div>

                        <Row>
                            <Col sm={4}>
                                <Label>Operation type</Label>
                            </Col>
                            <Col sm={8}>

                                <Select
                                  className="basic-single"
                                  classNamePrefix="select"
                                  defaultValue={this.billReportOperations[0]}
                                  options={this.billReportOperations}
                                  value={this.state.billReportOption}
                                  onChange={this.onBillOptionChange}
                                />

                            </Col>
                        </Row>


                        {this.state.billReportOption === 1 &&

                                <div>

                                    <h5 style={{ marginTop: "50px"}}>Generate Payments Bill.com Options</h5>

                                    {this.props.params.map(
                                    (j, r) =>
                                        <div key={j.id} className={j.isHidden ? "hidden" : ""}>
                                            <Row>
                                                <Col sm={3}>
                                                    <Label>{j.name}</Label>
                                                </Col>
                                                <Col sm={4}>
                                                    <Select
                                                        id={"ddl_" + j.id}
                                                        name="dropdown"
                                                        clearable
                                                        className={j.values && j.values.length ? "rs-dropdown-theme" : "hidden"}
                                                        options={j.options}
                                                        defaultValue={j.defaultValue}
                                                        value={(r === 1 ? this.state.value2 : r === 2 ? this.state.value3 : this.state.value)}
                                                        onChange={(r === 1 ? this.onDateChange2 : r === 2 ? this.onDateChange3 : r === 3 ? this.onDateChange4 : this.onDateChange)}
                                                        onInputClear={(r === 1 ? this.onInputClear2 : r === 2 ? this.onInputClear3 : r === 3 ? this.onInputClear4 : this.onInputClear)}
                                                    />

                                                    <Form.Control className={(!j.values || !j.values.length) && j.dataType === "String" ? "" : "hidden"}
                                                        id={"txt_" + j.id} onChange={this.onChange} value={this.state[String(j.id)]} defaultValue={j.defaultValue} />
                                                    <DatePicker
                                                        id={"cal_" + j.id}
                                                        clearable
                                                        dateFormat="MMMM d, yyyy"
                                                        selected={(r === 1 ? this.state.value2 : r=== 2 ? this.state.value3 : this.state.value)}
                                                        onChange={(r === 1 ? this.onDateChange2 : r === 2 ? this.onDateChange3 : this.onDateChange)}
                                                        onInputClear={(r === 1 ? this.onInputClear2 : r === 2 ? this.onInputClear3 : this.onInputClear)}
                                                        className={j.dataType === "DateTime" ? "" : "hidden"}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        openToDate={new Date(Date.now())}
                                                    />

                                                </Col>
                                            </Row>
                                        </div>
                                    )}

                                </div >
                        }

                        {this.state.billReportOption === 2 &&

                            <div>
                                <h5 style={{ marginTop: "50px"}}>Process payments status Options</h5>

                                <Form.Group className="mb-3">
                                    <Form.Label>Control Number</Form.Label>
                                    <Form.Control
                                        onChange={this.onNumberControlChange}
                                        value={this.state.numberControl}
                                    />
                                </Form.Group>

                            </div>

                        }
                    </div>)
    }

    render() {
        const customReport = [9, 11]
        let isCustomReport = false;

        if (this.props.report) {
            isCustomReport =  customReport.includes(this.props.report.id)
        }

        return (

            <div>
           {isCustomReport ? (
                this.viewCustomReport(this.props.report.id)
           ) : (

            this.props.params.map(
                (j, r) =>
                    <div key={j.id} className={j.isHidden ? "hidden" : ""}>
                        { j.dataType === "File" ?
                            (
                                <Form.Group className="mb-3">
                                        <Form.Label>{j.name}</Form.Label>
                                        <Form.Control 
                                            id={"file_" + j.id}
                                            type="file" 
                                            size="sm"
                                            onChange={this.onChangeFile}
                                        />
                                    </Form.Group>
                            ) :
                            (
                            <Row>
                                <Col sm={3}>
                                    <Label>{j.name}</Label>
                                </Col>
                                <Col sm={4}>
                                    <Select
                                        id={"ddl_" + j.id}
                                        name="dropdown"
                                        clearable
                                        className={j.values && j.values.length ? "rs-dropdown-theme" : "hidden"}
                                        options={j.options}
                                        defaultValue={j.defaultValue}
                                        value={(r === 1 ? this.state.value2 : r === 2 ? this.state.value3 : r === 3 ? this.state.value4 : this.state.value)}
                                        onChange={(r === 1 ? this.onDateChange2 : r === 2 ? this.onDateChange3 :  r === 3 ? this.onDateChange4 : this.onDateChange)}
                                        onInputClear={(r === 1 ? this.onInputClear2 : r === 2 ? this.onInputClear3 : r === 3 ? this.onInputClear4 : this.onInputClear)}
                                    />
    
                                    <Form.Control className={(!j.values || !j.values.length) && j.dataType === "String" ? "" : "hidden"}
                                        id={"txt_" + j.id} onChange={this.onChange} value={this.state[String(j.id)]} defaultValue={j.defaultValue} />
                                    <DatePicker
                                        id={"cal_" + j.id}
                                        clearable
                                        dateFormat="MMMM d, yyyy"
                                        selected={(r === 1 ? this.state.value2 : r=== 2 ? this.state.value3 : this.state.value)}
                                        onChange={(r === 1 ? this.onDateChange2 : r === 2 ? this.onDateChange3 : this.onDateChange)}
                                        onInputClear={(r === 1 ? this.onInputClear2 : r === 2 ? this.onInputClear3 : this.onInputClear)}
                                        className={j.dataType === "DateTime" ? "" : "hidden"}
                                        showMonthDropdown
                                        showYearDropdown
                                        openToDate={new Date(Date.now())}
                                    />
                                                                                              
                                </Col>
                            </Row>
                            )
                        }

                       
                    </div>
                )


           )}

        </div>
        );

            

    }
    
}
    
function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user        
    }
}

export default connect(mapStateToProps, { setParam, setSuboption })(ReportParamList);
