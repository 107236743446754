import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { showClientDetails } from '../../../hooks/store/actions/financeActions';
import { selectContact } from '../../../hooks/store/actions/contactActions.js';
import { formatCurrency } from '../../../hooks/store/actionConsts.js'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const monthNames = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.",
    "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."
];

class ClientRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.viewClientDetails = this.viewClientDetails.bind(this);
    }

    viewClientDetails(contact) {
        this.props.showClientDetails(contact);
        this.props.selectContact(contact);
    }

    getClientStatus(c) {
        return c.clientStatus;
    }

    formatDate(d) {
        if (d && d.length >= 10) {
            var m = Number(d.substring(5, 7));
            if (d.substring(0, 4) == '2099') {
                return 'Ongoing'
            }
            else {
                return monthNames[m - 1] + " " + d.substring(8, 10) + ", " + d.substring(0, 4);
            }
        }
        else
            return "";
    }

    handleOpenModal(item){
        const name = item.firstName + " " + item.lastName;
        this.props.openModalClientRequest(name);
    }

    render() {
        return (
            this.props.contacts.map(
                (j, r) =>
                    <Row className={r % 2 === 0 ? "grid-row row-white notranslate" : "grid-row row-gray notranslate"} key={r}>      
                        <Col style={{ display: 'flex', alignItems: 'center', zIndex: '0', overflow: 'hidden' }} xs={1} sm={1}>
                            
                            <OverlayTrigger
                                placement="top"
                                trigger="hover"
                                overlay={<Tooltip>
                                    {this.getClientStatus(j) == 'Pending'
                                        ? 'What is "Status: Pending"? Your client has not signed their client service agreement yet.'
                                        : 'How do I activate a client? Have an active Signed Agreement (aka "Billing Plan").'
                                }
                                    </Tooltip>}
                            >
                                <span className="graphTextFront">{this.getClientStatus(j)}</span>
                            </OverlayTrigger>

                            
                            <div style={{ position: 'absolute', width: '100%', right: '0', height: '78%', borderRight: '1px solid black' }}>
                            </div></Col>
                        <Col style={{ display: 'flex', alignItems: 'center', zIndex: '0', overflow: 'hidden' }} xs={1} sm={1}><span className="graphTextFront">{j.clientType}</span><div style={{ position: 'absolute', width: '100%', right: '0', height: '78%', borderRight: '1px solid black' }}></div></Col>
                        {(this.props.sysUser.userRole > 2) && (
                            <Col style={{ display: 'flex', alignItems: 'center', zIndex: '0', overflow: 'hidden' }} xs={1} sm={1}><span className="graphTextFront">{j.affiliateName}</span><div style={{ position: 'absolute', width: '100%', right: '0', height: '78%', borderRight: '1px solid black' }}></div></Col>
                        )}
                        <Col style={{ display: 'flex', alignItems: 'center', zIndex: '0', overflow: 'hidden' }} xs={1} sm={1}><span className="graphTextFront">{j.firstName}</span><div style={{ position: 'absolute', width: '100%', right: '0', height: '78%', borderRight: '1px solid black' }}></div></Col>
                        <Col style={{ display: 'flex', alignItems: 'center', zIndex: '0', overflow: 'hidden' }} xs={1} sm={1}><span className="graphTextFront">{j.lastName}</span><div style={{ position: 'absolute', width: '100%', right: '0', height: '78%', borderRight: '1px solid black' }}></div></Col>
                        <Col style={{ display: 'flex', alignItems: 'center', zIndex: '0', overflow: 'hidden', whiteSpace: 'nowrap' }} xs={2} sm={2}><span className="graphTextFront"><a className="logoURLText" href={`mailto:${j.email}`}>{j.email}</a></span><div style={{ position: 'absolute', width: '100%', right: '0', height: '78%', borderRight: '1px solid black' }}></div></Col>
                        {(this.props.sysUser.userRole < 3) && (
                        <Col style={{ display: 'flex', alignItems: 'center', zIndex: '0', overflow: 'hidden'}} xs={1} sm={1}><span className="graphTextFront">{j.sessionsPerMonth}</span><div style={{ position: 'absolute', width: '100%', right: '0', height: '78%', borderRight: '1px solid black' }}></div></Col>
                        )}
                        <Col style={{ display: 'flex', alignItems: 'center', zIndex: '0', overflow: 'hidden' }} xs={1} sm={1}><span className="graphTextFront">${j.billingAmount ? formatCurrency(j.billingAmount.toFixed(2)) : 0.00}</span><div style={{ position: 'absolute', width: '100%', right: '0', height: '78%', borderRight: '1px solid black' }}></div></Col>
                        <Col style={{ display: 'flex', alignItems: 'center', zIndex: '0', overflow: 'hidden' }} xs={1} sm={1}><span className="graphTextFront">{j.invoiceStatus}</span><div style={{ position: 'absolute', width: '100%', right: '0', height: '78%', borderRight: '1px solid black' }}></div></Col>
                        <Col style={{ display: 'flex', alignItems: 'center', zIndex: '0', overflow: 'hidden' }} xs={1} sm={1}><span className="graphTextFront">{j.paymentStatus}</span><div style={{ position: 'absolute', width: '100%', right: '0', height: '78%', borderRight: '1px solid black' }}></div></Col>
                        <Col style={{ display: 'flex', alignItems: 'center', zIndex: '0', overflow: 'hidden' }} xs={2} sm={2}><span style={{minWidth: '30px' }} className="graphTextFront">{this.formatDate(j.expirationDate) == '' ? '' : this.formatDate(j.expirationDate)}</span>
                            <Button className="button-gridrow-invdetails payorrefundClientDetails2" onClick={() => (this.viewClientDetails(j))} href="#">CLIENT<br />DETAILS.</Button>
                            {/* <Button className="button-gridrow-invdetails payorrefundClientDetails2" onClick={() => (this.handleOpenModal(j))} href="#">SEND<br />REQUEST.</Button> */}
                        </Col>
                    </Row>
            )
        );
    }
}

function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        sysUser: state.loginReducer.sysUser,
    }
}

export default connect(mapStateToProps, { showClientDetails, selectContact })(ClientRow);
