import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { reissueInvoice, voidInvoice, updateInvoiceDetail, regenerateInvoiceClient } from '../../../hooks/store/actions/financeActions.js';
import { monthNames, sleep } from '../../../hooks/store/actionConsts';
import InvoiceDetailRow from './InvoiceDetailRow';
import PaymentDetailRow from './PaymentDetailRow';
import PaymentDetailForm from './PaymentDetailForm';
import swal from 'sweetalert';
import MediaQuery from 'react-responsive';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import CheckBox from 'react-bootstrap/FormCheckInput';
import Label from 'react-bootstrap/FormLabel';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    margin: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
      selectEmpty: {
        marginTop: theme.spacing(2),
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles((theme) => ({
    root: {
      margin: 0,  
      padding: theme.spacing(1),
    },
  }))(MuiDialogContent);
  
  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

class InvoiceRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDetails: false,
            currentRow: 0,
            showPaymentForm: false,
            showUpdateInvoiceDetailsForm: false,
            newAmountMerchantFee: '',
            errorMerchantFeeAmount: false,
            checkSendEmail: false,
            checkGenInvoice: false,
            checkGenPdf: false,
            showGenerateOptions: false,
        };
        this.editBP = this.editBP.bind(this);
        this.onClickPayment = this.onClickPayment.bind(this);
        this.onHidePayment = this.onHidePayment.bind(this);
        this.collapseDetails = this.collapseDetails.bind(this);
        this.onClickReissue = this.onClickReissue.bind(this);
        this.onClickVoid = this.onClickVoid.bind(this);
        this.onclickUpdateInvoiceDetail = this.onclickUpdateInvoiceDetail.bind(this);
        this.closeUpdateInvoiceDetail = this.closeUpdateInvoiceDetail.bind(this);
        this.performUpdateInvoiceDetail = this.performUpdateInvoiceDetail.bind(this);
        this.handleChangeInputText = this.handleChangeInputText.bind(this);
        this.onclickGenerateInvoiceOptions = this.onclickGenerateInvoiceOptions.bind(this);
        this.onCheck = this.onCheck.bind(this);
        this.renderGenOptionsSection = this.renderGenOptionsSection.bind(this);
    }

    onClickPayment(invoice) {
        this.setState({ showPaymentForm: true, invoice: invoice });
    }

    onclickUpdateInvoiceDetail(invoice) {
        this.setState({ showUpdateInvoiceDetailsForm: true, invoice: invoice });
    }

    onclickGenerateInvoiceOptions(invoice) {
       // console.log('regen', invoice, this.state.checkGenPdf, this.state.checkSendEmail, this.state.checkGenInvoice);
       this.props.regenerateInvoiceClient(invoice.invoiceID, this.state.checkGenPdf, this.state.checkSendEmail, this.state.checkGenInvoice);
    }

    onCheck(e) {
        this.setState({ [e.target.name]: e.target.checked });
    }

    closeUpdateInvoiceDetail() {
        this.setState({ showUpdateInvoiceDetailsForm: false});
    }

    validateMerchantFee(){
        this.setState({ errorMerchantFeeAmount: false })
        if(this.state.newAmountMerchantFee === "") {
            this.setState({ errorMerchantFeeAmount: true })
        }
    }

    performUpdateInvoiceDetail() {

        this.validateMerchantFee();

        setTimeout(() => {
            var instance = this;
            if(!instance.state.errorMerchantFeeAmount){
                instance.props.updateInvoiceDetail(this.props.logintoken, this.state.invoice.invoiceID, this.state.newAmountMerchantFee);
                instance.closeUpdateInvoiceDetail();
            }
        }, 600);
       // swal("Please refresh the page to see the changes!");
    }

    handleChangeInputText(e) {
        this.setState({[e.target.name] : e.target.value})
    }

    refreshClientDetails() {
        sleep(50).then(() => {
            document.location = "/clientMgmt/autoload";
        })
    }

    editBP(row) {
        var open = true;

        if (this.state.currentRow === row.invoiceID) {
            open = (this.state.showDetails ? false : true);
        }
        this.setState({ showDetails: open, currentRow: row.invoiceID });
    }

    collapseDetails() {
        this.setState({ showDetails: false });
    }

    formatDate(d) {
        if (d && d.length >= 10) {
            var m = Number(d.substring(5, 7));
            return monthNames[m - 1] + " " + d.substring(8, 10) + ", " + d.substring(0, 4);
        }
        else
            return "";
    }

    getStatus(id) {
        var ret = this.props.invoiceStatus.filter((a) => id === a.id);
        if (ret && ret.length > 0) {
            return ret[0].name;
        }
    }

    getPaymentMethod(id) {
        var ret = this.props.paymentMethods.filter((a) => Number(id) === a.id);
        if (ret && ret.length > 0) {
            return ret[0].name;
        }
    }

    getType(id) {
        var ret = this.props.invoiceTypes.filter((a) => Number(id) === a.id);
        if (ret && ret.length > 0) {
            return ret[0].name;
        }
    }

    onHidePayment() {
        this.setState({ showPaymentForm: false, showDetails: false });
        this.refreshClientDetails();
    }

    onClickReissue(inv) {
        swal({
            title: "AC Admin", text: "Do you really want to Resend this Invoice to your Client?", icon: "warning",
            buttons:
                {
                    no: {
                        text: "No",
                        value: "no"
                    },
                    yes: {
                        text: "Yes",
                        value: "yes"
                    },
                    cancel: false
                }
        }).then((value) => {
            switch (value) {
                case "yes":
                    this.props.reissueInvoice(this.props.logintoken, inv.billingPlanID, inv.invoiceID);
                    break;
                case "no":
                    break;
                default:
                    break;
            }
        });

    }

    onClickVoid(inv) {
        swal({
            title: "AC Admin", text: "Are you sure you want to void this invoice?", icon: "warning",
            buttons:
                {
                    no: {
                        text: "No",
                        value: "no"
                    },
                    yes: {
                        text: "Yes",
                        value: "yes"
                    }
                }
        }).then((value) => {
            switch (value) {
                case "yes":
                    inv.invoiceStatusID = 7; // void
                    inv.isVoid = true;
                    this.props.voidInvoice(this.props.logintoken, inv);
                    sleep(50).then(() => this.collapseDetails());

                    break;
                case "no":
                    break;
                default:
                    break;
            }
        });
    }

    getBalance(j) {
        // console.log(j);
        let currency = j.chargeCurrency.toLowerCase();
        let currenciesPayments = j.paymentDetails.map(element => {
            return element.stripeCurrency;
        });
        // console.log(currency, currenciesPayments);

        if(!currenciesPayments.includes(currency)){
            return 0.0;
        }

        var pmt = this.getTotalPayments(j);
        if (pmt >= 0) {
            return j.invoiceTotal - pmt;
        }
        else {
            return j.invoiceTotal + pmt;
        }
    }

    /* Refunds should be negative #s*/
    getTotalPayments(j) {
        var ret = 0;
        for (var i = 0; i < j.paymentDetails.length; i++) {
            if (!j.paymentDetails[i].isVoid) {
                ret = ret + j.paymentDetails[i].amountReceived;
            }
        }
        return ret;
    }

    renderGenOptionsSection(j) {
        return (
            <div style={{border : '1px solid black', width: '250px', textAlign: 'center'}}>
                                                        {/* <h6> Invoice Options </h6> */}
                                                        <div className="filterColumnContactGrid">
                                                            <CheckBox className="contactGridCheckbox" name="checkGenPdf" checked={this.state.checkGenPdf} onChange={this.onCheck} /><Label>PDF&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Label>
                                                        </div>   
                                                        <div className="filterColumnContactGrid">
                                                            <CheckBox className="contactGridCheckbox" name="checkSendEmail" checked={this.state.checkSendEmail} onChange={this.onCheck}  /><Label>Send Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Label>
                                                        </div>  
                                                        <div className="filterColumnContactGrid">
                                                            <CheckBox className="contactGridCheckbox" name="checkGenInvoice" checked={this.state.checkGenInvoice} onChange={this.onCheck}  disabled={j.stripeInvoiceId != ""}  /><Label>Stripe Invoice</Label>
                                                        </div>    
                                                        <button disabled={!(this.state.checkGenPdf || this.state.checkSendEmail || this.state.checkGenInvoice) } onClick={() => this.onclickGenerateInvoiceOptions(j)}>Generate</button>
          </div>
        )
    }

    render() {
        return (
            <div>
                {this.state.showPaymentForm && this.props.sysUser.userRole === 8 && (
                            <Modal
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            className="modal-popup"
                            show={this.state.showPaymentForm}
                            onHide={this.onHidePayment}>
                            <Modal.Body>
            
                                    <PaymentDetailForm onClickCancel={this.onHidePayment} invoiceID={this.state.invoice.invoiceID} invoice={this.state.invoice} currentContact={this.props.currentContact} />
                                    
                                </Modal.Body>
                            </Modal>
                        )}

                
                {this.state.showUpdateInvoiceDetailsForm && this.props.sysUser.userRole === 8 && (
                            <Dialog onClose={this.closeUpdateInvoiceDetail} aria-labelledby="customized-dialog-title" open={this.state.showUpdateInvoiceDetailsForm}  fullWidth={true}
                            maxWidth={'sm'}>
                                <DialogTitle id="customized-dialog-title" onClose={this.closeUpdateInvoiceDetail} >
                                Update Invoice Details (#{this.state.invoice.invoiceID})
                                </DialogTitle>
                                <DialogContent dividers>
                                    <FormControl fullWidth className={this.props.classes.margin}>
                                        <InputLabel id="demo-simple-select-label">Detail Type</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={0}
                                        disabled={true}                                    
                                        >
                                        <MenuItem value={0}>Merchant Fee</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth className={this.props.classes.margin} error={this.state.errorMerchantFeeAmount}>
                                        <InputLabel htmlFor="standard-adornment-amount">Amount</InputLabel>
                                        <Input id="standard-basic"
                                        value={this.state.newAmountMerchantFee}
                                        name={'newAmountMerchantFee'}
                                        onChange={this.handleChangeInputText}
                                        type={'number'}
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>} />
                                        {this.state.errorMerchantFeeAmount && (
                                            <FormHelperText id="component-error-text">The value is required</FormHelperText>
                                        )}                                    
                                    </FormControl>
                                </DialogContent>
                                <DialogActions>
                                <Button autoFocus color="primary" onClick={this.performUpdateInvoiceDetail}>
                                    Save changes
                                </Button>
                                </DialogActions>
                          </Dialog>
                        )}

            
{this.props.invoices.map(
                (j, r) =>
                    <span key={j.invoiceID}>
                        {((this.state.currentRow === j.invoiceID) && (this.state.showDetails == true))
                            ? <>
                                <hr style={{ borderWidth: '2px', borderColor: 'darkgray', marginTop: '0px', marginLeft: '-5px', marginBottom: '0px' }} />
                                <hr style={{ borderWidth: '9px', borderColor: 'cornflowerblue', marginBottom: '0px', marginTop: '0px', marginLeft: '-5px' }} />
                                <hr style={{ borderWidth: '3px', borderColor: 'darkgray', marginTop: '0px', marginLeft: '-5px', marginBottom: '0px' }} /></>
                            : <></>}
                        <Row className={r % 2 === 0 ? "grid-row row-white billingPlanRow notranslate" : "grid-row row-gray billingPlanRow notranslate"}>
                                <Col sm={2} className="billingPlanRowItemText">{j.invoiceName}</Col>
                                <Col sm={1} className="billingPlanRowItemText">{this.getType(j.invoiceTypeID)}</Col>
                                <Col sm={1} className="billingPlanRowItemText">{this.getStatus(j.invoiceStatusID)}</Col>
                                <Col sm={1} className="billingPlanRowItemText">{this.formatDate(j.createdDateUTC)}</Col>
                                <Col sm={1} className="billingPlanRowItemText">{this.getPaymentMethod(j.paymentMethodID)}</Col>
                                <Col sm={1} className="billingPlanRowItemText">${j.invoiceTotal ? j.invoiceTotal.toFixed(2) : 0.00}</Col>
                                <Col sm={1} className="billingPlanRowItemText">${this.getBalance(j) ? this.getBalance(j).toFixed(2) : 0.00}</Col>
                                <Col sm={1} className="billingPlanRowItemText">{this.formatDate(j.dueDate)}</Col>
                                <Col sm={1} className="billingPlanRowItemText">{this.formatDate(j.paidOn)}</Col>
                            <Col sm={1} className="billingPlanRowItemText pdfInvoiceRow">
                                <a target="_blank" rel="noopener noreferrer" href={"https://acadminhubstorage.blob.core.windows.net/invoices/" + String(j.invoiceID) + "." + this.props.currentContact.lastName.replace(" ", "") + ".pdf"}>PDF</a>
                                <span className="tooltiptextPDF">What client actually sees</span>
                            </Col>
                                {/* <Col>{((this.state.currentRow === j.invoiceID) && (this.state.showDetails == true)) ? <span className="material-icons iconSort" onClick={() => (this.editBP(j))} href="#">remove</span> : <span className="material-icons iconSort" onClick={() => (this.editBP(j))} href="#">add</span>} */}
                            <Col sm={1} style={{ textAlign: 'center', paddingLeft: 0, paddingBottom: 0, paddingRight: 0 }} className="detailsActionsCol">
                                {((this.state.currentRow === j.invoiceID) && (this.state.showDetails == true))
                                    ? <button translate="yes" className="button-gridrow-invdetails payorrefundActions2 mb10" onClick={() => (this.editBP(j))} href="#">CLOSE</button>
                                    : <div>
                                    <MediaQuery minWidth={1031}>
                                        <button translate="yes" className="button-gridrow-invdetails payorrefundActions2 mb10" onClick={() => (this.editBP(j))} href="#">DETAILS<br />& ACTIONS</button>
                                    </MediaQuery>
                                    <MediaQuery maxWidth={1030} minWidth={500}>
                                            <button translate="yes" className="button-gridrow-invdetailssm payorrefundActions2 mb10" onClick={() => (this.editBP(j))} href="#">DETAILS</button>
                                    </MediaQuery>
                                    <MediaQuery maxWidth={499}>
                                            <button translate="yes" className="button-gridrow-invdetails payorrefundActions2 mb10" onClick={() => (this.editBP(j))} href="#">DETAILS<br />& ACTIONS</button>
                                    </MediaQuery>
                                    </div>
                                    }
                                </Col>
                                {/* {j.billingPlanID && (
                                <Col className="paddingBottom5"><button className="button-gridrow-new-contact payorrefund" onClick={() => this.onClickReissue(j)}>RESEND INVOICE PDF</button></Col>
                            )} */}
                            </Row>
                        {this.state.showDetails && this.state.currentRow === j.invoiceID && (
                            <div><span>
                                <hr style={{ borderWidth: '3px', borderColor: 'darkgray', marginBottom: '0px', marginTop: '0px', marginLeft: '-5px' }} />
                                <Row style={{ backgroundColor: '#c1d4ef' }} className="grid-row medium-text wide-table details-label">
                                    <Col className="invoicePaymentHeader" sm={2}>ITEM</Col>
                                    <Col className="invoicePaymentHeader" sm={2}>DESCRIPTION</Col>
                                    <Col className="invoicePaymentHeader" sm={1}>PRICE</Col>
                                    <Col className="invoicePaymentHeader" sm={1}>TOTAL</Col>
                                    <Col className="invoicePaymentHeader" sm={1}></Col>
                                </Row>
                                <InvoiceDetailRow details={j.details} />
                            </span></div>
                        )}
                        {this.state.showDetails && this.state.currentRow === j.invoiceID && j.paymentDetails.length > 0 && (
                            <div><span><hr style={{ borderWidth: '2px', borderColor: 'darkgray', marginBottom: '0px', marginLeft: '-5px' }} />
                                <Row style={{ backgroundColor: '#c1d4ef' }} className="grid-row medium-text wide-table details-label">
                                    <Col className="invoicePaymentHeader" sm={1}>PMT TYPE</Col>
                                    <Col className="invoicePaymentHeader" sm={2}>PMT NAME</Col>
                                    <Col className="invoicePaymentHeader" sm={1}>PMT DATE</Col>
                                    <Col className="invoicePaymentHeader" sm={1}>STATUS</Col>
                                    <Col className="invoicePaymentHeader" sm={1}>&nbsp;AMOUNT</Col>
                                    <Col className="invoicePaymentHeader" sm={1}>&nbsp;PMT METHOD</Col>
                                    <Col className="invoicePaymentHeader" sm={1}>CURRENCY</Col>
                                    <Col className="invoicePaymentHeader" sm={2}>&nbsp;MEMO</Col>
                                    <Col className="invoicePaymentHeader" sm={1}>&nbsp;STRIPE RECEIPT</Col>
                                    <Col className="invoicePaymentHeader" sm={2}>
                                        {this.props.sysUser.userRole === 8 ? <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;VOID</span> : <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        }
                                        </Col>
                                </Row>
                                <PaymentDetailRow collapseDetails={this.collapseDetails} details={j.paymentDetails} currentContact={this.props.currentContact} />
                                <hr style={{ borderWidth: '2px', borderColor: 'darkgray', marginTop: '0px', marginLeft: '-5px', marginBottom: '0px' }} />
                            </span></div>
                        )}
                        {this.state.showDetails && this.state.currentRow === j.invoiceID && (
                            <div>
 
                                <Row style={{ backgroundColor: '#c1d4ef', borderWidth: '3px', marginBottom: '-6px' }} className="grid-row medium-text wide-table details-label">
                                    <Col className="invoicePaymentHeaderBottom" sm={3}>BALANCE: &nbsp;${this.getBalance(j) ? this.getBalance(j).toFixed(2) : 0.00}</Col>
                                    <Col className="invoicePaymentHeaderBottom" sm={3}>&nbsp;TOTAL PAYMENTS: &nbsp;${this.getTotalPayments(j) ? this.getTotalPayments(j).toFixed(2) : 0.00}</Col>
                                    <Col className="invoicePaymentHeaderBottom" sm={3}>&nbsp;&nbsp;&nbsp;SUBTOTAL: &nbsp;${j.invoiceSubTotal ? j.invoiceSubTotal.toFixed(2) : 0}</Col>
                                    <Col className="invoicePaymentHeaderBottom" sm={3}>&nbsp;&nbsp;&nbsp;&nbsp;TAX: &nbsp;${j.invoiceTax ? j.invoiceTax.toFixed(2) : 0}</Col>
                            </Row></div>
                        )}
                        {this.state.showDetails && this.state.currentRow === j.invoiceID && (
                            <Row className="grid-row medium-text wide-table details-label">
                                <MediaQuery minWidth={1400}>
                                    <Col sm={3}>
                                        <Row>
                                            {!this.state.showPaymentForm && this.props.sysUser.userRole === 8 && j.invoiceStatusID !== 7 && (
                                                <>
                                                    <Col><button className="button-gridrow-new-contact payorrefund" onClick={() => this.onClickPayment(j)}>PAY/REFUND</button></Col>

                                                    <Col><button className="button-gridrow-new-contact payorrefundVoid" onClick={() => this.onClickVoid(j)}>VOID INVOICE</button></Col>
                                                </>
                                            )}
                                            {j.invoiceID && (
                                                <Col className="paddingBottom5"><button className="button-gridrow-new-contact payorrefund resendInvoice" onClick={() => this.onClickReissue(j)}>RESEND INVOICE PDF<span className="tooltiptextResend">Click here to Resend a PDF of this Invoice to your Client. If you want to see what client will actually receive, click on the PDF hyperlink above</span></button></Col>
                                            )}

                                            {this.props.sysUser.userRole === 8 && (
                                                <>
                                                <Col><button className="button-gridrow-new-contact payorrefundVoid" onClick={() => this.onclickUpdateInvoiceDetail(j)}>UPDATE INVOICE DETAILS</button></Col>
                                                <Col><button className="button-gridrow-new-contact payorrefundVoid" onClick={() => { this.setState({showGenerateOptions: !this.state.showGenerateOptions}); }}>GENERATE OPTIONS</button></Col>
                                                </>
                                            )}

                                            {this.props.sysUser.userRole === 8 && this.state.showGenerateOptions && (
                                                <Col>
                                                    {this.renderGenOptionsSection(j)}
                                                </Col>
                                            )}
                                                
                                        </Row>
                                    </Col>
                                </MediaQuery>
                                <MediaQuery minWidth={1061} maxWidth={1399}>
                                    <Col sm={4}>
                                        <Row>
                                            {!this.state.showPaymentForm && this.props.sysUser.userRole === 8 && j.invoiceStatusID !== 7 && (
                                                <>
                                                    <Col><button className="button-gridrow-new-contact payorrefund" onClick={() => this.onClickPayment(j)}>PAY/REFUND</button></Col>

                                                    <Col><button className="button-gridrow-new-contact payorrefundVoid" onClick={() => this.onClickVoid(j)}>VOID INVOICE</button></Col>
                                                </>
                                            )}
                                            {j.invoiceID && (
                                                <Col className="paddingBottom5"><button className="button-gridrow-new-contact payorrefund resendInvoice" onClick={() => this.onClickReissue(j)}>RESEND INVOICE PDF<span className="tooltiptextResend">Click here to Resend a PDF of this Invoice to your Client. If you want to see what client will actually receive, click on the PDF hyperlink above</span></button></Col>
                                            )}

                                            {this.props.sysUser.userRole === 8 && (
                                                <>
                                                <Col className="paddingBottom5"><button className="button-gridrow-new-contact payorrefundVoid" onClick={() => this.onclickUpdateInvoiceDetail(j)}>UPDATE INVOICE DETAILS</button></Col>
                                                <Col><button className="button-gridrow-new-contact payorrefundVoid" onClick={() => { this.setState({showGenerateOptions: !this.state.showGenerateOptions}); }}>GENERATE OPTIONS</button></Col>
                                                </>
                                            )}

                                            {this.props.sysUser.userRole === 8 && this.state.showGenerateOptions && (
                                                <Col>
                                                    {this.renderGenOptionsSection(j)}
                                                </Col>
                                            )}
                                        </Row>
                                    </Col>
                                </MediaQuery>
                                <MediaQuery minWidth={861} maxWidth={1060}>
                                    <Col sm={5}>
                                        <Row>
                                            {!this.state.showPaymentForm && this.props.sysUser.userRole === 8 && j.invoiceStatusID !== 7 && (
                                                <>
                                                    <Col><button className="button-gridrow-new-contact payorrefund" onClick={() => this.onClickPayment(j)}>PAY/REFUND</button></Col>

                                                    <Col><button className="button-gridrow-new-contact payorrefundVoid" onClick={() => this.onClickVoid(j)}>VOID INVOICE</button></Col>
                                                </>
                                            )}
                                            {j.invoiceID && (
                                                <Col className="paddingBottom5"><button className="button-gridrow-new-contact payorrefund resendInvoice" onClick={() => this.onClickReissue(j)}>RESEND INVOICE PDF<span className="tooltiptextResend">Click here to Resend a PDF of this Invoice to your Client. If you want to see what client will actually receive, click on the PDF hyperlink above</span></button></Col>
                                            )}
                                            {this.props.sysUser.userRole === 8 && (
                                                <>    
                                                    <Col className="paddingBottom5"><button className="button-gridrow-new-contact payorrefundVoid" onClick={() => this.onclickUpdateInvoiceDetail(j)}>UPDATE INVOICE DETAILS</button></Col>
                                                    <Col><button className="button-gridrow-new-contact payorrefundVoid" onClick={() => { this.setState({showGenerateOptions: !this.state.showGenerateOptions}); }}>GENERATE OPTIONS</button></Col>
                                                </>
                                            )}


                                            {this.props.sysUser.userRole === 8 && this.state.showGenerateOptions && (
                                                <Col>
                                                    {this.renderGenOptionsSection(j)}
                                                </Col>
                                            )}
                                        </Row>
                                    </Col>
                                </MediaQuery>
                                <MediaQuery minWidth={751} maxWidth={860}>
                                    <Col sm={6}>
                                        <Row>
                                            {!this.state.showPaymentForm && this.props.sysUser.userRole === 8 && j.invoiceStatusID !== 7 && (
                                                <>
                                                    <Col><button className="button-gridrow-new-contact payorrefund" onClick={() => this.onClickPayment(j)}>PAY/REFUND</button></Col>

                                                    <Col><button className="button-gridrow-new-contact payorrefundVoid" onClick={() => this.onClickVoid(j)}>VOID INVOICE</button></Col>
                                                </>
                                            )}
                                            {j.invoiceID && (
                                                <Col className="paddingBottom5"><button className="button-gridrow-new-contact payorrefund resendInvoice" onClick={() => this.onClickReissue(j)}>RESEND INVOICE PDF<span className="tooltiptextResend">Click here to Resend a PDF of this Invoice to your Client. If you want to see what client will actually receive, click on the PDF hyperlink above</span></button></Col>
                                            )}
                                            {this.props.sysUser.userRole === 8 && (
                                                <>
                                                <Col className="paddingBottom5"><button className="button-gridrow-new-contact payorrefundVoid" onClick={() => this.onclickUpdateInvoiceDetail(j)}>UPDATE INVOICE DETAILS</button></Col>
                                                <Col><button className="button-gridrow-new-contact payorrefundVoid" onClick={() => { this.setState({showGenerateOptions: !this.state.showGenerateOptions}); }}>GENERATE OPTIONS</button></Col>
                                                </>
                                            )}

                                            {this.props.sysUser.userRole === 8 && this.state.showGenerateOptions && (
                                                <Col>
                                                    {this.renderGenOptionsSection(j)}
                                                </Col>
                                            )}

                                        </Row>
                                    </Col>
                                </MediaQuery>
                                <MediaQuery maxWidth={750}>
                                    <Col sm={9}>
                                        <Row>
                                            {!this.state.showPaymentForm && this.props.sysUser.userRole === 8 && j.invoiceStatusID !== 7 && (
                                                <>
                                                    <Col><button className="button-gridrow-new-contact payorrefund" onClick={() => this.onClickPayment(j)}>PAY/REFUND</button></Col>

                                                    <Col><button className="button-gridrow-new-contact payorrefundVoid" onClick={() => this.onClickVoid(j)}>VOID INVOICE</button></Col>
                                                </>
                                            )}
                                            {j.invoiceID && (
                                                <Col className="paddingBottom5"><button className="button-gridrow-new-contact payorrefund resendInvoice" onClick={() => this.onClickReissue(j)}>RESEND INVOICE PDF<span className="tooltiptextResend">Click here to Resend a PDF of this Invoice to your Client. If you want to see what client will actually receive, click on the PDF hyperlink above</span></button></Col>
                                            )}
                                            {this.props.sysUser.userRole === 8 && (
                                                <>
                                                <Col className="paddingBottom5"><button className="button-gridrow-new-contact payorrefundVoid" onClick={() => this.onclickUpdateInvoiceDetail(j)}>UPDATE INVOICE DETAILS</button></Col>
                                                <Col><button className="button-gridrow-new-contact payorrefundVoid" onClick={() => { this.setState({showGenerateOptions: !this.state.showGenerateOptions}); }}>GENERATE OPTIONS</button></Col>
                                                </>
                                            )}

                                            {this.props.sysUser.userRole === 8 && this.state.showGenerateOptions && (
                                                <Col>
                                                    {this.renderGenOptionsSection(j)}
                                                </Col>
                                            )}
                                        </Row>
                                    </Col>
                                </MediaQuery>
                            </Row>
                           
                        )}
                        {((this.state.currentRow === j.invoiceID) && (this.state.showDetails == true))
                            ? <>
                                <hr style={{ borderWidth: '2px', borderColor: 'darkgray', marginTop: '0px', marginLeft: '-5px', marginBottom: '0px' }} />
                                <hr style={{ borderWidth: '9px', borderColor: 'cornflowerblue', marginBottom: '0px', marginTop: '0px', marginLeft: '-5px' }} />
                                <hr style={{ borderWidth: '3px', borderColor: 'darkgray', marginTop: '0px', marginLeft: '-5px', marginBottom: '0px' }} /></>
                            : <></>}
                </span>
            )}

        </div>
    )

    }

}

function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        sysUser: state.loginReducer.sysUser,
        paymentMethods: state.sysReducer.paymentMethods,
        invoiceStatus: state.sysReducer.invoiceStatus,
        invoiceTypes: state.sysReducer.invoiceTypes
    }
}

export default connect(mapStateToProps, { reissueInvoice, voidInvoice, updateInvoiceDetail, regenerateInvoiceClient })(withStyles(styles)(InvoiceRow));
