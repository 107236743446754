import React from 'react';
import { connect } from 'react-redux';
import { hideNavbar } from '../../hooks/store/actions/navBarActions';
import { showEditUserForm } from '../../hooks/store/actions/loginActions';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { StarBorder } from '@material-ui/icons';
import { ExpandLess } from '@material-ui/icons';
import { ExpandMore } from '@material-ui/icons';
import { Collapse, IconButton } from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import Icon from '@material-ui/core/Icon';
import { getLibraryData, downloadLibraryItem, updateLibraryData, updateLoading } from '../../hooks/store/actions/libraryActions';
import { loadCSS } from 'fg-loadcss/src/loadCSS';
import {fulfilled_function, libraryActionTypes} from "../../hooks/store/actionConsts";
import toaster from "toasted-notes";

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing.unit * 4,
    },
});

class LibraryPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { update: false };
        this.props.hideNavbar();
        this.onClickCancel = this.onClickCancel.bind(this);
        this.onClickHideForm = this.onClickHideForm.bind(this);
        this.onClickShowForm = this.onClickShowForm.bind(this);
        this.handleListItemClick = this.handleListItemClick.bind(this);
        this.handleDownloadFile = this.handleDownloadFile.bind(this);
    }

    componentDidMount() {
        this.props.getLibraryData(this.props.logintoken);
        loadCSS(
            'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
            document.querySelector('#insertion-point-jss'),
          );
    }

    onClickCancel() {
        this.props.showEditUserForm(false);
    }

    onClickShowForm() {
        this.setState({ createUser: true });
    }

    onClickHideForm() {
        this.setState({ createUser: false });
    }


    handleListItemClick = (event, item) => {
        this.props.updateLibraryData(item);
        this.setState({ update: !this.state.update});
    };

    handleDownloadFile = (event, item) => {
        console.log('download file', item);
        this.props.updateLoading(true);
        downloadLibraryItem(this.props.logintoken, item.id)
        .then((res) => {
            this.props.updateLoading(false);
            const type = res.headers['content-type']
            this.saveFile(res.data, type, item.name);
        }).catch(err => {
            this.props.updateLoading(false);
            if (err.response && err.response.status === 400) {
                toaster.notify("Unable to download file " + item.name + ", try again later", { duration: 4000 });
            }
        }); // (error) => console.log(error)
    }

    saveFile = (blobContent, type, fileName) => {
        const blob = new Blob([blobContent], { type: type });
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName;
        link.click()
    }

    getIconFile = (icon) => {
        switch(icon){
            case "video":
                return (<Icon className="fa fa-video" />);
            case "pdf":
                return (<Icon className="fa fa-file-pdf" />);
            case "word":
                return (<Icon className="fa fa-file-word" />);
            default:
                return (<Icon className="fa fa-video" />);
        }
    }

    componentDidUpdate() {
        // console.log('update comp');
    }

    rowListItem = (item, paddingLeft) => {           
        return (
            <>
                <ListItem
                    button
                    style={{paddingLeft: paddingLeft}}
                    // selected={this.state.selectedIndex === 1}
                    onClick={event => this.handleListItemClick(event, item)}
                >
                    <ListItemIcon>
                        {item.type == "folder" ? (<FolderIcon />) : this.getIconFile(item.icon)}
                    </ListItemIcon>
                    <ListItemText primary={item.name} />

                    {item.type == "file" && (
                         <ListItemSecondaryAction onClick={e => this.handleDownloadFile(e, item)}>
                         <IconButton aria-label="Download">
                           <DownloadIcon />
                         </IconButton>
                       </ListItemSecondaryAction>
                    ) }


                    {item.type === "folder" && (
                        item.open ? <ExpandLess /> : <ExpandMore />
                    )}
                </ListItem>
                {item.type === "folder" && (
                    <Collapse in={item.open} unmountOnExit>
                        <List component="div" disablePadding>
                            {item.items && item.items.map(sub => {
                                return (this.rowListItem(sub, paddingLeft + 15))
                            })}
                        </List>
                    </Collapse>
                )}
            </>
        )
    }

    render() {
        const { libraryData } = this.props;
        return (
            <div style={{ color: "black" }}>
                {(this.props.showLoadingGif) && (<div id="loadingGif"></div>)}
                <h3>Library</h3>

                <Card style={{ height: "100%", marginBottom: "15px" }}>
                    <CardContent className='notranslate'>
                        {
                            libraryData && libraryData.map(item => {
                                return (
                                    <List key={item.id} component="nav">
                                        {this.rowListItem(item, 20)}
                                    </List>
                                )
                            })
                        }
                    </CardContent>
                    <CardActions>

                    </CardActions>
                </Card>

            </div>
        );
    }


}

function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        sysUser: state.loginReducer.sysUser,
        selectedUser: state.loginReducer.selectedUser,
        showEUForm: state.loginReducer.showEUForm,
        libraryData: state.libraryReducer.libraryData,
        showLoadingGif: state.libraryReducer.showLoadingGif,
    }
}

export default connect(mapStateToProps, { hideNavbar, showEditUserForm, getLibraryData, updateLibraryData, updateLoading })(withStyles(styles)(LibraryPage));
