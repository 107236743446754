import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { formatCurrency, monthNames } from '../../../hooks/store/actionConsts';
import { voidPayment } from '../../../hooks/store/actions/financeActions';
import { selectContact } from '../../../hooks/store/actions/contactActions.js';
import { sleep } from '../../../hooks/store/actionConsts';
import swal from 'sweetalert';
class PaymentDetailRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };

        this.onClickVoidPayment = this.onClickVoidPayment.bind(this);
    }

    onClickVoidPayment(invoiceID, id, isRefund) {
        swal({
            title: "AC Admin", text: "Are you sure you want to void this " + (isRefund ? "refund" : "payment") + "??", icon: "warning",
            buttons:
            {
                no: {
                    text: "No",
                    value: "no"
                },
                yes: {
                    text: "Yes",
                    value: "yes"
                }
            }
        }).then((value) => {
            switch (value) {
                case "yes":
                    this.props.voidPayment(this.props.logintoken, invoiceID, id);       
                    this.props.collapseDetails();
                    this.refreshClientDetails();
                    break;
                case "no":
                    break;
                default:
                    break;
            }
        });
    }

    refreshClientDetails() {
        //this.props.selectContact(this.props.contact);
        sleep(50).then(() => {
            document.location = "/clientMgmt/autoload";
        })
    }

    formatDate(d) {
        if (d && d.length >= 10) {
            var m = Number(d.substring(5, 7));
            return monthNames[m - 1] + " " + d.substring(8, 10) + ", " + d.substring(0, 4);
        }
        else
            return "";
    }

    getPaymentMethod(id) {
        var ret = this.props.paymentMethods.filter((a) => Number(id) === a.id);
        if (ret && ret.length > 0) {
            return ret[0].name;
        }
    }

    componentDidMount() {
        //console.log(this.props.details);
    }

    render() {
        return (
            this.props.details.map(
                (j, r) =>
                    <span key={j.paymentDetailID}>
                        <Row style={{ marginLeft: '-5px', paddingTop: '10px' }} className={r % 2 === 0 ? "grid-row wide-table row-gray2 ml1" : "grid-row wide-table row-gray3 ml1"} >
                            <Col sm={1}>{j.isRefund ? "REFUND" : "PAYMENT"}</Col>
                            <Col sm={2}>{j.paymentName}</Col>
                            <Col sm={1}>{this.formatDate(j.receivedOnUTC)}</Col>
                            <Col sm={1}>{j.paymentStatus ? j.paymentStatus.name : j.refundStatus ?  j.refundStatus.name : ""}</Col>
                            <Col sm={1}>${j.amountReceived ? formatCurrency(j.amountReceived.toFixed(2)) : 0.00}</Col>
                            <Col sm={1}>{this.getPaymentMethod(j.paymentTypeID)}</Col>
                            <Col sm={1}>{j.stripeCurrency}</Col>
                            <Col sm={2}>{j.notes}</Col>
                            <Col sm={1}><a href={j.stripeReceiptURL} target="_blank" rel="noopener noreferrer">{j.stripeReceiptURL ? 'RECEIPT' : ''}</a></Col>
                            <Col sm={1}>
                                
                                {!j.stripeChargeId && !j.isVoid && this.props.sysUser.userRole === 8 && (
                                   <button style={{ marginTop: '-20px' }} className="button-gridrow-new-contact payorrefundVoid" onClick={() => this.onClickVoidPayment(j.invoiceID, (j.paymentDetailID ? j.paymentDetailID : j.refundDetailID), j.isRefund)}>VOID</button>
                                )}
                                {j.isVoid ? <div style={{ height: '26px', marginLeft: '18px', fontWeight: '900', color: 'darkred' }}>Voided</div> : <></>}

                            </Col>
                        </Row>
                    </span>
            )
        );
    }

    
}
    
function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        sysUser: state.loginReducer.sysUser,
        paymentMethods: state.sysReducer.paymentMethods
    }
}

export default connect(mapStateToProps, { voidPayment, selectContact})(PaymentDetailRow);
