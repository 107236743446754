import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer, createMigrate } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';

const initialState = {};
const middleware = [thunk];

const migrations = {
    20: state => initialState
};

const persistConfig = {
    key: 'root',
    version: 20,
    storage: storage,
    migrate: createMigrate(migrations, {debug: false})
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
export const useStore = createStore(

    persistedReducer,
    initialState,
    composeWithDevTools(
        applyMiddleware(...middleware)
    ));

export const persistor = persistStore(useStore);




