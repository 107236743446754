import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


export class AffiliationRow extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
        };
    }

    formatCurrency(value) {
        let val = (value/1).toFixed(2);
        let prev = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        return '$' + prev;
    }

    render() {
        return (
            this.props.report.map(
                (item, key) =>
                    <Row className={key % 2 === 0 ? "grid-row row-white settingsTableScroll notranslate" : "grid-row row-gray settingsTableScroll notranslate"} key={key} data-is-summary={item.ClientName === "Total"}>
                        <Col className="centerUsersCol" xs={1} sm={1}><span>{item.ClientName}</span><div className="borderMarkerCol"></div></Col>
                        <Col className="centerUsersCol" xs={1} sm={1}><span>{item.PaymentType}</span><div className="borderMarkerCol"></div></Col>
                        <Col className="centerUsersCol" xs={2} sm={1}><span>{ this.formatCurrency(item.NetPayment)}</span><div className="borderMarkerCol"></div></Col>
                        <Col className="centerUsersCol" xs={1} sm={1}><span>{ this.formatCurrency(item.BillingFee)}</span><div className="borderMarkerCol"></div></Col>
                        <Col className="centerUsersCol" xs={1} sm={1}><span>{ this.formatCurrency(item.MerchantFee)}</span><div className="borderMarkerCol"></div></Col>
                        <Col className="centerUsersCol" xs={1} sm={1}><span>{ this.formatCurrency(item.AffiliateTax)}</span><div className="borderMarkerCol"></div></Col>
                        <Col className="centerUsersCol" xs={1} sm={1}><span>{ this.formatCurrency(item.TotalTax)}</span><div className="borderMarkerCol"></div></Col>
                        <Col className="centerUsersCol" xs={1} sm={1}><span>{ this.formatCurrency(item.NetIncome)}</span><div className="borderMarkerCol"></div></Col>
                        <Col className="centerUsersCol" xs={1} sm={1}><span>{ this.formatCurrency(item.NetIncomeBeforeTax)}</span><div className="borderMarkerCol"></div></Col>
                        <Col className="centerUsersCol" xs={1} sm={1}><span>{ this.formatCurrency(item.TotalBeforeTax)}</span><div className="borderMarkerCol"></div></Col>
                        <Col className="centerUsersCol" xs={1} sm={1}><span>{ this.formatCurrency(item.AffiliateTotal)}</span><div className="borderMarkerCol"></div></Col>
                        <Col className="centerUsersCol" xs={1} sm={1}><span>{ this.formatCurrency(item.Total)}</span><div className="borderMarkerCol"></div></Col>
                    </Row>
            )
        );
    }

    
}
    
function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        sysUser: state.loginReducer.sysUser,
    }
}

export default connect(mapStateToProps, {  })(AffiliationRow);
