import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import Label from 'react-bootstrap/FormLabel';
import { selectUser, getAllUsers } from '../../../hooks/store/actions/loginActions';
import { addSystemNotification } from '../../../hooks/store/actions/sysActions.js';
import UserRow from './UserRow';
import SystemNotificationRow from './SystemNotificationRow';
import Modal from 'react-bootstrap/Modal';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


class UserGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            direction: true,
            sort: "",
            showSysNotifications: false,
            showAddNewSysNotificationModal: false,
            dropdownOpenFilterUsers: false,
            currentFilterUser: 'Active',
        };
        this.props.getAllUsers(this.props.token, "active");

        this.getSortedSet = this.getSortedSet.bind(this);
        this.sort = this.sort.bind(this);
        this.onClickImpersonate = this.onClickImpersonate.bind(this);
        this.onSelectFilterUser = this.onSelectFilterUser.bind(this);
        this.toggleFilterUser = this.toggleFilterUser.bind(this);
    }

    handleCancel = () => {
        this.setState({ showAddNewSysNotificationModal: false });
    }

    onClickImpersonate() {
        this.props.selectUser(this.props.sysUser, true);
    }

    getSortedSet() {
        var users = this.props.users.slice();

        if (this.state.sort === "lastName") {
            if (this.state.direction) {
                users = users.sort((a, b) => (a.lastName > b.lastName) ? 1 : -1)
            }
            else {
                users = users.sort((a, b) => (a.lastName < b.lastName) ? 1 : -1)
            }
        }
        else if (this.state.sort === "firstName") {
            if (this.state.direction) {
                users = users.sort((a, b) => (a.firstName > b.firstName) ? 1 : -1)
            }
            else {
                users = users.sort((a, b) => (a.firstName < b.firstName) ? 1 : -1)
            }
        }
        else if (this.state.sort === "email") {
            if (this.state.direction) {
                users = users.sort((a, b) => (a.email.toLowerCase() > b.email.toLowerCase()) ? 1 : -1);
            }
            else {
                users = users.sort((a, b) => (a.email.toLowerCase() < b.email.toLowerCase()) ? 1 : -1);
            }
        }
        else if (this.state.sort === "userRole") {
            if (this.state.direction) {
                users = users.sort((a, b) => (a.userRole < b.userRole) ? 1 : -1)
            }
            else {
                users = users.sort((a, b) => (a.userRole > b.userRole) ? 1 : -1)
            }
        }
        return users;
    }

    sort(type) {
        if (this.props.users) {
            if (this.state.sort === type) {
                this.setState({ direction: !this.state.direction });
            }
            else {
                this.setState({ sort: type, direction: true });
            }
        }
    }

    handleChange = (e) => {
        this.setState({ taNew: e.target.value });
    }

    addSysNotification = () => {
        this.setState({ showAddNewSysNotificationModal: true });
    }

    saveSysNotification = () => {
        var notification = { "notificationText": this.state.taNew }
        console.log(notification);
        this.props.addSystemNotification(this.props.token, notification)
        this.setState({ showAddNewSysNotificationModal: false });
    }

    onSelectFilterUser = (text) => {
        this.setState({ currentFilterUser: text });
        this.props.getAllUsers(this.props.token, text.toLowerCase());
    }

    toggleFilterUser = () => {
        this.setState({ dropdownOpenFilterUsers: !this.state.dropdownOpenFilterUsers });
    }

    render() {
        var items = this.getSortedSet();

        return (
            <>
                <div style={{ whiteSpace: 'nowrap' }}>
                    <Button style={{ float: 'right', marginTop: '10px' }} onClick={() => this.setState({ showSysNotifications: !this.state.showSysNotifications })}>
                        {this.state.showSysNotifications ? 'HIDE SYSTEM NOTIFICATIONS' : 'MANAGE SYSTEM NOTIFICATIONS'}</Button>
                    <br />
                </div>


                <div className={this.state.showSysNotifications ? 'sysNotificationHeader' : 'hidden'}>

                <h2 className="settingsHeaderText"><b>System Notifications</b></h2>

                <div style={{ whiteSpace: 'nowrap', marginTop: '16px', marginBottom: '13px' }}>
                    <Button style={{ float: 'left', marginTop: '-20px', marginLeft: '9px' }} onClick={this.addSysNotification}>
                        ADD NEW</Button>
                    <br />
                        <Modal
                            dialogClassName="modalDialog"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={this.state.showAddNewSysNotificationModal}
                            onHide={() => this.setState({ showAddNewSysNotificationModal: !this.state.showAddNewSysNotificationModal })}
                            style={{ height: 'auto', textAlign: 'center' }}
                        >
                            <Modal.Body className="modalBody">
                                <div>
                                    <div style={{ marginTop: '13px', marginBottom: '3px', fontWeight: '800' }}><label>Notification Text</label></div>
                                 <textarea id={'taNew'} onBlur={this.handleChange} className="graphTextFront modalTextArea"></textarea>
                                    <div style={{ marginTop: '10px', marginBottom: '-60px', fontSize: '13px', fontStyle: 'italic', fontWeight: '600' }}>
                                    <label>Enter New System Notification Text or HTML.<br />Notifications will be displayed based on how recently they were last updated.</label></div>
                                </div>
                                <br /><br /><br /><br />
                                <button className={'reset-password-button'} style={{ borderRadius: '13px' }} onClick={this.saveSysNotification}>SAVE NEW</button>
                                &nbsp;
                                <button className="cancel-button" onClick={this.handleCancel}>CANCEL</button>
                            </Modal.Body>
                        </Modal>
                </div>

                <div className={'sysNotificationHeader2'}>
                    <Row className={"grid-row sysNotificationHeader3"} key={'sysNotificationID'}>
                        <Col style={{ display: 'flex', alignItems: 'center', zIndex: '0', overflow: 'hidden', fontWeight: '700', fontSize: '14px' }} xs={3} sm={2}>
                            <span className="graphTextFront">{'Last Updated Date'}</span>
                            <div style={{ position: 'absolute', width: '100%', right: '0', height: '78%', borderRight: '1px solid black' }}></div>
                        </Col>
                            <Col style={{ display: 'flex', alignItems: 'center', zIndex: '0', overflow: 'hidden', fontWeight: '700', fontSize: '14px' }} xs={9} sm={7}>
                            <span className="graphTextFront">{'Notification Text'}</span>
                            <div style={{ position: 'absolute', width: '100%', right: '0', height: '78%', borderRight: '1px solid black' }}></div>
                        </Col>
                            <Col style={{ display: 'flex', alignItems: 'center', zIndex: '0', overflow: 'hidden', fontWeight: '700', fontSize: '14px' }} xs={0} sm={1}>
                            <span className="graphTextFront">{'Start Date'}</span><div className="highlightCell"></div>
                            <div style={{ position: 'absolute', width: '100%', right: '0', height: '78%', borderRight: '1px solid black' }}></div>
                        </Col>
                            <Col style={{ display: 'flex', alignItems: 'center', zIndex: '0', overflow: 'hidden', fontWeight: '700', fontSize: '14px' }} xs={0} sm={1}
                        ><span className="graphTextFront">{'End Date'}</span><div className="highlightCell"></div>
                            <div style={{ position: 'absolute', width: '100%', right: '0', height: '78%', borderRight: '1px solid black' }}></div>
                        </Col>
                        <Col style={{ display: 'flex', alignItems: 'center', zIndex: '0', overflow: 'hidden' }} xs={1} sm={1}>&nbsp;
                        </Col>
                    </Row>
                </div>

                <SystemNotificationRow />

                <hr style={{border: '5px', fontWeight: '900'}} />
                </div>

                <h2 className="settingsHeaderText2"><b>Users</b></h2>
                <Dropdown isOpen={this.state.dropdownOpenFilterUsers} toggle={this.toggleFilterUser}>
                                        <DropdownToggle className="ddStyle" caret>
                                            {this.state.currentFilterUser ? this.state.currentFilterUser : "Select a filter"}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem className="text-primary" onClick={() => this.onSelectFilterUser("All")}>All</DropdownItem>
                                            <DropdownItem className="text-primary" onClick={() => this.onSelectFilterUser("Active")}>Active</DropdownItem>
                                            <DropdownItem className="text-primary" onClick={() => this.onSelectFilterUser("Inactive")}>Inactive</DropdownItem>
                                        </DropdownMenu>
                </Dropdown>
                <h5 className="impersonationName">{this.props.sysUser.id !== this.props.user.id ? (
                    <span>
                        <span>Impersonating: </span>
                        <span><b>{this.props.user.firstName}</b> </span>
                        <span><b>{this.props.user.lastName}</b></span>
                    </span>
                )
                    :
                    ""}</h5>
                {this.props.sysUser.id !== this.props.user.id && (
                    <div className="unimpersonateContainer"><span className="unimpersonateButton" onClick={() => this.onClickImpersonate()}><span>&#10006;</span>Unimpersonate</span></div>
                )}
                <div className="table-responsive contactGridTableResponsive contactGridMobile" style={{ overflow: "unset", whiteSpace: "pre-line" }}>
                    <Row className="grid-row row-header wide-table wideTableMobile">
                        <Col className="grid-header contactGridHead" onClick={() => this.sort("firstName")} xs={1} sm={1}><Label className="contactGridLabel" style={{ cursor: 'pointer' }}>First Name</Label>{(this.state.sort === "firstName" && this.state.direction === true) && (<span className="material-icons iconSort">expand_more</span>)} {(this.state.sort === "firstName" && this.state.direction === false) && (<span className="material-icons iconSort">expand_less</span>)}{(this.state.sort !== "firstName") && (<span className="material-icons iconSort">chevron_right</span>)}</Col>
                        <Col className="grid-header contactGridHead" onClick={() => this.sort("lastName")} xs={1} sm={2}><Label className="contactGridLabel" style={{ cursor: 'pointer' }}>Last Name</Label>{(this.state.sort === "lastName" && this.state.direction === true) && (<span className="material-icons iconSort">expand_more</span>)} {(this.state.sort === "lastName" && this.state.direction === false) && (<span className="material-icons iconSort">expand_less</span>)}{(this.state.sort !== "lastName") && (<span className="material-icons iconSort">chevron_right</span>)}</Col>
                        <Col className="grid-header contactGridHead" onClick={() => this.sort("email")} xs={2} sm={2}><Label className="contactGridLabel" style={{ cursor: 'pointer' }}>Email</Label>{(this.state.sort === "email" && this.state.direction === true) && (<span className="material-icons iconSort">expand_more</span>)} {(this.state.sort === "email" && this.state.direction === false) && (<span className="material-icons iconSort">expand_less</span>)}{(this.state.sort !== "email") && (<span className="material-icons iconSort">chevron_right</span>)}</Col>
                        <Col className="grid-header contactGridHead" xs={1} sm={1}>Phone</Col>
                        <Col className="grid-header contactGridHead" xs={2} sm={2}>Logo URL</Col>
                        <Col className="grid-header contactGridHead cobrandingLabel" xs={1} sm={1}>Cobranding</Col>
                        <Col className="grid-header contactGridHead" onClick={() => this.sort("userRole")} xs={1} sm={1}><Label className="contactGridLabel" style={{ cursor: 'pointer' }}>User Role</Label>{(this.state.sort === "userRole" && this.state.direction === true) && (<span className="material-icons iconSort">expand_more</span>)} {(this.state.sort === "userRole" && this.state.direction === false) && (<span className="material-icons iconSort">expand_less</span>)}{(this.state.sort !== "userRole") && (<span className="material-icons iconSort">chevron_right</span>)}</Col>
                        <Col className="grid-header contactGridHead" xs={3} sm={1}></Col>
                    </Row>
                    <div className="short-table shortTableMobile">
                        <UserRow users={items} />
                    </div>
                </div>
            </>
        );
    }

}

function mapStateToProps(state) {
    return {
        token: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        sysUser: state.loginReducer.sysUser,
        users: state.loginReducer.allUsers
    }
}

export default connect(mapStateToProps, { selectUser, getAllUsers, addSystemNotification })(UserGrid);
