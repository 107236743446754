import toaster from 'toasted-notes';
import { affiliationActionTypes, pending, rejected, fulfilled, serverEnvironment } from '../actionConsts.js';

export default function reducer(state = {
    showLoadingGif: false,
    report: [],
    progress: 0.0,
    message: ''
}, action) {

    switch (action.type) {
        case pending(affiliationActionTypes.GET_INVOICE_REPORT):
            return {
                ...state,
                showLoadingGif: true,
                message: "Fetching invoice report..."
            }
        case fulfilled(affiliationActionTypes.GET_INVOICE_REPORT):
            return {
                ...state,
                report: action.payload.data,
                showLoadingGif: false,
                message: "Report fetched"
            }
        case rejected(affiliationActionTypes.GET_INVOICE_REPORT):
            toaster.notify(action.payload.message, { duration: 4000 });
            // eslint-disable-next-line no-case-declarations
            let message = ''
            if (action.payload.data)
                message = action.payload.data.Message
            return {
                ...state,
                showLoadingGif: false,
                message: message
            }
        case pending(affiliationActionTypes.GET_AFFILIATION_PROGRESS):
            return {
                ...state,
                showLoadingGif: true,
                message: "Fetching affiliation progress..."
            }
        case fulfilled(affiliationActionTypes.GET_AFFILIATION_PROGRESS):
            return {
                ...state,
                progress: action.payload.data.Progress,
                showLoadingGif: false,
                message: "Affiliation progress fetched"
            }
        case rejected(affiliationActionTypes.GET_AFFILIATION_PROGRESS):
            toaster.notify(action.payload.message, { duration: 4000 });
            if (action.payload.data)
                message = action.payload.data.Message
            return {
                ...state,
                showLoadingGif: false,
                message: message
            }
        default:
            return {
                ...state
            }
        }
};
