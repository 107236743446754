import React from "react";
import { connect } from "react-redux";

//stylesheet
import '../app/style/App.css';

import EditProfile from './EditProfile.js'
import EditUserContact from './contact/EditUserContact.js'
import MenuSearchBar from './MenuSearchBar'
import ContactGrid from './contact/ContactGrid.js';
import Dashboard from './finance/Dashboard.js';
import ClientGrid from './finance/ClientGrid.js';
import AffiliationPage from './AffiliationPage';
import ReportForm from './ReportForm';
import SettingsForm from './SettingsForm';
import SearchContactResult from './contact/SearchContactResult';
import Action from './finance/Action.js';
import { showEditForm, getContactsById, initializeGif } from '../../hooks/store/actions/contactActions.js';
import { getUserSysNotifications, dismissUserSysNotification } from '../../hooks/store/actions/loginActions.js';
import { sleep } from '../../hooks/store/actionConsts';
import Modal from 'react-bootstrap/Modal';
import MediaQuery from 'react-responsive';
import Library  from './LibraryPage';
import StripeAccountOk from './StripeAccountOk';


import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";


//form components
import NewContact from './contact/NewContact'
import AffiliateView from './contact/AffiliateView.js';
import ClientInfo from './contact/ClientInfo.js';

//export context objects
export const HeaderContext = React.createContext();
export const NavBarContext = React.createContext();
export const MainContext = React.createContext();
export const SideBarContext = React.createContext();
export const FooterContext = React.createContext();

class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModalCount: 0,
        };

        if (!document.location.href.includes("/viewContacts") || (!this.props.currentContact || this.props.currentContact.contactID === 0)) {
            sleep(100).then(
                this.props.showEditForm(1));
        }

    }

    componentDidMount() {
        this.props.getContactsById(this.props.logintoken, this.props.user.contactId, this.props.user.contactId);
        this.props.initializeGif();

        if (this.props.showSysNotifications) {
            this.setState({ showSysNotificationModal: true })
            this.setState({ showModalCount: this.state.showModalCount + 1 })
        }
    }

    render() {

        var showModal = this.state.showSysNotificationModal === undefined ? this.props.showSysNotifications : this.state.showSysNotificationModal;
        //console.log(this.state.showModalCount)
        showModal = this.state.showModalCount > 0 ? false : showModal;
        showModal = this.props.showSysNotifications === false ? false : showModal;

        return (
            <>
            {(this.props.showloadinggif || this.props.showloadinggifFin || this.props.showloadinggifLog) && (<div id="loadingGif"></div>)}
            <div id="divApp" className={this.props.navbar ? "app" : "app noNav"}>

                <HeaderContext.Provider value={'divHeaderMsg'}>
                        <header className="app-header" id="header" role="banner">

                            <Modal
                                dialogClassName="modalDialog2"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                show={showModal}
                                onHide={() => this.setState({ showSysNotificationModal: this.state.showSysNotificationModal === undefined ? false : !this.state.showSysNotificationModal })}
                                style={{ height: 'auto', maxHeight: '99%', textAlign: 'center' }}
                            >
                                <Modal.Body
                                    className="modalBody2"
                                >
                                    <div style={{ textAlign: 'center' }}>
                                    <div style={{ marginTop: '23px', marginBottom: '-13px', fontWeight: '800', maxHeight: '500px', overflowY: 'auto'}}
                                            dangerouslySetInnerHTML={{ __html: this.props.sysNotificationMessages && this.props.sysNotificationMessages[0] && this.props.sysNotificationMessages[0].notificationText}}>
                                    </div>
                                    <br /><br />
                                    <button className={'dismiss-button'} style={{ borderRadius: '13px' }} onClick={() => {
                                        this.setState({ showSysNotificationModal: this.state.showSysNotificationModal === undefined ? false : !this.state.showSysNotificationModal })
                                        if (this.props.sysNotificationMessages[0]) { this.props.dismissUserSysNotification(this.props.token, this.props.user.userID, this.props.sysNotificationMessages[0].notificationId) }
                                    }}>
                                        DISMISS & DON'T SHOW AGAIN</button>
                                    &nbsp;
                                <button className="cancel-button" onClick={() => this.setState({ showSysNotificationModal: this.state.showSysNotificationModal === undefined ? false : !this.state.showSysNotificationModal })}>
                                        HIDE</button>
                                    </div>
                                </Modal.Body>
                            </Modal>

                        <MenuSearchBar />
                    </header>
                </HeaderContext.Provider>
                <Switch>
                    <Route path="/dashboard">
                        <NavBarContext.Provider value={'divNavBarMsg'}>
                            <nav className="app-navbar" id="navbar" role="nav">
                                <AffiliateView />
                            </nav>
                        </NavBarContext.Provider>
                    </Route>
                    <Route exact path="/newContact">
                        <NavBarContext.Provider value={'divNavBarMsg'}>
                            <nav className="app-navbar app-navbar-new-contact" id="navbar" role="nav">
                                {this.props.show && (!this.props.currentContact || this.props.currentContact.contactID === 0) && (
                                    <ClientInfo useProps={false} currentContact={this.props.emptyContact} />
                                )}
                            </nav>
                        </NavBarContext.Provider>
                    </Route>
                   
                    <Route exact path="/viewContacts">
                        <NavBarContext.Provider value={'divNavBarMsg'}>
                            <nav className="app-navbar app-navbar-new-contact" id="navbar" role="nav">
                                {this.props.currentContact && this.props.show && (
                                    <ClientInfo useProps={true} currentContact={this.props.currentContact} />
                                )}
                            </nav>
                        </NavBarContext.Provider>
                    </Route>
    

                    <Route exact path="/">
                        <NavBarContext.Provider value={'divNavBarMsg'}>
                            <nav className="app-navbar" id="navbar" role="nav">
                                <AffiliateView />
                            </nav>
                        </NavBarContext.Provider>
                    </Route>

                    <Route exact path="/library">
                        <NavBarContext.Provider value={'divNavBarMsg'}>
                            <nav className="app-navbar" id="navbar" role="nav">
                                <Library />
                            </nav>
                        </NavBarContext.Provider>
                    </Route>

                    <Route exact path="/stripe-connect/redirect">
                        <NavBarContext.Provider value={'divNavBarMsg'}>
                        </NavBarContext.Provider>
                    </Route>

                </Switch>

                <MainContext.Provider value={'divMainMsg'}>
                        <main className="app-main" id="main" role="main">

                        <Switch>
                            <Route path="/dashboard">
                                {this.props.token && (
                                    <Dashboard />
                                )}
                            </Route>
                            <Route path="/newContact">
                                {this.props.token && (
                                    <NewContact />
                                )}
                            </Route>
                            <Route path="/viewContacts">
                                {this.props.token && (
                                    <ContactGrid />
                                )}
                            </Route>
                            <Route path="/clientMgmt">
                                {this.props.token && (
                                    <ClientGrid />
                                )}
                            </Route>
                            <Route path="/action">
                                {this.props.token && (
                                    <Action />
                                )}
                            </Route>
                            <Route path="/reports">
                                {this.props.token && (
                                    <ReportForm />
                                )}
                            </Route>
                            <Route path="/affiliation">
                                {this.props.token && (
                                    <AffiliationPage />
                                )}
                            </Route>
                            <Route path="/settings">
                                {this.props.token && (
                                    <SettingsForm />
                                )}
                            </Route>
                            <Route path="/library">
                                {(this.props.token && this.props.user.canAccessDocs) && (
                                    <Library />
                                )}
                            </Route>
                            <Route path="/stripe-connect/redirect">
                                {(this.props.token) && (
                                   <StripeAccountOk />
                                )}
                            </Route>
                            <Route path="/">
                                {this.props.token && (
                                    <Dashboard />
                                )}
                            </Route>
                        </Switch>

                        {!this.props.token && ("Please log in to access AC Admin!")}
                    </main>
                    {this.props.token && this.props.showEditProfile && (
                        <EditProfile />
                    )}
                    {this.props.token && this.props.showEditUserContact && (
                        <EditUserContact show={true} />
                    )}
                    {this.props.token && this.props.showSearchResults && (
                        <SearchContactResult show={this.props.showSearchResults} />
                    )}
                </MainContext.Provider>


                <FooterContext.Provider value={'divFooterMsg'}>

                </FooterContext.Provider>

            </div>
            </>
        );
    }
}
function mapStateToProps(state) {
    return {
        token: state.loginReducer.loginToken,
        message: state.loginReducer.message,
        user: state.loginReducer.user,
        currentContact: state.contactReducer.currentContact,
        emptyContact: state.contactReducer.emptyContact,
        showEditProfile: state.loginReducer.showEditProfile,
        show: state.contactReducer.showEditForm,
        showEditUserContact: state.contactReducer.showEditUserContact,
        showSearchResults: state.contactReducer.showSearchResults,
        navbar: state.navbarReducer.navBar,
        showloadinggif: state.contactReducer.showLoadingGif,
        showloadinggifFin: state.financeReducer.showLoadingGif, 
        showloadinggifLog: state.loginReducer.showLoadingGif,
        showSysNotifications: state.loginReducer.showSysNotifications,
        sysNotificationsLoaded: state.loginReducer.sysNotificationsLoaded,
        sysNotificationMessages: state.loginReducer.sysNotificationMessages,
    }
}

export default connect(mapStateToProps, { showEditForm, getContactsById, initializeGif, getUserSysNotifications, dismissUserSysNotification})(HomePage);
