import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CheckBox from 'react-bootstrap/FormCheckInput';
import Modal from 'react-bootstrap/Modal';
import SearchContactRow from './SearchContactRow.js';
import Label from 'react-bootstrap/FormLabel';
import ViewEditContact from './ViewEditContact.js';
import { getContactsByUser, cancelSearch } from '../../../hooks/store/actions/contactActions.js';
import { getClientStatus } from '../../../hooks/store/actionConsts.js';
import { hideNavbar, showNavbar } from '../../../hooks/store/actions/navBarActions';


class ContactGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            includePast: true,
            includeCurrent: true,
            includeFuture: true,
            other: true,
            ascending: true
        };
       
        this.props.getContactsByUser(this.props.logintoken, this.props.user.id);
        this.checkPotential = this.checkPotential.bind(this);
        this.checkActive = this.checkActive.bind(this);
        this.checkPast = this.checkPast.bind(this);
        this.checkOther = this.checkOther.bind(this);
        this.filterContacts = this.filterContacts.bind(this);
        this.sortContacts = this.sortContacts.bind(this);
        this.onClickCancel = this.onClickCancel.bind(this);

    }

    onClickCancel() {
        this.props.cancelSearch(false);
    }

    checkPotential = (e) => {
        this.setState({ 'includeFuture': e.target.checked });
    }
    checkActive = (e) => {
        this.setState({ 'includeCurrent': e.target.checked });
    }
    checkPast = (e) => {
        this.setState({ 'includePast': e.target.checked });
    }
    checkOther = (e) => {
        this.setState({ 'other': e.target.checked });
    }

    getClientStatus(c) {
        return c.clientStatus;
    }

    filterContacts() {
        var contacts = [];
        var c;
        if (this.props.contacts) {
            for (var i = 0; i < this.props.contacts.length; i++) {
                c = this.props.contacts[i];
                if (this.state.includeCurrent && (getClientStatus(c) === "Active" || getClientStatus(c) === "Paused")) {
                    contacts.push(c);
                }
                else if (this.state.includeFuture && (getClientStatus(c) === "Potential" || getClientStatus(c) === "Pending")) {
                    contacts.push(c);
                }
                else if (this.state.includePast && (getClientStatus(c) === "Past" || getClientStatus(c) === "Expired" || getClientStatus(c) === "Terminated")) {
                    contacts.push(c);
                }
                else if (this.state.other && getClientStatus(c) === "None") {
                    contacts.push(c);
                }
            }
        }

        if (this.state.sort === "lastName" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.lastName ? a.lastName : "";
                var bCompare = b.lastName ? b.lastName : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "lastName" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.lastName ? a.lastName : "";
                var bCompare = b.lastName ? b.lastName : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "firstName" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.firstName ? a.firstName : "";
                var bCompare = b.firstName ? b.firstName : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "firstName" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.firstName ? a.firstName : "";
                var bCompare = b.firstName ? b.firstName : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "clientStatus" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = getClientStatus(a) ? getClientStatus(a) : "";
                var bCompare = getClientStatus(b) ? getClientStatus(b) : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "clientStatus" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = getClientStatus(a) ? getClientStatus(a) : "";
                var bCompare = getClientStatus(b) ? getClientStatus(b) : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "clientType" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.clientType ? a.clientType : "";
                var bCompare = b.clientType ? b.clientType : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "clientType" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.clientType ? a.clientType : "";
                var bCompare = b.clientType ? b.clientType : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "invoiceStatus" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.invoiceStatus ? a.invoiceStatus : "";
                var bCompare = b.invoiceStatus ? b.invoiceStatus : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "invoiceStatus" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.invoiceStatus ? a.invoiceStatus : "";
                var bCompare = b.invoiceStatus ? b.invoiceStatus : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "paymentStatus" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.paymentStatus ? a.paymentStatus : "";
                var bCompare = b.paymentStatus ? b.paymentStatus : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "paymentStatus" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.paymentStatus ? a.paymentStatus : "";
                var bCompare = b.paymentStatus ? b.paymentStatus : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "clientStatus" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = this.getClientStatus(a) ? this.getClientStatus(a) : "";
                var bCompare = this.getClientStatus(b) ? this.getClientStatus(b) : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "clientStatus" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = this.getClientStatus(a) ? this.getClientStatus(a) : "";
                var bCompare = this.getClientStatus(b) ? this.getClientStatus(b) : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "email" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.email ? a.email : "";
                var bCompare = b.email ? b.email : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "email" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.email ? a.email : "";
                var bCompare = b.email ? b.email : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "billingAmount" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.billingAmount ? parseFloat(a.billingAmount) : 0;
                var bCompare = b.billingAmount ? parseFloat(b.billingAmount) : 0;
                if (aCompare > bCompare) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "billingAmount" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.billingAmount ? parseFloat(a.billingAmount) : "";
                var bCompare = b.billingAmount ? parseFloat(b.billingAmount) : "";
                if (aCompare > bCompare) { return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "sessionsPerMonth" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.sessionsPerMonth ? parseInt(a.sessionsPerMonth) : 0;
                var bCompare = b.sessionsPerMonth ? parseInt(b.sessionsPerMonth) : 0;
                if (aCompare > bCompare) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "sessionsPerMonth" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.sessionsPerMonth ? parseInt(a.sessionsPerMonth) : "";
                var bCompare = b.sessionsPerMonth ? parseInt(b.sessionsPerMonth) : "";
                if (aCompare > bCompare) { return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "source" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.contactType.name ? a.contactType.name : "";
                var bCompare = b.contactType.name ? b.contactType.name : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "source" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.contactType.name ? a.contactType.name : "";
                var bCompare = b.contactType.name ? b.contactType.name : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "affiliate" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.affiliateName ? a.affiliateName : "";
                var bCompare = b.affiliateName ? b.affiliateName : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "affiliate" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.affiliateName ? a.affiliateName : "";
                var bCompare = b.affiliateName ? b.affiliateName : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "phone" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.phone ? a.phone : "";
                var bCompare = b.phone ? b.phone : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "phone" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.phone ? a.phone : "";
                var bCompare = b.phone ? b.phone : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "contractEnd" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.expirationDate ? a.expirationDate : "01/01/0001";
                if (aCompare === "Ongoing") { "01/01/2099" } { aCompare }
                var bCompare = b.expirationDate ? b.expirationDate : "01/01/0001";
                if (bCompare === "Ongoing") { "01/01/2099" } { bCompare }
                if (Date.parse(aCompare) > Date.parse(bCompare)) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "contractEnd" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.expirationDate ? a.expirationDate : "01/01/0001";
                if (aCompare === "Ongoing") { "01/01/2099" } { aCompare }
                var bCompare = b.expirationDate ? b.expirationDate : "01/01/0001";
                if (bCompare === "Ongoing") { "01/01/2099" } { bCompare }
                if (Date.parse(aCompare) > Date.parse(bCompare)) { return -1 } else { return 1 }
            })
        }

        return contacts;
    }

    sortContacts(type) {
        if (this.state.sort != type && this.props.contacts) {
            this.setState({ sort: type, ascending: true })
        } else if (this.props.contacts) {
            this.setState({ sort: type, ascending: !this.state.ascending })
        }
    }

    componentDidMount() {
        var windowLocation = window.location.href.split("/").pop();
        if (windowLocation !== "dashboard") {
            this.props.hideNavbar();
        }
    }

    render() {
        var contacts = this.filterContacts();

        return (
            <Modal      {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-popup contact-grid-form searchGridModal"
                show={this.props.show}
                onHide={this.onClickCancel}
            >

                {!this.props.showEditForm && (
                    <div className="contact-grid-header searchGridHeader">
                        <div className="grid-checkboxes-search viewEditNoTopSearch">
                            <div className="headerContainerViewEdit">
                                {/* <div className="checkboxHeaderLeftClient" /> */}
                                <div className="headerTitleTextSearch"><span>Filter Contact Status</span></div>
                                {/* <div className="checkboxheaderRightClient" /> */}
                            </div>
                            <div className="filterColumnContactGrid"><CheckBox className="contactGridCheckbox" checked={this.state.includeCurrent} onChange={this.checkActive} />
                                <Label>Active Client</Label></div>
                            <div className="filterColumnContactGrid"><CheckBox className="contactGridCheckbox" checked={this.state.includeFuture} onChange={this.checkPotential} />
                                <Label>Potential Client</Label></div>
                            <div className="filterColumnContactGrid"><CheckBox className="contactGridCheckbox" checked={this.state.includePast} onChange={this.checkPast} />
                                <Label>Past Client</Label></div>
                            <div className="filterColumnContactGrid"><CheckBox className="contactGridCheckbox" checked={this.state.other} onChange={this.checkOther} />
                                <Label>Other</Label></div>
                        </div>
                    </div>
                )}
                {!this.props.showEditForm && (
                    <div className="table-responsive searchTableModal" style={{ overflow: 'unset', whiteSpace: 'pre-line' }}>
                        <Row className="grid-row row-header">
                            <Col onClick={() => this.sortContacts("clientStatus")} className="grid-header contactGridHead" sm={this.props.sysUser.userRole > 2 ? 1 : 2} xs={this.props.sysUser.userRole > 2 ? 1 : 2}>
                                <Label className="contactGridLabel" style={{ cursor: 'pointer' }}>{this.props.sysUser.userRole > 2 ? "STATUS" : "CLIENT STATUS"}</Label>
                                {this.state.sort === "clientStatus" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> :
                                    this.state.sort === "clientStatus" && !this.state.ascending ? <span className="material-icons iconSort">expand_less</span> :
                                        <span className="material-icons iconSort">chevron_right</span>}
                            </Col>
                            {(this.props.sysUser.userRole > 2) &&
                                (
                                    <Col onClick={() => this.sortContacts("affiliate")} className="grid-header contactGridHead" sm={1} xs={1}>
                                        <Label className="contactGridLabel" style={{ cursor: 'pointer' }}>AFFILIATE</Label>
                                        {this.state.sort === "affiliate" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> :
                                            this.state.sort === "affiliate" && !this.state.ascending ? <span className="material-icons iconSort">expand_less</span> :
                                                <span className="material-icons iconSort">chevron_right</span>}
                                    </Col>
                                )}

                            {/* <Col>
                                <Label className="contactGridLabel" style={{ cursor: 'pointer' }}>FIRST NAME</Label>
                                {this.state.sort === "firstName" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> :
                                    this.state.sort === "firstName" && !this.state.ascending ? <span className="material-icons iconSort">expand_less</span> :
                                        <span className="material-icons iconSort">chevron_right</span>}
                            </Col> */}

                            <Col onClick={() => this.sortContacts("firstName")} className="grid-header contactGridHead" sm={this.props.sysUser.userRole > 2 ? 1 : 2} xs={this.props.sysUser.userRole > 2 ? 1 : 2}>
                                <Label className="contactGridLabel" style={{ cursor: 'pointer' }}>FIRST NAME</Label>
                                {this.state.sort === "firstName" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> :
                                    this.state.sort === "firstName" && !this.state.ascending ? <span className="material-icons iconSort">expand_less</span> :
                                        <span className="material-icons iconSort">chevron_right</span>}
                            </Col>

                            <Col onClick={() => this.sortContacts("lastName")} className="grid-header contactGridHead" sm={this.props.sysUser.userRole > 2 ? 1 : 2} xs={this.props.sysUser.userRole > 2 ? 1 : 2}>
                                <Label className="contactGridLabel" style={{ cursor: 'pointer' }}>LAST NAME</Label>
                                {this.state.sort === "lastName" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> :
                                    this.state.sort === "lastName" && !this.state.ascending ? <span className="material-icons iconSort">expand_less</span> :
                                        <span className="material-icons iconSort">chevron_right</span>}
                            </Col>

                            <Col onClick={() => this.sortContacts("phone")} className="grid-header contactGridHead" sm={this.props.sysUser.userRole > 2 ? 1 : 1} xs={this.props.sysUser.userRole > 2 ? 1 : 1}>
                                <Label className="contactGridLabel" style={{ cursor: 'pointer' }}>PHONE</Label>
                                {this.state.sort === "phone" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> :
                                    this.state.sort === "phone" && !this.state.ascending ? <span className="material-icons iconSort">expand_less</span> :
                                        <span className="material-icons iconSort">chevron_right</span>}
                            </Col>

                            <Col onClick={() => this.sortContacts("email")} className="grid-header contactGridHead" sm={this.props.sysUser.userRole > 2 ? 3 : 2} xs={this.props.sysUser.userRole > 2 ? 3 : 2}>
                                <Label className="contactGridLabel" style={{ cursor: 'pointer' }}>EMAIL</Label>
                                {this.state.sort === "email" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> :
                                    this.state.sort === "email" && !this.state.ascending ? <span className="material-icons iconSort">expand_less</span> :
                                        <span className="material-icons iconSort">chevron_right</span>}
                            </Col>
                            <Col onClick={() => this.sortContacts("source")} className="grid-header contactGridHead" sm={1} xs={1}>
                                <Label className="contactGridLabel" style={{ cursor: 'pointer' }}>SOURCE</Label>
                                {this.state.sort === "source" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> :
                                    this.state.sort === "source" && !this.state.ascending ? <span className="material-icons iconSort">expand_less</span> :
                                        <span className="material-icons iconSort">chevron_right</span>}
                            </Col>
                            {/* <Col onClick={() => this.sortContacts("paymentStatus")} className="grid-header contactGridHead" sm={1} xs={1}><Label className="contactGridLabel" style={{ cursor: 'pointer' }}>PAYMENT STATUS</Label></Col>
                            <Col onClick={() => this.sortContacts("contractEnd")} className="grid-header contactGridHead" sm={1} xs={1}><Label className="contactGridLabel" style={{ cursor: 'pointer' }}>CONTRACT END</Label></Col> */}
                            <Col className="grid-header contactGridHead" sm={2} xs={2}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Col>
                        </Row>
                        {this.props.contacts && (
                            <SearchContactRow contacts={contacts} />
                        )}
                    </div>
                )}
                {this.props.showEditForm && this.props.currentContact && (
                    <ViewEditContact show={true} currentContact={this.props.currentContact} />
                )}
            </Modal>
        );
    }
}


function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        contacts: state.contactReducer.searchResults,
        currentContact: state.contactReducer.currentContact,
        showEditForm: state.contactReducer.showEditForm,
        showEditUserContact: state.contactReducer.showEditUserContact,
        sysUser: state.loginReducer.sysUser,
    }
}

export default connect(mapStateToProps, { getContactsByUser, cancelSearch, hideNavbar })(ContactGrid);
