import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { setOpenModalDetails } from '../../../hooks/store/actions/sysActions';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    margin: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
      selectEmpty: {
        marginTop: theme.spacing(2),
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles((theme) => ({
    root: {
      margin: 0,  
      padding: theme.spacing(1),
    },
  }))(MuiDialogContent);
  
  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);


class NewModal extends React.Component {


    constructor(props){
        super(props);
        this.state = {};

        this.closeModal = this.closeModal.bind(this);
    }

    closeModal() {
        this.props.setOpenModalDetails(false);
        this.props.onClose();
    }

    render() {
        return (

            <Dialog onClose={this.closeModal} aria-labelledby="customized-dialog-title" open={this.props.openModalDetails}  fullWidth={true}
                            maxWidth={'sm'}>
                                <DialogTitle id="customized-dialog-title" onClose={this.closeModal} >
                                {this.props.title}
                                </DialogTitle>
                                <DialogContent dividers>
                                    {this.props.children}
                                </DialogContent>
                                <DialogActions>
                                <Button onClick={this.closeModal} color="accent">
                                    Close
                                </Button>
                                </DialogActions>
                          </Dialog>

        )
    }   

}

function mapStateToProps(state) {
  return {
      openModalDetails: state.sysReducer.openModalDetails,
  }
}

export default connect(mapStateToProps, {setOpenModalDetails})(withStyles(styles)(NewModal));

