import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import CheckBox from 'react-bootstrap/FormCheckInput';
import ClientRow from './ClientRow.js';
import ClientDetails from './ClientDetails.js'
import Label from 'react-bootstrap/FormLabel';

import { getClients, showClientDetails } from '../../../hooks/store/actions/financeActions.js';
import { getClientStatus } from '../../../hooks/store/actionConsts.js';
import { hideNavbar } from '../../../hooks/store/actions/navBarActions';

import { Modal, Container, Form } from 'react-bootstrap';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { sendRequestClient } from '../../../hooks/store/actions/clientActions';
import toaster from "toasted-notes";

class ClientGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            includePast: document.location.href.includes("current=1") ? false : true,
            includeCurrent: true,
            includeFuture: document.location.href.includes("current=1") ? false : true,
            onlyPaused: document.location.href.includes("current=0") ? true : false,
            individual: true,
            group: true,
            sponsored: true,
            other: true,
            unpaid: document.location.href.includes("unpaid=1") ? true : false,
            expiring: document.location.href.includes("expiring=1") ? true : false,
            // client request modal
            openModal: false,
            dropdownOpenUserRole: false,
            invoiceMonth: '',
            invoiceYear: '',
            invoiceAmount: '',
            clientName: '',
            description: '',
            actionRequest: '',
            errorMessage: '',
            dropDownToggleInvoiceMonthValue: false,
        };
        this.props.hideNavbar();
        this.props.getClients(this.props.logintoken, this.props.user.id);
        this.checkPotential = this.checkPotential.bind(this);
        this.checkActive = this.checkActive.bind(this);
        this.checkPast = this.checkPast.bind(this);
        this.filterContacts = this.filterContacts.bind(this);
        this.sortClients = this.sortClients.bind(this);
        this.checkIndividual = this.checkIndividual.bind(this);
        this.checkSponsored = this.checkSponsored.bind(this);
        this.checkGroup = this.checkGroup.bind(this);

        if (document.location.href.includes("autoload") && this.props.currentContact && this.props.currentContact.contactID) {
            this.props.showClientDetails(this.props.currentContact);
        }

        this.openModalClientRequest = this.openModalClientRequest.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.onSelectActionRequesting = this.onSelectActionRequesting.bind(this);
        this.handleSendClientRequest = this.handleSendClientRequest.bind(this);
        this.toggleUserRole = this.toggleUserRole.bind(this);
        this.resetModalClientRequest = this.resetModalClientRequest.bind(this);
        this.onSelectInvoiceMonth = this.onSelectInvoiceMonth.bind(this);
        this.dropDownToggleInvoiceMonth = this.dropDownToggleInvoiceMonth.bind(this);
    }

    checkIndividual = (e) => {
        this.setState({ 'individual': e.target.checked });
    }
    checkGroup = (e) => {
        this.setState({ 'group': e.target.checked });
    }
    checkSponsored = (e) => {
        this.setState({ 'sponsored': e.target.checked });
    }
    checkPotential = (e) => {
        this.setState({ 'includeFuture': e.target.checked });
    }
    checkActive = (e) => {
        this.setState({ 'includeCurrent': e.target.checked });
    }
    checkPast = (e) => {
        this.setState({ 'includePast': e.target.checked });
    }

    filterContacts() {
        var ret = [];
        var contacts = [];
        var c;
        var i;
        if (this.props.clients && (!this.state.includeCurrent || !this.state.includeFuture || !this.state.includePast)) {
            for (i = 0; i < this.props.clients.length; i++) {
                c = this.props.clients[i];
                if (this.state.includeCurrent && (getClientStatus(c) === "Active")) {
                    contacts.push(c);
                }
                else if (this.state.includeFuture && (getClientStatus(c) === "Potential" || getClientStatus(c) === "Pending")) {
                    contacts.push(c);
                }
                else if (this.state.includePast && (getClientStatus(c) === "Past" || getClientStatus(c) === "Expired" || getClientStatus(c) === "Terminated")) {
                    contacts.push(c);
                }
            }
        }
        else if (this.props.clients && this.state.onlyPaused) {
            for (i = 0; i < this.props.clients.length; i++) {
                c = this.props.clients[i];
                if (this.state.onlyPaused && getClientStatus(c) === "Paused"){
                    contacts.push(c);
                }
            }
        }
        else {
            contacts = this.props.clients;
        }

        if (contacts && (!this.state.individual || !this.state.group || !this.state.sponsored)) {
            var temp = [];
            for (i = 0; i < contacts.length; i++) {
                c = contacts[i];
                if (this.state.individual && c.clientType === "One-on-One") {
                    temp.push(c);
                }
                else if (this.state.group && c.clientType === "Group") {
                    temp.push(c);
                }
                else if (this.state.sponsored && c.clientType === "Sponsored") {
                    temp.push(c);
                }
            }
            ret = temp.slice();
        }
        else
            ret = contacts;

        if (this.state.expiring) {
            var today = new Date();
            today.setDate(today.getDate() + 60);
            var now = today.getFullYear() + "-"
                + ((today.getMonth() + 1) < 10 ? "0" : "") + (today.getMonth() + 1) + "-"
                + ((today.getDay() + 1) < 10 ? "0" : "") + (today.getDay() + 1);

            ret = ret.filter((a) => a.expirationDate <= now);
        }
        else if (this.state.unpaid) {
            ret = ret.filter((a) => (a.paymentStatus === "SentToClient" || a.paymentStatus === "Overdue" || a.paymentStatus === "SentToStripe"));
        }

        return this.sortContacts(ret);
    }

    sortContacts(c) {
        var contacts = c.slice();

        if (this.state.sort === "lastName" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.lastName ? a.lastName : "";
                var bCompare = b.lastName ? b.lastName : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "lastName" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.lastName ? a.lastName : "";
                var bCompare = b.lastName ? b.lastName : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "firstName" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.firstName ? a.firstName : "";
                var bCompare = b.firstName ? b.firstName : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "firstName" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.firstName ? a.firstName : "";
                var bCompare = b.firstName ? b.firstName : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "clientStatus" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = getClientStatus(a) ? getClientStatus(a) : "";
                var bCompare = getClientStatus(b) ? getClientStatus(b) : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "clientStatus" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = getClientStatus(a) ? getClientStatus(a) : "";
                var bCompare = getClientStatus(b) ? getClientStatus(b) : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "clientType" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.clientType ? a.clientType : "";
                var bCompare = b.clientType ? b.clientType : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "clientType" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.clientType ? a.clientType : "";
                var bCompare = b.clientType ? b.clientType : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "invoiceStatus" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.invoiceStatus ? a.invoiceStatus : "";
                var bCompare = b.invoiceStatus ? b.invoiceStatus : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "invoiceStatus" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.invoiceStatus ? a.invoiceStatus : "";
                var bCompare = b.invoiceStatus ? b.invoiceStatus : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "paymentStatus" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.paymentStatus ? a.paymentStatus : "";
                var bCompare = b.paymentStatus ? b.paymentStatus : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "paymentStatus" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.paymentStatus ? a.paymentStatus : "";
                var bCompare = b.paymentStatus ? b.paymentStatus : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "email" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.email ? a.email : "";
                var bCompare = b.email ? b.email : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "email" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.email ? a.email : "";
                var bCompare = b.email ? b.email : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "billingAmount" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.billingAmount ? parseFloat(a.billingAmount) : 0;
                var bCompare = b.billingAmount ? parseFloat(b.billingAmount) : 0;
                if (aCompare > bCompare) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "billingAmount" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.billingAmount ? parseFloat(a.billingAmount) : "";
                var bCompare = b.billingAmount ? parseFloat(b.billingAmount) : "";
                if (aCompare > bCompare) { return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "sessionsPerMonth" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.sessionsPerMonth ? parseInt(a.sessionsPerMonth) : 0;
                var bCompare = b.sessionsPerMonth ? parseInt(b.sessionsPerMonth) : 0;
                if (aCompare > bCompare) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "sessionsPerMonth" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.sessionsPerMonth ? parseInt(a.sessionsPerMonth) : "";
                var bCompare = b.sessionsPerMonth ? parseInt(b.sessionsPerMonth) : "";
                if (aCompare > bCompare) { return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "source" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.contactType.name ? a.contactType.name : "";
                var bCompare = b.contactType.name ? b.contactType.name : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "source" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.contactType.name ? a.contactType.name : "";
                var bCompare = b.contactType.name ? b.contactType.name : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "affiliate" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.affiliateName ? a.affiliateName : "";
                var bCompare = b.affiliateName ? b.affiliateName : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "affiliate" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.affiliateName ? a.affiliateName : "";
                var bCompare = b.affiliateName ? b.affiliateName : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "contractEnd" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.expirationDate ? a.expirationDate : "01/01/0001";
                if (aCompare === "Ongoing") { "01/01/2099" } { aCompare }
                var bCompare = b.expirationDate ? b.expirationDate : "01/01/0001";
                if (bCompare === "Ongoing") { "01/01/2099" } { bCompare }
                if (Date.parse(aCompare) > Date.parse(bCompare)) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "contractEnd" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.expirationDate ? a.expirationDate : "01/01/0001";
                if (aCompare === "Ongoing") { "01/01/2099" } { aCompare }
                var bCompare = b.expirationDate ? b.expirationDate : "01/01/0001";
                if (bCompare === "Ongoing") { "01/01/2099" } { bCompare }
                if (Date.parse(aCompare) > Date.parse(bCompare)) { return -1 } else { return 1 }
            })
        }
        return contacts;
    }

    sortClients(type) {
        if (this.state.sort != type && this.props.clients) {
            this.setState({ sort: type, ascending: true })
        } else if (this.props.clients) {
            this.setState({ sort: type, ascending: !this.state.ascending })
        }
    }

    openModalClientRequest(nameClient) {
        console.log('open modal out');
        this.setState({ openModal: true, clientName: nameClient })
    }

    handleCloseModal() {
        this.setState({ openModal: false })
        this.resetModalClientRequest();
    }

    isValidModal(){
        if(!this.state.clientName){
            this.setState({ errorMessage: 'Client Name is required'});
            return false;
        }
        if(!this.state.invoiceMonth){
            this.setState({ errorMessage: 'Invoice Month is required'});
            return false;
        }
        if(!this.state.invoiceYear){
            this.setState({ errorMessage: 'Invoice Year is required'});
            return false;
        }
        if(!this.state.invoiceAmount){
            this.setState({ errorMessage: 'Invoice Amount is required'});
            return false;
        }
        if(!this.state.actionRequest){
            this.setState({ errorMessage: 'Action Requesting is required'});
            return false;
        }
        if(!this.state.description){
            this.setState({ errorMessage: 'Detail Explanation is required'});
            return false;
        }
        return true;
    }

    formatCurrency(value) {
        let val = (value/1).toFixed(2);
        let prev = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        return '$' + prev;
    }

    handleSendClientRequest = (event) => {

        if(!this.isValidModal()){
            return;
        }

        let body = "<h3> Client Request </h3>";
        body += `<p> Client Name: ${this.state.clientName}</p>`;
        body += `<p> Invoice Month: ${this.state.invoiceMonth}</p>`;
        body += `<p> Invoice Year: ${this.state.invoiceYear}</p>`;
        body += `<p> Invoice Amount: ${this.formatCurrency(this.state.invoiceAmount)}</p>`;
        body += `<p> Action Request: ${this.state.actionRequest}</p>`;
        body += `<p> Detailed Explanation: ${this.state.description}</p>`;

        sendRequestClient(this.props.logintoken, "Client Request",  "Help@accomplishmentcoaching.com", body)
        .then((res) => {
            this.setState({ openModal: false })
            toaster.notify("Send request successfully", { duration: 4000 });
            this.resetModalClientRequest();
        }).catch(err => {
            if (err.response && err.response.status === 400) {
                toaster.notify("Unable to send request " + item.name + ", try again later", { duration: 4000 });
            }
            this.resetModalClientRequest();
        });
    }

    resetModalClientRequest = () => {
        this.setState({
            dropdownOpenUserRole: false,
            invoiceMonth: '',
            invoiceYear: '',
            invoiceAmount: '',
            clientName: '',
            description: '',
            actionRequest: '',
            errorMessage: '',
        });
    }

    onChange = (e) => {  
        this.setState({ [e.target.id]: e.target.value, isDirty: true });
    }

    toggleUserRole = () => { 
        this.setState({ dropdownOpenUserRole: !this.state.dropdownOpenUserRole });
    }

    dropDownToggleInvoiceMonth = () => { 
        this.setState({ dropDownToggleInvoiceMonthValue: !this.state.dropDownToggleInvoiceMonthValue });
    }

    onSelectActionRequesting = (text) => {
        this.setState({ actionRequest: text, isDirty: true});
    }

    onSelectInvoiceMonth = (text) => {
        this.setState({ invoiceMonth: text});
    }

    render() {
        var cs = this.filterContacts();
        
        return (
            <span className="contact-grid-form">

                    <Modal
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            className="modal-popup"
                            show={this.state.openModal}
                            onHide={this.handleCloseModal}
                            style={{width: '80%', marginLeft: '10%', marginTop: '10%', marginRight: '10%', height: '500px'}}
                        >
                            <Modal.Body className="full-screen" style={{height: '450px'}}>
                            <Container  className="Modal.Body form-editcontact modal-small">
                                <Form.Group as={Row}>
                                    <Form.Label column sm={2}>
                                        Client Name&nbsp;<span style={{ color: 'red' }}>*</span>
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Form.Control id="clientName" placeholder="Client Name" onChange={this.onChange} value={this.state.clientName} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm={2}>
                                        Invoice Month&nbsp;<span style={{ color: 'red' }}>*</span>
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Dropdown isOpen={this.state.dropDownToggleInvoiceMonthValue} toggle={this.dropDownToggleInvoiceMonth}>
                                            <DropdownToggle className="ddStyle" caret>
                                            {this.state.invoiceMonth ? this.state.invoiceMonth : "Select one option"}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem className="text-primary" onClick={() => this.onSelectInvoiceMonth("January")}>January</DropdownItem>
                                                <DropdownItem className="text-primary" onClick={() => this.onSelectInvoiceMonth("February")}>February</DropdownItem>
                                                <DropdownItem className="text-primary" onClick={() => this.onSelectInvoiceMonth("March")}>March</DropdownItem>
                                                <DropdownItem className="text-primary" onClick={() => this.onSelectInvoiceMonth("April")}>April</DropdownItem>
                                                <DropdownItem className="text-primary" onClick={() => this.onSelectInvoiceMonth("May")}>May</DropdownItem>
                                                <DropdownItem className="text-primary" onClick={() => this.onSelectInvoiceMonth("June")}>June</DropdownItem>
                                                <DropdownItem className="text-primary" onClick={() => this.onSelectInvoiceMonth("July")}>July</DropdownItem>
                                                <DropdownItem className="text-primary" onClick={() => this.onSelectInvoiceMonth("August")}>August</DropdownItem>
                                                <DropdownItem className="text-primary" onClick={() => this.onSelectInvoiceMonth("September")}>September</DropdownItem>
                                                <DropdownItem className="text-primary" onClick={() => this.onSelectInvoiceMonth("October")}>October</DropdownItem>
                                                <DropdownItem className="text-primary" onClick={() => this.onSelectInvoiceMonth("November")}>November</DropdownItem>
                                                <DropdownItem className="text-primary" onClick={() => this.onSelectInvoiceMonth("December")}>December</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm={2}>
                                        Invoice Year&nbsp;<span style={{ color: 'red' }}>*</span>
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Form.Control id="invoiceYear" type='number' placeholder="Invoice Year" onChange={this.onChange} value={this.state.invoiceYear} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm={2}>
                                        Invoice Amount&nbsp;<span style={{ color: 'red' }}>*</span>
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Form.Control id="invoiceAmount" type='number' placeholder="Invoice Amount" onChange={this.onChange} value={this.state.invoiceAmount} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm={2}>
                                        Action Requesting&nbsp;<span style={{ color: 'red' }}>*</span>
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Dropdown isOpen={this.state.dropdownOpenUserRole} toggle={this.toggleUserRole}>
                                            <DropdownToggle className="ddStyle" caret>
                                            {this.state.actionRequest ? this.state.actionRequest : "Select one option"}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem className="text-primary" onClick={() => this.onSelectActionRequesting("Void Invoice")}>Void Invoice</DropdownItem>
                                                <DropdownItem className="text-primary" onClick={() => this.onSelectActionRequesting("Stop Auto-Payment Collection")}>Stop Auto-Payment Collection</DropdownItem>
                                                <DropdownItem className="text-primary" onClick={() => this.onSelectActionRequesting("Pause Reminder Notifications")}>Pause Reminder Notifications</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>

                                    </Col>            
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm={2}>
                                        Detailed Explanation&nbsp;<span style={{ color: 'red' }}>*</span>
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Form.Control id="description" type='textarea' placeholder="Detailed Explanation" onChange={this.onChange} value={this.state.description} />
                                    </Col>
                                </Form.Group>
                                <Form.Group>
                                    <Col sm={8}>
                                        <span style={{ color: 'red' }}>{this.state.errorMessage}</span>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Col sm={2} width="50px">
                                            <button className="button-gridrow-edit-profile" onClick={this.handleSendClientRequest} href="#">SEND</button>
                                    </Col>

                                    <Col sm={4}>
                                        <span style={{ color: 'red' }}>* Required Field</span>
                                    </Col>
                                </Form.Group>    
                            </Container>    
                            </Modal.Body>
                        </Modal>

                {!this.props.show && (
                    <div>
                        <div className="contact-grid-header">
                            <div className="grid-checkboxes4">
                                <div className="clientGridCheckboxWrapper">
                                    <div className="headerContainer">
                                        <div className="headerTitleTextAgreement"><span>Select Agreement Types to View</span></div>
                                    </div>
                                    <div>
                                        <div />
                                        <div><CheckBox checked={this.state.individual} onChange={this.checkIndividual} />
                                            <Label>One-on-One</Label></div>
                                    </div>
                                    <div>
                                        <div />
                                        <div><CheckBox checked={this.state.group} onChange={this.checkGroup} />
                                            <Label>Group</Label></div>
                                    </div>
                                </div>

                                <div className="mgmtFilterResponsiveDivider"/>

                                <div className="clientGridCheckboxWrapperAdj">
                                    <div className="headerContainerClient">
                                        <div className="checkboxHeaderLeftClient" />
                                        <div className="headerTitleTextClient"><span>Select Client Status to View</span></div>
                                        <div className="checkboxheaderRightClient" />
                                    </div>
                                    <div>
                                        <div />
                                        <div><CheckBox checked={this.state.includeCurrent} onChange={this.checkActive} />
                                            <Label>Active Client</Label></div>
                                    </div>
                                    <div>
                                        <div />
                                        <div><CheckBox checked={this.state.includeFuture} onChange={this.checkPotential} />
                                            <Label>Potential Client</Label></div>
                                    </div>
                                    <div>
                                        <div />
                                        <div><CheckBox checked={this.state.includePast} onChange={this.checkPast} />
                                            <Label>Past Client</Label></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                )}
                {!this.props.show && (
                    <div className="table-responsive contactGridTableResponsive clientMgmtMobile" style={{ overflow: 'unset', whiteSpace: 'pre-line' }}>
                        <Row className="grid-row row-header">
                            <Col onClick={() => this.sortClients("clientStatus")} className="grid-header contactGridHead" xs={1} sm={1}>
                                    <Label className="contactGridLabel" style={{ cursor: 'pointer' }}>CLIENT STATUS</Label>
                                {this.state.sort === "clientStatus" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> :
                                    this.state.sort === "clientStatus" && !this.state.ascending ? <span className="material-icons iconSort">expand_less</span> :
                                        <span className="material-icons iconSort">chevron_right</span>}
                            </Col>
     
                            <Col onClick={() => this.sortClients("clientType")} className="grid-header contactGridHead" xs={1} sm={1}>
                                <Label className="contactGridLabel" style={{ cursor: 'pointer' }}>CLIENT TYPE</Label>
                                {this.state.sort === "clientType" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> :
                                    this.state.sort === "clientType" && !this.state.ascending ? <span className="material-icons iconSort">expand_less</span> :
                                        <span className="material-icons iconSort">chevron_right</span>}
                            </Col>

                            {(this.props.sysUser.userRole > 2) && (
                                <Col onClick={() => this.sortClients("affiliate")} className="grid-header contactGridHead" xs={1} sm={1}>
                                    <Label className="contactGridLabel" style={{ cursor: 'pointer' }}>AFFILIATE</Label>
                                    {this.state.sort === "affiliate" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> :
                                        this.state.sort === "affiliate" && !this.state.ascending ? <span className="material-icons iconSort">expand_less</span> :
                                            <span className="material-icons iconSort">chevron_right</span>}
                                </Col>
                            )}

                            <Col onClick={() => this.sortClients("firstName")} className="grid-header contactGridHead" xs={1} sm={1}>
                                <Label className="contactGridLabel" style={{ cursor: 'pointer' }}>FIRST NAME</Label>
                                {this.state.sort === "firstName" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> :
                                    this.state.sort === "firstName" && !this.state.ascending ? <span className="material-icons iconSort">expand_less</span> :
                                        <span className="material-icons iconSort">chevron_right</span>}
                            </Col>

                            <Col onClick={() => this.sortClients("lastName")} className="grid-header contactGridHead" xs={1} sm={1}>
                                <Label className="contactGridLabel" style={{ cursor: 'pointer' }}>LAST NAME</Label>
                                {this.state.sort === "lastName" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> :
                                    this.state.sort === "lastName" && !this.state.ascending ? <span className="material-icons iconSort">expand_less</span> :
                                        <span className="material-icons iconSort">chevron_right</span>}
                            </Col>

                            <Col onClick={() => this.sortClients("email")} className="grid-header contactGridHead" xs={2} sm={2}>
                                <Label className="contactGridLabel" style={{ cursor: 'pointer' }}>EMAIL</Label>
                                {this.state.sort === "email" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> :
                                    this.state.sort === "email" && !this.state.ascending ? <span className="material-icons iconSort">expand_less</span> :
                                        <span className="material-icons iconSort">chevron_right</span>}
                            </Col>
                            {this.props.sysUser.userRole < 3 &&
                                <Col onClick={() => this.sortClients("sessionsPerMonth")} className="grid-header contactGridHead" xs={1} sm={1}>
                                <Label className="contactGridLabel" style={{ cursor: 'pointer' }}>NUM SESSIONS</Label>
                                {this.state.sort === "sessionsPerMonth" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> :
                                    this.state.sort === "sessionsPerMonth" && !this.state.ascending ? <span className="material-icons iconSort">expand_less</span> :
                                        <span className="material-icons iconSort">chevron_right</span>}
                            </Col>
                            } 

                            <Col onClick={() => this.sortClients("billingAmount")} className="grid-header contactGridHead" xs={1} sm={1}>
                                <Label className="contactGridLabel" style={{ cursor: 'pointer' }}>MO BILLING AMT</Label>
                                {this.state.sort === "billingAmount" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> :
                                    this.state.sort === "billingAmount" && !this.state.ascending ? <span className="material-icons iconSort">expand_less</span> :
                                        <span className="material-icons iconSort">chevron_right</span>}
                            </Col>

                            <Col onClick={() => this.sortClients("invoiceStatus")} className="grid-header contactGridHead" xs={1} sm={1}>
                                <Label className="contactGridLabel" style={{ cursor: 'pointer' }}>INVOICE STATUS</Label>
                                {this.state.sort === "invoiceStatus" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> :
                                    this.state.sort === "invoiceStatus" && !this.state.ascending ? <span className="material-icons iconSort">expand_less</span> :
                                        <span className="material-icons iconSort">chevron_right</span>}
                            </Col>


                            <Col onClick={() => this.sortClients("paymentStatus")} className="grid-header contactGridHead" xs={1} sm={1}>
                                <Label className="contactGridLabel" style={{ cursor: 'pointer', marginLeft: '-10px' }}>PAYMENT STATUS</Label>
                                {this.state.sort === "paymentStatus" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> :
                                    this.state.sort === "paymentStatus" && !this.state.ascending ? <span className="material-icons iconSort">expand_less</span> :
                                        <span className="material-icons iconSort">chevron_right</span>}
                            </Col>


                            <Col onClick={() => this.sortClients("contractEnd")} className="grid-header contactGridHead" xs={2} sm={2}>
                                <Label className="contactGridLabel" style={{ cursor: 'pointer' }}>CONTRACT END</Label>
                                {this.state.sort === "contractEnd" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> :
                                    this.state.sort === "contractEnd" && !this.state.ascending ? <span className="material-icons iconSort">expand_less</span> :
                                        <span className="material-icons iconSort">chevron_right</span>}
                            </Col>

                        </Row>
                        {cs && cs.length > 0 && (
                            <ClientRow contacts={cs} openModalClientRequest={this.openModalClientRequest} />
                        )}
                    </div>
                )}
                {this.props.show && (
                    <ClientDetails />
                )}
            </span>
        );
    }
}


function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        currentContact: state.contactReducer.currentContact,
        clients: state.financeReducer.clients,
        show: state.financeReducer.showClientDetails,
        sysUser: state.loginReducer.sysUser,
    }
}

export default connect(mapStateToProps, { getClients, hideNavbar, showClientDetails })(ClientGrid);
