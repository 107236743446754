import React from 'react';
import { connect } from 'react-redux';
import { hideNavbar } from '../../hooks/store/actions/navBarActions';
import { login, logout, sendResetPasswordEmail, changePassword, verifyToken } from '../../hooks/store/actions/loginActions.js';
import { withStyles } from '@material-ui/core/styles';
import { getUserProfile } from '../../hooks/store/actions/loginActions';
import Row from "react-bootstrap/Row";
import { StyleSheet, css } from 'aphrodite';
import aplogo from '../app/style/AC_PrimaryCenteredLogo.png';
import toaster from 'toasted-notes';

const styles =  StyleSheet.create({    
    acLoginRow: {
        width: '85%',
        margin: 'auto'
    },

    acLoginCol: {
        width: '80%'
    },

    acLoginInputRowContainer: {
        marginLeft: 0,
        marginRight: 0,
        fontFamily: "'Open Sans', sans-serif"
    },

    acLoginInputRow: {
        width: '100%',
        backgroundColor: '#e5e5e5',
        border: '1px solid #949494',
        height: '32px',
        fontSize: '14px',
        textIndent: '10px'
    },

    loginFormHR: {
        borderTop: '1px solid #949494'
    },
    acLoginLabel: {
        fontFamily: "'Open Sans', sans-serif"
    },
    acLoginInputForgotLink: {
        marginRight: 0,
        marginLeft: 'auto',
        fontFamily: "'Open Sans', sans-serif"
    },
    loginRememberMeLabel: {
        fontSize: '14px',
        marginBottom: 0
    }
});



class ResetPasswordPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            password: '',
            submit: false,
            url: '',
            showChangePwd: false,
            req: {},
            showResetPwdModal: false,
        };
        // this.props.hideNavbar();

        this.changePassword = this.changePassword.bind(this);
        this.verifyToken = this.verifyToken.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
       // console.log('refresh profile data', this.props.user);
      //  this.props.getUserProfile(this.props.user.id);
    }



    componentDidUpdate() {
        // console.log('update comp');
    }

    changePassword() {
        if (this.props.forcePasswordChange) {
            if (this.state.password !== this.state.password2) {
                toaster.notify("Passwords do not match", { duration: 4000 });
                return;
            }

            var pwdCheck = this.pwdCheck(this.state.password, this.state.name.trim());
            //alert(pwdCheck)
            if (pwdCheck) {
                toaster.notify(pwdCheck, { duration: 4000 });
                return;
            }

            this.props.changePassword(this.state.name.trim(), "", this.state.password.trim());

            setTimeout(() => {
                document.location = "/login";
            }, 5000);
        }
    }
    
    verifyToken() {        

        if(!this.state.name){
            toaster.notify("Please enter your email", { duration: 4000 });
            return;
        }

        if (!this.state.token || this.state.token.length < 6) {
            toaster.notify("Please enter your Verification Code", { duration: 4000 });
            return;
        }

        this.props.verifyToken(this.state.name.trim(), this.state.token.trim());  
    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }


    render() {
        return (
            <div className="loginPage">
            <div className="loginForm">
                {!this.props.token && (
                    <div className={css(styles.acLoginRow)}>
                            <center>
                            <div className="acLogo"><img src={aplogo} height="180" alt="" /></div>
                            </center>
                        <hr className={css(styles.loginFormHR)}/>

                        <Row className={css(styles.acLoginInputRowContainer)}>
                                <input
                                id="name"
                                className={css(styles.acLoginInputRow)}
                                name="name"
                                type="text"
                                disabled={false}
                                value={this.state.name}
                                onChange={this.handleChange}
                                    />

                        </Row>
                        <Row className={css(styles.acLoginInputRowContainer)}>
                            <label className={css(styles.acLoginLabel)} htmlFor="inputName">Email</label>
                        </Row>
                        
                        { (this.state.showChangePwd === false && !this.props.forcePasswordChange) && (
                            <span>
                                <Row className={css(styles.acLoginInputRowContainer)}>
                                    <input
                                        id="token"
                                        className={css(styles.acLoginInputRow)}
                                        name="token"
                                        type="text"
                                        value={this.state.token}
                                        onChange={this.handleChange}
                                    />

                                </Row>
                                <Row className={css(styles.acLoginInputRowContainer)}>
                                    <label className={css(styles.acLoginLabel)} htmlFor="inputName">Enter Verification Code</label>
                                </Row>
                                <br /><br /><br /><br /><br />
                                <div style={{ marginTop: '10px' }}>
                                <button className="verify-token-button" onClick={this.verifyToken}>Verify Code</button>                              
                              
                                <br />
                                    <br />
                                </div>
                            </span>
                        )}

                        {(this.props.forcePasswordChange === true) && (
                            <span>
                                <Row className={css(styles.acLoginInputRowContainer)}>

                                    <input className={css(styles.acLoginInputRow)}
                                        id="password"
                                        name="password"
                                        type="password"
                                        value={this.state.password}
                                        onChange={this.handleChange}
                                        
                                    />
                                </Row>
                                <Row className={css(styles.acLoginInputRowContainer)}>

                                    <label htmlFor="inputPassword" >
                                        Password*
                                    </label>
                                </Row>
                                <Row className={css(styles.acLoginInputRowContainer)}>

                                    <input className={css(styles.acLoginInputRow)}
                                        id="password2"
                                        name="password"
                                        type="password"
                                        value={this.state.password2}
                                        onChange={this.handleChange}
                                        
                                    />
                                </Row>
                                <Row className={css(styles.acLoginInputRowContainer)}>

                                    <label htmlFor="inputPassword" >
                                        Re-enter Password*
                                    </label>
                                    
                                </Row>

                                <Row className={css(styles.acLoginInputRowContainer)}>
                                    <br />
                                    <label style={{fontSize: '11px', fontStyle: 'italic', marginTop: '-16px', marginBottom: '-0px', fontWeight: '600'}}>*Password must contain 8 characters, at least one letter, one number and a symbol</label>
                                </Row>

                                <br />
                                <button className="change-pw-button" onClick={this.changePassword}>Change Password</button>
                                &nbsp;
                                
                                <br />
                            </span>
                        )}

                        

                    
                    </div>
                )}

            </div>
            </div>
        );
    }


}

function mapStateToProps(state) {
    return {
        user: state.loginReducer.user,
        showLoadingGif: state.libraryReducer.showLoadingGif,
        forcePasswordChange: state.loginReducer.forcePasswordChange,
    }
}

export default connect(mapStateToProps, { hideNavbar, getUserProfile, verifyToken })(ResetPasswordPage);

