import toaster from 'toasted-notes';
import { loginActionTypes, pending, rejected, fulfilled, serverEnvironment } from '../actionConsts.js';

export default function reducer(state = {
    loginToken: '',
    loginTime: new Date(),
    isLoggedIn: false,
    sysUser: [],  // logged-in user
    user: [],  // Current selected affiliate
    userName: '',  // Current user's name
    allUsers: [],  // Every user in the system (if admin)
    selectedUser: {},  // The user the admin is editing
    loginShow: false,
    registerShow: false,
    showEditProfile: false,   // edit own profile
    showEUForm: false,  // editing other users
    showLoadingGif: false,
    forcePasswordChange: false,
    registrationStep: 0,    
    message: 'Please log in',
    emailSent: false,
    showTokenBox: false,
    showSysNotifications: false,
    sysNotificationMessages: [],
    sysNotificationsLoaded: false,
    newDataProfile: {},
}, action) {

    var u;

    switch (action.type) {
        case fulfilled(loginActionTypes.CANCEL_EDIT_PROFILE):
            return {
                ...state,
                showEditProfile: false
            }
        case fulfilled(loginActionTypes.CANCEL_LOGIN):
            return {
                ...state,
                loginShow: false,
                message: ""
            }
        case pending(loginActionTypes.EDIT_USER_PROFILE):
            return {
                ...state,
                message: "Working..."
            }
        case fulfilled(loginActionTypes.EDIT_USER_PROFILE):
            toaster.notify("User profile updated", { duration: 4000 });
            var user = state.user;
            var sysUser = state.sysUser;
            var selectedUser = state.selectedUser;
            var allUsers = state.allUsers.slice();

            if (action.payload.userID === state.user.userID) {
                // Update the current user
                user = action.payload;
                if (user.userID === sysUser.userID) {
                    sysUser = action.payload;
                }
            }
            else {
                // update the selected user
                selectedUser = action.payload;
            }
            // Update the user record in place
            for (u = 0; u < allUsers.length; u++) {
                if (allUsers[u].userID === action.payload.userID) {
                    allUsers[u] = action.payload;
                    break;
                }
            }

            return {
                ...state,
                showEditProfile: false,
                message: "User profile updated",
                user: user,
                allUsers: allUsers,
                sysUser: sysUser,
                selectedUser: selectedUser
            }
        case rejected(loginActionTypes.EDIT_USER_PROFILE):
            toaster.notify("Failed to Update User Profile --- error: " + action.payload.message, { duration: 9000 });
            return {
                ...state,
                message: action.payload.message
            }
        case pending(loginActionTypes.REQUEST_TOKEN):
            return {
                ...state,
                message: "Sending email..."
            }
        case fulfilled(loginActionTypes.REQUEST_TOKEN):
            return {
                ...state,
                showEditProfile: false,
                message: "Email sent",
                user: action.payload
            }
        case rejected(loginActionTypes.REQUEST_TOKEN):
            toaster.notify("Failed to Request Token --- error: " + action.payload.message, { duration: 9000 });
            return {
                ...state,
                message: action.payload.message
            }
        case pending(loginActionTypes.LOGIN):
            return {
                ...state,
                message: "Working..."
            }
        case fulfilled(loginActionTypes.LOGIN):
            //console.log("Logged In! " + action.payload.token);
            var token = action.payload.token;
            if (action.payload.user.forcePasswordChange === true) {
                token = null;
            }
            var isVerified = false;
            state.loginTime = new Date();

            return {
                ...state,
                loginShow: false,
                loginToken: token,
                user: action.payload.user,
                sysUser: action.payload.user,
                userName: action.payload.user.name,
                forcePasswordChange: action.payload.user.forcePasswordChange,
                message: "Success!",
                isLoggedIn: true,
                emailSent: action.payload.user.forcePasswordChange ? true : false, //force to change password use emailSent=true
            }
        case fulfilled(loginActionTypes.NEW_DATA_PROFILE):
              
                return {
                    ...state,
                    newDataProfile: action.payload,
                }
        case fulfilled(loginActionTypes.DISCONNECT_STRIPE_CONNECT):
              
                return {
                    ...state,
                    newDataProfile: {
                        ...state.newDataProfile,
                        stripeConnectAccountId: null,
                    },
                }    
        case rejected(loginActionTypes.LOGIN):
            toaster.notify("Invalid user name or password", { duration: 4000 });
            return {
                ...state,
                loginShow: true,
                loginToken: '',
                isLoggedIn: false,
                userName: '',
                user: [],
                message: 'Invalid user name or password',
                emailSent: false,
            }
        case pending(loginActionTypes.LOGOUT):
            return {
                ...state,
                loginToken: '',
                isLoggedIn: false,
                message: "Working..."
            }
        case fulfilled(loginActionTypes.LOGOUT):
            return {
                ...state,
                loginShow: false,
                loginTime: new Date(),
                loginToken: '',
                userName: '',
                user: [],
                sysUser: [],
                message: "",
                forcePasswordChange: false,
                isLoggedIn: false,
                emailSent: false,
                showTokenBox: false,
                showSysNotifications: false,
                sysNotificationMessages: [],
                sysNotificationsLoaded: false,
            }
        case rejected(loginActionTypes.LOGOUT):
            return {
                ...state,
                loginShow: true,
                loginToken: '',
                isLoggedIn: false,
                showSysNotifications: false,
                sysNotificationMessages: [],
                sysNotificationsLoaded: false,
                message: action.payload.message
            }
        case fulfilled(loginActionTypes.GET_USER_SYSNOTIFICATIONS):
            //console.log(action.payload.data)
            //alert(action.payload.data.length)
            return {
                ...state,
                showSysNotifications: action.payload.data.length > 0 ? true : false,
                sysNotificationMessages: action.payload.data,
                sysNotificationsLoaded: true,
            }
        case rejected(loginActionTypes.GET_USER_SYSNOTIFICATIONS):
            toaster.notify("Failed to Get System Notifications --- error: " + action.payload.message, { duration: 9000 });
            return {
                ...state,
                showSysNotifications: false,
                sysNotificationMessages: [],
                sysNotificationsLoaded: false,
            }
        case rejected(loginActionTypes.NEW_DATA_PROFILE):
                toaster.notify("Failed to Get New Profile data --- error: " + action.payload.message, { duration: 9000 });
                return {
                    ...state,
                   newDataProfile: {}
                }
        case rejected(loginActionTypes.DISCONNECT_STRIPE_CONNECT):
                toaster.notify("Failed to disconnect Stripe Connect --- error: " + action.payload.message, { duration: 9000 });
                    return {
                        ...state,
                       newDataProfile: {}
                }
        case fulfilled(loginActionTypes.DISMISS_USER_SYSNOTIFICATION):
            //console.log(action.payload.data)
            //alert(action.payload.data.length)
            return {
                ...state,
                showSysNotifications: false,
                sysNotificationMessages: [],
                sysNotificationsLoaded: true,
            }
        case rejected(loginActionTypes.DISMISS_USER_SYSNOTIFICATION):
            toaster.notify("Failed to Dismiss System Notification --- error: " + action.payload.message, { duration: 9000 });
            return {
                ...state,
                showSysNotifications: false,
                sysNotificationMessages: [],
                sysNotificationsLoaded: false,
            }
        case pending(loginActionTypes.REGISTER):
            return {
                ...state,
                message: "Working..."
            }
        case fulfilled(loginActionTypes.REGISTER):
            toaster.notify("User has been created", { duration: 4000 });
            allUsers = state.allUsers.slice();
            allUsers.push(action.payload);
            return {
                ...state,
                showEUForm: false,
                allUsers: allUsers,
                selectedUser: action.payload
            }
        case rejected(loginActionTypes.REGISTER):
            toaster.notify("An error has occurred: " + action.payload.message, { duration: 4000 });
            return {
                ...state,
                message: action.payload.message
            }
        case pending(loginActionTypes.DEACTIVATE_USER):
            return {
                ...state,
                message: "Working..."
            }
        case fulfilled(loginActionTypes.DEACTIVATE_USER):
            toaster.notify("User has been deactivated", { duration: 4000 });
            allUsers = state.allUsers.slice();
            for (u = 0; u < state.allUsers.length; u++) {
                if (allUsers[u].userID === action.payload) {
                    allUsers[u].isActive = false;
                    break;
                }
            }
            return {
                ...state,
                allUsers: allUsers
            }
        case rejected(loginActionTypes.DEACTIVATE_USER):
            toaster.notify("An error has occurred: " + action.payload.message, { duration: 4000 });
            return {
                ...state,
                message: action.payload.message
            }
        case fulfilled(loginActionTypes.READY_TO_VERIFY):
            return {
                ...state,
                registrationStep: 2,
                registerShow: true
            }
        case fulfilled(loginActionTypes.START_LOGIN):
            return {
                ...state,
                loginTime: new Date(),
                loginShow: true,
                registrationStep: 0,
                registerShow: false,
                message: ""
            }


        case fulfilled(loginActionTypes.DONE_REGISTERING):
            return {
                ...state,
                message: "",
                registrationStep: 0,
                registerShow: false
            }
        case fulfilled(loginActionTypes.START_REGISTERING):
            return {
                ...state,
                message: "",
                userName: action.payload,
                registrationStep: 0,
                loginShow: false,
                registerShow: true
            }
        case pending(loginActionTypes.RESET_PASSWORD):
            return {
                ...state,
                message: "Resetting password..."
            }
        case fulfilled(loginActionTypes.RESET_PASSWORD):
            // toaster.notify("Email sent - please check your inbox");
            return {
                ...state,
                message: "Email sent",
                forcePasswordChange: false,
                emailSent: true,
                showTokenBox: true,
            }
        case rejected(loginActionTypes.RESET_PASSWORD):
            toaster.notify("Email not found", { duration: 4000 });
            return {
                ...state,
                message: action.payload.message,
                forcePasswordChange: false,
                emailSent: false,
                showTokenBox: false,
            }
        case pending(loginActionTypes.VERIFY_TOKEN):
            return {
                ...state,
                message: "Verifying token..."
            }
        case fulfilled(loginActionTypes.VERIFY_TOKEN):
            toaster.notify("Token is confirmed!", { duration: 4000 });
            return {
                ...state,
                forcePasswordChange: true,
                message: "Enter a new password",
                emailSent: true,
                showTokenBox: false,
            }
        case rejected(loginActionTypes.VERIFY_TOKEN):
            toaster.notify("Token is invalid", { duration: 4000 });
            return {
                ...state,
                forcePasswordChange: false,
                message: action.payload.message,
                emailSent: false,
                showTokenBox: false,
            }
        case fulfilled(loginActionTypes.SHOW_EDIT_PROFILE):
            return {
                ...state,
                showEditProfile: true,
                message: ""
            }
        case fulfilled(loginActionTypes.SHOW_EDIT_USER_FORM):
            return {
                ...state,
                showEUForm: action.payload,
                message: ""
            }
        case pending(loginActionTypes.CHANGE_PASSWORD):
            return {
                ...state,
                message: "Changing password..."
            }
        case fulfilled(loginActionTypes.CHANGE_PASSWORD):
            toaster.notify("Password changed!", { duration: 4000 });
            return {
                ...state,
                message: "Password changed",
                loginShow: false,
                forcePasswordChange: false,
                emailSent: false,
            }
        case rejected(loginActionTypes.CHANGE_PASSWORD):
            toaster.notify("Failed to Change Password --- error: " + action.payload.message, { duration: 9000 });
            return {
                ...state,
                message: action.payload.message,
                emailSent: false,
            }
        case pending(loginActionTypes.GET_ALL_USERS):
            return {
                ...state,
                showLoadingGif: true,
                message: "Fetching users..."
            }
        case fulfilled(loginActionTypes.GET_ALL_USERS):
            return {
                ...state,
                message: "",
                showLoadingGif: false,
                allUsers: action.payload.data
            }
        case rejected(loginActionTypes.GET_ALL_USERS):
            toaster.notify("Failed to Get All Users --- error: " + action.payload.message, { duration: 9000 });
            return {
                ...state,
                showLoadingGif: false,
                message: action.payload.message
            }
        case pending(loginActionTypes.IMPERSONATE_USER):
            return {
                ...state,
                message: ""
            }
        case fulfilled(loginActionTypes.IMPERSONATE_USER):
            var user1 = state.user;
            if (action.payload.data && action.payload.data.length > 0) {
                toaster.notify("Impersonating " + action.payload.data[0].email, { duration: 4000 });
                user1 = action.payload.data[0];
            }
            else {
                toaster.notify("Invalid affiliate");
            }
            return {
                ...state,
                user: user1
            }
        case rejected(loginActionTypes.IMPERSONATE_USER):
            toaster.notify("Failed to Impersonate User --- error: " + action.payload.message, { duration: 9000 });
            return {
                ...state,                
                message: action.payload.message
            }
        case fulfilled(loginActionTypes.SELECT_USER):
            if (action.payload.impersonate) {
                toaster.notify("Impersonating " + action.payload.user.email, { duration: 4000 });
            }
            return {
                ...state,
                message: "",
                selectedUser: action.payload.user,
                user: action.payload.impersonate ? action.payload.user : state.user
            }
        case pending(loginActionTypes.VERIFY_EMAIL):
            return {
                ...state,
                message: "Verifying email..."
            }
        case fulfilled(loginActionTypes.VERIFY_EMAIL):
            //console.log("Verified email...");
            isVerified = false;
            state.loginTime = new Date();
            customerId = serverEnvironment.customer.id;

            return {
                ...state,
                loginShow: false,
                registerShow: (isVerified ? false : true),
                loginToken: action.payload.data.token,
                user: action.payload.data.user,
                userName: action.payload.data.user.name,
                message: "",
                registrationStep: (isVerified ? 0 : 2), 
                isLoggedIn: true,
                forcePasswordChange: action.payload.data.user.ForcePasswordChange,
                loginTime: new Date()
            }
        case rejected(loginActionTypes.VERIFY_EMAIL):
            toaster.notify("Failed to Verify Email --- error: " + action.payload.message, { duration: 9000 });
            return {
                ...state,
                registrationStep: 1,
                message: action.payload.message
            }
        default:
            return state;
    }
};
