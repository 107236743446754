import { navBarActionTypes, pending, rejected, fulfilled, serverEnvironment } from '../actionConsts.js';

export default function reducer(state = {
    navBar: true,
    editProfile: false
}, action) {

    switch (action.type) {
        case fulfilled(navBarActionTypes.SHOW_NAVBAR):
           return { 
               ...state,
               navBar: true
           }
        case fulfilled(navBarActionTypes.HIDE_NAVBAR): 
           return {
               ...state,
               navBar: false
           }
        case fulfilled(navBarActionTypes.SHOW_EDITPROF): 
           return {
               ...state,
               editProfile: true
           }
        case fulfilled(navBarActionTypes.HIDE_EDITPROF):
           return {
               ...state,
               editProfile: false
           }
        default:
            return {
                ...state
            }
    }
}
