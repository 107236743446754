
import React from 'react';

import { connect } from 'react-redux'
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import { startEditProfile } from '../../hooks/store/actions/loginActions';
import { showEditForm, editUserContact} from '../../hooks/store/actions/contactActions';

class EditProfileMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMenuOpen: true,
            anchorEl: null,
            showProfile: false
        };
        this.onClickEditProfile = this.onClickEditProfile.bind(this);
        this.onClickAccountDetails = this.onClickAccountDetails.bind(this);
        this.onMenuOpen = this.onMenuOpen.bind(this);
    }


    onClickEditProfile(e) {
        
        this.props.startEditProfile();
    }
   

    onClickAccountDetails(e) {        
        this.props.editUserContact(true);
    }

    onMenuOpen() {
        this.setState({ "isMenuOpen": this.state.isMenuOpen ? false : true });        
    }

    render() {
        return (

            <Menu                
                anchorEl={this.props.anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id="menuProfile"
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={this.state.isMenuOpen}
                onClick={this.onMenuOpen}                
            >
                <MenuItem onClick={this.onClickEditProfile}>Profile</MenuItem>
                
            </Menu>
        )
    }
}


function mapStateToProps(state) {
    return {        
        user: state.loginReducer.user,
        showEditProfile: state.loginReducer.showEditProfile,
        userContact: state.contactReducer.userContact
    }
}

export default connect(mapStateToProps, { startEditProfile, showEditForm, editUserContact })(EditProfileMenu);
