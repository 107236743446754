import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import { StyleSheet, css } from 'aphrodite';
import toaster from 'toasted-notes';
import { login, logout, sendResetPasswordEmail, changePassword, verifyToken } from '../../hooks/store/actions/loginActions.js';
import aplogo from '../app/style/AC_PrimaryCenteredLogo.png';
import Modal from 'react-bootstrap/Modal';
import MediaQuery from 'react-responsive';

const styles = StyleSheet.create({
    acLoginRow: {
        width: '85%',
        margin: 'auto'
    },

    acLoginCol: {
        width: '80%'
    },

    acLoginInputRowContainer: {
        marginLeft: 0,
        marginRight: 0,
        fontFamily: "'Open Sans', sans-serif"
    },

    acLoginInputRow: {
        width: '100%',
        backgroundColor: '#e5e5e5',
        border: '1px solid #949494',
        height: '32px',
        fontSize: '14px',
        textIndent: '10px'
    },

    loginFormHR: {
        borderTop: '1px solid #949494'
    },
    acLoginLabel: {
        fontFamily: "'Open Sans', sans-serif"
    },
    acLoginInputForgotLink: {
        marginRight: 0,
        marginLeft: 'auto',
        fontFamily: "'Open Sans', sans-serif"
    },
    loginRememberMeLabel: {
        fontSize: '14px',
        marginBottom: 0
    }
});

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            password: '',
            submit: false,
            url: '',
            showChangePwd: false,
            req: {},
            showResetPwdModal: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.forgotPassword = this.forgotPassword.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.verifyToken = this.verifyToken.bind(this);
    }

    handleCancel = () => {
        this.setState({ showChangePwd: false });
        this.props.logout(this.props.token);
    }

    handleCancelForgotPassword = () => {
        this.setState({ showResetPwdModal: !this.state.showResetPwdModal });
    }

    handleSubmit = () => {

        var userName = this.state.name.trim();
        var password = this.state.password.trim();
        this.props.login(userName, password);
        this.setState({ showChangePwd: false });

        try {
            if (document.getElementById('rememberMe').checked) {
                if (userName !== "") {
                    var currentRememberMe = localStorage.getItem('rememberMe');
                    //console.log(currentRememberMe);
                    if (currentRememberMe !== userName) {
                        localStorage.setItem('rememberMe', userName);
                    }
                }
            }
        } catch {
            //do nothing
        }

    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }

    handleLogout = () => {
        this.props.logout(this.props.token);
    }

    enterPressed(event) {
        var code = event.keyCode || event.which;
        if (code === 13) {
            this.handleSubmit();
        }
    }

    enterResetPwdPressed(event) {
        var code = event.keyCode || event.which;
        if (code === 13) {
            this.forgotPassword();
        }
    }

    pwdCheck(PW, Email) {
        //^ The password string will start this way
        //(?=.*[A-Za-z])	The string must contain at least 1 alphabetical character
        //(?=.*[0-9])		The string must contain at least 1 numeric character
        //(?=.*[.!@#$%^&*])	The string must contain at least one special character, but we are escaping reserved RegEx characters to avoid conflict
        //(?=.{8,})			The string must be eight characters or longer

        var regEx = new RegExp("^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[.!@#\$%\^&\*])(?=.{8,})");
        var msg = "";

        if (Email.toLowerCase() == PW.toLowerCase()) {
            //alert('Your password can not be the same as your email address');
            msg = 'Your password can not be the same as your email address';
            return msg;
        }

        if (regEx.test(PW)) {
            //alert('password is good');
            return false;
        } else {
            //alert('Your password does not meet our minimum requirements. It must be at least 8 characters long containing at least one number, one letter and one special character');
            //msg = 'Your password does not meet our minimum requirements. It must be at least 8 characters long containing at least one number, one letter and one special character';
            msg = 'Password entered does not meet minimum requirements.';
            return msg;
        }
    }

    changePassword() {
        if (this.props.forcePasswordChange) {
            if (this.state.password !== this.state.password2) {
                toaster.notify("Passwords do not match", { duration: 4000 });
                return;
            }

            var pwdCheck = this.pwdCheck(this.state.password, this.state.name.trim());
            //alert(pwdCheck)
            if (pwdCheck) {
                toaster.notify(pwdCheck, { duration: 4000 });
                return;
            }

            this.props.changePassword(this.state.name.trim(), "", this.state.password.trim());
        }
    }

    verifyToken() {        
        if (!this.state.token || this.state.token.length < 6) {
            toaster.notify("Please enter your Verification Code", { duration: 4000 });
            return;
        }

        this.props.verifyToken(this.state.name.trim(), this.state.token.trim());  
    }

    forgotPassword() {
        // Calls endpoint to send reset password link
        var email = this.state.name.trim();
        if (!email) {
            toaster.notify("Please enter an email", { duration: 4000 });
            return;
        }
        this.props.sendResetPasswordEmail(email, false);
        toaster.notify("Email sent - please check your inbox");
        this.setState({ showChangePwd: false });
        this.setState({ showResetPwdModal: false });
    }

    componentDidMount() {

        if ((this.props.forcePasswordChange === false && this.props.emailSent === false)) {
            var remMeValue = localStorage.getItem('rememberMe');
            if (remMeValue && remMeValue !== "") {
                this.setState({ name: remMeValue });
                //Keep checkbox checked 
                try {
                    document.getElementById('rememberMe').checked = true;
                } catch{
                    //do nothing
                }
            }
        } 
    }

    render() {

        let loginClose = () => {
            alert('close')
            //this.props.cancelLogin();
        };

        if (this.props.token) {
            document.location = "/home";
        }


        return (

            <div className="loginForm">
                {!this.props.token && (
                    <div className={css(styles.acLoginRow)}>
                            <center>
                            <div className="acLogo"><img src={aplogo} height="180" alt="" /></div>
                            </center>
                        <hr className={css(styles.loginFormHR)}/>
                        <Row className={css(styles.acLoginInputRowContainer)}>
                                <input
                                id="name"
                                className={css(styles.acLoginInputRow)}
                                name="name"
                                type="text"
                                disabled={(this.props.forcePasswordChange === true && this.props.emailSent === true) || (this.props.showTokenBox === true && this.state.showChangePwd === false)}
                                value={this.state.name}
                                onChange={this.handleChange}
                                    />

                        </Row>
                        <Row className={css(styles.acLoginInputRowContainer)}>
                            <label className={css(styles.acLoginLabel)} htmlFor="inputName">Email</label>
                        </Row>

                        { (this.props.showTokenBox === true && this.state.showChangePwd === false) && (
                            <span>
                                <Row className={css(styles.acLoginInputRowContainer)}>
                                    <input
                                        id="token"
                                        className={css(styles.acLoginInputRow)}
                                        name="token"
                                        type="text"
                                        value={this.state.token}
                                        onChange={this.handleChange}
                                    />

                                </Row>
                                <Row className={css(styles.acLoginInputRowContainer)}>
                                    <label className={css(styles.acLoginLabel)} htmlFor="inputName">Enter Verification Code</label>
                                </Row>
                                <br /><br /><br /><br /><br />
                                <div style={{ marginTop: '10px' }}>
                                <button className="verify-token-button" onClick={this.verifyToken}>Verify Code</button>
                                &nbsp;
                                <button className="cancel-button" onClick={this.handleCancel}>CANCEL</button>
                                <br />
                                    <br />
                                </div>
                            </span>
                        )}

                        {(this.props.forcePasswordChange === true && this.props.emailSent === true) && (
                            <span>
                                <Row className={css(styles.acLoginInputRowContainer)}>

                                    <input className={css(styles.acLoginInputRow)}
                                        id="password"
                                        name="password"
                                        type="password"
                                        value={this.state.password}
                                        onChange={this.handleChange}
                                        
                                    />
                                </Row>
                                <Row className={css(styles.acLoginInputRowContainer)}>

                                    <label htmlFor="inputPassword" >
                                        Password*
                                    </label>
                                </Row>
                                <Row className={css(styles.acLoginInputRowContainer)}>

                                    <input className={css(styles.acLoginInputRow)}
                                        id="password2"
                                        name="password"
                                        type="password"
                                        value={this.state.password2}
                                        onChange={this.handleChange}
                                        
                                    />
                                </Row>
                                <Row className={css(styles.acLoginInputRowContainer)}>

                                    <label htmlFor="inputPassword" >
                                        Re-enter Password*
                                    </label>
                                    
                                </Row>

                                <Row className={css(styles.acLoginInputRowContainer)}>
                                    <br />
                                    <label style={{fontSize: '11px', fontStyle: 'italic', marginTop: '-16px', marginBottom: '-0px', fontWeight: '600'}}>*Password must contain 8 characters, at least one letter, one number and a symbol</label>
                                </Row>

                                <br />
                                <button className="change-pw-button" onClick={this.changePassword}>Change Password</button>
                                &nbsp;
                                <button className="cancel-button" onClick={this.handleCancel}>CANCEL</button>
                                <br />
                            </span>
                        )}

                        { (this.props.showTokenBox === false && this.props.emailSent === false) && (
                            <span>
                                <Row className={css(styles.acLoginInputRowContainer)}>

                                    <input className={css(styles.acLoginInputRow)}
                                        id="password"
                                        name="password"
                                        type="password"
                                        value={this.state.password}
                                        onChange={this.handleChange}
                                        onKeyPress={this.enterPressed.bind(this)}
                                    />
                                </Row>
                                <Row className={css(styles.acLoginInputRowContainer)}>

                                    <label htmlFor="inputPassword" >
                                        Password
                                        </label>
                                    <a className={css(styles.acLoginInputForgotLink)} href="#"><span onClick={() => this.setState({ showResetPwdModal: !this.state.showResetPwdModal }) }>Forgot Password?</span></a>
                                </Row>
                                <input type="checkbox" value="lsRememberMe" id="rememberMe" /> <label className={css(styles.loginRememberMeLabel)} htmlFor="rememberMe">Remember me</label>
                                <hr className={css(styles.loginFormHR)} />
                                <br /><br />
                                <button className="login-button" onClick={this.handleSubmit}>SIGN IN</button>
                                <br />
                            </span>
                        )}

                        <Modal
                            dialogClassName="modalDialog"
                            centered
                            aria-labelledby="contained-modal-title-vcenter"
                            show={this.state.showResetPwdModal}
                            onHide={this.handleCancelForgotPassword}
                            style={{ height: 'auto', textAlign: 'center' }}
                        >
                            <Modal.Body className="modalBody">
                                <div>
                                    <div style={{ marginTop: '3px', marginBottom: '-13px', fontWeight: '800' }}>
                                    <label>Email</label></div>

                                    <MediaQuery maxWidth={875}>
                                        <input style={{ width: '200px', textAlign: 'center', marginTop: '13px', borderRadius: '17px', height: '43px', fontSize: '18px' }}
                                            id="name"
                                            name="name"
                                            type="text"
                                            value={this.state.name}
                                            onChange={this.handleChange}
                                            onKeyPress={this.enterResetPwdPressed.bind(this)}
                                            centered
                                        />
                                    </MediaQuery>

                                    <MediaQuery minWidth={876}>
                                        <input style={{ width: '400px', textAlign: 'center', marginTop: '13px', borderRadius: '17px', height: '43px', fontSize: '18px' }}
                                            id="name"
                                            name="name"
                                            type="text"
                                            value={this.state.name}
                                            onChange={this.handleChange}
                                            onKeyPress={this.enterResetPwdPressed.bind(this)}
                                            centered
                                        />
                                    </MediaQuery>

                                    <div style={{ marginTop: '26px', marginBottom: '-60px', fontSize: '14px', fontStyle: 'italic', fontWeight: '600' }}>
                                    <label>Click Reset Password to receive an Emailed Token you'll use to Reset your Password.</label></div>

                                </div>
                                <br /><br /><br /><br />
                                <button className={'reset-password-button'} style={{ borderRadius: '13px' }} onClick={this.forgotPassword}>RESET PASSWORD</button>
                                &nbsp;
                                <button className="cancel-button" onClick={this.handleCancelForgotPassword}>CANCEL</button>
                            </Modal.Body>
                        </Modal>
                    </div>
                )}

            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        token: state.loginReducer.loginToken,
        message: state.loginReducer.message,
        user: state.loginReducer.user,
        forcePasswordChange: state.loginReducer.forcePasswordChange,
        emailSent: state.loginReducer.emailSent,
        showTokenBox: state.loginReducer.showTokenBox,
    }
}

export default connect(mapStateToProps, { login, logout, sendResetPasswordEmail, changePassword, verifyToken })(LoginForm);
