import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import LoginForm from './LoginForm.js'

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="loginPage">
                <LoginForm/>
            </div>
            )
    }
}
function mapStateToProps(state) {
    return {
        token: state.loginReducer.loginToken,
        message: state.loginReducer.message,
        user: state.loginReducer.user
    }
}

export default connect(mapStateToProps, {})(LoginPage);
