import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './components/app/style/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { serverEnvironment } from './hooks/store/actionConsts.js';

import { useStore, persistor } from './hooks/store/useStore';
import StoreContextProvider from './hooks/store/StoreContext';
import { PersistGate } from 'redux-persist/integration/react'

if (process.env && process.env.REACT_APP_API_URL) {
    serverEnvironment.API_URL = process.env.REACT_APP_API_URL;
    console.log("Using " + serverEnvironment.API_URL);
}
if (process.env && process.env.REACT_APP_ASPNET_URL) {
    serverEnvironment.ASPNET_URL = process.env.REACT_APP_ASPNET_URL;
    serverEnvironment.ASPNET_URL = "https://acadminhubwebform.azurewebsites.net/react/"
    console.log("Using " + serverEnvironment.ASPNET_URL);
}
if (process.env && process.env.REACT_APP_MAINT_MODE) {
    serverEnvironment.MAINT_MODE = process.env.REACT_APP_MAINT_MODE;
    console.log("Using " + serverEnvironment.MAINT_MODE);


    //<PersistGate loading={null} persistor={persistor}>
    //    <StoreContextProvider>
    //        <App />
    //    </StoreContextProvider>
    //</PersistGate>
}
if (process.env && !process.env.REACT_AC_BACKEND_API_URL) {
    serverEnvironment.AC_BACKEND_API_URL = 'https://acapibackend.azurewebsites.net';
    console.log("Using " + serverEnvironment.AC_BACKEND_API_URL);
}



ReactDOM.render(
    <Provider store={useStore}>
        <PersistGate loading={null} persistor={persistor}>
            <StoreContextProvider>
                <App />
            </StoreContextProvider>
        </PersistGate>
    </Provider >

  , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
//
//
// if (serviceWorker in navigator) {alert('yes')} else {alert('no')}
//
// //test serviceWorker implementation
// if ('serviceWorker' in navigator) {
//
//   navigator.serviceWorker.register('/sw.js')
//     .then(function(registration) {
//       // Registration was successful
//       })
//     .catch(function(err) {
//          // registration failed :(
//      });
// }
//
// if (serviceWorker in navigator) {alert('yes')} else {alert('no')}
