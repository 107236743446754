import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Iframe from 'react-iframe'
import { startEditProfile } from '../../../hooks/store/actions/loginActions';
import { showEditForm, resendCSA_PDF, SIP, searchContacts } from '../../../hooks/store/actions/contactActions';
import { showClientDetails } from '../../../hooks/store/actions/financeActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TemplateViewer from './TemplateViewer';
import PauseBillingPlan from './PauseBillingPlan';
import toaster from 'toasted-notes';
import { getEmptyInvoice, getBillingPlans, cancelBillingPlan, deactivateBillingPlan, requestManualInvoice, activateBillingPlan } from '../../../hooks/store/actions/financeActions.js';
import { serverEnvironment, sleep } from '../../../hooks/store/actionConsts';
import { hideNavbar } from '../../../hooks/store/actions/navBarActions';
import RequestManualInvoiceForm from './RequestManualInvoiceForm';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import MediaQuery from 'react-responsive';
import $ from "jquery";
import { getMessageTerminatePlan } from '../../form/utils/message-utils';
import InvoiceRequestModal from '../../modals/InvoiceRequestModal';


class Action extends React.Component {
    constructor(props) {
        super(props);
        this.sipEmailRef = React.createRef();
        this.sipFNRef = React.createRef();
        this.sipLNRef = React.createRef();
        this.state = {
            showIFrame: false,
            iFrameUrl: '',
            csaEmail: '',
            sipEmail: '',
            sipFN: '',
            sipLN: '',
            editTemplate: false,
            showBillingPlan: false,
            showResendCSA: false,
            showSIP: false,
            search: ''
        };


        this.props.hideNavbar();
        this.editProfile = this.editProfile.bind(this);
        this.editContact = this.editContact.bind(this);
        this.clientDetails = this.clientDetails.bind(this);
        this.createAgreement = this.createAgreement.bind(this);
        this.editAgreement = this.editAgreement.bind(this);
        this.modifyLanguage = this.modifyLanguage.bind(this);
        this.feeCalculator = this.feeCalculator.bind(this);
        this.sendInformationPacket = this.sendInformationPacket.bind(this);
        this.onHideIFrame = this.onHideIFrame.bind(this);
        this.onClickHideTemplate = this.onClickHideTemplate.bind(this);
        this.focusSearchBar = this.focusSearchBar.bind(this);
        this.getActiveBillingPlan = this.getActiveBillingPlan.bind(this);
        this.getBillingPlan = this.getBillingPlan.bind(this);
        this.isMyContact = this.isMyContact.bind(this);
        this.requestManualInvoice = this.requestManualInvoice.bind(this);
        this.terminateAgreement = this.terminateAgreement.bind(this);
        this.pauseAgreement = this.pauseAgreement.bind(this);
        this.deactivateBP = this.deactivateBP.bind(this);
        this.onClickHideBillingPlan = this.onClickHideBillingPlan.bind(this);
        this.formatNotes = this.formatNotes.bind(this);
        this.resendCSA = this.resendCSA.bind(this);
        this.sendInfoPacket = this.sendInfoPacket.bind(this);
        this.enterPressed = this.enterPressed.bind(this);
        this.setSearch = this.setSearch.bind(this);
        this.search = this.search.bind(this);
        this.closeInvoiceRequestModal = this.closeInvoiceRequestModal.bind(this);
    }

    componentDidMount() {
        if (this.props.currentContact) {
            this.props.getBillingPlans(this.props.logintoken, this.props.currentContact.contactID, this.props.currentContact.affiliateID);
        }
    }

    isMyContact() {
        if (!this.props.currentContact || this.props.currentContact.affiliateID === 0 || this.props.currentContact.affiliateID !== this.props.sysUser.id) {
            return false;
        }
        return true;
    }

    isContactSelected() {
        if (this.props.currentContact && this.props.currentContact.contactID) {
            return true;
        }
        return false;
    }

    // Create contact
    createContact() {
        document.location = "/NewContact";
        this.setState({ showIFrame: false });
    }

    focusSearchBar() {
        var sb = document.getElementById("searchInputFocus");
        sb.classList.add("searchInputFocus");
        sb.focus();
        toaster.notify("Use the search bar to search by Contact Name or Email", { duration: 4000 });
    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }

    resendCSA = () => {
        //alert('this.state.csaEmail=' + this.state.csaEmail)
        if (this.getBillingPlan()) {
            var url = 'https://acadminhubstorage.blob.core.windows.net/billingplandocuments/' + String(this.getBillingPlan().CSAFileName);
            //window.open(url, '_blank');
            this.props.resendCSA_PDF(this.props.logintoken, this.state.csaEmail, url, this.getBillingPlan().CSAFileName, (this.props.sysUser.firstName + ' ' + this.props.sysUser.lastName))
        }
        this.setState({ showResendCSA: false })
    }

    enterResendCSA(event) {
        var code = event.keyCode || event.which;
        if (code === 13) {
            this.resendCSA();
        }
    }

    toggleShowResendCSA = () => {
        if (this.state.showResendCSA !== true) {
            this.setState({ csaEmail: this.props.currentContact.email })
        }
        
        this.setState({ showResendCSA: !this.state.showResendCSA })
    }

    sendInfoPacket = () => {
        const sipEmail = this.sipEmailRef.current.value;
        const sipFN = this.sipFNRef.current.value;
        const sipLN = this.sipLNRef.current.value;
        //alert('sipEmail=' + sipEmail + ' --- sipFN=' + sipFN + ' --- sipLN=' + sipLN)
        this.props.SIP(this.props.logintoken, sipFN, sipLN, sipEmail, this.props.currentContact ? this.props.currentContact.contactID : null, this.props.sysUser.id);
        this.setState({ showSIP: false })
    }

    enterSIP(event) {
        var code = event.keyCode || event.which;
        if (code === 13) {
            this.SIP();
        }
    }

    toggleShowSIP = () => {
        if (this.state.showSIP !== true) {
            this.setState({ sipFN: this.props.currentContact ? this.props.currentContact.firstName : '' })
            this.setState({ sipLN: this.props.currentContact ? this.props.currentContact.lastName : ''})
            this.setState({ sipEmail: this.props.currentContact ? this.props.currentContact.email : '' })
        }

        this.setState({ showSIP: !this.state.showSIP })
    }

    // Edit Contact
    editContact() {
        // If the contact is selected, open the modal popup
        if (this.props.currentContact && this.props.currentContact.contactID) {
            this.props.showEditForm(this.props.currentContact);
            sleep(100).then(() => { document.location = "viewContacts"; });
        }
        else {
            toaster.notify("Please select a contact from search", { duration: 4000 });
        }
    }

    // Client Details
    clientDetails() {
        if (this.props.currentContact && this.props.currentContact.contactID) {
            this.props.showClientDetails(this.props.currentContact);
            sleep(100).then(() => { document.location = "clientMgmt/autoload"; });
        }
        else {
            toaster.notify("Please select a client from search", { duration: 4000 });
        }
    }

    getIframeCloseSelector() {
        document.getElementById('iFrameModalSelector').setAttribute("show", false);
    }

    // Create agreement
    createAgreement() {
        // If a contact is selected, show iFrame with URL to create billing plan
        var selectedBillingPlanID = this.getActiveBillingPlan();
        if (!selectedBillingPlanID) {
            this.setState({
                showIFrame: true, iFrameUrl: serverEnvironment.ASPNET_URL + "SetupBillingPlan.aspx?ContactId=" + String(this.props.currentContact.contactID)
            });
        }
        else {
            toaster.notify("This contact already has an active billing plan", { duration: 4000 });
        }
    }

    getActiveBillingPlan() {
        var selectedBillingPlanID = 0;
        for (var i = 0; i < this.props.billingPlans.length; i++) {
            if (this.props.billingPlans[i].statusID === 2 || this.props.billingPlans[i].statusID === 3) { // they can only have one active/paused billing plan
                selectedBillingPlanID = this.props.billingPlans[i].billingPlanID;
                break;
            }
        }

        return selectedBillingPlanID;
    }

    getPendingBillingPlan() {
        var selectedBillingPlanID = 0;
        for (var i = 0; i < this.props.billingPlans.length; i++) {
            if (this.props.billingPlans[i].statusID === 1) { 
                selectedBillingPlanID = this.props.billingPlans[i].billingPlanID;
                break;
            }
        }

        return selectedBillingPlanID;
    }

    getBillingPlan() {
        var selectedBillingPlanID = 0;
        for (var i = 0; i < this.props.billingPlans.length; i++) {
            if (this.props.billingPlans[i].statusID === 2 || this.props.billingPlans[i].statusID === 3) { // they can only have one active billing plan
                return this.props.billingPlans[i];                
            }
        }

        return null;
    }

    getPendingBillingPlanObj() {
        var selectedBillingPlanID = 0;
        for (var i = 0; i < this.props.billingPlans.length; i++) {
            if (this.props.billingPlans[i].statusID === 1) { 
                return this.props.billingPlans[i];
            }
        }

        return null;
    }

    // Modify agreement
    editAgreement(autoSubmit=false) {
        var selectedBillingPlanID = this.getActiveBillingPlan();
        if (!selectedBillingPlanID) { selectedBillingPlanID = this.getPendingBillingPlan(); }

        if (selectedBillingPlanID) {
            var newURL = serverEnvironment.ASPNET_URL + "SetupBillingPlan.aspx?ContactId=" + String(this.props.currentContact.contactID) +
                "&Bpid=" + String(selectedBillingPlanID);
            if (autoSubmit === true) { newURL += "&AutoSubmit=1" };

            this.setState({
                showIFrame: true,
                iFrameUrl: newURL
            });
        }
        else {
            toaster.notify("The contact must have one active or pending billing plan.", { duration: 4000 });
        }
    }

    // Modify language
    modifyLanguage() {
        //If user is Admin, route to Modify Contract page 
        //If user is Affiliate, show Modify Contract Request form
        if (!this.props.sysUser.userRole || this.props.sysUser.userRole <= 2) {
            this.setState({ editTemplate: true });
        } else {
            this.setState({
                showIFrame: true, iFrameUrl: serverEnvironment.ASPNET_URL + "CoachingAgreementTemplateEditor.aspx/?x=1"
            });
        }
    }

    // Fee Calculator
    feeCalculator() {
        // If a contact is selected, show iFrame with URL to fee calc

        this.setState({ showIFrame: true, iFrameUrl: serverEnvironment.ASPNET_URL + "FeeCalculator.aspx?" });
    }

    // iFrameGifLoader() {
    //     var iframe = document.getElementById('aspNetSite    ');
    //     var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

    //     if ( iframeDoc.readyState == 'complete' ) {
    //         iframe.contentWindow.onload = function() {
    //             alert("I am loaded"); 
    //         };
    //     }
    // }

    // Send Information Packet
    sendInformationPacket() {
        // If a contact is selected, show iFrame with URL to send information packet
        var qp = (this.props.currentContact && this.props.currentContact.contactID) ? "?ContactId=" + String(this.props.currentContact.contactID) : "?1=1";
        this.setState({
            showIFrame: true, iFrameUrl: serverEnvironment.ASPNET_URL + "SendInformationPacket.aspx" + qp

        });
    }

    deactivateBP(month, bp, sendEmail) {
        bp.pauseUntilDate = month;
        if (sendEmail) {
            if (!this.props.emptyInvoice.invoiceTotal) {
                toaster.notify("Please enter a positive invoice amount", { duration: 4000 });
                return;
            }
            bp.statusID = 3;
            bp.notes = this.formatNotes();
            this.props.requestManualInvoice(this.props.logintoken, bp);
        } else {
            this.props.deactivateBillingPlan(this.props.logintoken, bp);
        }
        this.setState({ showBillingPlan: false });
    }

    pauseAgreement() {
        var bp = this.getBillingPlan();
        if (bp && bp.statusID === 3) {            
            this.props.activateBillingPlan(this.props.logintoken, bp);
            sleep(500).then(() => {
                this.forceUpdate();
            })
        }
        else {
            this.setState({ showBillingPlan: true, showInvoiceForm: false });
        }
    }

    refreshActionPage() {
        sleep(50).then(() => {
            document.location = "/action";
        })
    }

    formatNotes() {
        var pm = "";
        for (var i = 0; i < this.props.paymentMethods.length; i++) {
            if (String(this.props.paymentMethods[i].id) === String(this.props.emptyInvoice.paymentMethodID)) {
                pm = this.props.paymentMethods[i].name;
                break;
            }
        }

        return "Affiliate: " + this.props.user.firstName + " " + this.props.user.lastName
        + "<br/>Contact: " + this.props.currentContact.firstName + " " + this.props.currentContact.lastName
        + "<br/>Due Date: " + String(this.props.emptyInvoice.dueDate).substring(0, 10)
        + "<br/>Billing Amount: $" + String(this.props.emptyInvoice.invoiceTotal.toFixed(2))
        + "<br/>Payment Method: " + pm
        + "<br/>Charge Currency: " + String(this.props.emptyInvoice.chargeCurrency)
    }

    terminateAgreement() {
        var row;
        for (var i = 0; i < this.props.billingPlans.length; i++) {
            if (this.props.billingPlans[i].statusID === 2) { // they can only have one active billing plan
                row = this.props.billingPlans[i];
                break;
            }
        }

        var content = getMessageTerminatePlan();
        
        swal({
            title: "AC Admin", 
            text: "", 
            content: content,
            icon: "warning",
            buttons:
            {
                no: {
                    text: "No",
                    value: "no"
                },
                yes: {
                    text: "Yes",
                    value: "yes"
                }
            }
        }).then((value) => {
            switch (value) {
                case "yes":
                    swal({
                        title: "AC Admin", text: "Are you sure? This process is irreversible.", icon: "warning",
                        buttons:
                        {
                            no: {
                                text: "No",
                                value: "no"
                            },
                            yes: {
                                text: "Yes",
                                value: "yes"
                            }
                        }
                    }).then((value) => {
                        //console.log(value)
                        switch (value) {
                            case "yes":
                                var bp = this.getBillingPlan();
                                if (bp === null) { bp = this.getPendingBillingPlanObj() };
                                if (bp === null) {
                                    toaster.notify("Could not locate Billing Plan. Please go to Client Details page to Terminate this Billing Plan", { duration: 4000 }); return;
                                }
                                bp.statusID = 4;
                                this.props.cancelBillingPlan(this.props.logintoken, bp)
                                sleep(1500).then(() => {
                                    this.refreshActionPage()
                                    //toaster.notify("Billing Plan Terminated", { duration: 4000 });
                                })
                                break;
                            default:
                                break
                        }
                    })
                    break
                case "no":
                    break;
                default:
                    break;
            }
        });


    }

    requestManualInvoice() {
        // TODO: Popup form to specify coach, client, location of both
        // # of sessions OR total billing amount, payment method, due date
        // Option 1: pause and request resumption w/date (same as pauseAgreement)
        // Option 2: terminate (terminateAgreement when client pays)
        // call requestManualInvoice() after setting new billingamount on bp, notes include description (affiliate, contact, locations)
        var currency = "USD";
        if (this.props.currentContact.addresses && this.props.currentContact.addresses.length > 0) {
            var cntry = this.props.currentContact.addresses[0].address.country;
            if (cntry === "Canada") {
                currency = "CAD";
            }
        }
        this.props.getEmptyInvoice(this.props.logintoken, this.props.currentContact.contactID, this.props.currentContact.affiliateID, 5, currency);
        // Get active billing plan
        var row;
        for (var i = 0; i < this.props.billingPlans.length; i++) {
            if (this.props.billingPlans[i].statusID === 2) { // they can only have one active billing plan
                row = this.props.billingPlans[i];
                break;
            }
        }
        this.setState({ showBillingPlan: false, showInvoiceForm: true });

    }

    // Modify Profile
    editProfile() {
        // Popup the user profile
        this.props.startEditProfile();
    }

    // Add/Edit logo
    uploadLogo() {
        // dialog to upload logo. Just the profile for now.


    }
    
    
    onHideIFrame() {
        this.setState({ showIFrame: false });
        sleep(50).then(() => {
            document.location = "/action";
        })
    }

    onClickHideTemplate() {
        this.setState({ editTemplate: false });
    }

    onClickUnselectUser = () => {
        this.props.showEditForm(null)
    }

    onClickHideBillingPlan() {
        this.setState({ showBillingPlan: false });
    }

    frameload() {
        //console.log("iframe loaded");
    }

    enterPressed(event) {
        var code = event.keyCode || event.which;
        if (code === 13) {
            this.search();
        }
    }

    setSearch(value) {
        this.setState({search: value});
    }

    search() {
        if (this.state.search.length < 2) {
            toaster.notify("Need at least 2 letters to Search...", { duration: 4000 });
            return;
        }
        else {
            this.props.searchContacts(this.props.logintoken, this.props.user.id, this.state.search);
        }
    }

    closeInvoiceRequestModal() {
        // console.log('closeInvoiceRequestModal');
        this.setState({ openInvoiceRequestModal : false })
    }

    render() {

        return (
            <div className="actionsView">
                <div className={this.props.currentContact && this.props.currentContact.contactID ? "actions-contact" : "actions-contact dangerText"}>
                    <b>{this.props.currentContact && this.props.currentContact.contactID ? this.props.currentContact.firstName + " " + this.props.currentContact.lastName : ""}</b>
                    {this.props.currentContact && this.props.currentContact.contactID ? " selected." : 
                    (<>
                        <b>Search contact:</b>
                        <input type="text" id="contact-search" className="search-box-input" placeholder={window.innerWidth > 500 ? "Contact Search" : "Search"}
                        onChange={(e) => this.setSearch(e.target.value)}
                        onKeyPress={this.enterPressed}/>
                        <span className="search-box-icon">
                            <FontAwesomeIcon onClick={this.search} icon="search" transform="grow-0" />
                        </span>
                    </>)
                    }
                    {(this.props.currentContact && this.props.currentContact.contactID != 0) && (<div><span onClick={this.onClickUnselectUser} className="unselect-client-button"><span>&#10006;</span>Unselect Contact</span></div>)}
                </div>
                <span className="action-form">
                    <div id="contacts" className="small-table">
                        <Row className="dashboard-header" sm={8}><FontAwesomeIcon className="dashboardIcon" icon="user" color="white" />&nbsp;Contacts</Row>
                        <Row className="dashboard-body" sm={8}>
                            <Col sm={12}><Button className="button-gridrow button-action makeActionButton" onClick={this.createContact}>CREATE CONTACT</Button></Col>
                        </Row>
                        <Row className="dashboard-body" sm={8}>
                            <Col sm={12}><Button disabled={!this.props.currentContact || !this.props.currentContact.firstName} className="button-gridrow button-action makeActionButton" onClick={this.editContact}>EDIT CONTACT
                            {(!this.props.currentContact || !this.props.currentContact.firstName) && (
                                    <span className="tooltiptext">Select a Contact to enable this Action</span>
                                )}
                            </Button></Col>
                        </Row>
                        <Row className="dashboard-body dashboard-footer" sm={8}>
                            <Col sm={12}></Col>
                        </Row>
                        <Row className="dashboard-body dashboard-footer" sm={8}>
                            <Col sm={12}></Col>
                        </Row>

                    </div>

                    <div id="invoices" className="small-table">
                        <Row className="dashboard-header" sm={8}><FontAwesomeIcon className="dashboardIcon" icon="file-alt" color="white" />&nbsp;Client Agreements</Row>
                        <Row className="dashboard-body" sm={8}>
                            <Col sm={12}><Button disabled={(!this.props.currentContact || !this.props.currentContact.firstName || this.props.user.id !== this.props.sysUser.id || !this.isMyContact() || this.getActiveBillingPlan() || this.getPendingBillingPlan())} className="button-gridrow button-action makeActionButton" onClick={this.createAgreement}>CREATE AGREEMENT
                            {(!this.props.currentContact || !this.props.currentContact.firstName || !(this.props.sysUser.id === this.props.user.id)) && (
                                    <span className="tooltiptext">Select a Contact to enable this Action</span>
                                )}
                                {this.getPendingBillingPlan() > 0 
                                    ? <span className="tooltiptext">Terminate <i><b>Pending</b> Billing Plan</i> to create New Agreement</span>
                                    : <></>
                                }
                                {this.getActiveBillingPlan() > 0
                                    ? <span className="tooltiptext">Only One (1) Active Agreement is allowed per Client</span>
                                    : <></>
                                }
                            </Button></Col>
                        </Row>
                        <Row className="dashboard-body" sm={8}>
                            <Col sm={12}>
                                <OverlayTrigger
                                    placement="top"
                                    trigger="hover"
                                    overlay={<Tooltip>
                                        Use Cancel and Reissue when you want to make an adjustment to an Active or Pending Agreement.  For example: use this feature to change a client's payment method from "auto-pay" to "receive actionable invoices."
                                    </Tooltip>}
                                >
                                    <Button disabled={(!this.props.currentContact || !this.props.currentContact.firstName || (!this.getActiveBillingPlan() && !this.getPendingBillingPlan()))}
                                        className="button-gridrow button-action makeActionButton"
                                        onClick={this.editAgreement}
                                    >
                                        CANCEL AND REISSUE AGREEMENT
                                        {(!this.props.currentContact || !this.props.currentContact.firstName)
                                            ?
                                            <span className="tooltiptext">Select a Contact with a signed or pending Agreement to enable this Action</span>
                                            :
                                            (!this.props.currentContact || !this.props.currentContact.firstName || (!this.getActiveBillingPlan() && !this.getPendingBillingPlan())) && (
                                                <span className="tooltiptext">Cancel and Reissue Agreement can only be used for Active Clients with a Signed Agreement or Clients with Pending Agreements</span>
                                        )}
                                    </Button>
                                </OverlayTrigger>
                           </Col>
                        </Row>
                        <Row className="dashboard-body dashboard-footer" sm={8}>
                            <Col sm={12}>
                                <OverlayTrigger
                                    placement="top"
                                    trigger="hover"
                                    overlay={<Tooltip>
                                        Use Create Custom Language when you want to Request a customized Client Services Agreement "Template" with added and/or modified language.
                                    </Tooltip>}
                                >
                                    <Button disabled={((!this.props.currentContact || !this.props.currentContact.firstName) && this.props.user.userRole < 2)} className="button-gridrow button-action makeActionButton" onClick={this.modifyLanguage}>
                                        CREATE CUSTOM LANGUAGE
                                    {((!this.props.currentContact || !this.props.currentContact.firstName) && this.props.user.userRole < 2) && (
                                        <span className="tooltiptext">Select a Contact to enable this Action</span>
                                    )}
                                    </Button>
                                </OverlayTrigger>
                            </Col>
                        </Row>
                        <Row className="dashboard-body dashboard-footer" sm={8}>
                            <Col sm={12}>
                                <OverlayTrigger
                                    placement="top"
                                    trigger="hover"
                                    overlay={<Tooltip>
                                        {this.getActiveBillingPlan() ? 'Re-Send Active Client Services Agreement PDF' : 'Re-Send Pending Client Services Agreement'}
                                    </Tooltip>}
                                >
                                    <Button disabled={(!this.props.currentContact || !this.props.currentContact.firstName || (!this.getActiveBillingPlan() && !this.getPendingBillingPlan()) || (this.getActiveBillingPlan() && !this.getBillingPlan().CSAFileName))} className="button-gridrow button-action makeActionButton"
                                        onClick={ () => this.getActiveBillingPlan() ? this.toggleShowResendCSA() : this.editAgreement(true) }
                                    >
                                        {this.getActiveBillingPlan() ? 'RE-SEND ACTIVE CLIENT AGREEMENT PDF' : 'RE-SEND PENDING AGREEMENT' }
                                        {(!this.props.currentContact || !this.props.currentContact.firstName || (!this.getActiveBillingPlan() && !this.getPendingBillingPlan()) || (this.getActiveBillingPlan() && !this.getBillingPlan().CSAFileName))
                                            ? <span className="tooltiptext">Select a Contact with a signed or pending Agreement to enable this Action</span> : <></>
                                        }
                                    </Button>
                                </OverlayTrigger>
                            </Col>
                        </Row>


                    </div>

                    <div id="revenue" className="small-table">
                        <Row className="dashboard-header" sm={8}><FontAwesomeIcon className="dashboardIcon" icon="tools" color="white" />&nbsp;Tools</Row>
                        <Row className="dashboard-body" sm={8}>
                            <Col sm={12}><Button className="button-gridrow button-action makeActionButton" onClick={this.feeCalculator}>FEE CALCULATOR
                            {(!this.props.currentContact || !this.props.currentContact.firstName) && (
                                    <span className="tooltiptext">Select a Contact to enable this Action</span>
                                )}
                            </Button></Col>
                        </Row>
                        <Row className="dashboard-body" sm={8}>
                            <Col sm={12}></Col>
                        </Row>
                        <Row className="dashboard-body dashboard-footer" sm={8}>
                            <Col sm={12}></Col>
                        </Row>
                        <Row className="dashboard-body dashboard-footer" sm={8}>
                            <Col sm={12}></Col>
                        </Row>
                    </div>

                    <div id="potential" className="small-table">
                        <Row className="dashboard-header" sm={8}><FontAwesomeIcon className="dashboardIcon" icon="bullhorn" color="white" />&nbsp;Marketing</Row>
                        
                        <Row className="dashboard-body" sm={8}>
                            <Col sm={12}><Button disabled={!this.isContactSelected() && (this.props.user.id !== this.props.sysUser.id && !this.isMyContact())} className="button-gridrow button-action makeActionButton" onClick={this.toggleShowSIP}>SEND INFORMATION PACKET
                            {!this.isContactSelected() && (this.props.user.id !== this.props.sysUser.id && !this.isMyContact()) && (
                                    <span className="tooltiptext">Stop impersonating to enable this Action</span>
                                )}
                            </Button></Col>
                        </Row>
                        <Row className="dashboard-body" sm={8}>
                            <Col className="hidden" sm={12}><Button disabled={!this.isContactSelected() && (this.props.user.id !== this.props.sysUser.id && !this.isMyContact())} className="button-gridrow button-action makeActionButton" onClick={this.sendInformationPacket}>SEND INFORMATION PACKET
                            {!this.isContactSelected() && (this.props.user.id !== this.props.sysUser.id && !this.isMyContact()) && (
                                    <span className="tooltiptext">Stop impersonating to enable this Action</span>
                                )}
                            </Button></Col>
                        </Row>
                        <Row className="dashboard-body dashboard-footer" sm={8}>
                            <Col sm={12}></Col>
                        </Row>
                        <Row className="dashboard-body dashboard-footer" sm={8}>
                            <Col sm={12}></Col>
                        </Row>
                    </div>

                    <div id="clientMgmt" className="medium-table">
                        <Row className="dashboard-header" sm={8}><FontAwesomeIcon className="dashboardIcon" icon="users" color="white" />&nbsp;Client Management</Row>
                        <Row className="dashboard-body" sm={8}>
                            <Col sm={12}><Button disabled={(!this.props.currentContact || !this.props.currentContact.firstName || this.props.user.id !== this.props.sysUser.id || !this.getActiveBillingPlan())}
                                className="button-gridrow button-action makeActionButton" onClick={this.pauseAgreement}>{this.getActiveBillingPlan() && this.getBillingPlan().statusID === 3 ? "UN" : ""}PAUSE AGREEMENT
                            {(!this.props.currentContact || !this.props.currentContact.firstName || !(this.props.sysUser.id === this.props.user.id)) && (
                                    <span className="tooltiptext">Select a Contact to enable this Action</span>
                                )} {(!this.getActiveBillingPlan() && this.props.currentContact) && (
                                    <span className="tooltiptext">Must have an Active or Paused Billing Plan to use this Feature</span>
                                )}
                                {(this.props.currentContact && this.props.currentContact.firstName && this.getActiveBillingPlan())
                                    ? <span className="tooltiptext">{this.getBillingPlan().statusID === 3 ? 'Un' : ''}Pause {this.props.currentContact.firstName}&apos;s Active Billing Plan</span>
                                    : <></>
                                }
                            </Button></Col>
                        </Row>
                        <Row className="dashboard-body" sm={8}>
                            <Col sm={12}><Button disabled={(!this.props.currentContact || !this.props.currentContact.firstName || (!this.getActiveBillingPlan() && !this.getPendingBillingPlan()))}
                                className="button-gridrow button-action makeActionButton" onClick={this.terminateAgreement}>TERMINATE AGREEMENT
                            {(!this.props.currentContact || !this.props.currentContact.firstName) && (
                                    <span className="tooltiptext">Select a Contact to enable this Action</span>
                                )} {!this.getActiveBillingPlan() && !this.getPendingBillingPlan() && this.props.currentContact && (
                                    <span className="tooltiptext">Must have an Active, Paused or Pending Billing Plan to use this Feature</span>
                                )}
                                {(this.props.currentContact && this.props.currentContact.firstName && (this.getActiveBillingPlan() || this.getPendingBillingPlan()))
                                    ? <span className="tooltiptext">Terminate {this.props.currentContact.firstName}&apos;s {this.getPendingBillingPlan() ? 'Pending' : 'Active'} Billing Plan</span>
                                    : <></>
                                }
                            </Button></Col>
                        </Row>
                        <Row className="dashboard-body" sm={8}>
                            <Col sm={12}><Button disabled={((!this.props.currentContact || !this.props.currentContact.firstName || !this.getActiveBillingPlan() ) )}
                                    className="button-gridrow button-action makeActionButton" onClick={this.requestManualInvoice}>REQUEST MANUAL INVOICE
                            {((!this.props.currentContact || !this.props.currentContact.firstName)) && (
                                    <span className="tooltiptext">Select a Contact to enable this Action</span>
                                )} {!this.getActiveBillingPlan() && this.props.currentContact && (
                                    <span className="tooltiptext">Must have an Active or Paused Billing Plan to use this Feature</span>
                                )}
                                {(this.props.currentContact && this.props.currentContact.firstName && this.getActiveBillingPlan())
                                    ? <span className="tooltiptext">Request a different billing amount and (optionally) Pause or Terminate {this.props.currentContact.firstName}&apos;s Active Agreement</span>
                                    : <></>
                                }

                            </Button></Col>
                        </Row>

                        <Row className="dashboard-body dashboard-footer" sm={8}>
                            <Col sm={12}><Button disabled={!this.props.currentContact || !this.props.currentContact.firstName || (this.props.sysUser.userRole < 3 && this.props.currentContact.clientStatus.toUpperCase() == 'NONE')} className="button-gridrow button-action makeActionButton" onClick={this.clientDetails}>CLIENT DETAILS
                            {(!this.props.currentContact || !this.props.currentContact.firstName || (this.props.sysUser.userRole < 3 && this.props.currentContact.clientStatus.toUpperCase() == 'NONE')) && (
                                    <span className="tooltiptext">Select a Client to enable this Action</span>
                                )}
                            </Button></Col>
                        </Row>

                        <Row className='dashboard-body dashboard-footer' sm={8}>
                            <Col sm={12}>
                                <Button className="button-gridrow button-action makeActionButton" onClick={() => {
                                    this.setState({
                                        openInvoiceRequestModal: true,
                                    })
                                }} disabled={!this.props.currentContact || !this.props.currentContact.contactID}>VOID INVOICE / PAUSE AUTO-PAY</Button>
                            </Col>
                        </Row>

                    </div>

                    <div id="myprofile" className="small-table">
                        <Row className="dashboard-header" sm={8}><FontAwesomeIcon className="dashboardIcon" icon="user-circle" color="white" />&nbsp;My Profile</Row>
                        <Row className="dashboard-body" sm={8}>
                            <Col sm={12}><Button className="button-gridrow button-action makeActionButton" onClick={this.editProfile}>MODIFY PROFILE</Button></Col>
                        </Row>
                        <Row className="dashboard-body" sm={8}>
                            <Col sm={12}><Button className="button-gridrow button-action makeActionButton" onClick={this.editProfile}>ADD/EDIT LOGO</Button></Col>
                        </Row>
                        <Row className="dashboard-body dashboard-footer" sm={8}>
                            <Col sm={12}></Col>
                        </Row>
                        <Row className="dashboard-body dashboard-footer" sm={8}>
                            <Col sm={12}></Col>
                        </Row>
                    </div>
                </span>
                {this.props.logintoken && this.state.showIFrame && (
                    <Modal
                        width="100%"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        dialogClassName="modal-popup"
                        show={true}
                        className="iFrameModalSelector"
                        onHide={this.onHideIFrame}>
                        <Modal.Body >
                            {this.state.iFrameUrl.includes("FeeCalculator.aspx") && "Fee Calculator"}
                            {this.state.iFrameUrl.includes("SendInformationPacket.aspx") && "Send Information Packet"}
                            {this.state.iFrameUrl.includes("CoachingAgreementTemplateEditor.aspx") && "Modify Language"}
                            {this.state.iFrameUrl.includes("SetupBillingPlan.aspx?ContactId=") && "Create Agreement"}
                            <span className="material-icons closeIframeIcon" onClick={this.onHideIFrame}>clear</span>
                            {/* {this.iFrameGifLoader()} */}
                            <Iframe url={this.state.iFrameUrl + "&t=" + this.props.logintoken}
                                width="100%"
                                height="100%"
                                id="aspNetSite"
                                position="inherit"
                                className="aspNetSelector"
                                onload={this.frameload()}
                            />
                            {/* <Button onClick={this.getIframeCloseSelector}>
                            Close
                            </Button> */}
                        </Modal.Body>
                    </Modal>
                )}

                {this.props.logintoken && this.state.editTemplate && (

                    <Modal
                        {...this.props}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        className="modal-popup"
                        show={this.state.editTemplate}
                        onHide={this.onClickHideTemplate}
                    >
                        <Modal.Body>
                            <TemplateViewer currentContact={this.props.currentContact && this.props.currentContact.contactID} show={true} hideTemplate={this.onClickHideTemplate} />
                        </Modal.Body>
                    </Modal>
                )}

                {this.props.logintoken && this.state.showBillingPlan && (
                    <Modal
                        {...this.props}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        className={"modal-popup pauseBillingPlanModalContainerv2"}
                        show={this.state.showBillingPlan}
                        onHide={this.onClickHideBillingPlan}
                        style={{width: '80%', marginLeft: '10%', marginTop: '10%', marginRight: '10%', padding: '20px'}}
                    >                        
                        <PauseBillingPlan showInvoiceForm={false} invoice={this.props.emptyInvoice}
                            currentContact={this.props.currentContact && this.props.currentContact.contactID}
                            bp={this.getBillingPlan()} deactivateBP={this.deactivateBP}
                            terminateBP={this.terminateAgreement}
                            show={true} hideForm={this.onClickHideBillingPlan} />                        
                    </Modal>
                )}

                {this.props.logintoken && this.state.showInvoiceForm && (
                    <Modal
                        {...this.props}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        dialogClassName="modal-cleanup"
                        className={"modal-popupPauseRequest modal-cleanup"}
                        show={this.state.showInvoiceForm}
                        onHide={this.onClickHideBillingPlan}
                    >
                        {this.state.showInvoiceForm && (
                            <><RequestManualInvoiceForm hideButtons={true}
                                invoice={this.props.emptyInvoice}
                                currentContact={this.props.currentContact && this.props.currentContact.contactID}
                                bp={this.getBillingPlan()} deactivateBP={this.deactivateBP}
                                terminateBP={this.terminateAgreement}
                                show={true} hideForm={this.onClickHideBillingPlan}
                              /> <br /></>
                        )}
                    </Modal>
                )}
                {this.props.logintoken && this.state.showResendCSA && (
                <Modal
                    dialogClassName="modalDialog"
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                        show={this.state.showResendCSA}
                        onHide={this.toggleShowResendCSA}
                    style={{ height: 'auto', textAlign: 'center' }}
                >
                    <Modal.Body className="modalBody">
                        <div>
                            <div style={{ marginTop: '3px', marginBottom: '-13px', fontWeight: '800' }}>
                                <label>Email</label></div>

                            <MediaQuery maxWidth={875}>
                                <input style={{ width: '200px', textAlign: 'center', marginTop: '13px', borderRadius: '17px', height: '43px', fontSize: '18px' }}
                                        id="csaEmail"
                                        name="csaEmail"
                                        disabled="true"
                                        type="text"
                                        value={this.state.csaEmail}
                                        defaultValue={this.props.currentContact.email}
                                        onChange={this.handleChange}
                                        onKeyPress={this.enterResendCSA.bind(this)}
                                        autoComplete="offrandomhacktext" 
                                    centered
                                />
                            </MediaQuery>

                            <MediaQuery minWidth={876}>
                                <input style={{ width: '400px', textAlign: 'center', marginTop: '13px', borderRadius: '17px', height: '43px', fontSize: '18px' }}
                                        id="csaEmail"
                                        name="csaEmail"
                                        disabled="true"
                                        type="text"
                                        value={this.state.csaEmail}
                                        onChange={this.handleChange}
                                        onKeyPress={this.enterResendCSA.bind(this)}
                                        autoComplete="offrandomhacktext" 
                                    centered
                                />
                            </MediaQuery>

                            <div style={{ marginTop: '26px', marginBottom: '-60px', fontSize: '14px', fontStyle: 'italic', fontWeight: '600' }}>
                                    <label>Clicking 'Send' will re-send the most recent Client Agreement that was sent to this Contact. If you need to change details on the Client Agreement before sending, please press 'Cancel', and then choose 'Cancel and Re-Issue Agreement' from the Client Agreement options. </label></div>

                        </div>
                        <br /><br /><br /><br />
                        <button className={'reset-password-button'} style={{ borderRadius: '13px' }} onClick={this.resendCSA}>SEND</button>
                        &nbsp;
                                <button className="cancel-button" onClick={this.toggleShowResendCSA}>CANCEL</button>
                    </Modal.Body>
                </Modal>
                )}

                {this.props.logintoken && this.state.showSIP && (
                    <Modal
                        dialogClassName="modalDialog2"
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                        show={this.state.showSIP}
                        onHide={this.toggleShowSIP}
                        style={{ height: 'auto', textAlign: 'center' }}
                    >
                        <Modal.Body className="modalBody2">
                            <div>
                                <div style={{ marginTop: '3px', marginBottom: '-13px', fontWeight: '800'}}>
                                    <div style={{ marginTop: '3px', marginBottom: '53px', fontWeight: '800', fontSize: '26px' }}>
                                        <label>Send Information Packet<br /></label>
                                    </div>
                                <div style={{ marginTop: '3px', marginBottom: '53px', fontWeight: '800' }}>
                                        <label>Fill out the fields and click the 'send' button to send out an information packet to:</label>
                                    </div>
                                </div>

                                <MediaQuery maxWidth={875}>
                                    <div style={{ marginTop: '13px', marginBottom: '-21px', fontWeight: '800' }}><label>First Name</label></div>
                                    <input style={{ width: '300px', textAlign: 'center', marginTop: '13px', borderRadius: '17px', height: '43px', fontSize: '18px' }}
                                        id="sipFN"
                                        name="sipFN"
                                        type="text"
                                        onChange={this.handleChange}
                                        autoComplete="offrandomhacktext" 
                                        defaultValue={this.state.sipFN}
                                        //onKeyPress={this.enterSIP.bind(this)}
                                        ref={this.sipFNRef}
                                        centered
                                    />
                                    <div style={{ marginTop: '13px', marginBottom: '-21px', fontWeight: '800' }}><label>Last Name</label></div>
                                    <input style={{ width: '300px', textAlign: 'center', marginTop: '13px', borderRadius: '17px', height: '43px', fontSize: '18px' }}
                                        id="sipLN"
                                        name="sipLN"
                                        type="text"
                                        onChange={this.handleChange}
                                        autoComplete="offrandomhacktext" 
                                        defaultValue={this.state.sipLN}
                                        ref={this.sipLNRef}
                                        //onKeyPress={this.enterSIP.bind(this)}
                                        centered
                                    />
                                    <div style={{ marginTop: '13px', marginBottom: '-21px', fontWeight: '800' }}><label>Email</label></div>
                                    <input style={{ width: '300px', textAlign: 'center', marginTop: '13px', borderRadius: '17px', height: '43px', fontSize: '18px' }}
                                        id="sipEmail"
                                        name="sipEmail"
                                        type="text"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.sipEmail}
                                        onKeyPress={this.enterSIP.bind(this)}
                                        autoComplete="offrandomhacktext" 
                                        ref={this.sipEmailRef}
                                        centered
                                    />
                                </MediaQuery>

                                <MediaQuery minWidth={876}>
                                    <div style={{ marginTop: '13px', marginBottom: '-21px', fontWeight: '800' }}><label>First Name</label></div>
                                    <input style={{ width: '400px', textAlign: 'center', marginTop: '13px', borderRadius: '17px', height: '43px', fontSize: '18px' }}
                                        id="sipFN"
                                        name="sipFN"
                                        type="text"
                                        onChange={this.handleChange}
                                        defaultValue={this.state.sipFN}
                                        autoComplete="offrandomhacktext" 
                                        ref={this.sipFNRef}
                                        //onKeyPress={this.enterSIP.bind(this)}
                                        centered
                                    />
                                    <div style={{ marginTop: '13px', marginBottom: '-21px', fontWeight: '800' }}><label>Last Name</label></div>
                                    <input style={{ width: '400px', textAlign: 'center', marginTop: '13px', borderRadius: '17px', height: '43px', fontSize: '18px' }}
                                        id="Last Name"
                                        name="Last Name"
                                        type="text"
                                        defaultValue={this.state.sipLN}
                                        onChange={this.handleChange}
                                        autoComplete="offrandomhacktext" 
                                        ref={this.sipLNRef}
                                        //onKeyPress={this.enterSIP.bind(this)}
                                        centered
                                    />
                                    <div style={{ marginTop: '13px', marginBottom: '-21px', fontWeight: '800' }}><label>Email</label></div>
                                    <input style={{ width: '400px', textAlign: 'center', marginTop: '13px', borderRadius: '17px', height: '43px', fontSize: '18px' }}
                                        id="Email"
                                        name="Email"
                                        type="text"
                                        defaultValue={this.state.sipEmail}
                                        onChange={this.handleChange}
                                        onKeyPress={this.enterSIP.bind(this)}
                                        autoComplete="offrandomhacktext" 
                                        ref={this.sipEmailRef}
                                        centered
                                    />
                                </MediaQuery>

                                <div style={{ marginTop: '46px', marginBottom: '-60px', fontSize: '14px', fontStyle: 'italic', fontWeight: '600' }}>
                                    <label>* This will send an email to the contact and a copy to your email address</label></div>

                            </div>
                            <br /><br /><br /><br />
                            <button className={'reset-password-button'} style={{ borderRadius: '13px' }} onClick={this.sendInfoPacket}>SEND</button>
                        &nbsp;
                                <button className="cancel-button" onClick={this.toggleShowSIP}>CANCEL</button>
                        </Modal.Body>
                    </Modal>
                )}

                {this.props.currentContact && this.props.currentContact.contactID != 0 && (
                    <InvoiceRequestModal closeModal={this.closeInvoiceRequestModal} openModal={this.state.openInvoiceRequestModal} />
                )}

            </div>
        );

    }

}

function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        sysUser: state.loginReducer.sysUser,
        data: state.financeReducer.dashboard,
        currentContact: state.contactReducer.currentContact,
        billingPlans: state.financeReducer.billingPlans,
        emptyInvoice: state.financeReducer.emptyInvoice,
        paymentMethods: state.sysReducer.paymentMethods,
        show: state.contactReducer.showEditForm,
    }
}

export default connect(mapStateToProps, {
    startEditProfile, showEditForm, showClientDetails, hideNavbar, getEmptyInvoice, getBillingPlans, cancelBillingPlan, deactivateBillingPlan,
    requestManualInvoice, activateBillingPlan, resendCSA_PDF, SIP, searchContacts
})(Action);
