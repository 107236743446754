import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { selectUser, showEditUserForm, sendResetPasswordEmail } from '../../../hooks/store/actions/loginActions';
import { sleep } from '../../../hooks/store/actionConsts';
import toaster from 'toasted-notes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class UserRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.onClickRow = this.onClickRow.bind(this);
        this.onClickImpersonate = this.onClickImpersonate.bind(this);
        this.onClickResetPassword = this.onClickResetPassword.bind(this);
    }

    onClickRow(j) {
        this.props.selectUser(j, false);
        this.props.showEditUserForm(true);
    }

    onClickImpersonate(j) {
        // This needs to set the current affiliate to the selected user
        // While keeping the "site" user as the current user

        this.props.selectUser(j, true);
    }

    onClickResetPassword(j) {
        console.log(j);
        const name = j.firstName + " " + j.lastName;
        swal({
            title: "", 
            text: "Are you sure you want to reset the password for the user " + name  +" ?",
            icon: "warning",
            buttons:
                {
                    no: {
                        text: "No",
                        value: "no"
                    },
                    yes: {
                        text: "Yes",
                        value: "yes"
                    }
                }
        }).then((value) => {
            switch (value) {
                case "yes":
                    this.props.sendResetPasswordEmail(j.email, true);
                    toaster.notify("Email sent");
                    break;
                case "no":
                    console.log('cancel');
                    break;
                default:
                    break;
            }
        });
    }

    render() {
        return (
            this.props.users.map(
                (j, r) =>

                    <Row className={r % 2 === 0 ? "grid-row row-white settingsTableScroll notranslate" : "grid-row row-gray settingsTableScroll notranslate"} key={j.userID}>
                        <Col className={!j.isActive ? "label-darkred centerUsersCol" : "centerUsersCol"} xs={1} sm={1}><span className="graphTextFront">{j.firstName}</span><div className="borderMarkerCol"></div></Col>
                        <Col className={!j.isActive ? "label-darkred centerUsersCol" : "centerUsersCol"} xs={1} sm={2}><span className="graphTextFront">{j.lastName}</span><div className="borderMarkerCol"></div></Col>
                        <Col className={!j.isActive ? "label-darkred centerUsersCol" : "centerUsersCol"} xs={2} sm={2}><span className="graphTextFront">{j.email}</span><div className="borderMarkerCol"></div></Col>
                        <Col className={!j.isActive ? "label-darkred centerUsersCol" : "centerUsersCol"} xs={1} sm={1}><span className="graphTextFront">{j.phone}</span><div className="borderMarkerCol"></div></Col>
                        <Col className={!j.isActive ? "label-darkred centerUsersCol" : "centerUsersCol"} xs={2} sm={2}><a className="logoURLText" href={j.logoURL} alt="" >{j.logoURL}</a><div className="borderMarkerCol"></div></Col>
                        <Col className={!j.isActive ? "label-darkred centerUsersCol cobrandingCenter" : "centerUsersCol"} xs={1} sm={1}><span className="cobrandingCenter">{j.includeLogoURL ? "Yes" : "No"}</span><div className="borderMarkerCol"></div></Col>
                        <Col xs={1} sm={1} className={j.userRole > 2 ? "label-green centerUsersCol" : "centerUsersCol"}>{j.userRole <= 2 ? "Affiliate" : (j.userRole === 3 ? "Office Staff" : "Finance")}</Col>
                        <Col xs={3} sm={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Row>
                                <Col className="viewEditButtonEdit"><Button translate="yes"className="button-gridrow viewEditButtonStyle" onClick={() => this.onClickRow(j)}>EDIT</Button></Col>
                                <Col className="viewEditButtonEdit"><Button translate="yes" className={(this.props.sysUser.userRole === 8 || this.props.sysUser.userRole === 37) || (j.userRole !== 8 && j.userRole !== 37) ? 'button-gridrow viewEditButtonStyle' : 'hidden'} onClick={() => this.onClickImpersonate(j)}>IMPERSONATE</Button></Col>
                                <Col className="viewEditButtonEdit"><FontAwesomeIcon onClick={() => this.onClickResetPassword(j)} className={(this.props.sysUser.userRole === 8 || this.props.sysUser.userRole === 37) || (j.userRole !== 8 && j.userRole !== 37) ? 'itstherightone' : 'hidden'} transform="shrink-1" icon="key" /></Col>
                            </Row>
                        </Col>
                    </Row>
            )
        );
    }

}

function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        sysUser: state.loginReducer.sysUser,
        selectedUser: state.loginReducer.selectedUser
    }
}

export default connect(mapStateToProps, { selectUser, showEditUserForm, sendResetPasswordEmail })(UserRow);
