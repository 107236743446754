import toaster from 'toasted-notes';
import { contactActionTypes, pending, rejected, fulfilled, serverEnvironment } from '../actionConsts.js';

export default function reducer(state = {
    contacts: [],
    searchResults: [],
    userContact: {},
    currentAddress: {},
    currentContact: {},
    personalData: {},
    showEditForm: false,
    emptyContact: { id: 0 },
    showAddressForm: false,
    showEditUserContact: false,
    showLoadingGif: false,
    showSearchResults: false,
    message: ''
}, action) {

    var contact = {};
    var userContact = {};
    var n = 0;
    switch (action.type) {
        case fulfilled(contactActionTypes.UPDATE_ADDRESS):
            contact = state.currentContact;
            
            if (action.payload && contact && action.payload.contactID === contact.contactID) {
                if (action.payload.address.addressID === 0) {
                    var alreadyAdded = contact.addresses.some(addr => addr.address.addressID === 0);
                    if (!alreadyAdded) {
                        contact.addresses.push(action.payload);
                    }
                }
                for (n = 0; n < contact.addresses.length; n++) {
                    if (action.payload.address.addressID === contact.addresses[n].address.addressID) {
                        contact.addresses[n] = action.payload;
                        break;
                    }
                }

            }
            return {
                ...state,
                currentAddress: action.payload,
                currentContact: contact,
                personalData: contact.personalData,
                message: "Address form updated"
            }
        case fulfilled(contactActionTypes.DELETE_ADDRESS):
            contact = state.currentContact;
            var add = [];

            if (contact && action.payload.contactID === contact.contactID) {
                for (n = 0; n < contact.addresses.length; n++) {
                    if (action.payload.address.addressID !== contact.addresses[n].address.addressID) {
                        add.push(contact.addresses[n]);                        
                    }
                }
                contact.addresses = add;
            }
           
            return {
                ...state,
                currentContact: contact,
                userContact: userContact,
                personalData: contact.personalData,
                showAddressForm: true,
                message: "Added address"
            }
        case fulfilled(contactActionTypes.SHOW_ADDRESS_FORM):
            return {
                ...state,
                showAddressForm: action.payload,
                message: ""
            }
        case pending(contactActionTypes.GET_CONTACT_BY_USER):
            return {
                ...state,
                showLoadingGif: true,
                message: "Fetching contact..."
            }
        case fulfilled(contactActionTypes.GET_CONTACT_BY_USER):
            return {
                ...state,
                contacts: action.payload.data,
                showLoadingGif: false,
                message: ""
            }
        case rejected(contactActionTypes.GET_CONTACT_BY_USER):
            return {
                ...state,
                showLoadingGif: false,
                message: action.payload.message
            }
        case pending(contactActionTypes.GET_CONTACT):
            return {
                ...state,
                message: "Fetching contact..."
            }
        case fulfilled(contactActionTypes.GET_CONTACT):
            return {
                ...state,
                userContact: action.payload.data,
                message: ""
            }
        case rejected(contactActionTypes.GET_CONTACT):
            return {
                ...state,
                message: action.payload.message
            }
        case pending(contactActionTypes.SEARCH_CONTACT):
            return {
                ...state,
                showLoadingGif: true, 
                message: "Searching contact..."
            }
        case fulfilled(contactActionTypes.SEARCH_CONTACT):
            return {
                ...state,
                searchResults: action.payload.data,
                showEditForm: false,
                showSearchResults: true,
                showLoadingGif: false,
                message: ""
            }
        case rejected(contactActionTypes.SEARCH_CONTACT):
            return {
                ...state,
                showSearchResults: false,
                showLoadingGif: false,
                message: action.payload.message
            }
        case pending(contactActionTypes.SEND_CONTACT_STRIPE_LINK):
            return {
                ...state,
                showLoadingGif: true,
                message: "Sending Email Link to Client..."
            }
        case fulfilled(contactActionTypes.SEND_CONTACT_STRIPE_LINK):
            toaster.notify("Email Link Sent Successfully to Client!", { duration: 4000 });  
            return {
                ...state,
                showLoadingGif: false,
                message: "Email Link Sent Successfully to Client!"
            }
        case rejected(contactActionTypes.SEND_CONTACT_STRIPE_LINK):
            toaster.notify("Email could not be sent - error message: " + action.payload.message, { duration: 4000 });  
            return {
                ...state,
                showLoadingGif: false,
                message: action.payload.message
            }
        case pending(contactActionTypes.INSERT_CONTACT):
            return {
                ...state,
                message: "Writing contact..."
                
            }
        case fulfilled(contactActionTypes.INSERT_CONTACT):
            var item = state.contacts.slice();
            item.push(action.payload.data);
            toaster.notify("Contact was created", { duration: 4000 });  
            return {
                ...state,
                contacts: item,
                currentContact: action.payload.data,
                personalData: action.payload.data.personalData,
                showEditForm: false,
                message: "Wrote contact"
            }
        case rejected(contactActionTypes.INSERT_CONTACT):
            toaster.notify("Contact could not be created.  Check if there is an existing user with this email", { duration: 4000 });  
            return {
                ...state,                
                message: action.payload.message
            }
        case fulfilled(contactActionTypes.SHOW_EDIT_FORM):
            var showEditForm = false;
            if (action.payload && action.payload !== 1) {
                showEditForm = true;
            }
            var currentContact = state.currentContact;
            if (action.payload !== 1) {
                currentContact = action.payload;
            }
            return {
                ...state,
                currentContact: currentContact,
                personalData: currentContact ? currentContact.personalData : {},
                showSearchResults: false,
                showEditForm: showEditForm
            }
        case fulfilled(contactActionTypes.CANCEL_SEARCH):
            return {
                ...state,                
                showSearchResults: false,
            }
        case fulfilled(contactActionTypes.SELECT_CONTACT):
            return {
                ...state,
                showSearchResults: false,
                currentContact: action.payload,
                personalData: action.payload.personalData,
            }
        case pending(contactActionTypes.UPDATE_CONTACT):
            return {
                ...state,
                message: "Updating contact..."
            }
        case fulfilled(contactActionTypes.UPDATE_CONTACT):
            item = state.contacts.slice();
            var currentUserContact = state.userContact;
            if (action.payload.data) {
                for (n = 0; n < item.length; n++) {
                    if (action.payload.data.contactID === item[n].contactID) {
                        var oldItem = item[n];
                        item[n] = action.payload.data;
                        item[n].paymentStatus = oldItem.paymentStatus;
                        item[n].expirationDate = oldItem.expirationDate;                        
                        break;
                    }
                }
            }
            if (action.payload.data.contactID === currentUserContact.contactID) {
                currentUserContact = action.payload.data;
            }
            toaster.notify("Contact was updated", { duration: 4000 });                            
            return {
                ...state,
                contacts: item,
                userContact: currentUserContact,
                message: "Updated contact"
            }
        case rejected(contactActionTypes.UPDATE_CONTACT):
            toaster.notify("Update failed: " + action.payload.message, { duration: 4000 });                                        
            return {
                ...state,
                message: action.payload.message
            }
        case pending(contactActionTypes.DELETE_CONTACT):
            return {
                ...state,
                message: "Deleting contact..."
            }
        case fulfilled(contactActionTypes.DELETE_CONTACT):
            item = [];
            if (action.payload) {
                for (n = 0; n < state.contacts.length; n++) {
                    if (action.payload !== state.contacts[n].contactID) {
                        item.push(state.contacts[n])                        
                    }
                }
            }
            return {
                ...state,     
                contacts: item,
                message: "Deleted contact"
            }
        case rejected(contactActionTypes.DELETE_CONTACT):
            return {
                ...state,
                message: action.payload.message
            }
        case pending(contactActionTypes.SAVE_ICON):
            return {
                ...state,
                message: "Saving icon..."
            }
        case fulfilled(contactActionTypes.SAVE_ICON):
            
            return {
                ...state,
                message: "Saved icon"
            }
        case fulfilled(contactActionTypes.SET_PERSONAL_DATA):
            return {
                ...state,
                personalData: action.payload                
            }
        case rejected(contactActionTypes.SAVE_ICON):
            return {
                ...state,
                message: action.payload.message
            }
        case pending(contactActionTypes.EMPTY_CONTACT):
            return {
                ...state,
                personalData: {},
                message: "Getting empty contact"
            }
        case fulfilled(contactActionTypes.EMPTY_CONTACT):
            if (!action.payload.data.addresses) {
                action.payload.data.addresses = [];
            }
            return {
                ...state,
                emptyContact: action.payload.data,                
                message: "Got empty contact"
            }
        case rejected(contactActionTypes.EMPTY_CONTACT):
            return {
                ...state,
                message: action.payload.message
            }
        case fulfilled(contactActionTypes.EDIT_USER_CONTACT):
            return {
                ...state,
                showEditUserContact: action.payload,
                message: "Editing user contact"
            }
        case fulfilled(contactActionTypes.INITIALIZE_GIF):
            return {
                ...state, 
                showLoadingGif: false
            }
        case pending(contactActionTypes.RESEND_CSA_PDF):
            return {
                ...state,
                message: "Sending Email to Client..."
            }
        case fulfilled(contactActionTypes.RESEND_CSA_PDF):
            toaster.notify("Email Sent Successfully!", { duration: 4000 });
            return {
                ...state,
                message: "Email Sent Successfully"
            }
        case rejected(contactActionTypes.RESEND_CSA_PDF):
            toaster.notify("Eror Sending Client Email: " + action.payload.message, { duration: 4000 });  
            return {
                ...state,
                message: action.payload.message
            }
        case pending(contactActionTypes.SEND_INFO_PACKET):
            return {
                ...state,
                message: "Sending Information Packet to Client..."
            }
        case fulfilled(contactActionTypes.SEND_INFO_PACKET):
            toaster.notify("Information Packet Sent Successfully!", { duration: 4000 });
            return {
                ...state,
                message: "Information Packet Sent Successfully"
            }
        case rejected(contactActionTypes.SEND_INFO_PACKET):
            toaster.notify("Eror Sending Client Information Packet: " + action.payload.message, { duration: 4000 });
            return {
                ...state,
                message: action.payload.message
            }
            
        default:
            return state;
    }
};

