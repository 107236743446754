import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Label from 'react-bootstrap/FormLabel';
import Form from 'react-bootstrap/Form';
import Select from 'react-styled-select';
import toaster from 'toasted-notes';
import { showEditForm, updateContact, editUserContact, insertContact, deleteContact, showAddressForm, saveIcon, sendUpdateCreditCardLink } from '../../../hooks/store/actions/contactActions.js';
import { showEditUserForm } from '../../../hooks/store/actions/loginActions.js';
import { getBillingPlans, getInvoices } from '../../../hooks/store/actions/financeActions.js';
import AddressList from './AddressList.js';
import AddressDetail from './AddressDetail.js';
import ClientInfo from './ClientInfo';
import { serverEnvironment, getClientStatus } from '../../../hooks/store/actionConsts';
import UserProfile from '../admin/UserProfile';
import CheckBox from 'react-bootstrap/FormCheckInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyleSheet, css } from 'aphrodite'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { showNavbar, hideNavbar } from '../../../hooks/store/actions/navBarActions';

const emptyAddress = { addressType: { id: 0, name: "" }, customLabel: "", address: { addressID: 0, address1: "", address2: "", city: "", state: "", zip: "", country: "" } };

let pronounList = [{ label: "He/His", value: "He/His" }, { label: "She/Her", value: "She/Her" }, { label: "They/Their", value: "They/Their" }, { label: "Other", value: "Other" }];

const styles = StyleSheet.create({
    newContactInputRow: {
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: "10px",
        fontFamily: "'Open Sans', sans-serif"
    },
    newContactFormInput: {
        backgroundColor: '#e5e5e5',
        border: '1px solid #949494',
        marginTop: '5px',
        borderRadius: "0px",
        height: '32px',
        fontSize: '14px',
        fontFamily: "'Open Sans', sans-serif"
    },
    newContactFormInputDate: {
        backgroundColor: '#e5e5e5',
        border: '1px solid #949494',
        borderRadius: "0px",
        height: '32px',
        fontSize: '14px',
        fontFamily: "'Open Sans', sans-serif",
        width: "100%",
        padding: ".375rem .75rem;",
        color: "#495057;"
    },
    newContactFormLabel: {
        fontSize: "small",
        fontFamily: "'Open Sans', sans-serif",
        color: "#535353"
    },
    newContactHeaderContainer: {
        //Unresponsive marginTop
        // marginTop: "0px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        height: "24px",
        display: "block",
        width: "100%",
        marginBottom: "15px",
        fontFamily: "'Open Sans', sans-serif",
        color: "#535353"
    },
    newContactFilterHeaders: {
        fontSize: "20px",
        fontWeight: "900",
        fontFamily: "'Open Sans', sans-serif",
        color: "#535353",
        width: "150px"
    },
    currentClientLabel: {
        fontSize: "22px",
        fontWeight: "700",
        fontStyle: "italic",
        fontFamily: "Helvetica, 'Open Sans', sans-serif",
        color: "cornflowerblue",
        width: "200px"
    },
    newContactFilterCol: {
        maxWidth: "180px"
    },
    newContactFilterCheckbox: {
        marginTop: "10px"
    },
    newContactHeader: {
        display: "inline-block",
        fontSize: "18px",
        width: "auto"
    },
    newContactCustomHr: {
        display: "inline-block",
        borderTop: '1px solid #949494',
        width: "100%",
        margin: "5px"
    },
    newContactBottomHr: {
        marginBottom: "1.5rem"
    }
})



// View Edit Contact information
class ViewEditContact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.currentContact,
            dateOfBirth: (this.props.currentContact.dateOfBirth) ? this.props.currentContact.dateOfBirth : "January 1, 1990 15:05:06 GMT-0700 (Pacific Daylight Time)",
            genderType: (this.props.currentContact.genderType ? this.props.currentContact.genderType.id : 0),
            maritalStatus: (this.props.currentContact.maritalStatus ? this.props.currentContact.maritalStatus.id : 0),
            contactType: (this.props.currentContact.contactType ? this.props.currentContact.contactType.id : 0),
            showAdvanced: false,
            preferredPronouns: (this.props.currentContact.preferredPronouns ? this.props.currentContact.preferredPronouns : "")
        };

        if (this.props.currentContact.contactID !== 0) {
            this.props.getBillingPlans(this.props.logintoken, this.props.currentContact.contactID, this.props.currentContact.affiliateID);
            this.props.getInvoices(this.props.logintoken, this.props.currentContact.contactID, this.props.currentContact.affiliateID);
        }

        this.onChange = this.onChange.bind(this);
        this.onChangeOtherPronoun = this.onChangeOtherPronoun.bind(this);
        this.onClickInclude = this.onClickInclude.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onClickCancel = this.onClickCancel.bind(this);
        this.onSelectGender = this.onSelectGender.bind(this);
        this.onSelectPronoun = this.onSelectPronoun.bind(this);
        this.onSelectContactType = this.onSelectContactType.bind(this);
        this.onSelectMaritalStatus = this.onSelectMaritalStatus.bind(this);
        this.getItems = this.getItems.bind(this);
        this.getItemsContactTypes = this.getItemsContactTypes.bind(this);
        this.getItemsMaritalStatus = this.getItemsMaritalStatus.bind(this);
        this.checkPotential = this.checkPotential.bind(this);
        this.checkActive = this.checkActive.bind(this);
        this.checkPast = this.checkPast.bind(this);
        this.onClickCanCallWork = this.onClickCanCallWork.bind(this);
        this.onClickCanCallHome = this.onClickCanCallHome.bind(this);
        this.onClickShowAdv = this.onClickShowAdv.bind(this);
        this.onClickAddAddr = this.onClickAddAddr.bind(this);
        this.onChangeFile = this.onChangeFile.bind(this);
        this.getGSTRate = this.getGSTRate.bind(this);
        this.canBeAffiliate = this.canBeAffiliate.bind(this);
        this.props.showNavbar()
        this.onClickCreateUser = this.onClickCreateUser.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
    }

    componentDidMount() {
        if (this.state.preferredPronouns != "He/His" && this.state.preferredPronouns != "She/Her" && this.state.preferredPronouns != "They/Their" && this.state.preferredPronouns.length > 0) {
            var otherInput = document.getElementById('otherPronoun');
            otherInput.style.display = 'block';
        }
        var testDate = new Date(this.state.dateOfBirth)
        var existingAddress = (this.state.addresses ? this.state.addresses.slice() : []);
        this.setState({ existingAddress: existingAddress });
        if (existingAddress.length == 0) {
            this.onClickAddAddr()
        }
    }

    getGSTRate() {
        if (this.state.addresses && this.state.addresses.length > 0 && this.props.taxRates) {
            var prov = {};
            var item = this.state.addresses.filter(a => a.addressType.id === 3); // billing address
            if (!item || item.length === 0) {
                item = this.state.addresses.filter(a => a.addressType.id === 1); // mailing address
            }
            if (item && item.length > 0) {
                prov = item[0].address.state;
            }
            else {
                prov = this.state.addresses[0].address.state; // first address available
            }
            var rate = this.props.taxRates.filter(a => a.provinceAbbr === prov);
        }
        return rate && rate.length > 0 ? rate[0] : { totalRate: 0.0 };
    }

    canBeAffiliate() {
        if (this.state.addresses && this.state.addresses.length > 0 && this.props.taxRates) {
            var country = this.state.addresses[0].address.country;
            if (country === "United States" || country === "Canada") {
                return true;
            }
        }
        return false;
    }

    formatDate(d) {
        if (d && d.length >= 10) {
            return d.substring(0, 4) + "-" + d.substring(5, 7) + "-" + d.substring(8, 10);
        }
        else
            return "";
    }

    getItems() {
        var items = [];
        if (this.props.genderTypes) {
            for (var i = 0; i < this.props.genderTypes.length; i++) {
                items.push({ label: this.props.genderTypes[i].name, value: this.props.genderTypes[i].id });
            }
        }
        return items;
    }
    getItemsContactTypes() {
        var items = [];
        if (this.props.contactTypes) {
            for (var i = 0; i < this.props.contactTypes.length; i++) {
                items.push({ label: this.props.contactTypes[i].name, value: this.props.contactTypes[i].id });
            }
        }
        return items;
    }
    getItemsMaritalStatus() {
        var items = [];
        if (this.props.maritalStatus) {
            for (var i = 0; i < this.props.maritalStatus.length; i++) {
                items.push({ label: this.props.maritalStatus[i].name, value: this.props.maritalStatus[i].id });
            }
        }
        return items;
    }

    onChangeOtherPronoun = (e) => {
        this.setState({ preferredPronouns: e.target.value });
        // pronounList.push({ label: e.target.value, value: e.target.value });
    }

    onChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }

    onBirthDateChange = (date) => {
        this.setState({ dateOfBirth: date });
    }

    onClickSendStripeEmail = () => {
        this.props.sendUpdateCreditCardLink(this.props.logintoken, this.props.currentContact, (this.props.currentContact.stripeCustomerId_Canada ? 'Canada' : 'USA'));
    }

    onClickInclude = (e) => {
        this.setState({ 'includeLogoOnEmails': e.target.checked });
    }

    onClickDelete = (e) => {
        swal({
            title: "AC Admin", text: "Delete this contact?", icon: "warning",
            buttons:
                {
                    no: {
                        text: "No",
                        value: "no"
                    },
                    yes: {
                        text: "Yes",
                        value: "yes"
                    },
                    cancel: false
                }
        }).then((value) => {
            switch (value) {
                case "yes":

                    this.props.deleteContact(this.props.logintoken, this.props.currentContact.contactID);
                    this.onClickCancel();
                    break;
                case "no":
                    break;
                default:
                    break;
            }
        });
    }

    onClickSave() {
        if (!this.props.currentAddress.addressType || !this.props.currentAddress.addressType.id || !this.props.currentAddress.addressType.name) {
            swal("Please select an address type");
            return;
        }
        if (!this.props.currentAddress.address.country) {
            swal("Please select a country");
            return;
        }
        if (!this.props.currentAddress.address.state) {
            swal("Please select a state or province");
            return;
        }

        var newContact = JSON.parse(JSON.stringify(this.props.currentContact));
        if (!this.state.firstName || !this.state.lastName) {
            toaster.notify("First and Last Names are required", { duration: 4000 });
            return;
        }
        if (!this.state.email) {
            toaster.notify("Email is required", { duration: 4000 });
            return;
        }
        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,6})+$/;
        if (!filter.test(this.state.email)) {
            toaster.notify("Please enter a valid email (xxx@yyy.zzz)", { duration: 4000 });
            return;
        }
        if (newContact.addresses < 1) {
            toaster.notify("Please enter an address");
            return;
        }

        newContact.affiliateID = this.props.user.userID;
        newContact.firstName = this.state.firstName.trim();
        newContact.lastName = this.state.lastName.trim();
        newContact.companyName = (this.state.companyName ? this.state.companyName.trim() : "");
        newContact.companyLogoURL = (this.state.companyLogoURL ? this.state.companyLogoURL.trim() : "");
        newContact.includeLogoOnEmails = this.state.includeLogoOnEmails;
        newContact.email = (this.state.email ? this.state.email.trim() : "");
        newContact.email2 = (this.state.email2 ? this.state.email2.trim() : "");
        newContact.homePhone = (this.state.homePhone ? this.state.homePhone.trim() : "");
        newContact.workPhone = (this.state.workPhone ? this.state.workPhone.trim() : "");
        newContact.mobilePhone = (this.state.mobilePhone ? this.state.mobilePhone.trim() : "");
        newContact.dateOfBirth = (this.state.dateOfBirth ? this.state.dateOfBirth : "");
        newContact.preferredPronouns = (this.state.preferredPronouns ? this.state.preferredPronouns.trim() : "");
        newContact.genderType = { id: this.state.genderType };
        newContact.maritalStatus = { id: this.state.maritalStatus };
        //newContact.currentClient = this.state.currentClient;
        newContact.pastClient = this.state.pastClient;
        newContact.prospectiveClient = this.state.prospectiveClient;
        newContact.canCallAtHome = this.state.canCallAtHome;
        newContact.canCallAtWork = this.state.canCallAtWork;

        newContact.voicemail = this.state.voicemail;
        newContact.fax = this.state.fax;
        newContact.emergencyContactName = this.state.emergencyContactName;
        newContact.emergencyContactNumber = this.state.emergencyContactNumber;
        newContact.spouseName = this.state.spouseName;
        newContact.occupation = this.state.occupation;
        newContact.employer = this.state.employer;
        newContact.gSTNumber = this.state.gSTNumber;
        newContact.gSTRate = this.state.gSTRate;
        newContact.vATNumber = this.state.vATNumber;
        newContact.vATRate = this.state.vATRate;
        newContact.stripeCustomerId_USA = this.state.stripeCustomerId_USA;
        newContact.stripeCustomerId_Canada = this.state.stripeCustomerId_Canada;
        newContact.personalData = this.props.personalData;
        if (!newContact.personalData) {
            newContact.personalData = {};
        }
        newContact.personalData.lastUpdatedDateUTC = new Date();
        newContact.personalData.createdDate = new Date();
        if (newContact.contactID === 0) {
            newContact.currentClient = false;
            this.props.insertContact(this.props.logintoken, newContact);
        }
        else {
            this.props.updateContact(this.props.logintoken, newContact);
        }
        this.onClickCancel();
    }

    onClickCreateUser() {
        this.props.showEditUserForm(true);
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    onClickCancel() {

        if (this.props.currentContact.contactID !== 0) {
            var contact = this.props.currentContact;
            contact.addresses = this.state.existingAddress;
            if (!this.props.showEditUserContact) {
                this.props.showEditForm(contact);
                this.props.showEditForm(1);
            }
            else {
                this.props.editUserContact(false);

            }
            this.props.hideNavbar()
        }
        else {
            this.sleep(2000).then(() => {
                document.location = "/viewContacts";
            })
        }
    }

    onChangeFile = (e) => {
        this.setState({ "fileName": e.target.files[0] });

        if (e.target.files[0]) {
            var fn = e.target.files[0];

            // 1. Ensure a valid file is selected
            var reader = new FileReader();
            if (fn.size) {
                var fileName = fn.name;
                if (fileName.indexOf(".") > 0) {
                    fileName = fileName.substring(0, fileName.indexOf("."));
                }
                reader.onload = function (e) {

                    var contents = e.target.result;
                    this.setState({ "tempLogo": contents });
                    this.props.saveIcon(this.props.logintoken, this.props.user.userID, fileName, contents);

                }.bind(this);
                reader.readAsDataURL(fn);
                this.setState({ companyLogoURL: serverEnvironment.API_URL + "/image/" + String(this.props.user.userID) + "/" + fileName });
            }
        }
    }

    onSelectPronoun(item) {
        var otherInput = document.getElementById('otherPronoun');
        if (item == "Other") {
            otherInput.style.display = 'block';
        }
        else {
            otherInput.style.display = 'none';
            this.setState({ preferredPronouns: item });
        }
    }

    onSelectGender(item) {
        this.setState({ genderType: item });
    }

    onSelectContactType(item) {
        this.setState({ contactType: item });
    }

    onSelectMaritalStatus(item) {
        this.setState({ maritalStatus: item });
    }

    checkPotential = (e) => {
        this.setState({ 'prospectiveClient': e.target.checked });
    }
    checkActive = (e) => {
        this.setState({ 'currentClient': e.target.checked });
    }
    checkPast = (e) => {
        this.setState({ 'pastClient': e.target.checked });
    }

    onClickCanCallWork(e) {
        this.setState({ canCallAtWork: e.target.checked });
    }
    onClickCanCallHome(e) {
        this.setState({ canCallAtHome: e.target.checked });
    }

    onClickShowAdv() {
        this.setState({ "showAdvanced": this.state.showAdvanced ? false : true });
    }

    onClickAddAddr() {
        this.props.showAddressForm(emptyAddress);
    }

    render() {
        var canDelete = (this.props.currentContact.contactID !== 0 && (this.props.invoices && !this.props.invoices.length) && (this.props.billingPlans && !this.props.billingPlans.length));

        var items = this.getItems();
        var contactTypes = this.getItemsContactTypes();
        var maritalStatus = this.getItemsMaritalStatus();
        var gstRate = this.getGSTRate();
        var prefferedPronounSelectValue
        //console.log(this.state.preferredPronouns)
        if (this.state.preferredPronouns != "He/His" && this.state.preferredPronouns != "She/Her" && this.state.preferredPronouns != "They/Their") {
            prefferedPronounSelectValue = "Other"
        } else {
            prefferedPronounSelectValue = this.state.preferredPronouns
        }
                                    
        return (
            <div className="full-screen viewEditContact viewEditContactSection" {...this.props} size="lg" centered onHide={() => this.props.showEditForm(null)}>
                <div className="Modal.Body form-editcontact modal-small">

                    <div className="grid-checkboxes2">
                        <Row className={css(styles.newContactInputRow)}>
                            <Col className={css(styles.newContactFilterCol)}>
                                <CheckBox className={css(styles.newContactFilterCheckbox)} checked={this.state.prospectiveClient} onChange={this.checkPotential} />
                                <Label className={css(styles.newContactFilterHeaders)}>Potential Client</Label>
                            </Col>
                            <Col >
                                <CheckBox className={css(styles.newContactFilterCheckbox)} checked={this.state.pastClient} onChange={this.checkPast} />
                                <Label className={css(styles.newContactFilterHeaders)}>Past Client</Label>
                            </Col>
                            <Col style={{textAlign: 'right'}} >
                                { (getClientStatus(this.props.currentContact) === "Active" || getClientStatus(this.props.currentContact) === "Paused") && 
                                    <Label className={css(styles.currentClientLabel)}>Current Client{ (getClientStatus(this.props.currentContact)) === "Paused" ? ' (Paused)' : '' }</Label>
                                }
                                { (this.props.currentContact.stripeCustomerId_USA || this.props.currentContact.stripeCustomerId_Canada) &&
                                    <><br /><button className="button-gridrow-send-stripe-email" onClick={this.onClickSendStripeEmail}>SEND CLIENT CREDIT CARD UPDATE LINK</button></>
                                }
                            </Col>
                        </Row>
                    </div>

                    <div className={css(styles.newContactHeaderContainer)}>
                        <div className={css(styles.newContactHeader)}>Contact</div>
                        <div className={css(styles.newContactCustomHr)} />
                    </div>

                    <Form.Group autocomplete="offrandomhacktext" className={this.state.showAdvanced ? "hidden" : "Modal.Body modal-small"}>
                        <Row>
                            <Col sm={6}><Form.Control autocomplete="offrandomhacktext" className={css(styles.newContactFormInput)} id="firstName" onChange={this.onChange} value={this.state.firstName} /><Label className={css(styles.newContactFormLabel)}>First Name&nbsp;<span style={{ color: 'red' }}>*</span></Label></Col>
                            <Col sm={6}><Form.Control autocomplete="offrandomhacktext" className={css(styles.newContactFormInput)} id="lastName" onChange={this.onChange} value={this.state.lastName} /><Label className={css(styles.newContactFormLabel)}>Last Name&nbsp;<span style={{ color: 'red' }}>*</span></Label></Col>
                        </Row>
                        <Row>
                            <Col sm={6}><Form.Control autocomplete="offrandomhacktext" className={css(styles.newContactFormInput)} id="email" onChange={this.onChange} value={this.state.email} /><Label className={css(styles.newContactFormLabel)}>Billing E-mail&nbsp;<span style={{ color: 'red' }}>*</span></Label></Col>
                            <Col sm={6}><Form.Control autocomplete="offrandomhacktext" className={css(styles.newContactFormInput)} id="email2" onChange={this.onChange} value={this.state.email2} /><Label className={css(styles.newContactFormLabel)}>Client E-mail</Label></Col>
                        </Row>

                        <Row>
                            <Col sm={4}><Form.Control autocomplete="offrandomhacktext" className={css(styles.newContactFormInput)} id="mobilePhone" onChange={this.onChange} value={this.state.mobilePhone} /><Label className={css(styles.newContactFormLabel)}>Mobile Phone</Label></Col>
                            <Col sm={4}><Form.Control autocomplete="offrandomhacktext" className={css(styles.newContactFormInput)} id="homePhone" onChange={this.onChange} value={this.state.homePhone} /><Label className={css(styles.newContactFormLabel)}>Home Phone</Label></Col>
                            <Col sm={4}><Form.Control autocomplete="offrandomhacktext" className={css(styles.newContactFormInput)} id="workPhone" onChange={this.onChange} value={this.state.workPhone} /><Label className={css(styles.newContactFormLabel)}>Work Phone</Label></Col>
                        </Row>

                        <Row>
                            <Col sm={3}></Col>
                            <Col sm={1}></Col>

                            <Col xs={1} sm={1}></Col>
                            <Col xs={4} sm={3}>
                                <CheckBox checked={this.state.canCallAtHome} onChange={this.onClickCanCallHome} /><Label className={css(styles.newContactFormLabel)}>Can Contact at Home</Label></Col>

                            <Col xs={1} sm={1}></Col>
                            <Col xs={4} sm={3}><CheckBox checked={this.state.canCallAtWork} onChange={this.onClickCanCallWork} /><Label className={css(styles.newContactFormLabel)}>Can Contact at Work</Label></Col>

                        </Row>
                        <Row >&nbsp;</Row>
                        <Row ><Col><span style={{ float: 'right', color: 'red' }}>* Required Field</span></Col></Row>
                        <hr />
                        <div className="blue-background">
                            {this.props.currentContact.addresses && (
                                <AddressList contactID={this.props.currentContact.contactID} addresses={this.props.currentContact.addresses} />
                            )}
                            <div className="topNav" onClick={this.onClickAddAddr}>
                                <FontAwesomeIcon icon="plus" />&nbsp;Add Address
                        </div>
                            {this.props.show2 === emptyAddress && (
                                <AddressDetail address={emptyAddress} contactID={this.props.currentContact.contactID} />
                            )}
                        </div>
                        <hr />
                        <Row>
                            <Col sm={6}><Form.Control autocomplete="offrandomhacktext" className={css(styles.newContactFormInput)} id="companyName" onChange={this.onChange} value={this.state.companyName} /></Col>
                            {/* <Col sm={6}>
                                    <Form.Control className={css(styles.newContactFormInput)} id="companyLogoURL" onChange={this.onChange} value={this.state.companyLogoURL} />
                                </Col> */}
                        </Row>
                        <Row>
                            <Col sm={6}><Label className={css(styles.newContactFormLabel)}>Company Name</Label></Col>
                            {/* <Col sm={6}><Label className={css(styles.newContactFormLabel)}>Profile Photo</Label></Col> */}
                        </Row>
                        <hr />
                        <Row>
                            <Col sm={6}>
                                <DatePicker
                                    dateFormat="MMMM d, yyyy"
                                    selected={(this.state.dateOfBirth == "January 1, 1990 15:05:06 GMT-0700 (Pacific Daylight Time)") ? null : new Date(this.state.dateOfBirth)}
                                    onChange={this.onBirthDateChange}
                                    className={css(styles.newContactFormInputDate)}
                                    showMonthDropdown
                                    showYearDropdown
                                    openToDate={new Date("1990/01/01")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}><Label className={css(styles.newContactFormLabel)}>Birth Date</Label></Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col sm={4}>
                                <Select
                                    name="genderType"
                                    options={items}

                                    defaultValue={items.filter(option => option.value === (this.state.genderType ? this.state.genderType : 1))}
                                    value={this.state.genderType}
                                    onChange={this.onSelectGender} className="rs-dropdown-theme" />
                                <Label className={css(styles.newContactFormLabel)}>Gender Identity</Label>
                            </Col>
                            <Col sm={4}>
                                {/* <Form.Control className={css(styles.newContactFormInput)} id="preferredPronouns" onChange={this.onChange} value={this.state.preferredPronouns} /> */}
                                <Select
                                    id="preferredPronouns"
                                    options={[{ label: "He/His", value: "He/His" }, { label: "She/Her", value: "She/Her" }, { label: "They/Their", value: "They/Their" }, { label: "Other", value: "Other"}]}
                                    
                                    value={prefferedPronounSelectValue}
                                    onChange={this.onSelectPronoun} 
                                    className="rs-dropdown-theme" />
                                <Form.Control autocomplete="offrandomhacktext" placeholder="Please enter your preferred pronoun" type="text" name="otherPronoun" id="otherPronoun" value={this.state.preferredPronouns} onChange={this.onChangeOtherPronoun} style={{ display: 'none', marginTop: '5px' }} />
                                <Label className={css(styles.newContactFormLabel)}>Preferred Pronouns</Label>
                            </Col>
                        </Row>
                        <Row >&nbsp;</Row>
                        <Row >&nbsp;</Row>
                        <Row>
                            <Col sm={4}></Col>
                            <Col sm={4}></Col>
                        </Row>
                    </Form.Group>
                    <Form.Group className={!this.state.showAdvanced ? "hidden" : "Modal.Body form-editcontact modal-small"}>
                        <Row>
                            <Col sm={6}><Form.Control autocomplete="offrandomhacktext" className={css(styles.newContactFormInput)} id="employer" onChange={this.onChange} value={this.state.employer} /><Label className={css(styles.newContactFormLabel)}>Employer</Label></Col>
                            <Col sm={6}><Form.Control autocomplete="offrandomhacktext" className={css(styles.newContactFormInput)} id="occupation" onChange={this.onChange} value={this.state.occupation} /><Label className={css(styles.newContactFormLabel)}>Occupation</Label></Col>

                        </Row>

                        <Row>
                            {/* <Col sm={6}><Form.Control autocomplete="offrandomhacktext" className={css(styles.newContactFormInput)} id="fax" onChange={this.onChange} value={this.state.fax} /></Col> */}
                            <Col sm={6}><Form.Control autocomplete="offrandomhacktext" className={css(styles.newContactFormInput)} id="voicemail" onChange={this.onChange} value={this.state.voicemail} /><Label className={css(styles.newContactFormLabel)}>Voice Mail</Label></Col>
                        </Row>
                        <Row>
                            <Col sm={6}><Form.Control autocomplete="offrandomhacktext" className={css(styles.newContactFormInput)} id="gSTNumber" onChange={this.onChange} value={this.state.gSTNumber} /><Label className={css(styles.newContactFormLabel)}>GST/HST Number</Label></Col>
                            <Col sm={6}><Form.Control autocomplete="offrandomhacktext" className={css(styles.newContactFormInput)} id="gSTRate" value={gstRate.totalRate} /><Label className={css(styles.newContactFormLabel)}>GST/HST Rate {gstRate.provinceName}</Label></Col>
                        </Row>

                        {this.props.sysUser.userRole >= 8 && (
                            <span>
                                <Row>
                                    <Col sm={6}><Form.Control autocomplete="offrandomhacktext" className={css(styles.newContactFormInput)} id="stripeCustomerId_USA" onChange={this.onChange} value={this.state.stripeCustomerId_USA} /><Label className={css(styles.newContactFormLabel)}>USA Stripe Customer ID</Label></Col>
                                    <Col sm={6}><Form.Control autocomplete="offrandomhacktext"className={css(styles.newContactFormInput)} id="stripeCustomerId_Canada" onChange={this.onChange} value={this.state.stripeCustomerId_Canada} /><Label className={css(styles.newContactFormLabel)}>CDN Stripe Customer ID</Label></Col>
                                </Row>
                            </span>
                        )}
                        <hr />
                        <Row>
                            <Col sm={4}><Select
                                name="maritalStatus"
                                options={maritalStatus}

                                // defaultValue={maritalStatus.filter(option => option.value === (this.state.maritalStatus ? this.state.maritalStatus : 1))}
                                value={this.state.maritalStatus ? this.state.maritalStatus : '1'}
                                onChange={this.onSelectMaritalStatus} className="rs-dropdown-theme" />
                                <Label className={css(styles.newContactFormLabel)}>Relationship Status</Label></Col>
                        </Row>
                        <Row >&nbsp;</Row>
                        <Row >&nbsp;</Row>
                        <Row>
                            <Col sm={4}>
                                <Form.Control autocomplete="offrandomhacktext" className={css(styles.newContactFormInput)} id="emergencyContactName" onChange={this.onChange} value={this.state.emergencyContactName} />
                                <Label className={css(styles.newContactFormLabel)}>Emergency Contact Name</Label></Col>
                            <Col sm={4}>
                                <Form.Control autocomplete="offrandomhacktext" className={css(styles.newContactFormInput)} id="emergencyContactNumber" onChange={this.onChange} value={this.state.emergencyContactNumber} />
                                <Label className={css(styles.newContactFormLabel)}>Emergency Contact Number</Label></Col>
                            <Col sm={4}>
                                <Form.Control autocomplete="offrandomhacktext" className={css(styles.newContactFormInput)} id="spouseName" onChange={this.onChange} value={this.state.spouseName} />
                                <Label className={css(styles.newContactFormLabel)}>Name of Significant Other</Label></Col>
                        </Row>
                    </Form.Group>

                    <hr className={css(styles.newContactBottomHr)} />

                    <Row>
                        <Col xs={4} sm={2}><button className="button-gridrow-new-contact" onClick={this.onClickSave}>SAVE</button></Col>
                        {(
                            <Col xs={4} sm={2}>
                                <button disabled={!canDelete} className="makeActionButton button-gridrow-new-contact" onClick={this.onClickDelete}>DELETE

                                {!canDelete && this.props.currentContact.contactID === 0 && (
                                    <span className="tooltiptext">Contact not saved</span>
                                )}
                                    {!canDelete && this.props.currentContact.contactID !== 0 && (
                                        <span className="tooltiptext">Contact "{this.props.currentContact.firstName + ' ' + this.props.currentContact.lastName}" cannot be deleted because current or historical Client Data Exists</span>
                                )}
                                {canDelete && (
                                    <span className="tooltiptext">Click here to permanently Delete Contact</span>
                                )}
                                </button>
                            </Col>
                        )}
                        {this.props.currentContact.contactID !== 0 && (
                            <Col xs={4} sm={2}><button className="button-gridrow-new-contact" onClick={this.onClickCancel}>CANCEL</button></Col>
                        )}
                        {(this.props.sysUser.userRole > 2
                            && this.props.currentContact.contactID !== 0
                            && !this.props.currentContact.userID
                            && this.canBeAffiliate()) && (
                                <Col xs={4} sm={2}><button className="button-gridrow-new-contact" onClick={this.onClickCreateUser}>CREATE USER</button></Col>
                            )}
                        <Col xs={4} sm={2}><button className="button-gridrow-new-contact" onClick={this.onClickShowAdv}>{this.state.showAdvanced ? "< PREVIOUS" : "NEXT >"}</button></Col>

                    </Row>
                </div>
                {this.props.showEUForm && (
                    <Modal
                        {...this.props}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        className="modal-popup"
                        user={this.props.selectedUser}
                        show={this.props.showEUForm}
                        onHide={this.onClickCancel}
                    >
                        <UserProfile user=
                            {{
                                userID: 0,
                                firstName: this.props.currentContact.firstName,
                                lastName: this.props.currentContact.lastName,
                                email: this.props.currentContact.email,
                                phone: this.props.currentContact.phone,
                                contactId: this.props.currentContact.contactID,
                                userRole: 2 // affiliate
                            }} />
                    </Modal>

                )}
            </div>
        );

    }

}

function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        sysUser: state.loginReducer.sysUser,
        //currentContact: state.contactReducer.currentContact,
        genderTypes: state.sysReducer.genderTypes,
        contactTypes: state.sysReducer.contactTypes,
        maritalStatus: state.sysReducer.maritalStatus,
        show2: state.contactReducer.showAddressForm,
        showEUForm: state.loginReducer.showEUForm,
        showEditUserContact: state.contactReducer.showEditUserContact,
        personalData: state.contactReducer.personalData,
        taxRates: state.sysReducer.taxRates,
        invoices: state.financeReducer.invoices,
        billingPlans: state.financeReducer.billingPlans,
        currentAddress: state.contactReducer.currentAddress
    }
}

export default connect(mapStateToProps, { showEditUserForm, showEditForm, insertContact, updateContact, deleteContact, editUserContact, showAddressForm, saveIcon, showNavbar, hideNavbar, getBillingPlans, getInvoices, sendUpdateCreditCardLink  })(ViewEditContact);