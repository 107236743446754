import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { } from '../../../hooks/store/actions/financeActions.js';
import { monthNames } from '../../../hooks/store/actionConsts';
import InvoiceListRow from './InvoiceListRow.js';
import swal from 'sweetalert';
import Label from 'react-bootstrap/FormLabel';

class InvoiceList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };        
        this.onClick = this.onClick.bind(this);
        this.lookupContact = this.lookupContact.bind(this);
        this.onClick = this.onClick.bind(this);
        this.processRow  = this.processRow.bind(this);
    }

    lookupContact(id) {
        for (var i = 0; i < this.props.contacts.length; i++) {
            if (id === this.props.contacts[i].contactID) {
                return this.props.contacts[i];
            }
        }
        return {};
    }


    formatDate(d) {
        if (d && d.length >= 10) {
            var m = Number(d.substring(5, 7));
            return monthNames[m - 1] + " " + d.substring(8, 10) + ", " + d.substring(0, 4);
        }
        else
            return "";
    }

   
    getStatus(id) {
        var ret = this.props.invoiceStatus.filter((a) => id === a.id);
        if (ret && ret.length > 0) {
            return ret[0].name;
        }
    }

    getPaymentMethod(id) {
        var ret = this.props.paymentMethods.filter((a) => id === a.id);
        if (ret && ret.length > 0) {
            return ret[0].name;
        }
    }

    getType(id) {
        var ret = this.props.invoiceTypes.filter((a) => id === a.id);
        if (ret && ret.length > 0) {
            return ret[0].name;
        }
    }

    processRow (row) {
            if (!row) {
                return "\r\n";
            }
            
            var contact = this.lookupContact(row.contactID);

            return "\"" +                
                String(contact.firstName).replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, "'") + "\",\"" +
                String(contact.lastName).replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, "'") + "\",\"" +
                    String(contact.email) + "\",\"" +
                    String(row.invoiceName) + "\",\"" +
                    this.formatDate(row.createdDateUTC) + "\",\"" +
                    this.getPaymentMethod(row.paymentMethodID) + "\"," +
                    String(row.invoiceTotal) + ",\"" + 
                    this.formatDate(row.dueDate) + "\",\"" +
                    this.getStatus(row.invoiceStatusID) + "\"\r\n"

    }

    onClick() {        

        if (this.props.invoices.length === 0) {
            swal("No data to export!");
            return;
        }

        var csvFile = "\"First Name\",\"Last Name\",\"Email\",\"Invoice Name\",\"Sent Date\",\"Payment Method\",\"Total\",\"Due Date\",\"Current Status\"\n";
        for (var i = 0; i < this.props.invoices.length; i++) {
            csvFile += this.processRow(this.props.invoices[i]);
        }

        var today = new Date();
        var fileName = "acadmin-invoices-" + today.toDateString() + ".csv";

        var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, fileName);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    filterInvoices() {
        var invoices = this.props.invoices;

        for (var i=0; i < invoices.length; i++) {
            let contact = this.lookupContact(invoices[i].contactID)
            let status = this.getStatus(invoices[i].invoiceStatusID)
            let type = this.getType(invoices[i].invoiceTypeID)
            invoices[i].firstName = contact.firstName
            invoices[i].email = contact.email
            invoices[i].status = status
            invoices[i].type = type
        }

        if (this.state.sort === "name" && this.state.ascending) {
            invoices = invoices.sort((a, b) => (a.firstName > b.firstName) ? 1 : -1)
        }
        else if(this.state.sort === "name" && !this.state.ascending){
            invoices = invoices.sort((a, b) => (a.firstName > b.firstName) ? -1 : 1)
        } 

        else if (this.state.sort === "email" && this.state.ascending) {
            invoices = invoices.sort((a, b) => (a.email.toLowerCase() > b.email.toLowerCase()) ? 1 : -1)
        }
        else if (this.state.sort === "email" && !this.state.ascending) {
            invoices = invoices.sort((a, b) => (a.email.toLowerCase() > b.email.toLowerCase()) ? -1 : 1)
        }

        else if (this.state.sort === "status" && this.state.ascending) {
            invoices = invoices.sort((a, b) => (a.status > b.status) ? 1 : -1)
        }
        else if (this.state.sort === "status" && !this.state.ascending) {
            invoices = invoices.sort((a, b) => (a.status > b.status) ? -1 : 1)
        }

        else if (this.state.sort === "type" && this.state.ascending) {
            invoices = invoices.sort((a, b) => (a.type > b.type) ? 1 : -1)
        }
        else if (this.state.sort === "type" && !this.state.ascending) {
            invoices = invoices.sort((a, b) => (a.type > b.type) ? -1 : 1)
        }

        return invoices;
    }

    onClickSort(type) {
        if (this.state.sort != type && this.props.invoices) {
            this.setState({ sort: type, ascending: true })
        } else if (this.props.invoices) {
            this.setState({ sort: type, ascending: !this.state.ascending })
        }
    }

    render() {
        var invoices = this.filterInvoices(this.props.invoices);
        var totalColumnText = 'Amount';
        if (this.props.reportFormId) {
            switch (this.props.reportFormId) {
                case 1:
                    totalColumnText = 'UNPAID';
                    break;
                case 2:
                    totalColumnText = 'ISSUED';
                    break;
                case 3:
                    totalColumnText = 'PAID';
                    break;
                case 4:
                    totalColumnText = 'OVERDUE';
                    break;
                default:
                    break;
            }
        } else {
            switch (this.props.reportId) {
                case 1:
                    totalColumnText = 'ISSUED';
                    break;
                case 2:
                    totalColumnText = 'PAID';
                    break;
                case 3:
                    totalColumnText = 'OVERDUE';
                    break;
                case 4:
                    totalColumnText = 'UNPAID';
                    break;
                default:
                    break;
            }
        }
        
        //console.log(this.props.reportId)
        //console.log(this.props.reportFormId)
        //console.log(totalColumnText)

        return (  
            <span>     
            <div className="table-responsive invoiceModalTable" style={{ overflow: 'unset', whiteSpace: 'pre-line' }}>  
                    <Row className="grid-row wide-table row-header">
                        <Col onClick={() => this.onClickSort("name")} className="contactGridHead" sm={2} xs={2}>
                            <Label className="contactGridLabel" style={{ cursor: 'pointer' }}>Contact Name</Label>
                            {this.state.sort === "name" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> : 
                            this.state.sort === "name" && !this.state.ascending ? <span className="material-icons iconSort">expand_less</span> :
                            <span className="material-icons iconSort">chevron_right</span>}
                        </Col>
                        <Col onClick={() => this.onClickSort("email")} className="contactGridHead" sm={2} xs={2}>
                            <Label className="contactGridLabel" style={{ cursor: 'pointer' }}>Email</Label>
                            {this.state.sort === "email" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> : 
                            this.state.sort === "email" && !this.state.ascending ? <span className="material-icons iconSort">expand_less</span> :
                            <span className="material-icons iconSort">chevron_right</span>}
                        </Col>
                        <Col onClick={() => this.onClickSort("type")} className="contactGridHead" sm={2} xs={2}>
                            <Label className="contactGridLabel" style={{ cursor: 'pointer' }}>Invoice Name/Type</Label>
                            {this.state.sort === "type" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> : 
                            this.state.sort === "type" && !this.state.ascending ? <span className="material-icons iconSort">expand_less</span> :
                            <span className="material-icons iconSort">chevron_right</span>}
                        </Col>
                        <Col className="contactGridHead" sm={1} xs={1}>
                            <Label className="contactGridLabel">Sent Date</Label>
                        </Col>
                        <Col className="contactGridHead" sm={1} xs={1}>
                            <Label className="contactGridLabel">Pay By </Label>
                        </Col>
                        <Col className="contactGridHead" sm={1} xs={1}>
                            <Label className="contactGridLabel">Total {totalColumnText}</Label>
                        </Col>
                        <Col className="contactGridHead" sm={1} xs={1}>
                            <Label className="contactGridLabel">Due Date</Label>
                        </Col>
                        <Col onClick={() => this.onClickSort("status")} className="contactGridHead" sm={2} xs={2}>
                            <Label  className="contactGridLabel" style={{ cursor: 'pointer' }}>Current Status</Label>
                            {this.state.sort === "status" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> : 
                            this.state.sort === "status" && !this.state.ascending ? <span className="material-icons iconSort">expand_less</span> :
                            <span className="material-icons iconSort">chevron_right</span>}
                        </Col>              
                    </Row>                        
                    <InvoiceListRow ascending={this.state.ascending} invoices={invoices} totalColumnText={totalColumnText} />
            </div>
                <Row className="grid-row-report medium-text wide-table">
                    <Button className="button-gridrow cardButton" onClick={this.onClick}>EXPORT TO CSV</Button>
                </Row>     
            </span>       
        );
    }
}

function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        contacts: state.contactReducer.contacts,
        paymentMethods: state.sysReducer.paymentMethods,
        invoiceStatus: state.sysReducer.invoiceStatus,
        invoiceTypes: state.sysReducer.invoiceTypes
    }
}

export default connect(mapStateToProps, {})(InvoiceList);
