import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from 'react-bootstrap/FormLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InvoiceDetailRow from './InvoiceDetailRow';
import Select from 'react-styled-select';
import { StyleSheet, css } from 'aphrodite'
import swal from 'sweetalert';
import { saveInvoice } from '../../../hooks/store/actions/financeActions.js'
import { formatCurrency } from '../../../hooks/store/actionConsts.js'
import { recordInvoiceData } from '../../../hooks/store/actions/financeActions.js'

const styles = StyleSheet.create({
    newContactInputRow: {
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: "10px",
        fontFamily: "'Open Sans', sans-serif"
    },
    newContactFormInput: {
        backgroundColor: '#e5e5e5',
        border: '1px solid #949494',
        marginTop: '5px',
        borderRadius: "0px",
        height: '32px',
        fontSize: '14px',
        fontFamily: "'Open Sans', sans-serif"
    },
    newContactFormInputDate: {
        backgroundColor: '#e5e5e5',
        border: '1px solid #949494',
        marginTop: '5px',
        borderRadius: "0px",
        height: '32px',
        fontSize: '14px',
        fontFamily: "'Open Sans', sans-serif"
    },
    newContactFormLabel: {
        fontSize: "small",
        fontFamily: "'Open Sans', sans-serif",
        color: "#535353"
    },
    newContactHeaderContainer: {
        //Unresponsive marginTop
        marginTop: "11px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        height: "24px",
        display: "block",
        width: "100%",
        marginBottom: "15px",
        fontFamily: "'Open Sans', sans-serif",
        color: "#535353"
    },
    newContactFilterHeaders: {
        fontSize: "20px",
        fontWeight: "900",
        fontFamily: "'Open Sans', sans-serif",
        color: "#535353",
        width: "150px"
    },
    newContactFilterCol: {
        maxWidth: "180px"
    },
    newContactFilterCheckbox: {
        marginTop: "10px"
    },  
    newContactHeader: {
        display: "inline-block",
        fontSize: "18px",
        width: "auto"
    },
    newContactCustomHr: {
        display: "inline-block",
        borderTop: '1px solid #949494',
        width: "100%",
        margin: "5px"
    }
})
class InvoiceForm extends React.Component {
    constructor(props) {
        super(props);
        var today = new Date();
        today.setDate(today.getDate() + 1);

        this.state = {
            ...this.props.invoice,
            invoiceTypeID: 5,
            transactionType: "0",
            dueDate: String(today.getMonth()+1) + "/" +
                String(today.getDate()) + "/" +
                String(today.getFullYear()),
            paymentTypeID: 1,
            notes: ""
        };
        
        this.saveInvoice = this.saveInvoice.bind(this);
        this.onSelectInvoiceType = this.onSelectInvoiceType.bind(this);
        this.onSelectPaymentType = this.onSelectPaymentType.bind(this);
        this.onChange = this.onChange.bind(this);
        this.tick = this.tick.bind(this);
     
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            500
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        this.setState({
            date: new Date()
        });
    }

    saveInvoice(){
              
        if (!this.props.invoice.invoiceTotal){
            swal("Please enter a positive dollar amount");
            return;
        }
        if (!this.state.dueDate) {
            swal("Please enter a due date");            
            return;
        }
        if (!this.state.invoiceName) {
            swal("Please enter an invoice name");
            return;
        }

        var invoice = { ...this.props.invoice };
        invoice.dueDate = this.state.dueDate;
        invoice.invoiceTypeID = 5;
        invoice.paymentMethodID = this.state.paymentTypeID;
        invoice.invoiceName = this.state.invoiceName;        

        for (var i = 0; i < invoice.details.length; i++) {
            invoice.details[i].groupName = this.state.invoiceName;
        }
       
        this.props.saveInvoice(this.props.logintoken, invoice);
        this.props.onClickCancel();
    }

    onChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });

        if (e.target.id === "dueDate") {
            var invoice = { ...this.props.invoice };
            invoice.dueDate = e.target.value;
            this.props.recordInvoiceData(invoice);
        }
    }

    onSelectInvoiceType(item) {
        this.setState({ invoiceTypeID: item});
    }

    onSelectPaymentType(item) {
        this.setState({ paymentTypeID: item });
        var invoice = { ...this.props.invoice };
        invoice.paymentMethodID = String(item);
        this.props.recordInvoiceData(invoice);
    }

    render() {
        var transType = [ { label: "Payment", value: "0"}, { label: "Refund", value: "1"}];
        var invoiceTypes = [];
        for (var i = 0; i < this.props.invoiceTypes.length; i++){
            if (this.props.invoiceTypes[i].id === 5) {
                invoiceTypes.push({ label: this.props.invoiceTypes[i].name, value: this.props.invoiceTypes[i].id });
            }
        }

        var paymentTypes = [];
        for (i = 0;i < this.props.paymentMethods.length; i++){
            if (this.props.invoice.chargeCurrency === "USD" && this.props.paymentMethods[i].description !== "CAN") {
                paymentTypes.push({ label: this.props.paymentMethods[i].name, value: this.props.paymentMethods[i].id});
            }
            else if (this.props.invoice.chargeCurrency === "CAD" && this.props.paymentMethods[i].description !== "USA") {
                paymentTypes.push({ label: this.props.paymentMethods[i].name, value: this.props.paymentMethods[i].id});
            }
        }
       
        return (
          <div className="full-screen viewEditContact viewEditContactSection" {...this.props} size="lg" centered onHide={this.props.onClickCancel}>
             <div className="Modal.Body form-editcontact modal-small">
                <Row>
                    <Col sm={4}><Select
                        name="invoiceTypeID"
                        options={invoiceTypes}
                        value={this.state.invoiceTypeID}
                        onChange={this.onSelectInvoiceType} className="rs-dropdown-theme" />
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}><Label className={css(styles.newContactFormLabel)}>Invoice Type</Label></Col>
                </Row>
                <Row>
                    <Col sm={4}><Select
                        name="paymentType"
                        options={paymentTypes}
                        value={this.state.paymentTypeID}
                        onChange={this.onSelectPaymentType} className="rs-dropdown-theme" />
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}><Label className={css(styles.newContactFormLabel)}>Payment Type</Label></Col>
                </Row>
                <Row>
                    <Col sm={6}><Form.Control className={css(styles.newContactFormInput)} id="invoiceName" onChange={this.onChange} value={this.state.invoiceName} /></Col>                    
                </Row>
                <Row>
                    <Col sm={6}><Label className={css(styles.newContactFormLabel)}>Invoice Name</Label></Col>                    
                </Row>
                 <Row>
                    <Col sm={6}><Form.Control className={css(styles.newContactFormInput)} id="dueDate" onChange={this.onChange} value={this.state.dueDate} /></Col>                    
                </Row>
                <Row>
                    <Col sm={6}><Label className={css(styles.newContactFormLabel)}>Due Date</Label></Col>                    
                </Row>
                {this.props.invoice && this.props.invoice.details && (
                    <InvoiceDetailRow details={this.props.invoice.details} editing={true}/>
                )}
                <Row>
                {this.props.invoice && this.props.invoice.invoiceTotal && typeof this.props.invoice.invoiceTotal ==='number' && (
                        <Col sm={6}>
                            <Label className={css(styles.newContactFormLabel)}>
                                <b>
                                    Total: {"$" + String(formatCurrency(this.props.invoice.invoiceTotal.toFixed(2))) + " " + this.props.invoice.chargeCurrency}
                                </b>
                            </Label>
                        </Col>
                )}
                    </Row>
                    {!this.props.hideButtons && (
                        <Row>
                            <Col sm={2}><button className="button-gridrow-new-contact" onClick={this.saveInvoice}>Save</button></Col>
                            <Col sm={2}><button className="button-gridrow-new-contact" onClick={this.props.onClickCancel}>Cancel</button></Col>
                        </Row>
                    )}
            </div>
        </div>

        );
    }
    
}
    
function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user  ,        
        paymentMethods: state.sysReducer.paymentMethods,
        invoiceTypes: state.sysReducer.invoiceTypes
    }
}

export default connect(mapStateToProps, { saveInvoice, recordInvoiceData })(InvoiceForm);
