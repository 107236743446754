import toaster from 'toasted-notes';

import { financeActionTypes, pending, rejected, fulfilled, serverEnvironment } from '../actionConsts.js';

export default function reducer(state = {
    dashboard: null,
    clients: [],
    billingPlans: [],
    invoices: [],
    allinvoices: [],
    allactivity: [],
    infoPackets: [],
    templates: [],
    emptyInvoice: {},
    showClientDetails: false,
    showLoadingGif: false,
    message: '',
    paymentDetailsFee: [],
}, action) {

    var i = 0;
    var billingPlan;
    var plans;

    switch (action.type) {
        case pending(financeActionTypes.ACTIVATE_BILLING_PLAN):
            return {
                ...state,
                showLoadingGif: true,
                message: "Activating billing plan..."
            }
        case fulfilled(financeActionTypes.ACTIVATE_BILLING_PLAN):
            billingPlan= action.payload.data;
            plans = state.billingPlans;
            for (i = 0; i < state.billingPlans.length; i++) {
                if (billingPlan.billingPlanID === plans[i].billingPlanID) {
                    plans[i] = billingPlan;
                    plans[i].statusID = 2;
                    break;
                }
            }
            toaster.notify("Billing plan activated", { duration: 4000 });
            return {
                ...state,
                showLoadingGif: false,
                billingPlans: plans,
                message: ""
            }
        case rejected(financeActionTypes.ACTIVATE_BILLING_PLAN):
            toaster.notify("Could not activate billing plan", { duration: 4000 });
            return {
                ...state,
                showLoadingGif: false,
                message: action.payload.message
            }
        case pending(financeActionTypes.UPDATE_BILLING_PLAN):
            return {
                ...state,
                message: "Updating billing plan..."
            }
        case fulfilled(financeActionTypes.UPDATE_BILLING_PLAN):
            billingPlan= action.payload.data;
            plans = state.billingPlans;
            for (i = 0; i < state.billingPlans.length; i++) {
                if (billingPlan.billingPlanID === plans[i].billingPlanID) {
                    plans[i] = billingPlan;
                    break;
                }
            }
            toaster.notify("Billing plan updated", { duration: 4000 });
            return {
                ...state,
                billingPlans: plans,
                message: ""
            }
        case rejected(financeActionTypes.UPDATE_BILLING_PLAN):
            toaster.notify("Could not update billing plan", { duration: 4000 });
            return {
                ...state,
                message: action.payload.message
            }
        case pending(financeActionTypes.CANCEL_BILLING_PLAN):
            return {
                ...state,
                message: "Cancelling billing plan..."
            }
        case fulfilled(financeActionTypes.CANCEL_BILLING_PLAN):
            billingPlan = action.payload.data;
            plans = state.billingPlans;
            for (i = 0; i < state.billingPlans.length; i++) {
                if (billingPlan.billingPlanID === plans[i].billingPlanID) {
                    plans[i] = billingPlan;
                    break;
                }
            }
            toaster.notify("Billing plan cancelled", { duration: 4000 });                                        
            return {
                ...state,
                billingPlans: plans,
                message: ""
            }
        case rejected(financeActionTypes.CANCEL_BILLING_PLAN):
            toaster.notify("Could not cancel billing plan", { duration: 4000 });                                        
            return {
                ...state,
                message: action.payload.message
            }
        case pending(financeActionTypes.DEACTIVATE_BILLING_PLAN):
            return {
                ...state,
                message: "Pausing billing plan..."
            }
        case fulfilled(financeActionTypes.DEACTIVATE_BILLING_PLAN):
            billingPlan = action.payload.data;
            plans = state.billingPlans;
            for (i = 0; i < state.billingPlans.length; i++) {
                if (billingPlan.billingPlanID === plans[i].billingPlanID) {
                    plans[i] = billingPlan;
                    break;
                }
            }
            toaster.notify("Billing plan paused", { duration: 4000 });
            return {
                ...state,
                billingPlans: plans,
                message: ""
            }
        case rejected(financeActionTypes.DEACTIVATE_BILLING_PLAN):
            toaster.notify("Could not deactivate billing plan", { duration: 4000 });
            return {
                ...state,
                message: action.payload.message
            }
        case pending(financeActionTypes.DASHBOARD):
            return {
                ...state,
                showLoadingGif: true
            }
        case fulfilled(financeActionTypes.DASHBOARD):
            //console.log(action.payload.data)
            return {
                ...state,
                dashboard: action.payload.data,
                showLoadingGif: false, 
                message: ""
            }
        case rejected(financeActionTypes.DASHBOARD):
            return {
                ...state,
                showLoadingGif: false, 
                message: action.payload.message
            }
        case fulfilled(financeActionTypes.GET_AGREEMENT_TEMPLATES):
            return {
                ...state,
                templates: action.payload.data,
                message: ""
            }
        case rejected(financeActionTypes.GET_AGREEMENT_TEMPLATES):
            return {
                ...state,
                message: action.payload.message
            }

        case pending(financeActionTypes.GET_ALL_INVOICES):
            return {
                ...state,
                showLoadingGif: true, 
                message: "Retrieving invoices..."
            }
        case fulfilled(financeActionTypes.GET_ALL_INVOICES):
            return {
                ...state,
                showLoadingGif: false, 
                allinvoices: action.payload.data,
                message: ""
            }
        case rejected(financeActionTypes.GET_ALL_INVOICES):
            return {
                ...state,
                showLoadingGif: false, 
                message: action.payload.message
            }
        case pending(financeActionTypes.GET_EMPTY_INVOICE):
            return {
                ...state,
                message: "Retrieving invoice template..."
            }
        case fulfilled(financeActionTypes.GET_EMPTY_INVOICE):
            action.payload.data.paymentMethodID = "1";
            return {
                ...state,
                emptyInvoice: action.payload.data,
                message: ""
            }
        case rejected(financeActionTypes.GET_EMPTY_INVOICE):
            return {
                ...state,
                message: action.payload.message
            }
        case pending(financeActionTypes.ISSUE_PAYMENT):
            return {
                ...state,
                showLoadingGif: true,
                message: "Recording payment..."
            }
        case fulfilled(financeActionTypes.ISSUE_PAYMENT):
            toaster.notify("Payment recorded", { duration: 4000});
            var payment = action.payload.data; // should be an updated invoice
            var invoices = state.invoices;
            for (i = 0; i < state.invoices.length; i++) {
                if (payment.invoiceID === invoices[i].invoiceID) {
                    invoices[i] = payment;
                    break;
                }
            }
            return {
                ...state,
                invoices: invoices,
                showLoadingGif: false,
                message: ""
            }
        case rejected(financeActionTypes.ISSUE_PAYMENT):
            toaster.notify("Could not record payment", { duration: 4000 });
            return {
                ...state,
                showLoadingGif: false,
                message: action.payload.message
            }
        case pending(financeActionTypes.GET_INFO_PACKET_ACTIVITY):
            return {
                ...state,
                message: "Retrieving activity..."
            }
        case fulfilled(financeActionTypes.GET_INFO_PACKET_ACTIVITY):
            return {
                ...state,
                infoPackets: action.payload.data,
                message: ""
            }
        case rejected(financeActionTypes.GET_INFO_PACKET_ACTIVITY):
            return {
                ...state,
                message: action.payload.message
            }
        case pending(financeActionTypes.GET_ALL_ACTIVITY):
            return {
                ...state,
                message: "Retrieving activity..."
            }
        case fulfilled(financeActionTypes.GET_ALL_ACTIVITY):
            return {
                ...state,
                showLoadingGif: false, 
                allactivity: action.payload.data,
                message: ""
            }
        case rejected(financeActionTypes.GET_ALL_ACTIVITY):
            return {
                ...state,
                showLoadingGif: false, 
                message: action.payload.message
            }
        case pending(financeActionTypes.GET_BILLING_PLANS):
            return {
                showLoadingGif: true, 
                ...state,
                message: "Retrieving billing plans..."
            }
        case fulfilled(financeActionTypes.GET_BILLING_PLANS):
            return {
                ...state,
                showLoadingGif: false, 
                billingPlans: action.payload.data,
                message: ""
            }
        case rejected(financeActionTypes.GET_BILLING_PLANS):
            return {
                ...state,
                showLoadingGif: false, 
                message: action.payload.message
            }
        case pending(financeActionTypes.GET_CLIENTS):
            return {
                ...state,
                showLoadingGif: true,
                message: "Retrieving clients..."
            }
        case fulfilled(financeActionTypes.GET_CLIENTS):
            return {
                ...state,
                showLoadingGif: false,
                clients: action.payload.data,
                message: ""
            }
        case rejected(financeActionTypes.GET_CLIENTS):
            return {
                ...state,
                showLoadingGif: false,
                message: action.payload.message
            }
        case pending(financeActionTypes.GET_INVOICES):
            return {
                ...state,
                showLoadingGif: true,
                message: "Retrieving invoices..."
            }
        case fulfilled(financeActionTypes.GET_INVOICES):
            return {
                ...state,
                showLoadingGif: false,
                invoices: action.payload.data,
                message: ""
            }
        case rejected(financeActionTypes.GET_INVOICES):
            return {
                ...state,
                showLoadingGif: false,
                message: action.payload.message
            }  
        case fulfilled(financeActionTypes.RECORD_INVOICE_DETAIL):
            var emptyInvoice= state.emptyInvoice;
            emptyInvoice.details[action.payload.rowNum].price = parseFloat(action.payload.price);
            emptyInvoice.details[action.payload.rowNum].total = emptyInvoice.details[action.payload.rowNum].price;
            emptyInvoice.invoiceTotal = 0;
            for (i = 0; i < emptyInvoice.details.length; i++) {
                if (emptyInvoice.details[i].isClientBillable) {
                    emptyInvoice.invoiceTotal += emptyInvoice.details[i].total;
                }
            }
            return {
                ...state,
                emptyInvoice: emptyInvoice
            }      
        case fulfilled(financeActionTypes.RECORD_INVOICE_DATA):
            return {
                ...state,
                emptyInvoice: action.payload
            }
        case pending(financeActionTypes.REQUEST_TEMPLATE_CHANGE):
            return {
                ...state,
                message: "Sending email..."
            }
        case fulfilled(financeActionTypes.REQUEST_TEMPLATE_CHANGE):
            swal("Thank you for submitting your Modified Language Request", "The back office will get this back to you before end of day and let you know it is ready for use!", "success");
            return {
                ...state,                
                message: "Email sent"
            }
        case rejected(financeActionTypes.REQUEST_TEMPLATE_CHANGE):
            swal("Could not submit request: " + action.payload.message);
            return {
                ...state,
                message: action.payload.message
            }          
        case pending(financeActionTypes.SAVE_INVOICE):
            return {
                ...state,
                showLoadingGif: true,
                message: "Saving invoice..."
            }
        case fulfilled(financeActionTypes.SAVE_INVOICE):
            swal("Invoice saved!");
            var ivs = state.invoices.slice();
            ivs.push(action.payload.data);

            return {
                ...state,                
                invoices: ivs,
                showLoadingGif: false,
                message: "Invoice saved"
            }
        case rejected(financeActionTypes.SAVE_INVOICE):
            swal("Could not save invoice: " + action.payload.message);
            return {
                ...state,
                showLoadingGif: false,
                message: action.payload.message
            }
        case fulfilled(financeActionTypes.SHOW_CLIENT_DETAILS):
            invoices = [];
            var billingPlans = [];
            var showClientDetails = action.payload;
            if (action.payload === 1) {
                showClientDetails = state.showClientDetails;
                invoices = state.invoices;
                billingPlans = state.billingPlans;
            }
            return {
                ...state,
                showLoadingGif: false,
                showClientDetails: showClientDetails,
                invoices: invoices,
                billingPlans: billingPlans,
                message: ""
            }    
        case pending(financeActionTypes.VOID_INVOICE):
            return {
                ...state,
                showLoadingGif: true,
                message: "Voiding invoice..."
            }
        case fulfilled(financeActionTypes.VOID_INVOICE):
            toaster.notify("Invoice voided", { duration: 4000 });
            var invs1 = state.invoices;
            for (i = 0; i < invs1.length; i++) {
                if (invs1[i] === action.payload) {
                    invs1[i].isVoid = true;
                    invs1[i].invoiceStatus.id = 7;
                    invs1[i].invoiceStatus.name = "Void";
                    invs1[i].invoiceStatusID = 7; // void
                    break;
                }
            }
            return {
                ...state,
                invoices: invs1,
                showLoadingGif: false,
                message: ""
            }
        case rejected(financeActionTypes.VOID_INVOICE):
            toaster.notify("Voiding invoice failed:  " + action.payload.message, { duration: 4000 });
            return {
                ...state,
                showLoadingGif: false,
                message: action.payload.message
            } 
        case pending(financeActionTypes.VOID_PAYMENT):
            return {
                ...state,
                message: "Voiding payment..."
            }
        case fulfilled(financeActionTypes.VOID_PAYMENT):
            toaster.notify("Payment voided", { duration: 4000 });
            var invs = state.invoices;
            for (i = 0; i < invs.length; i++) {
                for (var i2 = 0; i2 < invs[i].paymentDetails.length; i2++) {
                    if (invs[i].paymentDetails[i2].paymentDetailID === action.payload ||
                        invs[i].paymentDetails[i2].refundDetailID === action.payload) {
                        invs[i].paymentDetails[i2].isVoid = true;
                        break;
                    }
                }
            }
            return {
                ...state,       
                invoices: invs,
                message: ""
            }
        case rejected(financeActionTypes.VOID_PAYMENT):
            toaster.notify("Voiding payment failed:  " + action.payload.message, { duration: 4000 });
            return {
                ...state,
                message: action.payload.message
            } 
        case pending(financeActionTypes.REISSUE_INVOICE):
            return {
                ...state,
                showLoadingGif: true,
                message: "Resending invoice..."
            }
        case fulfilled(financeActionTypes.REISSUE_INVOICE):
            toaster.notify("Invoice resent!");
            return {
                ...state,
                showLoadingGif: false,
                message: "Invoice sent again!"
            }
        case rejected(financeActionTypes.REISSUE_INVOICE):
            toaster.notify("Invoice reissuance failed");
            return {
                ...state,
                showLoadingGif: false,
                message: action.payload.message
            }
        case pending(financeActionTypes.REQUEST_MANUAL_INVOICE):
            return {
                ...state,
                showLoadingGif: true,
                message: "Requesting manual invoice..."
            }
        case fulfilled(financeActionTypes.REQUEST_MANUAL_INVOICE):
            toaster.notify("Invoice requested!");
            return {
                ...state,
                showLoadingGif: false,
                message: "Invoice sent again!"
            }
        case rejected(financeActionTypes.REQUEST_MANUAL_INVOICE):
            toaster.notify("Invoice request failed");
            return {
                ...state,
                showLoadingGif: false,
                message: action.payload.message
            }
        case pending(financeActionTypes.UPDATE_INVOICE_DETAIL):
                return {
                    ...state,
                    showLoadingGif: true,
                    message: "Requesting invoice update..."
                }
        case fulfilled(financeActionTypes.UPDATE_INVOICE_DETAIL):
                toaster.notify("Done!, Please refresh the page to see the changes");
                return {
                    ...state,
                    showLoadingGif: false,
                    message: ""
                }
        case rejected(financeActionTypes.UPDATE_INVOICE_DETAIL):
                toaster.notify("Update invoice request failed ");
                return {
                    ...state,
                    showLoadingGif: false,
                    message: ''
                }
        case pending(financeActionTypes.GENERATE_OPTIONS_INVOICE_CLIENT):
                    return {
                        ...state,
                        showLoadingGif: true,
                        message: "Requesting invoice update..."
                    }
            case fulfilled(financeActionTypes.GENERATE_OPTIONS_INVOICE_CLIENT):
                    toaster.notify("Done!, Please refresh the page to see the changes");
                    return {
                        ...state,
                        showLoadingGif: false,
                        message: ""
                    }
            case rejected(financeActionTypes.GENERATE_OPTIONS_INVOICE_CLIENT):
                    toaster.notify("Invoice generate options request failed ");
                    return {
                        ...state,
                        showLoadingGif: false,
                        message: ''
                    }                
        case pending(financeActionTypes.FETCH_PAYMENT_DETAILS_INVOICE_FEE):
                    return {
                        ...state,
                        showLoadingGif: true,
                        message: "Requesting invoice update..."
                    }
        case fulfilled(financeActionTypes.FETCH_PAYMENT_DETAILS_INVOICE_FEE):           
                    return {
                        ...state,
                        showLoadingGif: false,
                        message: "",
                        paymentDetailsFee: action.payload
                    }
        case rejected(financeActionTypes.FETCH_PAYMENT_DETAILS_INVOICE_FEE):
                    toaster.notify("Fetch payment details invoice request failed ");
                    return {
                        ...state,
                        showLoadingGif: false,
                        message: ''
                    }
        case fulfilled(financeActionTypes.RESET_PAYMENT_DETAILS_INVOICE_FEE):
                    return {
                        ...state,
                        showLoadingGif: false,
                        message: "",
                        paymentDetailsFee: []
                    }                 
        default:
            return {
                ...state
            }
    }
}
