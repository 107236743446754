import { navBarActionTypes, pending_function, rejected_function, fulfilled_function} from '../actionConsts';

export function showNavbar() {
    const { SHOW_NAVBAR } = navBarActionTypes; 

    return function (dispatch) {
        dispatch(fulfilled_function(SHOW_NAVBAR)); 
    }
}

export function hideNavbar() {
    const { HIDE_NAVBAR } = navBarActionTypes; 

    return function (dispatch) { 
        dispatch(fulfilled_function(HIDE_NAVBAR)); 
    }
}

export function showEditProf() {
    const { SHOW_EDITPROF } = navBarActionTypes; 

    return function (dispatch) {
        dispatch(fulfilled_function(SHOW_EDITPROF)); 
    }
}

export function hideEditProf() {
    const { HIDE_EDITPROF } = navBarActionTypes; 
    
    return function (dispatch) {
        dispatch(fulfilled_function(HIDE_EDITPROF))
    }
}