import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import toaster from 'toasted-notes';
import CheckBox from 'react-bootstrap/FormCheckInput';
import { serverEnvironment } from '../../../hooks/store/actionConsts';
import { connect } from 'react-redux';
import Select from 'react-styled-select';
import swal from 'sweetalert';
import Container from 'react-bootstrap/Container';
import { getContactsByUser, saveIcon } from '../../../hooks/store/actions/contactActions';
import { register, editUserProfile, showEditUserForm , deactivateUser, reactivateUser} from '../../../hooks/store/actions/loginActions';

class UserProfile extends React.Component {
    constructor(props) {

        super(props);
        this.state = {            
            ...props.user,
            isActive: true,
            isDirty: false,
            tempLogo: null,
            dropdownOpen: false,
            dropdownOpenUserRole: false,
            userRoleDisplay: this.setUserRoleDisplay(this.props.user.userRole),
        };

        this.onChange = this.onChange.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onClickCancel = this.onClickCancel.bind(this);
        this.onChangeFile = this.onChangeFile.bind(this);
        this.onCheckInclude = this.onCheckInclude.bind(this);
        this.onSelectUserRole = this.onSelectUserRole.bind(this);
        this.onClickDeactivate = this.onClickDeactivate.bind(this);
        this.onClickReactivate = this.onClickReactivate.bind(this);
        this.onCheckAccessDocs = this.onCheckAccessDocs.bind(this);
        this.onCheckCoachApproval = this.onCheckCoachApproval.bind(this);

        this.setUserRoleDisplay();
    };

    onChange = (e) => {  
        this.setState({ [e.target.id]: e.target.value, isDirty: true });
    }

    toggle = () => this.setState({ dropdownOpen: !this.state.dropdownOpen });
    toggleUserRole = () => this.setState({ dropdownOpenUserRole: !this.state.dropdownOpenUserRole });
    onChangeAffiliateType = (newAffiliateFeeType) => this.setState({ affiliateFeeType: newAffiliateFeeType, isDirty: true });

    setUserRoleDisplay = (userRole) => {
        var result = "Affiliate"
        if (userRole === 2) { result = "Affiliate" }
        if (userRole === 3) { result = "Office Staff" }
        if (userRole === 8) { result = "Finance" }
        return result
    }

    pwdCheck(PW, Email) {

        //^ The password string will start this way
        //(?=.*[A-Za-z])	The string must contain at least 1 alphabetical character
        //(?=.*[0-9])		The string must contain at least 1 numeric character
        //(?=.*[.!@#$%^&*])	The string must contain at least one special character, but we are escaping reserved RegEx characters to avoid conflict
        //(?=.{8,})			The string must be eight characters or longer

            var regEx = new RegExp("^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[.!@#\$%\^&\*])(?=.{8,})");
            var msg = "";

        if (Email.toLowerCase() == PW.toLowerCase()) {
            //alert('Your password can not be the same as your email address');
            msg = 'Your password can not be the same as your email address';
            return msg;
        }

        if (regEx.test(PW)) {
            //alert('password is good');
            return false;
        } else {
            //alert('Your password does not meet our minimum requirements. It must be at least 8 characters long containing at least one number, one letter and one special character');
            //msg = 'Your password does not meet our minimum requirements. It must be at least 8 characters long containing at least one number, one letter and one special character';
            msg = 'Password entered does not meet minimum requirements.';
            return msg;
        }

    }

    onClick = (e) => {
        if (this.state.isDirty) {

            if (this.props.user.userID) {

                var email = this.state.email;
                var firstName = this.state.firstName;
                var lastName = this.state.lastName;
                var phone = this.state.phone;
                var logoURL = this.state.logoURL;
                var includeLogoURL = this.state.includeLogoURL;
                var programInformation = this.state.programInformation;
                var certsAndCredentials = this.state.certsAndCredentials;
                var affiliateFeeType = this.state.affiliateFeeType;
                var userRole = this.state.userRole;
                var accessDocs = this.state.canAccessDocs;
                var isLive = this.state.isLive;
                var website = this.state.website;
                var companyName = this.state.companyName;

                var coachingType = this.state.coachingType;

                if ((!firstName || (firstName && firstName === ""))) {
                    toaster.notify("First Name is required.", { duration: 4000 });
                    return;
                }
                if ((!lastName || (lastName && lastName === ""))) {
                    toaster.notify("Last Name is required.", { duration: 4000 });
                    return;
                }
                if (!email || (email && email === "")) {
                    toaster.notify("Email is required.", { duration: 4000 });
                    return;
                }
                if ((!phone || (phone && phone === ""))) {
                    toaster.notify("Primary Phone is required.", { duration: 4000 });
                    return;
                }

                if (email !== this.props.user.email) {
                    swal({
                        //title: "Username Change", text: "Are you absolutely sure you want to change your Username? If you change this field, you will need to use this new username/email to login to the AC Admin Hub from now on. Please be sure the spelling is correct and that this is an email you have access to in case you forget your password.\n\nConfirm Change?", icon: "warning",
                        title: "Username Change", text: "Are you absolutely sure you want to change this Affiliate Username? If you change this field, the Affiliate will need to use this new username to login to the AC Admin Hub from now on. Please be sure the spelling is correct and that this is an email that the Affiliate has access to in case they forget their password.\n\nConfirm Change?", icon: "warning",
                        buttons:
                        {
                            no: {
                                text: "No",
                                value: "no"
                            },
                            yes: {
                                text: "Yes",
                                value: "yes"
                            }
                        }
                    }).then((value) => {
                        switch (value) {
                            case "yes":
                                this.props.editUserProfile(this.props.token, this.props.user, email.trim(), firstName.trim(),
                                    lastName.trim(), phone, logoURL, includeLogoURL, '', '', programInformation, certsAndCredentials,
                                    affiliateFeeType, userRole, accessDocs, isLive, website, coachingType, companyName);
                                this.setState({ isDirty: false });
                                break;
                            case "no":
                                break;
                            default:
                                break;
                        }
                    });
                } else {
                    this.props.editUserProfile(this.props.token, this.props.user, email.trim(), firstName.trim(),
                        lastName.trim(), phone, logoURL, includeLogoURL, '', '', programInformation, certsAndCredentials,
                        affiliateFeeType, userRole, accessDocs, isLive, website, coachingType, companyName);
                    this.setState({ isDirty: false });
                }
            }
            else {
                var item = { ...this.state }; // update everything

                if (item.password) {

                    var pwdCheck = this.pwdCheck(item.password, item.email);
                    //alert(pwdCheck)
                    if (pwdCheck) { 
                        toaster.notify(pwdCheck, { duration: 4000 });
                        return;
                    }

                    //item.forcePasswordChange = true; // force new user to change their password
                    this.props.register(item);
                }
                else {
                    toaster.notify("Password is required", { duration: 4000 });
                    return;
                }
            }
            this.setState({ isDirty: false });

            this.props.showEditUserForm(false);
        }
        else {
            if (this.props.user.userID) {
                toaster.notify("Not Saved - Nothing Has Been Changed", { duration: 4000 });
                this.onClickCancel();
            }
            else {
                toaster.notify("Password is required", { duration: 4000 });
                return;
            }
        }        
    }

    onClickDeactivate() {
        swal({
            title: "AC Admin", text: "Deactivate this user?", icon: "warning",
            buttons:
            {
                no: {
                    text: "No",
                    value: "no"
                },
                yes: {
                    text: "Yes",
                    value: "yes"
                },
                cancel: false
            }
        }).then((value) => {
            switch (value) {
                case "yes":

                    this.props.deactivateUser(this.props.token, this.props.user.userID);
                    this.props.showEditUserForm(false);
                    break;
                case "no":
                    break;
                default:
                    break;
            }
        });
    }

    onClickReactivate() {
        swal({
            title: "AC Admin", text: "Reactivate this user?", icon: "warning",
            buttons:
            {
                no: {
                    text: "No",
                    value: "no"
                },
                yes: {
                    text: "Yes",
                    value: "yes"
                },
                cancel: false
            }
        }).then((value) => {
            switch (value) {
                case "yes":

                    this.props.reactivateUser(this.props.token, this.props.user);
                    this.props.showEditUserForm(false);
                    break;
                case "no":
                    break;
                default:
                    break;
            }
        });
    }

    onClickCancel = (e) => {
        this.setState({ isDirty: false });
        this.props.showEditUserForm(false);
    }

    onCheckInclude(e) {
        this.setState({ isDirty: true});
        this.setState({ 'includeLogoURL': e.target.checked });
    }

    onCheckAccessDocs(e) {
        this.setState({ isDirty: true});
        this.setState({ canAccessDocs: !this.state.canAccessDocs });
    }

    onCheckCoachApproval(e) {
        this.setState({ isDirty: true});
        this.setState({ isLive : !this.state.isLive });
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    onChangeFile = (e) => {
        this.setState({ "fileName": e.target.files[0], isDirty: true });

        if (e.target.files[0]) {
            var fn = e.target.files[0];

            // 1. Ensure a valid file is selected
            var reader = new FileReader();
            if (fn.size) {
                var fileName = fn.name;
                if (fileName.indexOf(".") > 0) {
                    fileName = fileName.substring(0, fileName.indexOf("."));
                }
                reader.onload = function (e) {

                    var contents = e.target.result;
                    this.setState({ "tempLogo": contents });
                    this.props.saveIcon(this.props.token, this.props.user.userID, fileName, contents);

                }.bind(this);
                reader.readAsDataURL(fn);
                this.setState({ logoURL: serverEnvironment.API_URL + "/image/" + String(this.props.user.userID) + "/" + fileName });
            }
        }
    } 

    onSelectUserRole(item) {
        this.setState({ userRole: item, isDirty: true });
        this.setState({ userRoleDisplay: this.setUserRoleDisplay(item) });
    }
    
    render() {
        
        return (            
                <Modal.Body className="full-screen">
                    <Container className="Modal.Body form-editcontact modal-small">
                        <Form.Group as={Row}>                       
                            <Form.Label column sm={2}>
                                User Role
                            </Form.Label>
                        <Col sm={8}>

                            <Dropdown isOpen={this.state.dropdownOpenUserRole} toggle={this.toggleUserRole}>
                                <DropdownToggle className="ddStyle" caret>
                                    {this.state.userRoleDisplay ? this.state.userRoleDisplay + ' ' : 'User Role'}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem header>User Role</DropdownItem>
                                    <DropdownItem disabled>{this.state.userRole <= 2 ? "Affiliate" : this.state.userRoleDisplay} <i>(current selection)</i></DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem></DropdownItem>
                                    <DropdownItem className="text-primary" onClick={() => this.onSelectUserRole(2)}>Affiliate</DropdownItem>
                                    <DropdownItem className="text-primary" onClick={() => this.onSelectUserRole(3)}>Office Staff</DropdownItem>
                                    <DropdownItem disabled={(this.props.sysUser.userRole === 8 || this.props.sysUser.userRole === 37) ? false : true} className={(this.props.sysUser.userRole === 8 || this.props.sysUser.userRole === 37) ? 'text-primary' : 'text-secondary' } onClick={() => this.onSelectUserRole(8)}>Finance</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>

                        </Col>                        
                     </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>
                            First Name&nbsp;<span style={{ color: 'red' }}>*</span>
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control id="firstName" placeholder="First Name" onChange={this.onChange} value={this.state.firstName} defaultValue={this.props.user.firstName} disabled={ (this.props.sysUser.userRole === 8 || this.props.sysUser.userRole === 37) || (this.props.user.userRole !== 8 && this.props.user.userRole !== 37) ? false : true } />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>
                            Last Name&nbsp;<span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                        <Col sm={8}>
                            <Form.Control id="lastName" placeholder="Last Name" onChange={this.onChange} value={this.state.lastName} defaultValue={this.props.user.lastName} disabled={(this.props.sysUser.userRole === 8 || this.props.sysUser.userRole === 37) || (this.props.user.userRole !== 8 && this.props.user.userRole !== 37) ? false : true} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>
                            Username&nbsp;<span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                        <Col sm={5}>
                            <Form.Control id="email" type="email" placeholder="Username" value={this.state.email} defaultValue={this.props.user.email} onChange={this.onChange} disabled={(this.props.sysUser.userRole === 8 || this.props.sysUser.userRole === 37) || (this.props.user.userRole !== 8 && this.props.user.userRole !== 37) ? false : true} />
                        </Col>
                    </Form.Group>
                    {!this.props.user.userID && (
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>
                                Password&nbsp;<span style={{ color: 'red' }}>*</span>
                            </Form.Label>
                            <Col sm={5}>
                                <Form.Control id="password" type="password" placeholder="password" value={this.state.password} onChange={this.onChange} />
                                <span style={{fontStyle: 'italic', fontWeight: '600', fontSize: '12px'}}>*Password must contain 8 characters, at least one letter, one number and a symbol</span>
                            </Col>
                        </Form.Group>
                    )}
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>
                            Primary Phone&nbsp;<span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                        <Col sm={10}>
                            <Form.Control id="phone" type="tel" placeholder="Phone" maxLength="31" value={this.state.phone} onChange={this.onChange} defaultValue={this.props.user.phone} disabled={(this.props.sysUser.userRole === 8 || this.props.sysUser.userRole === 37) || (this.props.user.userRole !== 8 && this.props.user.userRole !== 37) ? false : true} />
                        </Col>
                        </Form.Group>     
                        <Form.Group as={Row} className="editProfileLogoRow">
                        <Form.Label column sm={2}>
                            Affiliate Logo
                            </Form.Label>
                        <Col sm={6}><Form.Control className="smallTextBox" type="text" id="logoURL" onChange={this.onChange} value={this.state.logoURL} disabled={true} /></Col>
                        <Col sm={1}>
                            <a href={this.state.tempLogo ? this.state.tempLogo : this.state.logoURL} alt="" target="blank" rel="noopener noreferrer"><img src={this.state.tempLogo ? this.state.tempLogo : this.state.logoURL} height="60" width="" alt="" /></a>
                        </Col>
                        <Col sm={3}><input type="file" className="user-photo-input selectPhotoEditProfile" id="logoURLfile" onChange={this.onChangeFile} accept="image/*" defaultValue="" /></Col>
                    </Form.Group>

                        <Form.Group as={Row} className="editProfileIncludeLogo">
                        <Col sm={2}></Col>
                        <Col sm={6} className={this.state.logoURL ? "" : "includeLogoCheckbox"}>
                            <CheckBox style={{ marginLeft: "0px", fontSize: "30px" }} checked={this.state.includeLogoURL} onChange={this.onCheckInclude} /><br />
                            <Form.Label>Include Logo on Emails and Invoices</Form.Label>
                        </Col>
                    </Form.Group>


                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>
                                CTP Program 
                            </Form.Label>
                            <Col sm={8}>
                                <Form.Control id="programInformation" onChange={this.onChange} value={this.state.programInformation} defaultValue={this.props.user.programInformation} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>
                                Certs/Credentials
                            </Form.Label>
                            <Col sm={8}>
                                <Form.Control id="certsAndCredentials" onChange={this.onChange} value={this.state.certsAndCredentials} defaultValue={this.props.user.certsAndCredentials} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>
                                Website
                            </Form.Label>
                            <Col sm={8}>
                                <Form.Control id="website" onChange={this.onChange} value={this.state.website} defaultValue={this.props.user.website} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>
                                Coaching Type
                            </Form.Label>
                            <Col sm={8}>
                                <Form.Control id="coachingType" onChange={this.onChange} value={this.state.coachingType} defaultValue={this.props.user.coachingType} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>
                                Company Name
                            </Form.Label>
                            <Col sm={8}>
                                <Form.Control id="companyName" onChange={this.onChange} value={this.state.companyName} defaultValue={this.props.user.companyName} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="editProfileIncludeLogo">
                            <Col sm={2}></Col>
                            <Col sm={6} className={this.state.logoURL ? "" : "includeLogoCheckbox"}>
                                <CheckBox style={{ marginLeft: "0px", fontSize: "30px" }} checked={this.state.canAccessDocs} onChange={this.onCheckAccessDocs} /><br />
                                <Form.Label>Can Access Docs?</Form.Label>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm={2}></Col>
                            <Col sm={6}>
                                <CheckBox style={{ marginLeft: "0px", fontSize: "30px" }} checked={this.state.isLive} onChange={this.onCheckCoachApproval} /><br />
                                <Form.Label>Coach Approved? (It will depend if it appears in the coach finder page)</Form.Label>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>
                                Affiliate Fee Type
                            </Form.Label>
                        <Col sm={8}>

                            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                <DropdownToggle className="ddStyle" caret>
                                    {this.state.affiliateFeeType ? this.state.affiliateFeeType + ' ' : "Select Affiliate Fee Type"}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem header>Affiliate Fee Type</DropdownItem>
                                    <DropdownItem disabled>{this.state.affiliateFeeType} <i>(current selection)</i></DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem></DropdownItem>
                                    <DropdownItem className="text-primary" onClick={() => this.onChangeAffiliateType("Standard USA") }>Standard USA</DropdownItem>
                                    <DropdownItem className="text-primary" onClick={() => this.onChangeAffiliateType("Standard Canadian")}>Standard Canadian</DropdownItem>
                                    <DropdownItem className="text-primary" onClick={() => this.onChangeAffiliateType("Flat Rate USA") }>Flat Rate USA</DropdownItem>
                                    <DropdownItem className="text-primary" onClick={() => this.onChangeAffiliateType("Flat Rate Canadian")}>Flat Rate Canadian</DropdownItem>
                                    <DropdownItem className="text-primary" onClick={() => this.onChangeAffiliateType("Standard USA & Canadian")}>Standard USA & Canadian</DropdownItem>
                                    <DropdownItem className="text-primary" onClick={() => this.onChangeAffiliateType("Flat Rate USA & Canadian")}>Flat Rate USA & Canadian</DropdownItem>
                                    <DropdownItem className="text-primary" onClick={() => this.onChangeAffiliateType("Leader Standard USA")}>Leader Standard USA</DropdownItem>
                                    <DropdownItem className="text-primary" onClick={() => this.onChangeAffiliateType("Leader Standard Canadian")}>Leader Standard Canadian</DropdownItem>
                                    <DropdownItem className="text-primary" onClick={() => this.onChangeAffiliateType("Standard USA - No Fee")}>Standard USA - No Fee</DropdownItem>
                                    <DropdownItem className="text-primary" onClick={() => this.onChangeAffiliateType("Standard Canadian - No Fee")}>Standard Canadian - No Fee</DropdownItem>
                                    <DropdownItem className="text-primary" onClick={() => this.onChangeAffiliateType("Leader Standard USA - No Fee")}>Leader Standard USA - No Fee</DropdownItem>
                                    <DropdownItem className="text-primary" onClick={() => this.onChangeAffiliateType("Leader Standard Canada - No Fee")}>Leader Standard Canada - No Fee</DropdownItem>
                                    <DropdownItem className="text-primary" onClick={() => this.onChangeAffiliateType("Standard Canadian & USA")}>Standard Canadian & USA</DropdownItem>
                                    <DropdownItem className="text-primary" onClick={() => this.onChangeAffiliateType("Standard USA & Canadian – No Fee")}>Standard USA & Canadian – No Fee</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>

                        </Col>
                        </Form.Group>
                    <Form.Group as={Row}>
                        <center><div className="loginModalFooter">{this.props.message}</div></center>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={2} width="50px">
                                <button className="button-gridrow-edit-profile " href="#" onClick={this.onClick}>SAVE</button>
                        </Col>
                        { (this.props.user.userID && this.props.user.isActive) ? (
                            <Col sm={2} width="50px">
                                <button className={(this.props.sysUser.userRole === 8 || this.props.sysUser.userRole === 37) || (this.props.user.userRole !== 8 && this.props.user.userRole !== 37) ? 'button-gridrow-edit-profile' : 'hidden'} onClick={this.onClickDeactivate}>DEACTIVATE</button>
                            </Col>
                        ) : (<></>)}
                        { (this.props.user.userID && !this.props.user.isActive) ? (
                            <Col sm={2} width="50px">
                                <button className={(this.props.sysUser.userRole === 8 || this.props.sysUser.userRole === 37) || (this.props.user.userRole !== 8 && this.props.user.userRole !== 37) ? 'button-gridrow-edit-profile' : 'hidden'} onClick={this.onClickReactivate}>REACTIVATE</button>
                            </Col>
                        ) : (<></>)}

                        <Col sm={2} width="50px">
                                <button className="button-gridrow-edit-profile" href="#" onClick={this.onClickCancel}>CANCEL</button>
                        </Col> 

                        <Col sm={2} width="50px">
                            &nbsp;
                            </Col>
                        <Col sm={4}>
                            <span style={{ color: 'red' }}>* Required Field</span>
                        </Col>

                        </Form.Group>
                    </Container>                    
                </Modal.Body>           
        );
    }
}

function mapStateToProps(state) {
    return {
        token: state.loginReducer.loginToken,
        message: state.loginReducer.message,
        sysUser: state.loginReducer.sysUser,
    }
}
export default connect(mapStateToProps, { register, getContactsByUser, editUserProfile, showEditUserForm, saveIcon, deactivateUser, reactivateUser })(UserProfile);

