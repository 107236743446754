import axios from 'axios';

import { affiliationActionTypes, loginActionTypes, pending_function, rejected_function, fulfilled_function, serverEnvironment } from '../actionConsts';

export var axiosConfig = {
    headers: { 'token': '', 'refUrl': document.location.host }
};


export function getInvoiceReport(token, userId, dueDate) {
    const { GET_INVOICE_REPORT } = affiliationActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(GET_INVOICE_REPORT));
        
        var url = serverEnvironment.API_URL + '/invoice/report/' + String(userId);
            url += "?dueDate=" + (dueDate.getMonth()+1) + "-" + dueDate.getDate() + "-" + dueDate.getFullYear();
        
        axiosConfig.headers.token = token;

        axios
            .get(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(GET_INVOICE_REPORT, res));
            })
            .catch(err => {                
                if (err.response && err.response.status === 401) {                    
                    dispatch(fulfilled_function(LOGOUT), err);                    
                }
                dispatch(rejected_function(GET_INVOICE_REPORT, err));
            });
    }
}

export function getAffiliationProgress(token, userId) {
    const { GET_AFFILIATION_PROGRESS } = affiliationActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {

        dispatch(pending_function(GET_AFFILIATION_PROGRESS));
        
        var url = serverEnvironment.API_URL + '/invoice/affiliation-progress/' + userId;
        axiosConfig.headers.token = token;

        axios
            .get(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(GET_AFFILIATION_PROGRESS, res));
            })
            .catch(err => {                
                if (err.response && err.response.status === 401) {                    
                    dispatch(fulfilled_function(LOGOUT), err);                    
                }
                dispatch(rejected_function(GET_AFFILIATION_PROGRESS, err));
            });
    }
}