
import axios from 'axios';

import { systemActionTypes, pending_function, rejected_function, fulfilled_function, serverEnvironment, sleep } from '../actionConsts';

export var axiosConfig = {
    headers: { 'token': '', 'refUrl': document.location.host }
};


/*
 * getGenders() 
 * 
 * */
export function getGenders(token) {
    const { GENDERS } = systemActionTypes;

    return function (dispatch, getState) {

        dispatch(pending_function(GENDERS));

        var url = serverEnvironment.API_URL + '/gender';
        axiosConfig.headers.token = token;

        axios
            .get(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(GENDERS, res));
            })
            .catch(err => {
                dispatch(rejected_function(GENDERS, err));
            });

    }
}


/*
 * getMaritalStatus() 
 * 
 * */
export function getMaritalStatus(token) {
    const { MARITAL_STATUS } = systemActionTypes;

    return function (dispatch, getState) {

        dispatch(pending_function(MARITAL_STATUS));

        var url = serverEnvironment.API_URL + '/maritalStatus';
        axiosConfig.headers.token = token;

        axios
            .get(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(MARITAL_STATUS, res));
            })
            .catch(err => {
                dispatch(rejected_function(MARITAL_STATUS, err));
            });

    }
}


/*
 * getContactTypes() 
 * 
 * */
export function getContactTypes(token) {
    const { CONTACT_TYPE } = systemActionTypes;

    return function (dispatch, getState) {

        dispatch(pending_function(CONTACT_TYPE));

        var url = serverEnvironment.API_URL + '/contactType';
        axiosConfig.headers.token = token;

        axios
            .get(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(CONTACT_TYPE, res));
            })
            .catch(err => {
                dispatch(rejected_function(CONTACT_TYPE, err));
            });

    }
}

/*
 * getAddressTypes() 
 * 
 * */
export function getAddressTypes(token) {
    const { ADDRESS_TYPE } = systemActionTypes;

    return function (dispatch, getState) {

        dispatch(pending_function(ADDRESS_TYPE));

        var url = serverEnvironment.API_URL + '/addressType';
        axiosConfig.headers.token = token;

        axios
            .get(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(ADDRESS_TYPE, res));
            })
            .catch(err => {
                dispatch(rejected_function(ADDRESS_TYPE, err));
            });

    }
}


/*
 * getPaymentMethods() 
 * 
 * */
export function getPaymentMethods(token) {
    const { PAYMENT_METHOD } = systemActionTypes;

    return function (dispatch, getState) {

        dispatch(pending_function(PAYMENT_METHOD));

        var url = serverEnvironment.API_URL + '/paymentType';
        axiosConfig.headers.token = token;

        axios
            .get(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(PAYMENT_METHOD, res));
            })
            .catch(err => {
                dispatch(rejected_function(PAYMENT_METHOD, err));
            });

    }
}

/*
 * getInvoiceStatus() 
 * 
 * */
export function getInvoiceStatus(token) {
    const { INVOICE_STATUS } = systemActionTypes;

    return function (dispatch, getState) {

        dispatch(pending_function(INVOICE_STATUS));

        var url = serverEnvironment.API_URL + '/invoiceStatus';
        axiosConfig.headers.token = token;

        axios
            .get(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(INVOICE_STATUS, res));
            })
            .catch(err => {
                dispatch(rejected_function(INVOICE_STATUS, err));
            });

    }
}


/*
 * getInvoiceTypes() 
 * 
 * */
export function getInvoiceTypes(token) {
    const { INVOICE_TYPE } = systemActionTypes;

    return function (dispatch, getState) {

        dispatch(pending_function(INVOICE_TYPE));

        var url = serverEnvironment.API_URL + '/invoiceType';
        axiosConfig.headers.token = token;

        axios
            .get(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(INVOICE_TYPE, res));
            })
            .catch(err => {
                dispatch(rejected_function(INVOICE_TYPE, err));
            });

    }
}

/*
 * getCreditCardChargeTypes() 
 * 
 * */
export function getCreditCardChargeTypes(token) {
    const { CC_CHARGE_TYPE } = systemActionTypes;

    return function (dispatch, getState) {

        dispatch(pending_function(CC_CHARGE_TYPE));

        var url = serverEnvironment.API_URL + '/creditCardChargeType';
        axiosConfig.headers.token = token;

        axios
            .get(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(CC_CHARGE_TYPE, res));
            })
            .catch(err => {
                dispatch(rejected_function(CC_CHARGE_TYPE, err));
            });

    }
}

/*
 * getTaxRates() 
 * 
 * */
export function getTaxRates(token) {
    const { TAX_RATES } = systemActionTypes;

    return function (dispatch, getState) {

        dispatch(pending_function(TAX_RATES));

        var url = serverEnvironment.API_URL + '/taxRateByProvince';
        axiosConfig.headers.token = token;

        axios
            .get(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(TAX_RATES, res));
            })
            .catch(err => {
                dispatch(rejected_function(TAX_RATES, err));
            });

    }
}


/*
 * getSystemNotifications() 
 * 
 * */
export function getSystemNotifications(token) {
    const { GET_SYSTEMNOTIFICATIONS } = systemActionTypes;

    return function (dispatch, getState) {

        dispatch(pending_function(GET_SYSTEMNOTIFICATIONS));

        var url = serverEnvironment.API_URL + '/systemnotifications';
        axiosConfig.headers.token = token;

        axios
            .get(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(GET_SYSTEMNOTIFICATIONS, res));
            })
            .catch(err => {
                dispatch(rejected_function(GET_SYSTEMNOTIFICATIONS, err));
            });

    }
}


/*
 * updateSystemNotification() 
 * 
 * */
export function updateSystemNotification(token, notification) {
    const { UPDATE_SYSTEMNOTIFICATION } = systemActionTypes;
    const { GET_SYSTEMNOTIFICATIONS } = systemActionTypes;

    return function (dispatch, getState) {

        dispatch(pending_function(UPDATE_SYSTEMNOTIFICATION));

        var url = serverEnvironment.API_URL + '/systemnotification/update';
        axiosConfig.headers.token = token;

        axios
            .put(url, notification, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(UPDATE_SYSTEMNOTIFICATION, res));
            })
            .then((res) => {
                dispatch(pending_function(GET_SYSTEMNOTIFICATIONS));

                url = serverEnvironment.API_URL + '/systemnotifications';
                axiosConfig.headers.token = token;

                axios
                    .get(url, axiosConfig)
                    .then((res) => {
                        dispatch(fulfilled_function(GET_SYSTEMNOTIFICATIONS, res));
                    })
                    .catch(err => {
                        dispatch(rejected_function(GET_SYSTEMNOTIFICATIONS, err));
                    });
            })
            .catch(err => {
                dispatch(rejected_function(UPDATE_SYSTEMNOTIFICATION, err));
            })
    }
}


/*
 * deleteSystemNotification() 
 * 
 * */
export function deleteSystemNotification(token, notificationId) {
    const { DELETE_SYSTEMNOTIFICATION } = systemActionTypes;
    const { GET_SYSTEMNOTIFICATIONS } = systemActionTypes;

    return function (dispatch, getState) {

        dispatch(pending_function(DELETE_SYSTEMNOTIFICATION));

        var url = serverEnvironment.API_URL + '/systemnotification/delete/' + String(notificationId);
        axiosConfig.headers.token = token;

        axios
            .delete(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(DELETE_SYSTEMNOTIFICATION, res));
            })
            .then((res) => {
                dispatch(pending_function(GET_SYSTEMNOTIFICATIONS));

                url = serverEnvironment.API_URL + '/systemnotifications';
                axiosConfig.headers.token = token;

                axios
                    .get(url, axiosConfig)
                    .then((res) => {
                        dispatch(fulfilled_function(GET_SYSTEMNOTIFICATIONS, res));
                    })
                    .catch(err => {
                        dispatch(rejected_function(GET_SYSTEMNOTIFICATIONS, err));
                    });
            })
            .catch(err => {
                dispatch(rejected_function(DELETE_SYSTEMNOTIFICATION, err));
            })
    }
}


/*
 * addSystemNotification() 
 * 
 * */
export function addSystemNotification(token, notification) {
    const { ADD_SYSTEMNOTIFICATION } = systemActionTypes;
    const { GET_SYSTEMNOTIFICATIONS } = systemActionTypes;

    return function (dispatch, getState) {

        dispatch(pending_function(ADD_SYSTEMNOTIFICATION));

        var url = serverEnvironment.API_URL + '/systemnotification/add';
        axiosConfig.headers.token = token;

        axios
            .post(url, notification, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(ADD_SYSTEMNOTIFICATION, res));
            })
            .then((res) => {
                dispatch(pending_function(GET_SYSTEMNOTIFICATIONS));

                url = serverEnvironment.API_URL + '/systemnotifications';
                axiosConfig.headers.token = token;

                axios
                    .get(url, axiosConfig)
                    .then((res) => {
                        dispatch(fulfilled_function(GET_SYSTEMNOTIFICATIONS, res));
                    })
                    .catch(err => {
                        dispatch(rejected_function(GET_SYSTEMNOTIFICATIONS, err));
                    });
            })
            .catch(err => {
                dispatch(rejected_function(ADD_SYSTEMNOTIFICATION, err));
            })
    }
}

/*
 * getReports() 
 * 
 * */
export function getReports(token, impersonateUserRole) {
    const { GET_REPORTS } = systemActionTypes;

    return function (dispatch, getState) {

        dispatch(pending_function(GET_REPORTS));

        var url = serverEnvironment.API_URL + '/report/' + impersonateUserRole;
        axiosConfig.headers.token = token;

        axios
            .get(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(GET_REPORTS, res));
            })
            .catch(err => {
                dispatch(rejected_function(GET_REPORTS, err));
            });

    }
}


/*
 * runReport() 
 * 
 * */
export function runReport(token, reportId, params, impersonateUserRole, impersonateUserID) {
    const { RUN_REPORT } = systemActionTypes;

    return function (dispatch, getState) {

        console.log('report-run', params);

        dispatch(pending_function(RUN_REPORT));

        var url = serverEnvironment.API_URL + '/report/run/' + String(reportId) + '/' + String(impersonateUserRole) + '/' + String(impersonateUserID);
        axiosConfig.headers.token = token;
        axiosConfig.headers['Content-Type'] = 'application/json;charset=UTF-8'

        var formData = new FormData();

        /*if(params[0].name === '@File'){
            axiosConfig.headers['Content-Type'] = 'multipart/form-data';
            formData.append('File', params[0].value);
            params = formData;
        }*/

        axios
            .post(url, params, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(RUN_REPORT, res));
            })
            .catch(err => {
                dispatch(rejected_function(RUN_REPORT, err));
            });

    }
}

/*
 * setParam()
 * 
 * */
export function setParam(param) {
    const { SET_PARAM } = systemActionTypes;

    return function (dispatch, getState) {
        dispatch(fulfilled_function(SET_PARAM, param));
    }
}

/*
* setSuboption()
*
* */
export function setSuboption(param) {
    const { SET_SUBOPTION } = systemActionTypes;

    return function (dispatch, getState) {
        dispatch(fulfilled_function(SET_SUBOPTION, param));
    }
}

/*
 * clearParams()
 * 
 * */
export function clearParams() {
    const { CLEAR_PARAMS} = systemActionTypes;

    return function (dispatch, getState) {
        dispatch(fulfilled_function(CLEAR_PARAMS));
    }
}

/*
 * sortCol()
 * 
 *  */
export function sortCol(pos) {

    const { SORT_COL } = systemActionTypes;
    return function (dispatch) {
        dispatch(pending_function(SORT_COL, pos));
        dispatch(fulfilled_function(SORT_COL, pos));
    }
}

/*
 * selectReport()
 * 
 *  */
export function selectReport() {
    const { SELECT_REPORT } = systemActionTypes;
    return function (dispatch) {
        dispatch(pending_function(SELECT_REPORT));
        sleep(50).then(() => {
            dispatch(fulfilled_function(SELECT_REPORT));
        });
    }

}

export function runReportBillExports(exportId) {
    const { RUN_REPORT } = systemActionTypes;

    return function (dispatch, getState) {

        // console.log('runReportBillExports', exportId);

        dispatch(pending_function(RUN_REPORT));

        var url = serverEnvironment.AC_BACKEND_API_URL + '/api/v1/bill/find-export/' + exportId;

        axios
        .get(url)
        .then((res) => {
            dispatch(fulfilled_function(RUN_REPORT, res));
        })
        .catch(err => {
            dispatch(rejected_function(RUN_REPORT, err));
        });

    }
}


export function setOpenModalBillExportDetails(value) {
    const { OPEN_MODAL_BILLEXPORTDETAILS } = systemActionTypes;
    return function (dispatch, getState) {
        dispatch(fulfilled_function(OPEN_MODAL_BILLEXPORTDETAILS, value));
    }

}

export function setOpenModalDetails(value) {
    const { OPEN_MODAL_DETAILS } = systemActionTypes;
    return function (dispatch, getState) {
        dispatch(fulfilled_function(OPEN_MODAL_DETAILS, value));
    }

}


