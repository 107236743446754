import React from 'react';
import Label from 'react-bootstrap/FormLabel';
import { connect } from 'react-redux';
import { getAffiliationProgress } from '../../../hooks/store/actions/affiliationActions';

class AffiliationProgressBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        
        if(this.props.user.affiliateFeeType){
            this.props.getAffiliationProgress(this.props.logintoken, this.props.user.id);
        }

    }

    formatCurrency(value) {
        let val = (value/1).toFixed(2);
        let prev = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        return prev;
    }

    render() {
        return (
            <>
                <span className="progress-bar-title">Affiliation progress bar</span>
                <div className="progress-bar">
                    <div style={{width: this.props.progress + "%"}}>{this.formatCurrency(this.props.progress)}%</div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        sysUser: state.loginReducer.sysUser,
        progress: state.affiliationReducer.progress,
    }
}

export default connect(mapStateToProps, { getAffiliationProgress })(AffiliationProgressBar);