//#region imports
//<atom-region>

//#region app imports
//<atom-region>
import React, { Component, useState, useEffect, useContext, useReducer, useRef } from "react";
import { connect } from "react-redux";

//stylesheet
import './components/app/style/App.css';
import 'toasted-notes/src/styles.css';
//fonts
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fab, fas)

import LoginPage from './components/form/LoginPage.js';
import HomePage from './components/form/HomePage.js';
import MaintenancePage from './components/form/MaintenancePage.js'

import { serverEnvironment } from './hooks/store/actionConsts.js';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import {
    getGenders,
    getContactTypes,
    getMaritalStatus,
    getAddressTypes,
    getCreditCardChargeTypes,
    getInvoiceStatus,
    getInvoiceTypes,
    getPaymentMethods,
    getTaxRates
} from './hooks/store/actions/sysActions.js';
import ResetPasswordPage from "./components/form/ResetPasswordPage.js";



//export context objects
export const HeaderContext = React.createContext();
export const NavBarContext = React.createContext();
export const MainContext = React.createContext();
export const SideBarContext = React.createContext();
export const FooterContext = React.createContext();

//#region App --- Component Logic
//<atom-region>
const App = (props) => {

    
    let mainHTML_ElementStyle = { textAlign: 'center' }

    if (props.token && (!props.genderTypes || props.genderTypes.length === 0)) {
        props.getContactTypes(props.token);
        props.getGenders(props.token);
        props.getMaritalStatus(props.token);
        props.getAddressTypes(props.token);
        props.getCreditCardChargeTypes(props.token);
        props.getInvoiceStatus(props.token);
        props.getInvoiceTypes(props.token);
        props.getPaymentMethods(props.token);
        props.getTaxRates(props.token);
    }

    //render App
    return (
    <>
        { serverEnvironment.MAINT_MODE !== "none" && (
            <MaintenancePage/>
            )}
        {
            serverEnvironment.MAINT_MODE === "none" && (
                <Router>
                    <div>
                        <Switch>
                            <Route path="/login">
                                <LoginPage />
                            </Route>
                            <Route path="/home">
                                {!props.token && (
                                    <LoginPage />
                                )}
                                {props.token && !props.forcePasswordChange && (
                                    <HomePage />
                                )
                                }
                            </Route>
                            <Route path="/newContact">
                                {!props.token && (
                                    <LoginPage />
                                )}
                                {props.token && !props.forcePasswordChange && (
                                    <HomePage />
                                )}
                            </Route>
                            <Route path="/viewContacts">
                                {!props.token && (
                                    <LoginPage />
                                )}
                                {props.token && !props.forcePasswordChange && (
                                    <HomePage />
                                )}
                            </Route>
                            <Route path="/reset-password">
                                <ResetPasswordPage />
                            </Route>
                            <Route path="/">
                                {!props.token && (
                                    <LoginPage />
                                )}
                                {props.token && !props.forcePasswordChange && (
                                    <HomePage />
                                )}
                            </Route>
                        </Switch>
                    </div>
                </Router>
            )
            }
            </>
    );
}
//</atom-region>
//#endregion App --- Component Logic



function mapStateToProps(state) {
    return {
        token: state.loginReducer.loginToken,
        message: state.loginReducer.message,
        user: state.loginReducer.user,
        forcePasswordChange: state.loginReducer.forcePasswordChange,
        genderTypes: state.sysReducer.genderTypes
    }
}

export default connect(mapStateToProps, {
    getGenders,
    getContactTypes,
    getMaritalStatus,
    getAddressTypes,
    getCreditCardChargeTypes,
    getInvoiceStatus,
    getInvoiceTypes,
    getPaymentMethods,
    getTaxRates
})(App);
