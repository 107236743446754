import toaster from 'toasted-notes';
import { libraryActionTypes, pending, rejected, fulfilled } from '../actionConsts.js';

function updateLibraryOpenItem(data, param) {
    if(!param)
       return;
    data.forEach(item => {
        if(item.id === param.id){
            item.open = !item.open;
           // console.log('found, ', item);
        }
        if(item.items && item.items.length){
            updateLibraryOpenItem(item.items, param);
        }
    });
}

export default function reducer(state = {
    libraryData: [],
    showLoadingGif: false,
}, action) {

    switch (action.type) {
        case pending(libraryActionTypes.GET_LIBRARY_DATA):
            return {
                ...state,
                showLoadingGif: true,
            }
        case fulfilled(libraryActionTypes.GET_LIBRARY_DATA):
            return {
                ...state,
                libraryData: action.payload.data.data,
                showLoadingGif: false,
            }
        case rejected(libraryActionTypes.GET_LIBRARY_DATA):
            toaster.notify(action.payload.message, { duration: 4000 });
            // eslint-disable-next-line no-case-declarations
            let message = ''
            if (action.payload.data)
                message = action.payload.data.Message
            return {
                ...state,
                showLoadingGif: false,
            }
        case fulfilled(libraryActionTypes.UPDATE_OPEN_ITEM):
            // eslint-disable-next-line no-case-declarations
            let newData = Object.assign(state.libraryData, {});
            updateLibraryOpenItem(newData, action.payload);
            return {
                    ...state,
                    libraryData: newData,
                    showLoadingGif: false,
            }
        case fulfilled(libraryActionTypes.UPDATE_LOADING):
            return {
                    ...state,
                    showLoadingGif: action.payload,
                }
        default:
            return {
                ...state
            }
        }
};
