import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Modal, Container, Form } from 'react-bootstrap';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { sendRequestClient } from '../../hooks/store/actions/clientActions';
import toaster from "toasted-notes";

class InvoiceRequestModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openModal: false,
            dropdownOpenUserRole: false,
            invoiceMonth: '',
            invoiceYear: '',
            invoiceAmount: '',
            clientName: '',
            description: '',
            actionRequest: '',
            errorMessage: '',
            dropDownToggleInvoiceMonthValue: false,
        };

        this.openModalClientRequest = this.openModalClientRequest.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.onSelectActionRequesting = this.onSelectActionRequesting.bind(this);
        this.handleSendClientRequest = this.handleSendClientRequest.bind(this);
        this.toggleUserRole = this.toggleUserRole.bind(this);
        this.resetModalClientRequest = this.resetModalClientRequest.bind(this);
        this.onSelectInvoiceMonth = this.onSelectInvoiceMonth.bind(this);
        this.dropDownToggleInvoiceMonth = this.dropDownToggleInvoiceMonth.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // console.log(nextProps);
        if(nextProps.openModal){
            // console.log(nextProps.currentContact);
            const name = nextProps.currentContact.firstName + " " + nextProps.currentContact.lastName;
            this.openModalClientRequest(name);
        }
    }

    openModalClientRequest(nameClient) {
        // console.log('open modal out');
        this.setState({ openModal: true, clientName: nameClient })
    }

    handleCloseModal() {
        this.props.closeModal();
        // console.log('handleCloseModal');
        this.resetModalClientRequest();
        this.setState({ openModal: false })
    }

    isValidModal(){
        if(!this.state.clientName){
            this.setState({ errorMessage: 'Client Name is required'});
            return false;
        }
        if(!this.state.invoiceMonth){
            this.setState({ errorMessage: 'Invoice Month is required'});
            return false;
        }
        if(!this.state.invoiceYear){
            this.setState({ errorMessage: 'Invoice Year is required'});
            return false;
        }
        if(!this.state.invoiceAmount){
            this.setState({ errorMessage: 'Invoice Amount is required'});
            return false;
        }
        if(!this.state.actionRequest){
            this.setState({ errorMessage: 'Action Requesting is required'});
            return false;
        }
        if(!this.state.description){
            this.setState({ errorMessage: 'Detail Explanation is required'});
            return false;
        }
        return true;
    }

    formatCurrency(value) {
        let val = (value/1).toFixed(2);
        let prev = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        return '$' + prev;
    }

    handleSendClientRequest = (event) => {

        if(!this.isValidModal()){
            return;
        }

        let body = "<h3> Client Request </h3>";
        body += `<p> Client Name: ${this.state.clientName}</p>`;
        body += `<p> Invoice Month: ${this.state.invoiceMonth}</p>`;
        body += `<p> Invoice Year: ${this.state.invoiceYear}</p>`;
        body += `<p> Invoice Amount: ${this.formatCurrency(this.state.invoiceAmount)}</p>`;
        body += `<p> Action Request: ${this.state.actionRequest}</p>`;
        body += `<p> Detailed Explanation: ${this.state.description}</p>`;

        sendRequestClient(this.props.logintoken, "Client Request", "Help@accomplishmentcoaching.com", body)
        .then((res) => {
            this.setState({ openModal: false })
            toaster.notify("Request Successfully Submitted. Your request will be processed within the next 24 hours or 1 business day.", { duration: 9000 });
            this.resetModalClientRequest();
        }).catch(err => {
            if (err.response && err.response.status === 400) {
                toaster.notify("Unable to send request " + item.name + ", try again later", { duration: 4000 });
            }
            this.resetModalClientRequest();
        });
    }

    resetModalClientRequest = () => {
        this.setState({
            dropdownOpenUserRole: false,
            invoiceMonth: '',
            invoiceYear: '',
            invoiceAmount: '',
            clientName: '',
            description: '',
            actionRequest: '',
            errorMessage: '',
        });
    }

    onChange = (e) => {  
        this.setState({ [e.target.id]: e.target.value, isDirty: true });
    }

    toggleUserRole = () => { 
        this.setState({ dropdownOpenUserRole: !this.state.dropdownOpenUserRole });
    }

    dropDownToggleInvoiceMonth = () => { 
        this.setState({ dropDownToggleInvoiceMonthValue: !this.state.dropDownToggleInvoiceMonthValue });
    }

    onSelectActionRequesting = (text) => {
        this.setState({ actionRequest: text, isDirty: true});
    }

    onSelectInvoiceMonth = (text) => {
        this.setState({ invoiceMonth: text});
    }

    render() {
        return (
                    <Modal
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            className="modal-popup"
                            show={this.state.openModal}
                            onHide={this.handleCloseModal}
                            style={{width: '80%', marginLeft: '10%', marginTop: '10%', marginRight: '10%', height: '500px'}}
                        >
                            <Modal.Body className="full-screen" style={{height: '450px'}}>
                            <Container  className="Modal.Body form-editcontact modal-small">
                                <Form.Group as={Row}>
                                    <Form.Label column sm={2}>
                                        Client Name&nbsp;<span style={{ color: 'red' }}>*</span>
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Form.Control id="clientName" placeholder="Client Name" onChange={this.onChange} value={this.state.clientName} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm={2}>
                                        Invoice Month&nbsp;<span style={{ color: 'red' }}>*</span>
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Dropdown isOpen={this.state.dropDownToggleInvoiceMonthValue} toggle={this.dropDownToggleInvoiceMonth}>
                                            <DropdownToggle className="ddStyle" caret>
                                            {this.state.invoiceMonth ? this.state.invoiceMonth : "Select one option"}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem className="text-primary" onClick={() => this.onSelectInvoiceMonth("January")}>January</DropdownItem>
                                                <DropdownItem className="text-primary" onClick={() => this.onSelectInvoiceMonth("February")}>February</DropdownItem>
                                                <DropdownItem className="text-primary" onClick={() => this.onSelectInvoiceMonth("March")}>March</DropdownItem>
                                                <DropdownItem className="text-primary" onClick={() => this.onSelectInvoiceMonth("April")}>April</DropdownItem>
                                                <DropdownItem className="text-primary" onClick={() => this.onSelectInvoiceMonth("May")}>May</DropdownItem>
                                                <DropdownItem className="text-primary" onClick={() => this.onSelectInvoiceMonth("June")}>June</DropdownItem>
                                                <DropdownItem className="text-primary" onClick={() => this.onSelectInvoiceMonth("July")}>July</DropdownItem>
                                                <DropdownItem className="text-primary" onClick={() => this.onSelectInvoiceMonth("August")}>August</DropdownItem>
                                                <DropdownItem className="text-primary" onClick={() => this.onSelectInvoiceMonth("September")}>September</DropdownItem>
                                                <DropdownItem className="text-primary" onClick={() => this.onSelectInvoiceMonth("October")}>October</DropdownItem>
                                                <DropdownItem className="text-primary" onClick={() => this.onSelectInvoiceMonth("November")}>November</DropdownItem>
                                                <DropdownItem className="text-primary" onClick={() => this.onSelectInvoiceMonth("December")}>December</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm={2}>
                                        Invoice Year&nbsp;<span style={{ color: 'red' }}>*</span>
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Form.Control id="invoiceYear" type='number' placeholder="Invoice Year" onChange={this.onChange} value={this.state.invoiceYear} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm={2}>
                                        Invoice Amount&nbsp;<span style={{ color: 'red' }}>*</span>
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Form.Control id="invoiceAmount" type='number' placeholder="Invoice Amount" onChange={this.onChange} value={this.state.invoiceAmount} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm={2}>
                                        Action Requesting&nbsp;<span style={{ color: 'red' }}>*</span>
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Dropdown isOpen={this.state.dropdownOpenUserRole} toggle={this.toggleUserRole}>
                                            <DropdownToggle className="ddStyle" caret>
                                            {this.state.actionRequest ? this.state.actionRequest : "Select one option"}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem className="text-primary" onClick={() => this.onSelectActionRequesting("Void Invoice")}>Void Invoice</DropdownItem>
                                                <DropdownItem className="text-primary" onClick={() => this.onSelectActionRequesting("Stop Auto-Payment Collection")}>Stop Auto-Payment Collection</DropdownItem>
                                                <DropdownItem className="text-primary" onClick={() => this.onSelectActionRequesting("Pause Reminder Notifications")}>Pause Reminder Notifications</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>

                                    </Col>            
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm={2}>
                                        Detailed Explanation&nbsp;<span style={{ color: 'red' }}>*</span>
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Form.Control id="description" type='textarea' placeholder="Detailed Explanation" onChange={this.onChange} value={this.state.description} />
                                    </Col>
                                </Form.Group>
                                <Form.Group>
                                    <Col sm={8}>
                                        <span style={{ color: 'red' }}>{this.state.errorMessage}</span>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Col sm={2} width="50px">
                                            <button className="button-gridrow-edit-profile" onClick={this.handleSendClientRequest} href="#">SEND</button>
                                    </Col>

                                    <Col sm={4}>
                                        <span style={{ color: 'red' }}>* Required Field</span>
                                    </Col>
                                </Form.Group>    
                            </Container>    
                            </Modal.Body>
                        </Modal>
        );
    }
}


function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        currentContact: state.contactReducer.currentContact,
        sysUser: state.loginReducer.sysUser,
    }
}

export default connect(mapStateToProps, { })(InvoiceRequestModal);
