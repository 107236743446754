import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import RequestManualInvoiceForm from './RequestManualInvoiceForm';
import { recordInvoiceData, getEmptyInvoice, getBillingPlans, deactivateBillingPlan, activateBillingPlan } from '../../../hooks/store/actions/financeActions.js';

var currentDate = new Date();
var newDate = new Date(currentDate.setMonth(currentDate.getMonth() + 2));
newDate = new Date(newDate.setDate(1));

class PauseBillingPlan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mnth: "",
            year: newDate.getFullYear(),
            month: newDate.getMonth(),
            indefinitely: false
        };

    }

    onDateChange = (date) => {
        //console.log(date);
        this.setState({ month: date });
    }

    mapYearSelects = () => {
        const years = [];

        for (let i = this.state.year; i < 2100; i++) {
            years.push(i);
        }
        let yearSelects = years.map((year) => {
            return <option key={year} value={year}>{year}</option>
        }
        );

        return yearSelects;
    }

    handleCheckboxClick = (e) => {
        this.setState({
            indefinitely: e.target.checked,
            year: (e.target.checked) ? "2099" : newDate.getFullYear()
        })
    }

    onMonthChange = (e) => {
        this.setState({ month: e.target.value })
    }

    onYearChange = (e) => {
        this.setState({ year: e.target.value })
    }

    componentDidMount() {
        //console.log(this.props.bp);
        //alert('this.props.bp=' + this.props.bp);
        //this.setState({bp: this.props.bp})
    }

    convMonth(month) {
        if (month === -1) { return "December" }
        var mlist = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        return mlist[month];
    }

    render() {

        return (
            <Modal.Body className="full-screenModal pauseBillingPlanModal pauseBillingPlanModalContents">
                <div>

                    <Form.Group as={Row} style={{ justifyContent: "center", textAlign: 'center' }}>
                        <Form.Label className="pauseBillingPlanTitle">
                            <div style={{fontSize: '24px', fontWeight: '700', fontFamily: 'open sans'}}>DATE TO ISSUE NEXT INVOICE<br />TO THIS CLIENT</div>
                    </Form.Label>
                    </Form.Group>


                    <Form.Group as={Row} style={{ justifyContent: "center", textAlign: 'center' }}>
                        <Form.Label className="pauseBillingPlanTitle">
                            <div style={{fontSize: '20px', fontFamily: 'open sans'}}>
                            <p>THIS IS A 2 STEP PROCESS.</p>
                    <p>STEP 1: Pause the <strong>contract</strong> by following the instructions below.</p>
                    <p>STEP 2: Pause the invoicing process (and other actions) by clicking the VOID INVOICE/PAUSE AUTO-PAY button under your Client Management tab.</p>

                            </div>
                    </Form.Label>
                    </Form.Group>

                    

                    <Form.Group as={Row} style={{ justifyContent: "center", alignItems: 'center' }}>
                        <Form.Label className="pauseBillingPlanTitle" style={{ marginBottom: 0 }}>
                            Month
                    </Form.Label>
                        &nbsp;&nbsp;&nbsp;
                    <div>
                            <select className="pauseBillingPlanSelect" disabled={this.state.indefinitely} onChange={this.onMonthChange} value={this.state.month}>
                                <option key={"January"} value={0}>January</option>
                                <option key={"February"} value={1}>February</option>
                                <option key={"March"} value={2}>March</option>
                                <option key={"April"} value={3}>April</option>
                                <option key={"May"} value={4}>May</option>
                                <option key={"June"} value={5}>June</option>
                                <option key={"July"} value={6}>July</option>
                                <option key={"August"} value={7}>August</option>
                                <option key={"September"} value={8}>September</option>
                                <option key={"October"} value={9}>October</option>
                                <option key={"November"} value={10}>November</option>
                                <option key={"December"} value={11}>December</option>
                            </select>
                        </div>
                    </Form.Group>
                    <Form.Group as={Row} style={{ justifyContent: "center", alignItems: 'center' }}>
                        <Form.Label className="pauseBillingPlanTitle" style={{ marginBottom: 0 }}>
                            Year
                    </Form.Label>
                        &nbsp;&nbsp;&nbsp;
                    <div>
                            <select className="pauseBillingPlanSelect" disabled={this.state.indefinitely} value={this.state.year} onChange={this.onYearChange}>
                                {this.mapYearSelects()}
                            </select>
                        </div>
                    </Form.Group>
                    <Form.Group as={Row} style={{ justifyContent: "center", fontSize: '14px' }}>
                        <Form.Check onClick={this.handleCheckboxClick} type="checkbox" label="Indefinitely" checked={this.state.indefinitely} />
                    </Form.Group>

                    <div><br /><br />NOTE: This Invoice will be issued on the 25th of {this.convMonth(this.state.month - 1)}, and client will be charged on the 1st of {this.convMonth(this.state.month)}<br /><br /></div>
                    
                    <Form.Group as={Row} style={{ justifyContent: "center", marginBottom: '0px' }}>
                        <Button className="button-gridrow clientDetailsBtn" onClick={() => (this.props.deactivateBP(new Date(this.state.year, this.state.month, 1), this.props.bp, this.props.showInvoiceForm))} href="#">
                            PAUSE BILLING PLAN</Button>
                    </Form.Group>
                </div>
            </Modal.Body>
        );
    }

}

function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        billingPlans: state.financeReducer.billingPlans,
    }
}

export default connect(mapStateToProps, {})(PauseBillingPlan);
