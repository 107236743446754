import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Label from 'react-bootstrap/FormLabel';
import Button from 'react-bootstrap/Button';
import Select from 'react-styled-select'
import { StyleSheet, css } from 'aphrodite'
import swal from 'sweetalert';
import { selectContact } from '../../../hooks/store/actions/contactActions.js';
import { makePayment } from '../../../hooks/store/actions/financeActions.js'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const styles = StyleSheet.create({
    newContactInputRow: {
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: "10px",
        fontFamily: "'Open Sans', sans-serif"
    },
    newContactFormInput: {
        backgroundColor: '#e5e5e5',
        border: '1px solid #949494',
        marginTop: '5px',
        borderRadius: "0px",
        height: '32px',
        fontSize: '14px',
        fontFamily: "'Open Sans', sans-serif"
    },
    newContactFormInputDate: {
        backgroundColor: '#e5e5e5',
        border: '1px solid #949494',
        marginTop: '5px',
        borderRadius: "0px",
        height: '32px',
        fontSize: '14px',
        fontFamily: "'Open Sans', sans-serif"
    },
    newContactFormLabel: {
        fontSize: "small",
        fontFamily: "'Open Sans', sans-serif",
        color: "#535353"
    },
    newContactHeaderContainer: {
        //Unresponsive marginTop
        marginTop: "11px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        height: "24px",
        display: "block",
        width: "100%",
        marginBottom: "15px",
        fontFamily: "'Open Sans', sans-serif",
        color: "#535353"
    },
    newContactFilterHeaders: {
        fontSize: "20px",
        fontWeight: "900",
        fontFamily: "'Open Sans', sans-serif",
        color: "#535353",
        width: "150px"
    },
    newContactFilterCol: {
        maxWidth: "180px"
    },
    newContactFilterCheckbox: {
        marginTop: "10px"
    },
    newContactHeader: {
        display: "inline-block",
        fontSize: "18px",
        width: "auto"
    },
    newContactCustomHr: {
        display: "inline-block",
        borderTop: '1px solid #949494',
        width: "100%",
        margin: "5px"
    }
})
class PaymentDetailForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            transactionType: "0",
            paymentTypeID: 1,
            transType: [{ label: "Payment", value: "0" }, { label: "Refund", value: "1" }],
            notes: "",
            paymentDate: new Date(),
            paymentTypes: []
        };

        this.makePayment = this.makePayment.bind(this);
        this.onSelectTransType = this.onSelectTransType.bind(this);
        this.onSelectPaymentType = this.onSelectPaymentType.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onPaymentDateChange = this.onPaymentDateChange.bind(this);

        var defaultPaymentTypeID = undefined;
        for (var i = 0; i < this.props.paymentMethods.length; i++) {
            if (this.props.invoice.chargeCurrency === "USD" && this.props.paymentMethods[i].description !== "CAN") {
                this.state.paymentTypes.push({ label: this.props.paymentMethods[i].name, value: this.props.paymentMethods[i].id });
                defaultPaymentTypeID = this.props.paymentMethods[i].id;
            }
            else if (this.props.invoice.chargeCurrency === "CAD" && this.props.paymentMethods[i].description !== "USA") {
                this.state.paymentTypes.push({ label: this.props.paymentMethods[i].name, value: this.props.paymentMethods[i].id });
                defaultPaymentTypeID = this.props.paymentMethods[i].id;
            }
        }
        this.state.paymentTypeID = defaultPaymentTypeID;
    }

    makePayment() {

        var payment = { ...this.state }

        if (!payment.amountReceived) {
            swal("Please enter a dollar amount");
            return;
        }

        payment.invoiceID = this.props.invoiceID;
        payment.contactID = this.props.currentContact.contactID;
        payment.affiliateID = this.props.user.id;
        payment.receivedOnUTC = this.state.paymentDate;
        payment.receivedBy = this.props.user.email;
        payment.notes = this.state.notes;
        payment.refundName = this.state.paymentName;
        payment.paymentStatus = { id: 1 }; // Approved
        payment.amountReceived = (this.state.isRefund && this.state.amountReceived > 0 ? -1 * payment.amountReceived : payment.amountReceived);

        this.props.makePayment(this.props.logintoken, payment);
        this.props.onClickCancel();
        this.refreshClientDetails();
    }
    onChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }

    onPaymentDateChange = (date) => {
        this.setState({ paymentDate: date });
    }

    onSelectTransType(item) {
        this.setState({ isRefund: item === "0" ? false : true, transactionType: item });
    }
    onSelectPaymentType(item) {
        this.setState({ paymentTypeID: item });
    }

    refreshClientDetails() {
        this.props.selectContact(this.props.contact);
        sleep(50).then(() => {
            document.location = "/clientMgmt/autoload";
        })
    }

    render() {

        return (
            <div className="full-screen viewEditContact viewEditContactSection" {...this.props} size="lg" centered onHide={() => this.props.showEditForm(null)}>
                <div className="Modal.Body form-editcontact modal-small">
                    <Row>
                        <Col sm={4}><Select
                            name="isRefund"
                            options={this.state.transType}
                            defaultValue="0"
                            value={this.state.transactionType}
                            onChange={this.onSelectTransType} className="rs-dropdown-theme" />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}><Label className={css(styles.newContactFormLabel)}>Transaction Type</Label></Col>
                    </Row>
                    <Row>
                        <Col sm={4}><Select
                            name="paymentType"
                            options={this.state.paymentTypes}
                            value={this.state.paymentTypeID}
                            onChange={this.onSelectPaymentType} className="rs-dropdown-theme" />
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col sm={4}><Label className={css(styles.newContactFormLabel)}>Payment Type</Label></Col>
                    </Row>

                    <Row>

                        <Col sm={4}>
                            <DatePicker
                                dateFormat=" MMMM dd, yyyy"
                                selected={(this.state.paymentDate ? this.state.paymentDate : new Date())}
                                onChange={this.onPaymentDateChange}
                                className={css(styles.newContactFormInputDate)}
                                showMonthDropdown
                                showYearDropdown
                                openToDate={new Date()}
                            />
                        </Col>

                    </Row>

                    <Row>
                        <Col sm={4}><Label className={css(styles.newContactFormLabel)}>Payment Date</Label></Col>
                    </Row>

                    <Row>
                        <Col sm={6}><Form.Control className={css(styles.newContactFormInput)} id="paymentName" onChange={this.onChange} value={this.state.paymentName} /></Col>
                    </Row>
                    <Row>
                        <Col sm={6}><Label className={css(styles.newContactFormLabel)}>Payment Name</Label></Col>
                    </Row>
                    <Row>
                        <Col sm={6}><Form.Control className={css(styles.newContactFormInput)} id="notes" onChange={this.onChange} value={this.state.notes} /></Col>
                    </Row>
                    <Row>
                        <Col sm={6}><Label className={css(styles.newContactFormLabel)}>Notes/Check#/Auth Code</Label></Col>
                    </Row>
                    <Row>
                        <Col sm={6}><Form.Control className={css(styles.newContactFormInput)} id="amountReceived" onChange={this.onChange} value={this.state.amountReceived} /></Col>
                    </Row>
                    <Row>
                        <Col sm={6}><Label className={css(styles.newContactFormLabel)}>Amount {this.state.isRefund ? " refunded" : " received"}</Label></Col>
                    </Row>
                    <Row>
                        &nbsp;
                    </Row>
                    <Row>
                        &nbsp;
                    </Row>
                    <Row>
                        <Col sm={2}><button className="button-gridrow-new-contact" onClick={this.makePayment}>Save</button></Col>
                    </Row>
                </div>
            </div>

        );
    }

}

function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        paymentMethods: state.sysReducer.paymentMethods
    }
}

export default connect(mapStateToProps, { makePayment, selectContact })(PaymentDetailForm);
