//imports
import React, { useState } from 'react'

//export context objects
export const StoreContextState = React.createContext(null);
export const StoreContextDispatch = React.createContext(() => {});


//start: StoreContextProvider
//<atom-region>
const StoreContextProvider = ({ children }) => {

    const [store, set_Store] = useState({});

    return (

          <StoreContextDispatch.Provider value={[set_Store]}>
          <StoreContextState.Provider value={[store]}>

                  { children }

          </StoreContextState.Provider>
          </StoreContextDispatch.Provider>
       )

}
//</atom-region>
//end: StoreContextProvider

export default StoreContextProvider
