import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { getInfoPackets } from '../../../hooks/store/actions/financeActions';
import { monthNames } from '../../../hooks/store/actionConsts';

class ActivityGridRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }


    formatDate(d) {
        if (d && d.length >= 10) {
            var m = Number(d.substring(5, 7));
            return monthNames[m - 1] + " " + d.substring(8, 10) + ", " + d.substring(0, 4);
        }
        else
            return "";
    }

    render() {
        return (
            this.props.activity.map(
                (j, r) =>

                    <Row className={r % 2 === 0 ? "grid-row-report row-white invoiceListCol" : "grid-row-report row-gray invoiceListCol"} key={j.activityID}>
                        <Col className="searchGridColumn" sm={2} xs={2}>{j.activityType}<div className="borderMarkerCol"></div></Col>
                        <Col className="searchGridColumn" sm={2} xs={2}>{j.title}<div className="borderMarkerCol"></div></Col>
                        <Col className="searchGridColumn" sm={2} xs={2}>{this.formatDate(j.lastUpdatedDateUTC)}<div className="borderMarkerCol"></div></Col>
                        <Col className="searchGridColumn" sm={2} xs={2}>{j.contacts && j.contacts.length > 0 ? j.contacts[0].firstName + " " + j.contacts[0].lastName : ""}<div className="borderMarkerCol"></div></Col>
                        <Col className="searchGridColumn" sm={2} xs={2}>{j.contacts && j.contacts.length > 0 ? j.contacts[0].email : ""}<div className="borderMarkerCol"></div></Col>                                                
                    </Row>
            )
        );
    }
    
}
    
function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user
    }
}

export default connect(mapStateToProps, { getInfoPackets })(ActivityGridRow);
