import React from 'react'
import { connect } from 'react-redux'
import Form from 'react-bootstrap/Form'
import Label from 'react-bootstrap/FormLabel'
import { StyleSheet, css } from 'aphrodite'
import { showNavbar } from '../../../hooks/store/actions/navBarActions'
import { setPersonalData } from '../../../hooks/store/actions/contactActions';

const styles = StyleSheet.create({
    clientInfoFormContainer: {
        padding: 0,
        textAlign: "left",
        paddingLeft: "10px",
        paddingRight: "10px",
        color: "#535353",
        fontFamily: "'Open Sans', sans-serif"
    },
    clientInfoHeaderContainer: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        height: "24px",
        display: "block",
        width: "100%",
        marginBottom: "15px"
    },
    clientInfoHeader: {
        display: "inline-block",
        fontSize: "18px",
        width: "auto"
    },
    clientInfoCustomHr: {
        display: "inline-block",
        borderTop: '1px solid #949494',
        width: "100%",
        margin: "5px"
    },
    clientInfoHr: {
        borderTop: '1px solid #949494'
    },
    clientInfoFormLabel: {
        fontSize: "small",
    },
    clientInfoFormInput: {
        backgroundColor: '#e5e5e5',
        border: '1px solid #949494',
        marginTop: '5px',
        borderRadius: "0px",
        height: '32px',
        fontSize: '14px'
    }
})

class ClientInfo extends React.Component {
    constructor(props) {
        super(props)
        if (this.props.useProps) {
            this.state = {
                ...this.props.currentContact.personalData,
                notes: this.props.currentContact.notes
            }
        }
        else {
            this.state = {}
        }
        this.props.showNavbar()
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.setState({ ...this.props.currentContact.personalData})
    }

    onChange = (e) => {
        var data = {
            ...this.state
        }   

        if (e.target.id == 'notes') {
            this.props.currentContact.notes = e.target.value;
            //alert(this.props.currentContact.notes)
        }
        data[e.target.id] = e.target.value;

        this.props.setPersonalData(data);
        this.setState( data );
    }

    render() {
        
        return (
            <div className={css(styles.clientInfoFormContainer)}>
                <div className={css(styles.clientInfoHeaderContainer)}>
                    <div className={css(styles.clientInfoHeader)}>Client Info</div>
                    <div className={css(styles.clientInfoCustomHr)} />
                </div>

                {/* <Form.Group>
                    <Form.Control id="essence1" onChange={this.onChange} className={css(styles.clientInfoFormInput)}  />
                    <Form.Control id="essence2" onChange={this.onChange} className={css(styles.clientInfoFormInput)}  />
                    <Form.Control id="essence3" onChange={this.onChange} className={css(styles.clientInfoFormInput)} />
                    <Form.Control id="essence4" onChange={this.onChange} className={css(styles.clientInfoFormInput)} />
                    <Form.Control id="essence5" onChange={this.onChange} className={css(styles.clientInfoFormInput)} />

                    <Label className={css(styles.clientInfoFormLabel)}>Essence Name</Label>

                    <Form.Control id="survivalMechanism1" onChange={this.onChange} className={css(styles.clientInfoFormInput)}  />
                    <Form.Control id="survivalMechanism2" onChange={this.onChange} className={css(styles.clientInfoFormInput)}  />
                    <Form.Control id="survivalMechanism3" onChange={this.onChange} className={css(styles.clientInfoFormInput)} />

                    <Label className={css(styles.clientInfoFormLabel)}>Survival Mechanism</Label>

                    <Form.Control id="lifePurpose" onChange={this.onChange} className={css(styles.clientInfoFormInput)}  />

                    <Label className={css(styles.clientInfoFormLabel)}>Life Purpose</Label>
                    <hr className={css(styles.clientInfoHr)} />

                </Form.Group> */}

                {/* <Form.Group>
                    <Form.Control id="essence1" onChange={this.onChange} className={css(styles.clientInfoFormInput)} value={this.props.currentContact.personalData.essence1} />
                    <Form.Control id="essence2" onChange={this.onChange} className={css(styles.clientInfoFormInput)} value={this.props.currentContact.personalData.essence2} />
                    <Form.Control id="essence3" onChange={this.onChange} className={css(styles.clientInfoFormInput)} value={this.props.currentContact.personalData.essence3}/>
                    <Form.Control id="essence4" onChange={this.onChange} className={css(styles.clientInfoFormInput)} value={this.props.currentContact.personalData.essence4}/>
                    <Form.Control id="essence5" onChange={this.onChange} className={css(styles.clientInfoFormInput)} value={this.props.currentContact.personalData.essence5}/>

                    <Label className={css(styles.clientInfoFormLabel)}>Essence Name</Label>

                    <Form.Control id="survivalMechanism1" onChange={this.onChange} className={css(styles.clientInfoFormInput)} value={this.props.currentContact.personalData.survivalMechanism1} />
                    <Form.Control id="survivalMechanism2" onChange={this.onChange} className={css(styles.clientInfoFormInput)} value={this.props.currentContact.personalData.survivalMechanism2} />
                    <Form.Control id="survivalMechanism3" onChange={this.onChange} className={css(styles.clientInfoFormInput)} value={this.props.currentContact.personalData.survivalMechanism3}/>

                    <Label className={css(styles.clientInfoFormLabel)}>Survival Mechanism</Label>

                    <Form.Control id="lifePurpose" onChange={this.onChange} className={css(styles.clientInfoFormInput)} value={this.props.currentContact.personalData.lifePurpose} />

                    <Label className={css(styles.clientInfoFormLabel)}>Life Purpose</Label>
                    <hr className={css(styles.clientInfoHr)} />

                </Form.Group> */}





                <Form.Group>
                    <Form.Control autocomplete="offrandomhacktext" id="essence1" onChange={this.onChange} className={css(styles.clientInfoFormInput)} value={this.state.essence1} />
                    <Form.Control autocomplete="offrandomhacktext" id="essence2" onChange={this.onChange} className={css(styles.clientInfoFormInput)} value={this.state.essence2} />
                    <Form.Control autocomplete="offrandomhacktext" id="essence3" onChange={this.onChange} className={css(styles.clientInfoFormInput)} value={this.state.essence3}/>
                    <Form.Control autocomplete="offrandomhacktext" id="essence4" onChange={this.onChange} className={css(styles.clientInfoFormInput)} value={this.state.essence4}/>
                    <Form.Control autocomplete="offrandomhacktext" id="essence5" onChange={this.onChange} className={css(styles.clientInfoFormInput)} value={this.state.essence5}/>

                    <Label className={css(styles.clientInfoFormLabel)}>Essence Name</Label>

                    <Form.Control autocomplete="offrandomhacktext" id="survivalMechanism1" onChange={this.onChange} className={css(styles.clientInfoFormInput)} value={this.state.survivalMechanism1} />
                    <Form.Control autocomplete="offrandomhacktext" id="survivalMechanism2" onChange={this.onChange} className={css(styles.clientInfoFormInput)} value={this.state.survivalMechanism2} />
                    <Form.Control autocomplete="offrandomhacktext" id="survivalMechanism3" onChange={this.onChange} className={css(styles.clientInfoFormInput)} value={this.state.survivalMechanism3}/>

                    <Label className={css(styles.clientInfoFormLabel)}>Survival Mechanism</Label>

                    <Form.Control autocomplete="offrandomhacktext" id="lifePurpose" onChange={this.onChange} className={css(styles.clientInfoFormInput)} value={this.state.lifePurpose} />

                    <Label className={css(styles.clientInfoFormLabel)}>Life Purpose</Label>

                    <Form.Control autocomplete="offrandomhacktext" as="textarea" rows="10" id="notes" onChange={this.onChange} value={this.state.notes} />

                    <Label className={css(styles.clientInfoFormLabel)}>Notes</Label>
                    <hr className={css(styles.clientInfoHr)} />

                </Form.Group>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user
    }
}

export default connect(mapStateToProps, { showNavbar, setPersonalData })(ClientInfo)