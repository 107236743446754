import React from 'react';
import { connect } from 'react-redux';
import { hideNavbar } from '../../hooks/store/actions/navBarActions';
import { showEditUserForm } from '../../hooks/store/actions/loginActions';
import { withStyles } from '@material-ui/core/styles';
import { getUserProfile } from '../../hooks/store/actions/loginActions';

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing.unit * 4,
    },
});



class StripeAccountOk extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
       // console.log('refresh profile data', this.props.user);
        this.props.getUserProfile(this.props.user.id);
    }



    componentDidUpdate() {
        // console.log('update comp');
    }



    render() {
        return (

                <div style={{ color: "black" }}>
                {(this.props.showLoadingGif) && (<div id="loadingGif"></div>)}
                                        <h4 style={{marginTop: '20px'}} >You have connected your Stripe account</h4>
                </div>
        );
    }


}

function mapStateToProps(state) {
    return {
        user: state.loginReducer.user,
        showLoadingGif: state.libraryReducer.showLoadingGif,
    }
}

export default connect(mapStateToProps, { getUserProfile })(withStyles(styles)(StripeAccountOk));
