import toaster from 'toasted-notes';

import { systemActionTypes, pending, rejected, fulfilled, serverEnvironment } from '../actionConsts.js';

export default function reducer(state = {
    genderTypes: [],
    contactTypes: [],
    maritalStatus: [],
    addressTypes: [],
    paymentMethods: [],
    invoiceTypes: [],
    invoiceStatus: [],
    ccChargeTypes: [],
    taxRates: [],
    reports: [],
    reportResults: [],
    params: [],
    showLoadingGif: false,
    sortCol: -1,
    sortDir: 0,
    message: '',
    showParams: true,
    systemNoficationsList: [],
    subOption: {},
    openModalBillExportDetails: false,
    openModalDetails: false,
}, action) {

    switch (action.type) {
        case fulfilled(systemActionTypes.GENDERS):
            return {
                ...state,
                genderTypes: action.payload.data,
                message: ""
            }
        case rejected(systemActionTypes.GENDERS):
            return {
                ...state,
                message: action.payload.message
            }
        case fulfilled(systemActionTypes.MARITAL_STATUS):
            return {
                ...state,
                maritalStatus: action.payload.data,
                message: ""
            }
        case rejected(systemActionTypes.MARITAL_STATUS):
            return {
                ...state,
                message: action.payload.message
            }
        case fulfilled(systemActionTypes.CONTACT_TYPE):
            return {
                ...state,
                contactTypes: action.payload.data,
                message: ""
            }
        case rejected(systemActionTypes.CONTACT_TYPE):
            return {
                ...state,
                message: action.payload.message
            }
        case fulfilled(systemActionTypes.ADDRESS_TYPE):
            return {
                ...state,
                addressTypes: action.payload.data,
                message: ""
            }
        case rejected(systemActionTypes.ADDRESS_TYPE):
            return {
                ...state,
                message: action.payload.message
            }
        case fulfilled(systemActionTypes.PAYMENT_METHOD):
            return {
                ...state,
                paymentMethods: action.payload.data,
                message: ""
            }
        case rejected(systemActionTypes.PAYMENT_METHOD):
            return {
                ...state,
                message: action.payload.message
            }
        case fulfilled(systemActionTypes.INVOICE_STATUS):
            return {
                ...state,
                invoiceStatus: action.payload.data,
                message: ""
            }
        case rejected(systemActionTypes.INVOICE_STATUS):
            return {
                ...state,
                message: action.payload.message
            }
        case fulfilled(systemActionTypes.INVOICE_TYPE):
            return {
                ...state,
                invoiceTypes: action.payload.data,
                message: ""
            }
        case rejected(systemActionTypes.INVOICE_TYPE):
            return {
                ...state,
                message: action.payload.message
            }
        case fulfilled(systemActionTypes.CC_CHARGE_TYPE):
            return {
                ...state,
                ccChargeTypes: action.payload.data,
                message: ""
            }
        case rejected(systemActionTypes.CC_CHARGE_TYPE):
            return {
                ...state,
                message: action.payload.message
            }
        case pending(systemActionTypes.GET_SYSTEMNOTIFICATIONS):
            return {
                ...state,
                showLoadingGif: true,
            }
        case fulfilled(systemActionTypes.GET_SYSTEMNOTIFICATIONS):
            return {
                ...state,
                systemNoficationsList: action.payload.data,
                showLoadingGif: false,
                message: ""
            }
        case rejected(systemActionTypes.GET_SYSTEMNOTIFICATIONS):
            toaster.notify("Failed to retrieve System Notifications --- error: " + action.payload.message, { duration: 9000 });
            return {
                ...state,
                showLoadingGif: false,
                message: action.payload.message
            }
        case pending(systemActionTypes.UPDATE_SYSTEMNOTIFICATION):
            return {
                ...state,
                showLoadingGif: true,
            }
        case fulfilled(systemActionTypes.UPDATE_SYSTEMNOTIFICATION):
            return {
                ...state,
                showLoadingGif: false,
                message: ""
            }
        case rejected(systemActionTypes.UPDATE_SYSTEMNOTIFICATION):
            toaster.notify("Failed to Update System Notification --- error: " + action.payload.message, { duration: 9000 });
            return {
                ...state,
                showLoadingGif: false,
                message: action.payload.message
            }
        case pending(systemActionTypes.DELETE_SYSTEMNOTIFICATION):
            return {
                ...state,
                showLoadingGif: true,
            }
        case fulfilled(systemActionTypes.DELETE_SYSTEMNOTIFICATION):
            return {
                ...state,
                showLoadingGif: false,
                message: ""
            }
        case rejected(systemActionTypes.DELETE_SYSTEMNOTIFICATION):
            toaster.notify("Failed to Delete System Notification --- error: " + action.payload.message, { duration: 9000 });
            return {
                ...state,
                showLoadingGif: false,
                message: action.payload.message
            }
        case pending(systemActionTypes.ADD_SYSTEMNOTIFICATION):
            return {
                ...state,
                showLoadingGif: true,
            }
        case fulfilled(systemActionTypes.ADD_SYSTEMNOTIFICATION):
            return {
                ...state,
                showLoadingGif: false,
                message: ""
            }
        case rejected(systemActionTypes.ADD_SYSTEMNOTIFICATION):
            toaster.notify("Failed to Add New System Notification --- error: " + action.payload.message, { duration: 9000 });
            return {
                ...state,
                showLoadingGif: false,
                message: action.payload.message
            }
        case pending(systemActionTypes.GET_REPORTS):
            return {
                ...state,
                showLoadingGif: true,
            }
        case fulfilled(systemActionTypes.GET_REPORTS):
            return {
                ...state,
                showLoadingGif: false,
                reports: action.payload.data,
                message: ""
            }
        case rejected(systemActionTypes.GET_REPORTS):
            return {
                ...state,
                showLoadingGif: false,
                message: action.payload.message
            }
        case pending(systemActionTypes.RUN_REPORT):
            return {
                ...state,
                showLoadingGif: true                
            }
        case fulfilled(systemActionTypes.RUN_REPORT):
            return {
                ...state,
                reportResults: action.payload.data,
                showLoadingGif: false,
                message: ""
            }
        case rejected(systemActionTypes.RUN_REPORT):
            toaster.notify("Report failed", { duration: 9000 });
            return {
                ...state,
                showLoadingGif: false,
                message: action.payload.message
            }
        case fulfilled(systemActionTypes.SET_PARAM):
            var param = action.payload;
            var params = state.params;
            var found = false;
            for (var p = 0; p < state.params.length; p++) {
                if (params[p].name === param.name) {
                    params[p] = param;
                    found = true;
                    break;
                }
            }
            if (!found) {
                params.push(param);
            }
            return {
                ...state,
                params: params
            }
    case fulfilled(systemActionTypes.SET_SUBOPTION):
        var option = action.payload;
        return {
            ...state,
            subOption: option
        }
        case fulfilled(systemActionTypes.CLEAR_PARAMS):
            return {
                ...state,
                params: []
            }
        case fulfilled(systemActionTypes.TAX_RATES):
            return {
                ...state,
                taxRates: action.payload.data,
                message: ""
            }
        case rejected(systemActionTypes.TAX_RATES):
            return {
                ...state,
                message: action.payload.message
            }
        case pending(systemActionTypes.SORT_COL):
            return {
                ...state,
                showLoadingGif: true
            }
        case fulfilled(systemActionTypes.SORT_COL):
            var sortDir = state.sortDir;
            var sortCol = action.payload;
            var data = state.reportResults.data.slice();
            if (sortCol === action.payload) {
                sortDir = !sortDir;
            }
            switch (state.reportResults.columnList[sortCol].value) {
                case "Int32":
                    if (!sortDir) {
                        data = data.sort((a, b) => { return Number(a.values[sortCol]) > Number(b.values[sortCol]) ? 1 : -1; })
                    } else {
                        data = data.sort((a, b) => { return Number(a.values[sortCol]) < Number(b.values[sortCol]) ? 1 : -1; })
                    }
                    break;
                case "DateTime":
                    if (!sortDir) {
                        data = data.sort((a, b) => {
                            var da = Date.parse(a.values[sortCol]);
                            var db = Date.parse(b.values[sortCol]);
                            return da > db ? 1 : -1;
                        })
                    } else {
                        data = data.sort((a, b) => {
                            var da = Date.parse(a.values[sortCol]);
                            var db = Date.parse(b.values[sortCol]);
                            return da < db ? 1 : -1;
                        })
                    }
                    break;   
                case "Decimal":
                    if (!sortDir) {
                        data = data.sort((a, b) =>
                        {
                            var ta = parseFloat(
                                a.values[sortCol]
                                    .replace("$", "")
                                    .replace(",", "")
                                    .replace("(", "-")
                                    .replace(")", "")
                            );
                            var tb = parseFloat(
                                b.values[sortCol]
                                    .replace("$", "")
                                    .replace(",", "")
                                    .replace("(", "-")
                                    .replace(")", "")
                            );

                            return  ta > tb ? 1 : -1;
                        })
                    } else {
                        data = data.sort((a, b) =>
                        {
                            var ta = parseFloat(
                                a.values[sortCol]
                                    .replace("$", "")
                                    .replace(",", "")
                                    .replace("(", "-")
                                    .replace(")", "")
                            );
                            var tb = parseFloat(
                                b.values[sortCol]
                                    .replace("$", "")
                                    .replace(",", "")
                                    .replace("(", "-")
                                    .replace(")", "")
                            );

                            return ta < tb ? 1 : -1;
                        })
                    }
                    break;
                default:
                    if (!sortDir) {
                        data = data.sort((a, b) => { return a.values[sortCol].toLowerCase() > b.values[sortCol].toLowerCase() ? 1 : -1; })
                    } else {
                        data = data.sort((a, b) => { return a.values[sortCol].toLowerCase() < b.values[sortCol].toLowerCase() ? 1 : -1; })
                    }
                    break;
            }
            var rr = state.reportResults;
            rr.data = data;

            return {
                ...state,
                sortDir: sortDir,
                sortCol: sortCol,
                showLoadingGif: false,
                reportResults: rr
            }
        case pending(systemActionTypes.SELECT_REPORT):
            return {
                ...state,
                showParams: false
            }
        case fulfilled(systemActionTypes.SELECT_REPORT):
            return {
                ...state,
                showParams: true
            }
        case fulfilled(systemActionTypes.OPEN_MODAL_BILLEXPORTDETAILS):
        return {
            ...state,
            openModalBillExportDetails: action.payload
        }
        case fulfilled(systemActionTypes.OPEN_MODAL_DETAILS):
        return {
            ...state,
            openModalDetails: action.payload
        }
        default:
            return {
                ...state
            }
    }
}
