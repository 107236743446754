import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from 'react-bootstrap/FormLabel';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InvoiceDetailRow from './InvoiceDetailRow';
import Select from 'react-styled-select';
import { StyleSheet, css } from 'aphrodite'
import swal from 'sweetalert';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { saveInvoice } from '../../../hooks/store/actions/financeActions.js'
import { formatCurrency, sleep } from '../../../hooks/store/actionConsts.js'
import { recordInvoiceData, getEmptyInvoice, getBillingPlans, deactivateBillingPlan, activateBillingPlan, requestManualInvoice, cancelBillingPlan } from '../../../hooks/store/actions/financeActions.js';
import PauseBillingPlan from './PauseBillingPlan';

const styles = StyleSheet.create({
    newContactInputRow: {
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: "10px",
        fontFamily: "'Open Sans', sans-serif"
    },
    newContactFormInput: {
        backgroundColor: '#e5e5e5',
        border: '1px solid #949494',
        marginTop: '5px',
        borderRadius: "0px",
        height: '32px',
        fontSize: '14px',
        fontFamily: "'Open Sans', sans-serif"
    },
    newContactFormInputDate: {
        backgroundColor: '#e5e5e5',
        border: '1px solid #949494',
        marginTop: '5px',
        borderRadius: "0px",
        height: '32px',
        fontSize: '14px',
        fontFamily: "'Open Sans', sans-serif"
    },
    newContactFormLabel: {
        fontSize: "small",
        fontFamily: "'Open Sans', sans-serif",
        color: "#535353"
    },
    newContactHeaderContainer: {
        //Unresponsive marginTop
        marginTop: "11px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        height: "24px",
        display: "block",
        width: "100%",
        marginBottom: "15px",
        fontFamily: "'Open Sans', sans-serif",
        color: "#535353"
    },
    newContactFilterHeaders: {
        fontSize: "20px",
        fontWeight: "900",
        fontFamily: "'Open Sans', sans-serif",
        color: "#535353",
        width: "150px"
    },
    newContactFilterCol: {
        maxWidth: "180px"
    },
    newContactFilterCheckbox: {
        marginTop: "10px"
    },  
    newContactHeader: {
        display: "inline-block",
        fontSize: "18px",
        width: "auto"
    },
    newContactCustomHr: {
        display: "inline-block",
        borderTop: '1px solid #949494',
        width: "100%",
        margin: "5px"
    }
})
class RequestManualInvoice extends React.Component {
    constructor(props) {
        super(props);
        var today = new Date();
        today.setDate(today.getDate() + 1);
        

        this.state = {
            ...this.props.invoice,
            invoiceTypeID: 5,
            transactionType: "0",
            dueDate: new Date(),
            paymentTypeID: 1,
            notes: "",
            invoiceTotal: 0.00,
            showPauseAction: true,
            actionTypeID: '0',
            invoiceName: new Date().toLocaleString('default', { month: 'long' }) +  ' coaching services'
        };
        
        this.saveInvoice = this.saveInvoice.bind(this);
        this.onSelectInvoiceType = this.onSelectInvoiceType.bind(this);
        this.onSelectPaymentType = this.onSelectPaymentType.bind(this);
        this.onSelectActionType = this.onSelectActionType.bind(this);
        this.onPaymentDateChange = this.onPaymentDateChange.bind(this);
        this.pauseAgreement = this.pauseAgreement.bind(this);
        this.onClickCancel = this.onClickCancel.bind(this);
        this.deactivateBP = this.deactivateBP.bind(this);
        this.onChange = this.onChange.bind(this);
        this.tick = this.tick.bind(this);
     
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            500
        );
        //alert(this.props.currentContact.contactID)
        //alert(this.props.user.id)
        //alert(this.props.month)
        //if (this.props.currentContact) {
        //    this.props.getBillingPlans(this.props.logintoken, this.props.currentContact.contactID, this.props.currentContact.affiliateID);
        //    //alert(this.props.getBillingPlans())
        //}
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        this.setState({
            date: new Date()
        });
    }

    saveInvoice(pause, terminate) {

        //this.setState({ showPauseModal: true });

        if (!this.state.invoiceTotal) {
            swal("Please enter a positive dollar amount");
            return;
        }
        if (!this.state.dueDate) {
            swal("Please enter a due date");
            return;
        }
        if (!this.state.invoiceName) {
            swal("Please enter an invoice name");
            return;
        }

        var invoice = { ...this.props.invoice };
        invoice.invoiceTypeID = 5;
        invoice.paymentMethodID = this.state.paymentTypeID;
        invoice.invoiceName = this.state.invoiceName;

        for (var i = 0; i < invoice.details.length; i++) {
            invoice.details[i].groupName = this.state.invoiceName;
        }

        if (pause) { this.pauseAgreement(); }
        if (terminate) { this.terminateAgreement(); }
        if (!pause && !terminate) {
            var bp = this.getBillingPlan();
            bp.statusID = 0;
            bp.notes = this.formatNotes();
            this.setState({ bp: bp });

            this.props.requestManualInvoice(this.props.logintoken, bp);
            sleep(1500).then(() => {
                swal("Your Request has been Submitted!");
                sleep(3000).then(() => { this.refreshActionPage(); })
            })
        }

    }

    deactivateBP(pauseUntilDate, bp, sendEmail) {
        bp.pauseUntilDate = pauseUntilDate;
        if (sendEmail) {
            bp.statusID = 3;
            bp.notes = this.formatNotes();
            this.props.deactivateBillingPlan(this.props.logintoken, bp);
            this.props.requestManualInvoice(this.props.logintoken, bp);
        } else {
            this.props.deactivateBillingPlan(this.props.logintoken, bp);
        }
        this.setState({ showBillingPlan: false });
        sleep(1500).then(() => {
            swal("Your Request has been Submitted and this Billing Plan has been Paused.");
            sleep(3000).then(() => { this.refreshActionPage(); })
        })
    }

    refreshActionPage() {
        sleep(250).then(() => {
            document.location = "/action";
        })
    }

    pauseAgreement() {
        var bp = this.getBillingPlan();
        this.setState({ showPauseModal: true, bp: bp });
    }

    getBillingPlan() {
        var selectedBillingPlanID = 0;
        for (var i = 0; i < this.props.billingPlans.length; i++) {
            if (this.props.billingPlans[i].statusID === 2 || this.props.billingPlans[i].statusID === 3) { // they can only have one active billing plan
                return this.props.billingPlans[i];
            }
        }

        return null;
    }

    

    requestManualInvoice() {
        var currency = "USD";
        if (this.props.currentContact.addresses && this.props.currentContact.addresses.length > 0) {
            var cntry = this.props.currentContact.addresses[0].address.country;
            if (cntry === "Canada") {
                currency = "CAD";
            }
        }
        //this.state.getEmptyInvoice(this.props.logintoken, this.props.currentContact.contactID, this.props.currentContact.affiliateID, 5, currency);
    }

    formatNotes() {
        var pm = "";
        for (var i = 0; i < this.props.paymentMethods.length; i++) {
            if (String(this.props.paymentMethods[i].id) === String(this.props.emptyInvoice.paymentMethodID)) {
                pm = this.props.paymentMethods[i].name;
                break;
            }
        }

        //alert(this.state.emptyInvoice.invoiceTotal)

        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        return "<br/>Affiliate: " + this.props.user.firstName + " " + this.props.user.lastName
            + "<br/>Contact: " + this.props.currentContact.firstName + " " + this.props.currentContact.lastName
            + "<br/>Invoice Name: " + this.state.invoiceName
            + "<br/>Due Date: " + String(this.state.dueDate).substring(0, 15)
            + "<br/>Billing Amount: " + formatter.format(this.state.invoiceTotal)
            + "<br/>Payment Method: " + pm
            + "<br/>Charge Currency: " + String(this.props.emptyInvoice.chargeCurrency)
    }

    onChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }
    onPaymentDateChange = (date) => {
        this.setState({ dueDate: date });
    }

    onSelectActionType(item) {
        this.setState({ actionTypeID: item });
    }

    onSelectInvoiceType(item) {
        this.setState({ invoiceTypeID: item});
    }

    onSelectPaymentType(item) {
        this.setState({ paymentTypeID: item });
        var invoice = { ...this.props.invoice };
        invoice.paymentMethodID = String(item);
        this.props.recordInvoiceData(invoice);
    }

    terminateAgreement() {
        swal({
            title: "AC Admin", text: "Terminate this billing plan?", icon: "warning",
            buttons:
            {
                no: {
                    text: "No",
                    value: "no"
                },
                yes: {
                    text: "Yes",
                    value: "yes"
                }
            }
        }).then((value) => {
            switch (value) {
                case "yes":
                    swal({
                        title: "AC Admin", text: "Are you sure? This process is irreversible.", icon: "warning",
                        buttons:
                        {
                            no: {
                                text: "No",
                                value: "no"
                            },
                            yes: {
                                text: "Yes",
                                value: "yes"
                            }
                        }
                    }).then((value) => {
                        console.log(value)
                        switch (value) {
                            case "yes":
                                var bp = this.getBillingPlan();
                                this.setState({ showPauseModal: false, bp: bp });

                                bp.statusID = 4;
                                bp.notes = this.formatNotes();
                                this.props.cancelBillingPlan(this.props.logintoken, bp)
                                this.props.requestManualInvoice(this.props.logintoken, bp);
                                this.setState({ showPauseModal: false, showBillingPlan: false });
                                sleep(1500).then(() => {
                                    swal("Your Request has been Submitted and this Billing Plan has been Terminated.");
                                    sleep(3000).then(() => { this.refreshActionPage(); })
                                })
                                break;
                            default:
                                break
                        }
                    })
                    break
                case "no":
                    break;
                default:
                    break;
            }
        });
    }

    onClickCancel() {
        this.refreshActionPage();
}

    render() {
        var transType = [ { label: "Payment", value: "0"}, { label: "Refund", value: "1"}];
        var invoiceTypes = [];
        for (var i = 0; i < this.props.invoiceTypes.length; i++){
            if (this.props.invoiceTypes[i].id === 5) {
                invoiceTypes.push({ label: this.props.invoiceTypes[i].name, value: this.props.invoiceTypes[i].id });
            }
        }

        var paymentTypes = [];
        for (i = 0;i < this.props.paymentMethods.length; i++){
            if (this.props.invoice.chargeCurrency === "USD" && this.props.paymentMethods[i].description !== "CAN") {
                paymentTypes.push({ label: this.props.paymentMethods[i].name, value: this.props.paymentMethods[i].id});
            }
            else if (this.props.invoice.chargeCurrency === "CAD" && this.props.paymentMethods[i].description !== "USA") {
                paymentTypes.push({ label: this.props.paymentMethods[i].name, value: this.props.paymentMethods[i].id});
            }
        }

        const actionTypes = [{ label: 'PAUSE', value: '0' }, { label: 'TERMINATE', value: '1' }]
        
       
        return (

            <div className="full-screenModal viewEditContact viewEditContactSection" {...this.props} size="lg" centered onHide={this.props.onClickCancel}>
                <Modal
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="modal-popupPauseRequest pauseBillingPlanModalContainer"
                    show={this.state.showPauseModal}
                    onHide={this.onClickHidePauseModal}
                >
                    <PauseBillingPlan
                        showInvoiceForm={true}
                        invoice={this.props.emptyInvoice}
                        currentContact={this.props.currentContact && this.props.currentContact.contactID}
                        bp={this.state.bp}
                        deactivateBP={this.deactivateBP}
                        terminateBP={this.terminateAgreement}
                        show={true}
                        hideForm={this.onClickHidePauseModal}
                        closeBillingPlanModal={this.closePauseModal}
                    />
                </Modal>

                <div className="Modal.Body form-editcontact modal-small">
                    <div style={{ display: 'block' }} >
                    <Row>
                            <Col sm={12}><Label style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bolder', margin: '5px', marginTop: '30px', marginBottom: '-20px', fontSize: '12px' }}
                                >
                                <br /><p>Use this form when amount being billed in a month is DIFFERENT than the Standard Billing Plan (for example, if the client will receive fewer sessions than typical for any reason, e.g. terminating or pausing, or by agreement). Use the Pause and Request a Manual Invoice button if you intend to <b><i>replace</i></b> the standard invoice this month.
                                <br /></p></Label><br /></Col>
                    </Row>
                        </div>
                    <Row>
                        <Col sm={4}>
                            <DatePicker
                                id="dueDate"
                                dateFormat=" MMMM dd, yyyy"
                                selected={(this.state.dueDate ? this.state.dueDate : new Date())}
                                onChange={this.onPaymentDateChange}
                                className={css(styles.newContactFormInputDate)}
                                showMonthDropdown
                                showYearDropdown
                                openToDate={new Date()}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}><Label className={css(styles.newContactFormLabel)}>Due Date</Label></Col>
                    </Row>
                    <Row>
                        <Col sm={6}><input type="number" className={css(styles.newContactFormInput)} id="invoiceTotal" onChange={this.onChange} value={this.state.invoiceTotal} /></Col>
                    </Row>
                    <Row>
                        <Col sm={6}><Label className={css(styles.newContactFormLabel)}>Invoice Total</Label></Col>
                    </Row>
                    <Row>
                    <Col sm={12}><Select
                        name="invoiceTypeID"
                        options={invoiceTypes}
                        value={this.state.invoiceTypeID}
                            onChange={this.onSelectInvoiceType} className="rs-dropdown-theme" />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}><Label className={css(styles.newContactFormLabel)}>Invoice Type</Label></Col>
                    </Row>

                <Row>
                    <Col sm={12}><Select
                        name="paymentType"
                        options={paymentTypes}
                        value={this.state.paymentTypeID}
                        onChange={this.onSelectPaymentType} className="rs-dropdown-theme" />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}><Label className={css(styles.newContactFormLabel)}>Payment Type</Label></Col>
                </Row>
                <Row>
                    <Col sm={12}><Form.Control className={css(styles.newContactFormInput)} id="invoiceName" onChange={this.onChange} value={this.state.invoiceName} /></Col>                    
                </Row>
                <Row>
                    <Col sm={12}><Label className={css(styles.newContactFormLabel)}>Invoice Name</Label></Col>                    
                </Row>
                    <div style={{ textAlign: "center" }}>
                        <Row><Col sm={12}><Label style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bolder', margin: '5px', marginTop: '7px', marginBottom: '-20px', fontSize: '12px' }}
                        >
                            <p>***Make this request before the 25th of the month to ensure that a Standard Invoice does not get issued on the 1st of the following month</p></Label><br /><br />
                            </Col></Row>
                        <Row><Col>
                            <Button className="button-gridrow clientDetailsBtn" onClick={() => (this.saveInvoice(true, false))} href="#" style={{ margin: '2px', lineHeight: '1' }}>
                                PAUSE & REQUEST MANUAL INVOICE</Button>
                        </Col></Row>
                        <Row><Col>
                            <Button className="button-gridrow clientDetailsBtn" onClick={() => (this.saveInvoice(false, true))} href="#" style={{  margin: '2px', lineHeight: '1' }}>
                                TERMINATE & REQUEST MANUAL INVOICE</Button>
                         </Col></Row>
                        <Row><Col>
                            <Button className="button-gridrow clientDetailsBtn" onClick={() => (this.saveInvoice(false, false))} href="#" style={{ margin: '2px', lineHeight: '1' }}>
                                REQUEST MANUAL INVOICE ONLY</Button>
                        </Col></Row>
                        <Row><Col>
                            <Button className="button-gridrow clientDetailsBtn" onClick={this.onClickCancel} href="#" style={{ margin: '2px', lineHeight: '1' }}>
                                CANCEL</Button>
                        </Col></Row>
                        </div>
            </div>
        </div>

        );
    }
    
}
    
function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,        
        paymentMethods: state.sysReducer.paymentMethods,
        invoiceTypes: state.sysReducer.invoiceTypes,
        billingPlans: state.financeReducer.billingPlans,
        emptyInvoice: state.financeReducer.emptyInvoice,
        currentContact: state.contactReducer.currentContact,
        invoiceForm: state.showInvoiceForm,
    }
}

export default connect(mapStateToProps, { saveInvoice, recordInvoiceData, getEmptyInvoice, getBillingPlans, deactivateBillingPlan, requestManualInvoice, cancelBillingPlan })(RequestManualInvoice);
