import React, { useEffect, useRef } from "react";

const TranslatorDropdown = ({ newDataProfile }) => {
    const scriptLoadedRef = useRef(false);
    const previousSessionRef = useRef(null);

    const enableButtonTranslate = () => {
        const { featureFlags } = newDataProfile;

        if (!featureFlags || featureFlags === "") 
            return false;

        const features = featureFlags.split(",");
        // console.log("enableButtonTranslate", features.includes("enable_button_translate"));
        return features.includes("enable_button_translate");
    }

    const updateIncludedLanguages = () => {
        const { language } = newDataProfile;
        let allowedLanguages;
        if (language === "JPN") {
            allowedLanguages = "ja,en";
        } else if (language === "IND") {
            allowedLanguages = "id,en";
        } else {
            allowedLanguages = "ja,id,en";
        }
        return allowedLanguages;
    };

    const googleTranslateElementInit = () => {
        const allowedLanguages = updateIncludedLanguages();
        new window.google.translate.TranslateElement(
            {
                pageLanguage: "en",
                includedLanguages: allowedLanguages,
            },
            "google_translate_element"
        );
    };

    useEffect(() => {
        const sessionStatusChanged = previousSessionRef.current != JSON.stringify(newDataProfile);
        previousSessionRef.current = JSON.stringify(newDataProfile);

        if (sessionStatusChanged) {
            const dropdownContainer = document.getElementById("google-translate-script");
            if (dropdownContainer) {
                dropdownContainer.innerHTML = '';
                scriptLoadedRef.current = false;
            }
        }

        if (!scriptLoadedRef.current) {
            const addScript = document.createElement("script");
            addScript.setAttribute("id", "google-translate-script");
            addScript.setAttribute(
                "src",
                "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
            );
            document.body.appendChild(addScript);
            scriptLoadedRef.current = true;
        }
        window.googleTranslateElementInit = googleTranslateElementInit;
    }, [newDataProfile]);

    return (
        <div style={{display: enableButtonTranslate() ? "block" : "none",}}>
            <div id="google_translate_element"></div>
        </div>
    );
};

export default TranslatorDropdown;