import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CheckBox from 'react-bootstrap/FormCheckInput';
import ContactRow from './ContactRow.js';
import Label from 'react-bootstrap/FormLabel';
import ViewEditContact from './ViewEditContact.js';
import { getContactsByUser, showEditForm } from '../../../hooks/store/actions/contactActions.js';
import { hideNavbar, showNavbar } from '../../../hooks/store/actions/navBarActions';
import { getClientStatus} from '../../../hooks/store/actionConsts.js'; 

class ContactGrid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            includePast: document.location.href.includes("prospective=1") ? false : true,
            includeCurrent: document.location.href.includes("prospective=1") ? false : true,
            includeFuture: true,
            other: document.location.href.includes("prospective=1") ? false : true,            
            ascending: true
        };

        this.props.hideNavbar();
        this.props.getContactsByUser(this.props.logintoken, this.props.user.id);
        this.checkPotential = this.checkPotential.bind(this);
        this.checkActive = this.checkActive.bind(this);
        this.checkPast = this.checkPast.bind(this);
        this.checkOther = this.checkOther.bind(this);
        this.filterContacts = this.filterContacts.bind(this);
        this.sortContacts = this.sortContacts.bind(this);

//console.log(this.props.show);
//console.log(this.props.currentContact);

    }

    checkPotential = (e) => {
        this.setState({ 'includeFuture': e.target.checked });
    }
    checkActive = (e) => {
        this.setState({ 'includeCurrent': e.target.checked });
    }
    checkPast = (e) => {
        this.setState({ 'includePast': e.target.checked });
    }
    checkOther = (e) => {
        this.setState({ 'other': e.target.checked });
    }

    filterContacts() {
        var contacts = [];
        var c;
        if (this.props.contacts) {
            for (var i = 0; i < this.props.contacts.length; i++) {
                c = this.props.contacts[i];
                if (this.state.includeCurrent && (getClientStatus(c) === "Active" || getClientStatus(c) === "Paused")) {
                    contacts.push(c);
                }
                else if (this.state.includeFuture && (getClientStatus(c) === "Potential" || getClientStatus(c) === "Pending")) {
                    contacts.push(c);
                }
                else if (this.state.includePast && (getClientStatus(c) === "Past" || getClientStatus(c) === "Expired" || getClientStatus(c) === "Terminated")) {
                    contacts.push(c);
                }
                else if (this.state.other && getClientStatus(c) === "None") {
                    contacts.push(c);
                }
            }
        }
        if (this.state.sort === "lastName" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.lastName ? a.lastName : "";
                var bCompare = b.lastName ? b.lastName : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "lastName" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.lastName ? a.lastName : "";
                var bCompare = b.lastName ? b.lastName : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "firstName" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.firstName ? a.firstName : "";
                var bCompare = b.firstName ? b.firstName : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "firstName" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.firstName ? a.firstName : "";
                var bCompare = b.firstName ? b.firstName : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "phone" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.phone ? a.phone : "";
                var bCompare = b.phone ? b.phone : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "phone" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.phone ? a.phone : "";
                var bCompare = b.phone ? b.phone : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "clientStatus" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = getClientStatus(a) ? getClientStatus(a) : "";
                var bCompare = getClientStatus(b) ? getClientStatus(b) : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "clientStatus" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = getClientStatus(a) ? getClientStatus(a) : "";
                var bCompare = getClientStatus(b) ? getClientStatus(b) : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "paymentStatus" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.paymentStatus ? a.paymentStatus : "";
                var bCompare = b.paymentStatus ? b.paymentStatus : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "paymentStatus" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.paymentStatus ? a.paymentStatus : "";
                var bCompare = b.paymentStatus ? b.paymentStatus : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) {return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "email" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.email ? a.email : "";
                var bCompare = b.email ? b.email : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "email" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.email ? a.email : "";
                var bCompare = b.email ? b.email : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "source" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.contactType.name ? a.contactType.name : "";
                var bCompare = b.contactType.name ? b.contactType.name : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "source" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.contactType.name ? a.contactType.name : "";
                var bCompare = b.contactType.name ? b.contactType.name : "";
                if (aCompare.toLowerCase() > bCompare.toLowerCase()) { return -1 } else { return 1 }
            })
        }
        else if (this.state.sort === "contractEnd" && this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.expirationDate ? a.expirationDate : "01/01/0001";
                if (aCompare === "Ongoing") { "01/01/2099" } { aCompare }
                var bCompare = b.expirationDate ? b.expirationDate : "01/01/0001";
                if (bCompare === "Ongoing") { "01/01/2099" } { bCompare }
                if (Date.parse(aCompare) > Date.parse(bCompare)) { return 1 } else { return -1 }
            })
        }
        else if (this.state.sort === "contractEnd" && !this.state.ascending) {
            contacts = contacts.sort((a, b) => {
                var aCompare = a.expirationDate ? a.expirationDate : "01/01/0001";
                if (aCompare === "Ongoing") { "01/01/2099" } { aCompare }
                var bCompare = b.expirationDate ? b.expirationDate : "01/01/0001";
                if (bCompare === "Ongoing") { "01/01/2099" } { bCompare }
                if (Date.parse(aCompare) > Date.parse(bCompare)) { return -1 } else { return 1 }
            })
        }
        return contacts;
    }

    sortContacts(type) {
        if (this.state.sort != type && this.props.contacts) {
            this.setState({ sort: type, ascending: true })
        } else if (this.props.contacts) {
            this.setState({ sort: type, ascending: !this.state.ascending })
        }
    }

    render() {
        var contacts = this.filterContacts();

        return (
            <span className="contact-grid-form">

                {!this.props.show && (
                    <div className="contact-grid-header">
                        <div className={'grid-checkboxes viewEditNoTop'}>
                            <div className="headerContainerViewEdit">
                                <div className="checkboxHeaderLeftClient" />
                                <div className="headerTitleTextAgreement"><span>Select Contact Types to View</span></div>
                                <div className="checkboxheaderRightClient" />
                            </div>
                            <div className="filterColumnContactGrid"><CheckBox className="contactGridCheckbox" checked={this.state.includeCurrent} onChange={this.checkActive} />
                                <Label className="contactGridFilter">Active Client</Label></div>
                            <div className="filterColumnContactGrid"><CheckBox className="contactGridCheckbox" checked={this.state.includeFuture} onChange={this.checkPotential} />
                                <Label className="contactGridFilter">Potential Client</Label></div>
                            <div className="filterColumnContactGrid"><CheckBox className="contactGridCheckbox" checked={this.state.includePast} onChange={this.checkPast} />
                                <Label className="contactGridFilter">Past Client</Label></div>
                            <div className="filterColumnContactGrid"><CheckBox className="contactGridCheckbox" checked={this.state.other} onChange={this.checkOther} />
                                <Label className="contactGridFilter">Other</Label></div>
                        </div>
                    </div>
                )}
                {!this.props.show && (
                    <div className="table-responsive contactGridTableResponsive contactGridMobile" style={{ overflow: 'unset', whiteSpace: 'pre-line' }}>
                        <Row className="grid-row row-header contactRowMobile">
                            <Col onClick={() => this.sortContacts("clientStatus")} className="grid-header contactGridHead" xs={1} sm={1}>
                                <Label className="contactGridLabel" style={{ cursor: 'pointer' }}>CLIENT STATUS</Label>
                                {this.state.sort === "clientStatus" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> : 
                                this.state.sort === "clientStatus" && !this.state.ascending ? <span className="material-icons iconSort">expand_less</span> :
                                <span className="material-icons iconSort">chevron_right</span>}
                            </Col>
                            <Col onClick={() => this.sortContacts("firstName")} className="grid-header contactGridHead" xs={1} sm={2}>
                                <Label className="contactGridLabel" style={{ cursor: 'pointer' }}>FIRST NAME</Label>
                                {this.state.sort === "firstName" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> : 
                                this.state.sort === "firstName" && !this.state.ascending ? <span className="material-icons iconSort">expand_less</span> : 
                                <span className="material-icons iconSort">chevron_right</span>}
                            </Col>
                            <Col onClick={() => this.sortContacts("lastName")} className="grid-header contactGridHead" xs={1} sm={2}>
                                <Label className="contactGridLabel" style={{ cursor: 'pointer' }}>LAST NAME</Label>
                                {this.state.sort === "lastName" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> : 
                                this.state.sort === "lastName" && !this.state.ascending ? <span className="material-icons iconSort">expand_less</span> : 
                                <span className="material-icons iconSort">chevron_right</span>}
                            </Col>
                            <Col onClick={() => this.sortContacts("phone")} className="grid-header contactGridHead" xs={2} sm={1}>
                                <Label className="contactGridLabel" style={{ cursor: 'pointer' }}>PHONE</Label>
                                {this.state.sort === "phone" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> :
                                    this.state.sort === "phone" && !this.state.ascending ? <span className="material-icons iconSort">expand_less</span> :
                                        <span className="material-icons iconSort">chevron_right</span>}
                            </Col>
                            <Col onClick={() => this.sortContacts("email")} className="grid-header contactGridHead" xs={2} sm={2}>
                                <Label className="contactGridLabel" style={{ cursor: 'pointer' }}>EMAIL</Label>
                                {this.state.sort === "email" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> : 
                                this.state.sort === "email" && !this.state.ascending ? <span className="material-icons iconSort">expand_less</span> : 
                                <span className="material-icons iconSort">chevron_right</span>}
                            </Col>
                            <Col onClick={() => this.sortContacts("source")} className="grid-header contactGridHead" xs={1} sm={1}>
                                <Label className="contactGridLabel" style={{ cursor: 'pointer' }}>SOURCE</Label>
                                {this.state.sort === "source" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> : 
                                this.state.sort === "source" && !this.state.ascending ? <span className="material-icons iconSort">expand_less</span> : 
                                <span className="material-icons iconSort">chevron_right</span>}
                            </Col>
                            <Col onClick={() => this.sortContacts("paymentStatus")} className="grid-header contactGridHead" xs={1} sm={1}>
                                <Label className="contactGridLabel contactGridlabel" style={{ cursor: 'pointer', marginLeft: '-12px' }}>PAYMENT STATUS</Label>
                                {this.state.sort === "paymentStatus" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> : 
                                this.state.sort === "paymentStatus" && !this.state.ascending  ? <span className="material-icons iconSort">expand_less</span> : 
                                <span className="material-icons iconSort">chevron_right</span>}
                            </Col>
                            <Col onClick={() => this.sortContacts("contractEnd")} className="grid-header contactGridHead" xs={1} sm={1}>
                                <Label className="contactGridLabel" style={{ cursor: 'pointer', marginLeft: '-4px' }}>CONTRACT END</Label>
                                {this.state.sort === "contractEnd" && this.state.ascending ? <span className="material-icons iconSort">expand_more</span> : 
                                this.state.sort === "contractEnd" && !this.state.ascending ? <span className="material-icons iconSort">expand_less</span> : 
                                <span className="material-icons iconSort">chevron_right</span>}
                            </Col>
                            <Col className="grid-header contactGridHead" xs={1} sm={1}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Col>
                        </Row>
                        {this.props.contacts && (
                            <ContactRow contacts={contacts} />
                        )}
                    </div>
                )}
                {this.props.show && this.props.currentContact && (
                    <ViewEditContact show={true} currentContact={this.props.currentContact} />
                )}
            </span>
        );
    }
}


function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        contacts: state.contactReducer.contacts,
        currentContact: state.contactReducer.currentContact,
        show: state.contactReducer.showEditForm,
        showEditUserContact: state.contactReducer.showEditUserContact
    }
}

export default connect(mapStateToProps, { getContactsByUser, showEditForm, showNavbar, hideNavbar })(ContactGrid);
